import * as Yup from 'yup';

const validationSchema = languageSchema => Yup.object().shape({
	country: Yup.string()
		.required(languageSchema.validation.chooseField('countryRequired', languageSchema.Cities)),

	city: Yup.string()
		.trim()
		.min(1, languageSchema.validation.outOfBounds('city', languageSchema.Cities, 1, 30))
		.max(30, languageSchema.validation.outOfBounds('city', languageSchema.Cities, 1, 30))
		.required(languageSchema.validation.required()),
});

export default validationSchema;
