import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Input, Button } from 'antd';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import 'antd/dist/antd.css';

import request from 'helpers/request';
import { logInAsUser, setCurrentUserDetails } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { getUserName } from 'helpers/common';
import { ROLES_MAP } from 'utils/constants';

const PAGE_SIZE = 100;
const Search = Input.Search;

function List(props) {
	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);

	let { languageSchema } = props;

	useEffect(() => {
		fetchUsers(1);
	}, []);

	const columns = [{
		title: languageSchema.User.email,
		dataIndex: 'email',
	},
	{
		title: languageSchema.User.name,
		dataIndex: 'name',
		render: (text, record) => (
			<span>{getUserName(record)}</span>
		),
	},
	{
		title: languageSchema.User.accountType,
		dataIndex: 'accountType',
	},
	{
		title: languageSchema.User.phone,
		dataIndex: 'phone',
	},
	{
		title: languageSchema.User.role,
		dataIndex: 'role',
		render: (text, record) => (
			<span>{record.role}</span>
		),
	},
	{
		title: '',
		dataIndex: '_id',
		render: (text, record) => (
			<>
				<Link to={`/users/${record._id}/contracts`}
					className="ant-tag ant-tag-cyan" >
					{languageSchema.User.contracts}
				</Link>
				{' '}
				<Link to={`/users/${record._id}`} className="ant-tag ant-tag-blue">
					{languageSchema.Buttons.edit}
				</Link>
				{' '}
				<Button size='small' type="primary" onClick={() => becomeOrdinaryUser(record)}>{languageSchema.User.loginAsUser}</Button>
			</>
		),
	},
	];

	const becomeOrdinaryUser = async (user) => {
		await props.logInAsUser(user._id);
		await props.setCurrentUserDetails();

		if (user.role === ROLES_MAP.client) {
			props.history.push('/my-boxes');
		} else {
			props.history.push('/stocks');
		}
	};

	const handlePagination = page => {
		_.isEmpty(search) ? fetchUsers(page) : fetchUsersBySearch(page, search);
		setCurrentPage(page);
	};

	const onSearch = value => {
		setSearch(value);
		fetchUsersBySearch(1, value);
		setCurrentPage(1);
	};

	const fetchUsers = async (page) => {
		setLoading(true);
		const users = await request('GET', `users?page=${page}`);
		setUsers(users);
		setLoading(false);
	};

	const fetchUsersBySearch = async (page, search = '') => {
		setLoading(true);
		const users = await request('GET', `users/search?page=${page}&search=${search}`);
		setUsers(users);
		setLoading(false);
	};

	return (
		<>
			<div className='card'>
				<Row>
					<Col>
						<Link to="/users/add" className="btn btn-success mr-2">
							{languageSchema.User.addAccount}
						</Link>
					</Col>
					<Col md="auto"></Col>
					<Col xs lg="2">
						<Search placeholder="Email search" onSearch={onSearch} allowClear />
					</Col>
				</Row>

				<Table className="table-responsive"
					loading={loading}
					columns={columns}
					dataSource={users.filter(({ isDeleted }) => !isDeleted)}
					rowKey='_id'
					pagination={{
						pageSize: PAGE_SIZE,
						current: currentPage,
						onChange: (page) => {
							handlePagination(page);
						},
					}}>
				</Table>
			</div>
		</>
	);
}

let mapStateToProps = ({ common }) => {
	return {
		languageSchema: getLanguageSchema(common.language)
	};
};

let mapDispatchToProps = {
	logInAsUser,
	setCurrentUserDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
