import _ from 'lodash';

const initialState = {
	data: [],
	isFetched: false,
	current: null,
	boxHistory: [],
	isMapDragging: false
};

export default function reducer(state = initialState, action) {

	let boxes;

	switch (action.type) {
	case 'MAP_IS_DRAGGING':
		return { ...state, isMapDragging: action.payload.isDragging };

	case 'FETCH_BOXES':
		return { ...state, data: action.payload.sort((a, b) => a.size - b.size), isFetched: true };

	case 'FETCH_BOX':
		return { ...state, current: action.payload };

	case 'FETCH_BOX_HISTORY':
		return { ...state, boxHistory: action.payload };

	case 'CLEAR_CURRENT_BOX':
		return { ...state, current: null, boxHistory: [] };

	case 'CLEAR_CURRENT_BOXES':
		return { ...state, data: [] };

	case 'CREATE_BOX':
		return { ...state, data: [...state.data, action.payload] };

	case 'UPDATE_BOX':
		boxes = state.data.map(box => (box._id === action.payload._id) ? { ...box, ...action.payload } : box);
		return { ...state, data: boxes };

	case 'BOX_OPENED': {
		let { boxId } = action.payload;

		let indexOfBoxToUpdate = _.findIndex(
			state.data,
			({ _id }) => _id === boxId
		);

		let newBoxesArray = [...state.data];

		if (newBoxesArray[indexOfBoxToUpdate]) {
			newBoxesArray[indexOfBoxToUpdate].lockStatus = 'opened';
		}

		return {
			...state,
			data: newBoxesArray,
		};
	}


	case 'BOX_CLOSED': {
		let { boxId } = action.payload;

		let indexOfBoxToUpdate = _.findIndex(
			state.data,
			({ _id }) => _id === boxId
		);

		let newBoxesArray = [...state.data];
		newBoxesArray[indexOfBoxToUpdate].lockStatus = 'closed';

		return {
			...state,
			data: newBoxesArray,
		};
	}


	case 'DELETE_BOX':
		boxes = state.data.filter(box => box._id !== action.payload);
		return { ...state, data: boxes };

	default:
		return state;
	}

}
