import React, {Component} from 'react';

import SVGRendererWithZoom from './SVGRendererWithZoom';

import {Text, Path, Rect, Circle, Image, RectWithText, PathWithText} from './objects';

class Preview extends Component {
	static defaultProps = {
		objectTypes: {
			'text': Text,
			'rectangle': Rect,
			'circle': Circle,
			'polygon': Path,
			'image': Image,
			'rectangle-with-text': RectWithText,
			'polygon-with-text': PathWithText,
		}
	};

	UNSAFE_componentWillMount() {
		this.objectRefs = {};
	}

	render() {
		let {width, height, objects, objectTypes, responsive = false, selectedBoxUnitNumber} = this.props;
		//
		// let style = {
		// 	...styles.container,
		// 	...this.props.style,
		// 	width: responsive ? '100%' : width,
		// 	height: responsive ? '100%' : height,
		// 	padding: 0
		// };

		let canvas = {
			width: '100%' || responsive ? '100%' : width,
			height: '100%' || responsive ? '100%' : height,
			canvasWidth: responsive ? '100%' : width,
			canvasHeight: responsive ? '100%' : height
		};

		if (responsive) {
			objects = objects.map(object => ({
				...object,
				width: (object.width / width) * 100 + '%',
				height: (object.height / height) * 100 + '%',
				x: (object.x / width)*100 + '%',
				y: (object.y / height)*100 + '%',
			}));
		}

		return (
			<div>
				<SVGRendererWithZoom
					objects={objects}
					objectRefs={this.objectRefs}
					objectTypes={objectTypes}
					onRender={(ref) => this.svgElement = ref}
					canvas={canvas}
					setViewers={this.props.setViewers}
					viewers={this.props.viewers}
					mapName={this.props.mapName}
					onTouchEnd={this.props.onTouchEnd}
					onTouchStart={this.props.onTouchStart}
					selectedBoxUnitNumber={selectedBoxUnitNumber}
					selectedSizeCodes={this.props.selectedSizeCodes}
				/>
			</div>
		);
	}
}

export default Preview;
