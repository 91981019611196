import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';

import { getLanguageSchema } from 'dashboard-commons/languages';
import Table from 'components/UI/Table';
import BtnWithModal from 'components/Notification/BtnWithModal';
import NoItems from 'components/common/NoItems';
import request from 'helpers/request';

let NotificationList = (props) => {
	const ACTIVE_STATUSES = ['all', 'active', 'disactive'];
	const GLOBAL_STATUSES = ['all', 'global', 'local'];

	let { languageSchema } = props;

	let [activeStatus, setActiveStatus] = useState({
		label: languageSchema.Notification.all,
		value: 'all',
	});
	let [globalStatus, setGlobalStatus] = useState({
		label: languageSchema.Notification.all,
		value: 'all',
	});
	let [notifications, setNotifications] = useState([]);

	let fetchNotificationsToState = async () => {
		try {
			let fetchedNotifications = await request('GET', `notifications?activeStatus=${activeStatus.value}&globalStatus=${globalStatus.value}`);

			setNotifications(fetchedNotifications);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		try {
			fetchNotificationsToState();
		} catch (e) {
			console.error(e);
		}
	}, [activeStatus, globalStatus]);

	let getStatusOptions = (statuses) => statuses.map(status => ({
		label: languageSchema.Notification[status],
		value: status
	}));

	let onFilterChange = (type) => (option) => {
		let filterTypesMap = {
			activeStatus: setActiveStatus,
			globalStatus: setGlobalStatus
		};
		let filterHandler = filterTypesMap[type];

		filterHandler(option);
	};

	let onCreateNotification = useCallback(async (data) => {
		let createdNotification = await request('POST', 'notifications', data);

		setNotifications([...notifications, createdNotification]);
	}, [notifications]);

	let onUpdateNotification = useCallback(async (id, data) => {
		let updatedNotification = await request('PUT', `notifications/${id}`, data);

		let updatedNotifications = [...notifications];
		let index = updatedNotifications.findIndex(notification => notification._id === updatedNotification._id);
		updatedNotifications[index] = updatedNotification;

		setNotifications(updatedNotifications);
	}, [notifications]);

	let existIcon = <i className="far fa-check-circle green"></i>;
	let unexistIcon = <i className="far fa-times-circle red"></i>;

	return (
		<div className="card">
			<div className="form-row">
				<div className="col">
					<BtnWithModal
						action="create"
						submitCallback={onCreateNotification}
					/>
				</div>

				<div className="col-md-3">
					<Select
						options={getStatusOptions(ACTIVE_STATUSES)}
						value={activeStatus}
						onChange={onFilterChange('activeStatus')}
						placeholder={languageSchema.Notification.selectActiveStatus}
						name="activeStatus"
					/>
				</div>

				<div className="col-md-3">
					<Select
						options={getStatusOptions(GLOBAL_STATUSES)}
						value={globalStatus}
						onChange={onFilterChange('globalStatus')}
						placeholder={languageSchema.Notification.selectGlobalStatus}
						name="globalStatus"
					/>
				</div>
			</div>
			<div className="mb-3">
				<Table header={[
					'#',
					languageSchema.Notification.type,
					languageSchema.Notification.title,
					languageSchema.Notification.description,
					languageSchema.Notification.isActive,
					languageSchema.Notification.forAllUsers,
					languageSchema.Notification.editor,
					''
				]} centerHeader={true}>
					{
						notifications.length ?
							notifications.map((notification, idx) => (
								<tr key={notification._id} height="100">
									<td className="h5 text-center">{idx + 1}</td>
									<td className="text-center">
										{notification.type}
									</td>
									<td className="text-center overflow-notification-cell">
										<p>
											{notification.titleDE}
										</p>
										<p>
											{notification.titleEN}
										</p>
									</td>
									<td className="text-center overflow-notification-cell">
										<p>
											{notification.descriptionDE}
										</p>
										<p>
											{notification.descriptionEN}
										</p>
									</td>
									<td className="text-center">{notification.isActive ? existIcon : unexistIcon}</td>
									<td className="text-center">{notification.isGlobal ? existIcon : unexistIcon}</td>
									<td className="text-center">
										<Link
											to={`/users/${notification.userId._id}`}
											className="btn btn-sm btn-outline-secondary"
										>
											{notification.userId.firstName} {notification.userId.lastName}
										</Link>
									</td>
									<td className="text-center">
										<BtnWithModal
											action="edit"
											initialValues={notification}
											submitCallback={onUpdateNotification}
										/>
									</td>
								</tr>
							)) :
							<tr>
								<td colSpan={8}>
									<NoItems isLogo={false} />
								</td>
							</tr>
					}
				</Table>
			</div>
		</div >
	);
};

let mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
});

export default connect(mapStateToProps)(NotificationList);
