import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import StockForm from 'components/Stock/Form';
import Heading from 'components/UI/Heading';
import { createStock, fetchCities } from 'redux/actions';
import { timeout } from 'utils/functions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { ROLES_MAP } from 'utils/constants';
import fields from './fields';
import validationSchema from './validation';

class StockFormAdd extends React.Component {
	state = {
		isSuccess: false,
	}

	componentDidMount() {
		const {
			isFetchedCities,
			fetchCities
		} = this.props;

		if (!isFetchedCities) {
			fetchCities();
		}
	}

	handleSubmit = async (values, { setSubmitting }) => {
		try {
			await this.props.createStock(values);
			this.setState({ isSuccess: true }, async () => {
				await timeout(2000);
				this.setState({ isSuccess: false });
			});
		} finally {
			setSubmitting(false);
		}
	}

	render() {
		const { cities, languageSchema, language, role } = this.props;
		const { isSuccess } = this.state;
		let isSuperUser = role === ROLES_MAP.superUser;

		return (
			<div className="row py-3">
				<div className="col-md-8 offset-md-2 card">
					<Heading>{languageSchema.Stock.createStock}</Heading>

					<hr />

					<Formik
						initialValues={fields.props}
						onSubmit={this.handleSubmit}
						validationSchema={validationSchema(languageSchema)}
						render={props =>
							<StockForm
								type="create"
								cities={cities}
								isSuccess={isSuccess}
								{...props}
								languageSchema={languageSchema}
								language={language}
								isSuperUser={isSuperUser}
							/>
						}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ cities, common, auth }) => {
	return {
		role: auth.me.role,
		cities: cities.data,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
	};
};
export default connect(mapStateToProps, { createStock, fetchCities })(StockFormAdd);
