import React from 'react';
import { connect } from 'react-redux';

import BoxHistory from 'components/Box/History';
import Heading from 'components/UI/Heading';
import { fetchStocks, fetchStockHistory } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';

class StockHistory extends React.Component {
	componentDidMount() {
		const {
			isFetchedStocks,
			stockId,
		} = this.props;

		if (!isFetchedStocks) {
			this.props.fetchStocks();
		}

		this.props.fetchStockHistory(stockId);
	}

	render() {
		const { stock, history, languageSchema } = this.props;

		return (
			<div className="row py-3">
				<div className="col-md-8 offset-md-2">
					<div className="card">
						<Heading>{languageSchema.Stock.stockHistory} {stock.address}</Heading>
						<BoxHistory history={history} type="stock" languageSchema={languageSchema}/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ stocks, common }, props) => {
	const currentId = props.match.params.id;
	const currentStock = stocks.data.find(stock => stock._id === currentId);

	return {
		stock: currentStock || {},
		stockId: currentId,
		isFetchedStocks: stocks.isFetched,
		history: stocks.history,
		languageSchema: getLanguageSchema(common.language),
	};
};
export default connect(mapStateToProps, { fetchStocks, fetchStockHistory } )(StockHistory);
