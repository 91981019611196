import request from 'helpers/request';

const endpoint = 'stocks';

export const fetchStocks = () => async (dispatch) => {
	const stocks = await request('GET', endpoint);
	dispatch({ type: 'FETCH_STOCKS', payload: stocks });
};

export const fetchStockHistory = stockId => async (dispatch) => {
	const stockHistory = await request('GET', `${endpoint}/${stockId}/history`);
	dispatch({ type: 'FETCH_STOCK_HISTORY', payload: stockHistory });
};

export const createStock = stock => async (dispatch) => {
	const createdStock = await request('POST', endpoint, stock);
	dispatch({ type: 'CREATE_STOCK', payload: createdStock });
};

export const updateStock = stock => async (dispatch) => {
	const updatedStock = await request('PUT', `${endpoint}/${stock._id}`, stock);
	dispatch({ type: 'UPDATE_STOCK', payload: updatedStock });
};

export const deleteStock = stockId => async (dispatch) => {
	await request('DELETE', `${endpoint}/${stockId}`);
	dispatch({ type: 'DELETE_STOCK', payload: stockId });
};

export const openStockEquipment = (stockId, equipmentId) => async (dispatch) => {
	await request('PUT', `${endpoint}/${stockId}/open`, { locationId: equipmentId });
	dispatch({ type: 'OPEN_STOCK_EQUIPMENT', payload: equipmentId });
};
