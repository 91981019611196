export function generateMonthsWord(monthsCount, language) {
	let schemas = {
		DE: () => {
			if (monthsCount === 1) {
				return 'Monat';
			}

			return 'Monate';
		},

		EN: () => {
			if (monthsCount === 1) {
				return 'month';
			}

			return 'months';
		},

		RU: () => {
			if (monthsCount === 1) {
				return 'месяц';
			}

			if ([2, 3, 4].includes(monthsCount)) {
				return 'месяца';
			}

			return 'месяцев';
		}
	};

	let result = schemas[language]();

	return result;
}
