import { DEFAULT_APP_LANGUGAE } from 'utils/constants';

const initialState = {
	loadings: 0,
	language: DEFAULT_APP_LANGUGAE,
	error: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
	case 'START_LOADING':
		return { ...state, loadings: state.loadings + 1 };
	case 'END_LOADING':
		return { ...state, loadings: state.loadings - 1 };
	case 'ERROR':
		return { ...state, error: action.payload.error };
	case 'SET_LANGUAGE':
		return { ...state, language: action.payload.language };
	default:
		return state;
	}
}
