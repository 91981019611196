import request from 'helpers/request';

const endpoint = 'cities';

export const fetchCities = () => async (dispatch) => {
	const cities = await request('GET', endpoint);
	dispatch({ type: 'FETCH_CITIES', payload: cities });
};

export const createCity = city => async (dispatch) => {
	const createdCity = await request('POST', endpoint, city);
	dispatch({ type: 'CREATE_CITY', payload: createdCity });
};

export const updateCity = city => async (dispatch) => {
	const updatedCity = await request('PUT', `${endpoint}/${city._id}`, city);
	dispatch({ type: 'UPDATE_CITY', payload: updatedCity });
};

export const deleteCity = cityId => async (dispatch) => {
	await request('DELETE', `${endpoint}/${cityId}`);
	dispatch({ type: 'DELETE_CITY', payload: cityId });
};