import React, { Component } from 'react';
import Heading from 'components/UI/Heading';
import request from 'helpers/request';

export default class VATSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			legalUsersVATRate: props.stock.legalUsersVATRate || 0,
			individualUsersVATRate: props.stock.individualUsersVATRate || 0,
			isLoading: false,
		};
	}

	saveVATForm = async () => {
		this.setState({ isLoading: true });
		try {
			await request('PUT', `stocks/${this.props.stock._id}`, {
				legalUsersVATRate: this.state.legalUsersVATRate,
				individualUsersVATRate: this.state.individualUsersVATRate,
			});

			this.setState({ isLoading: false });
		} catch(error) {
			this.setState({ isLoading: false });
		}
	}

	handleChangeField = (fieldName) => (e) => this.setState({ [fieldName]: e.target.value});

	render() {
		let { languageSchema, isSuperUser } = this.props;
		return (
			<div className="row">
				<div className="col-md-12">
					<Heading>{languageSchema.Stock.vat}</Heading>

					<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
						<label htmlFor="individualUsersVATRate" className="col-sm-5 col-form-label">
							{languageSchema.Stock.individualUsersVATRate}:
						</label>
						<div className="col-sm-5">
							<div className="input-group">
								<input
									name="individualUsersVATRate"
									type="number"
									className="form-control"
									value={this.state.individualUsersVATRate}
									disabled={!isSuperUser}
									onChange={this.handleChangeField('individualUsersVATRate')}
								/>
								<div className="input-group-append">
									<span className="input-group-text">
										%
									</span>
								</div>
							</div>
						</div>
					</div>

					<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
						<label htmlFor="legalUsersVATRate" className="col-sm-5 col-form-label">
							{languageSchema.Stock.legalUsersVATRate}:
						</label>
						<div className="col-sm-5">
							<div className="input-group">
								<input
									name="legalUsersVATRate"
									type="number"
									className="form-control"
									value={this.state.legalUsersVATRate}
									disabled={!isSuperUser}
									onChange={this.handleChangeField('legalUsersVATRate')}
								/>
								<div className="input-group-append">
									<span className="input-group-text">
										%
									</span>
								</div>
							</div>
						</div>
					</div>

					<div style={{ textAlign: 'center' }}>
						<button
							className="btn btn-success"
							disabled={!isSuperUser}
							onClick={this.saveVATForm}
						>
							{this.state.isLoading && <span className="spinner-border spinner-border-sm"> </span>}
							<span> {languageSchema.Buttons.apply} </span>
						</button>
					</div>

				</div>
			</div>
		);
	}
}
