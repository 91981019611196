export default {
	contractDetails: 'Vertragsdetails',
	invoices: 'Rechnungen',
	contractNumber: 'Vertragsnummer',
	contractStatus: 'Vertragsstatus',
	userContracts: 'Verträge des Benutzers',
	attention: 'Achtung',
	closingContractConition: 'Der Vertrag kann nur dann gekündigt werden, wenn der Kunde den Lagerraum bereitstellt.',
	square: 'Fläche',
	period: 'Dauer',
	startDate: 'Startdatum',
	closingDate: 'Vertragsablaufdatum',
	paidInDays: 'bezahlt für Tage',
	paymentsFrequency: 'Zahlungen/Monat | mon.',
	monthlyCost: 'Monatspreis',
	periodCost: 'Für den Zeitraum',
	planningLeavingDate: 'geplantes Enddatum',
	from: 'vom',
	to: 'bis zum',
	simple: 'gewöhnlich',
	discount: 'Rabatt',
	all: 'Alle',
	opened: 'Ist offen',
	closed: 'Geschlossen',
	amount: 'Summe',
	debt: 'Schuld',
	vat: 'MwSt.',
	accountingDate: 'Abrechnungsdatum',
	documentDate: 'Datum des Dokuments',
	paymentDate: 'Zahlungsdatum',
	comment: 'Kommentar',
	withDiscount: 'mit Rabatt',
	inclVAT: 'Inkl. MwSt.',
	areYouSureYouWantToCloseContract: 'Sind Sie sicher, dass Sie den Vertrag beenden möchten? ',
	contractEndingDate: 'Enddatum',
	endingContracts: 'In Kürze auslaufende Verträge',
	didNotNotified: 'Der Kunde hat die Abreise nicht mitgeteilt',
	invoiceIsPaid: 'Die Rechnung ist bezahlt',
	sentInvoiceEmailNotification: 'E-Mail senden',
	subscriptions: 'Abonnements',
	transactions: 'Transaktionen',
	date: 'Datum',
	unitAccess: 'Zugang zur Einheit',
	boxsize: 'Boxgröße',
	download: 'Öffnen',
	insuranceCoverageAmount: 'Versicherungssumme',
	insuranceAmount: 'Versicherungsbetrag',
	regularPayment: 'Reguläre Monatsmiete',
	netto: 'netto',
	brutto: 'brutto'
};
