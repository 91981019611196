import React, { Component } from 'react';
import Preview from 'components/common/Designer/Preview';
import { Rect, Path, Circle, RectWithText, PathWithText } from 'components/common/Designer';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Tabs, Popover } from 'antd';
import { displayAmount, round } from 'helpers/amount';

import './Map.style.scss';

const { TabPane } = Tabs;
const redFillColor = '#D9ADAD';
const redStrokeColor = '#D75656';
const greenFillColor = '#3CAB26';
const greenStrokeColor = '#137400';
const freeBoxesFillColor = '#FED53047';
const freeBoxesStrokeColor = '#FED530';

// TODO: refactor to functional component
export default class MapConstructor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			objects: [],
			activeKey: undefined, // undefined is needed to set default item in Tabs component
		};
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (this.props.selectedBoxUnitNumber !== newProps.selectedBoxUnitNumber) {
			let boxTabPane = Object.keys(this.props.objects).find(tab =>
				this.props.objects[tab]
					.find(el => el.text === newProps.selectedBoxUnitNumber)
			);

			// change tab and then scroll
			// this.setState({ activeKey: boxTabPane }, () => {
			//
			// 	// this.props.viewer.reset();
			// 	// let itemOnMapElement = document.getElementById(`map_${newProps.selectedBoxUnitNumber}`);
			// 	//
			// 	// if (itemOnMapElement) {
			// 	// 	console.log('itemOnMapElement', itemOnMapElement);
			// 	// 	itemOnMapElement.scrollIntoView({
			// 	// 		block: 'center',
			// 	// 		inline: 'center',
			// 	// 		behavior: 'smooth'
			// 	// 	});
			// 	// }
			// });
			//
			this.setState({ activeKey: boxTabPane }, () => {
				if (this.props.viewers) {
					let object = this.props.objects[newProps.selectedBoxUnitNumber.split('-')[0]] &&
						this.props.objects[newProps.selectedBoxUnitNumber.split('-')[0]]
							.find(el => el.text === newProps.selectedBoxUnitNumber);

					setTimeout(() => {
						if (this.props.viewers[newProps.selectedBoxUnitNumber.split('-')[0]]) {
							this.props.viewers[newProps.selectedBoxUnitNumber.split('-')[0]].reset();
							this.props.viewers[newProps.selectedBoxUnitNumber.split('-')[0]].setPointOnViewerCenter(object.x, object.y, 1);
						}
					}, 300);

				}
			});
		}
	}

	render() {
		return (
			<div className="mb-5">
				{
					!_.isEmpty(this.props.objects) &&
					<div className="mt-2">
						<Tabs
							defaultActiveKey="1"
							activeKey={this.state.activeKey}
							type="card"
							size='small'
							onChange={(key) => this.setState({ activeKey: key })}
						>
							{Object.keys(this.props.objects).map((id) => {
								return (
									<TabPane key={id} tab={id}>
										<div className="map_wrapper mt-2">
											<Preview
												objects={this.props.objects[id]}
												height={800}
												width={1600}
												objectTypes={{
													'rectangle': Rect,
													'polygon': Path,
													circle: Circle,
													'rectangle-with-text': (props) => getRectangleObject({ ...props, ...this.props }),
													'polygon-with-text': (props) => getPolygonObject({ ...props, ...this.props })
												}}
												selectedBoxUnitNumber={this.props.selectedBoxUnitNumber}
												setViewers={this.props.setViewers}
												viewers={this.props.viewers}
												mapName={id}
												onTouchStart={this.props.onTouchStart}
												onTouchEnd={this.props.onTouchEnd}
												selectedSizeCodes={this.props.selectedSizeCodes}
											/>
										</div>
									</TabPane>
								);
							})}
						</Tabs>
					</div>
				}
				<div className="flex flex-inline flex-center flex-wrap">
					<div className="legenda mt-2">
						<BoxLegenda
							fill={greenFillColor}
							stroke={greenStrokeColor}
						/>
						<span className="legenda-text">
							{this.props.freeBoxesOfTheRightSize}
						</span>
					</div>

					<div className="legenda mt-2">
						<BoxLegenda
							fill={redFillColor}
							stroke={redStrokeColor}
						/>
						<span className="legenda-text">
							{this.props.occupiedBoxes}
						</span>
					</div>

					<div className="legenda mt-2">
						<BoxLegenda
							fill={freeBoxesFillColor}
							stroke={freeBoxesStrokeColor}
						/>
						<span className="legenda-text">
							{this.props.yourChoice}
						</span>
					</div>

					<div className="legenda mt-2">
						<BoxLegenda
							fill="lightgrey"
							stroke="#646464"
						/>
						<span className="legenda-text">
							{this.props.languageSchema.BoxBooking.boxesFromAnotherCategory}
						</span>
					</div>
				</div>
			</div>
		);
	}
}

function getRectangleObject(props) {
	let mapStateToProps = state => {
		return {
			isMapDragging: state.boxes.isMapDragging,
		};
	};

	let ReduxConnectedComponent = connect(mapStateToProps)(BoxOfRectangleForm);

	return (
		<ReduxConnectedComponent
			{..._.omit(props, ['MapName', 'Mapid', 'step3SelectABoxOnTheMap'])}
			handleClick={props.selectBox}
			viewMode={props.viewMode}
		/>
	);
}


function getPolygonObject(props) {
	return (
		<BoxOfPolygonForm
			{..._.omit(props, ['MapName', 'Mapid', 'step3SelectABoxOnTheMap'])}
			handleClick={props.selectBox}
		/>
	);
}

class BoxOfRectangleForm extends RectWithText {
	onElementClick = (box) => () => {
		this.props.handleClick(box)();

		let boxesListItem = document.getElementById(box.UnitNumber);

		// scroll in boxes list
		setTimeout(
			() => {
				if (boxesListItem) {
					boxesListItem.scrollIntoView({
						block: 'center',
						behavior: 'auto'
					});
				}
			},
			600
		);
	};

	render() {
		let { object, boxes, languageSchema } = this.props;

		let {
			rotate,
			textColor,
			stroke: defaultStroke,
			fill: defaultFill,
			strokeWidth,
			radius,
			...restOfAttributes
		} = this.getObjectAttributes();

		let box = boxes.find(box => box.UnitNumber === object.text);

		if (!box) {
			return null;
		}

		let isBusy = box.IsOccupied || Boolean(box.IsReserved);
		let isBoxCantBeSelected = !this.props.selectedSizeCodes.includes(Number(box.PhysicalSize));
		let isSelected = this.props.selectedBoxUnitNumber === box.UnitNumber;

		const getColor = () => {
			if (isBusy) {
				return { fill: '#D9ADAD', stroke: '#D75656' };
			}

			if (!box || isBoxCantBeSelected) {
				return { stroke: defaultStroke, fill: defaultFill };
			}

			if (isSelected) {
				return { fill: '#FED53047', stroke: '#FED530' };
			}

			return { fill: '#3CAB26', stroke: '#137400' };
		};

		const { fill, stroke } = getColor();

		const content=(box) => (
			<ul className="list-unstyled">
				<li><b>{languageSchema.BoxBooking.box}:</b> {box.UnitNumber}</li>
				<li><b>{languageSchema.Stock.monthRate}:</b> {displayAmount(round(box.MonthRate + box.MonthRate * 20 / 100), 'RUB')}</li>
				{
					(Boolean(box.Height) ||
					Boolean(box.Width) ||
					Boolean(box.Depth)) &&
					<li>
						{
							Boolean(box.Height) &&
							<><b>{languageSchema.Stock.height}:</b> {box.Height}{languageSchema.Etc.metersShortLetter}{'  '}</>
						}
						{
							Boolean(box.Width) &&
							<><b>{languageSchema.Stock.width}:</b> {box.Width}{languageSchema.Etc.metersShortLetter}{'  '}</>
						}
						{
							Boolean(box.Depth) &&
							<><b>{languageSchema.Stock.depth}:</b> {box.Depth}{languageSchema.Etc.metersShortLetter}</>
						}
					</li>
				}
				{
					Boolean(box.Width) &&
					Boolean(box.Depth) &&
					<li>
						<b>{languageSchema.Stock.square}:</b> {(box.Width * box.Depth).toFixed(2)} {languageSchema.Etc.metersShortLetter}²
					</li>
				}
			</ul>
		);

		let isCubicBox = box && box.UnitNumber && box.UnitNumber.includes('KB');

		let svgAttributes = _.pick(restOfAttributes, ['blendMode', 'fontFamily', 'fontSize', 'fontStyle', 'fontWeight', 'height', 'text', 'textDecoration', 'transform', 'width', 'x', 'y']);

		return (
			<Popover content={content(box)}>
				<g>
					<rect
						style={{ ...this.getStyle(), cursor: (isBusy || isBoxCantBeSelected) ? 'default' : 'pointer' }}
						{...svgAttributes}
						rx={object.radius}
						width={object.width}
						height={object.height}
						rotate={rotate}
						stroke={stroke}
						fill={fill}
						strokeWidth={strokeWidth}
						radius={radius}
						onClick={(isBoxCantBeSelected || isBusy) ? () => {} : this.onElementClick(box)}
						onTouchEnd={() => {
							if(!isBoxCantBeSelected && !isBusy && !this.props.isMapDragging) {
								this.onElementClick(box)();
							}
						}}
						id={`map_${box.UnitNumber}`}
					/>

					<text style={{ ...this.getStyle(), cursor: (isBusy || isBoxCantBeSelected) ? 'default' : 'pointer' }}
						{...svgAttributes}
						textAnchor="right"
						fontSize={object.fontSize}
						fontFamily={object.fontFamily}
						fill={textColor}
						{...this.setTextToCenter()}
						onClick={(isBoxCantBeSelected || isBusy) ? () => {} : this.onElementClick(box)}
						onTouchEnd={() => {
							if(!isBoxCantBeSelected && !isBusy && !this.props.isMapDragging) {
								this.onElementClick(box)();
							}
						}}
					>
						{object.text}
					</text>
					{
						box.PhysicalSize &&
						<text
							style={{ ...this.getStyle(), cursor: (isBusy || isBoxCantBeSelected) ? 'default' : 'pointer'}}
							{...svgAttributes}
							textAnchor="right"
							fontSize={String(Number(object.fontSize) - 2)}
							fontFamily={object.fontFamily}
							fill={textColor}
							{...this.setTextToCenter()}
							y={this.setTextToCenter().y + (14 <= Number(object.fontSize) ? 14 : 10)}
							onClick={(isBoxCantBeSelected || isBusy) ? () => {} : this.onElementClick(box)}
							onTouchEnd={() => {
								if(!isBoxCantBeSelected && !isBusy && !this.props.isMapDragging) {
									this.onElementClick(box)();
								}
							}}
						>
							{box.PhysicalSize} {languageSchema.Etc.metersShortLetter}<tspan dy="-3" fontSize=".7em">{isCubicBox ? 3 : 2}</tspan>
						</text>
					}

				</g>
			</Popover>

		);
	}
}

class BoxOfPolygonForm extends PathWithText {
	render() {
		let {object} = this.props;
		let fill = object.closed ? object.fill : 'transparent';

		let {
			textColor,
			//eslint-disable-next-line
			rotate,

			//eslint-disable-next-line
			stroke,
			//eslint-disable-next-line
			strokeWidth,
			//eslint-disable-next-line
			radius,
			...restOfAttributes
		} = this.getObjectAttributes();

		// WARN: this item can have own props, need to check it
		let svgAttributes = _.pick(restOfAttributes, ['fontFamily', 'fontSize', 'fontStyle', 'fontWeight', 'height', 'text', 'textDecoration', 'transform', 'width', 'x', 'y']);

		return (
			<g>
				<path
					style={this.getStyle(object)}
					{...this.getObjectAttributes()}
					d={this.buildPath(object)}
					fill={fill}
					onClick={this.props.handleClick}
					onTouchEnd={this.props.handleClick}
				/>
				<text style={this.getStyle()}
					{...svgAttributes}
					textAnchor="right"
					fontSize={object.fontSize}
					fontFamily={object.fontFamily}
					fill={textColor}
					{...this.setTextToCenter()}
				>
					{object.text}
				</text>
			</g>
		);
	}
}


function BoxLegenda(props) {
	return (
		<svg width={20} height={20} viewBox={'0 0 32 32'} fill={'none'}>
			<rect
				x={1}
				y={1}
				width={26}
				height={26}
				rx={5}
				fill={props.fill}
				stroke={props.stroke}
				strokeWidth={2}
			/>
		</svg>
	);
}
