import React from 'react';
import countryList from 'react-select-country-list';
import countries from 'i18n-iso-countries';
import _ from 'lodash';
import { Form, Select } from 'antd';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

export default (props) => {
	let {languageSchema, name, id, rules} = props;
	let language = props.language.toLowerCase();

	let userRoleSelectOptions = [
		{
			value: 'RU',
			label: countries.getName('RU', language),
		},
		..._.uniqBy(countryList().getData().map(country => ({
			value: country.value,
			label: countries.getName(country.value, language)
		})), 'value')
	];

	return (
		<div>
			<Form.Item
				id={id}
				name={name}
				rules={rules}
			>
				<Select
					placeholder={languageSchema.Etc.choose}
					options={userRoleSelectOptions}
				/>
			</Form.Item>
		</div>
	);
};
