import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { init as initRequest } from 'helpers/request';
import { formatRequest, formatResponse } from 'helpers/api.formatter';

import { apiStartLoading, apiEndLoading, setError } from './actions';
import rootReducer from './reducers';

function configureStore() {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const middleware = composeEnhancers(
		applyMiddleware(thunk)
	);

	const store = createStore(rootReducer, middleware);

	const requestInitOptions = {
		reduxStore: store,
		initRequestCallback: apiStartLoading,
		finishRequestCallback: apiEndLoading,
		setError,
		formatRequest,
		formatResponse
	};

	initRequest(requestInitOptions);

	return store;
}

const store = configureStore();

export default store;

export let getState = store.getState;
