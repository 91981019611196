export default {
	create: 'Utwórz',
	edit: 'Edytuj',
	type: 'Typ',
	title: 'Tytuł',
	description: 'Opis',
	isActive: 'Status aktywności',
	forAllUsers: 'Dla wszystkich użytkowników',
	editor: 'Ostatnio edytowane przez',
	active: 'Aktywny',
	all: 'Wszystko',
	disactive: 'Nieaktywny',
	global: 'Globalny',
	local: 'Dla autoryzowanych użytkowników',
	titleEN: 'Tytuł Angielski',
	titleDE: 'Tytuł Niemiecki',
	descriptionEN: 'Opis Angielski',
	descriptionDE: 'Niemiecki opis',
	warning: 'Ostrzeżenie',
	success: 'Suckces',
	info: 'Informacja',
	danger: 'Niebezpieczeństwo',
	boxLeavedOpened: 'Boks pozostał otwarty!',
	boxLeavedOpenedDescription: 'pozostał otwarty przez ponad 20 minut. Proszę sprawdzić!'
	
};
