import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';

import { IS_KYC_ENABLED } from 'utils/constants';
import { Formik, Field } from 'formik';
import request from 'helpers/request';
import { Modal } from 'react-bootstrap';
import { isAdmin } from 'utils/functions';
import ToggleBlock from 'components/common/InfoToggler';
import Heading from 'components/UI/Heading';
import DocumentsUploader from 'components/User/DocumentsUploader';
import CustomIframe from 'components/User/DocumentsUploader/CustomIFrame';

import kycRejectValidation from './KYCvalidation';

export default class UserPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			selfieImageUrl: null,
			authToken: null,
			isRejectModalOpened: false,
			kycFileTypeToReject: 'null',
			selfieFile: null,
			passportFile: null,
			addressFile: null,
			extraFile: null,
		};
	}

	openRejectKYCFileModal = fileType => () => this.setState({ isRejectModalOpened: true, kycFileTypeToReject: fileType });
	closeRejectKYCFileModal = () => this.setState({ isRejectModalOpened: false });

	handleKYCFileReject = async (values) => {
		toastr.confirm(this.props.languageSchema.User.areYouSureYouWantToRejectDocument, {
			onOk: async () => {
				try {
					let { kycFileTypeToReject } = this.state;

					await request('PUT', `users/${this.props.user._id}/kyc/${this.props.user._id}_${kycFileTypeToReject}/reject`, {
						reason: values.reason,
					});

					this.closeRejectKYCFileModal();

					await this.props.fetchUser(this.props.user._id);
				} catch (error) {
					throw error;
				}
			},
		});
	}

	setVerification = (isVerificationPassed) => async () => {
		let toastrText = isVerificationPassed ?
			this.props.languageSchema.User.areYouSureYouWantToMarkProfileAsVerified :
			this.props.languageSchema.User.areYouSureYouWantToCancelProfileVerification;

		toastr.confirm(toastrText, {
			onOk: async () => {
				try {
					this.setState({ isLoading: true });
					await request('PUT', `users/${this.props.user._id}`, { isKYCVerified: isVerificationPassed });

					await this.props.fetchUser(this.props.user._id);

					this.setState({ isLoading: false });
				} catch(error) {
					this.setState({ isLoading: false });
				}
			},
		});
	}

	applyUploadedPhotos = async (e) => {
		e.preventDefault();

		try {
			let uploadPhotosData = {};

			if (this.state.selfieFile) {
				uploadPhotosData.selfieFile = this.state.selfieFile;
			}

			if (this.state.passportFile) {
				uploadPhotosData.passportFile = this.state.passportFile;
			}

			if (this.state.extraFile) {
				uploadPhotosData.extraFile = this.state.extraFile;
			}

			if (this.state.addressFile) {
				uploadPhotosData.addressFile = this.state.addressFile;
			}

			let userId = this.props.user._id;

			await request('PUT', `users/${userId}`, uploadPhotosData);

			toastr.success(
				this.props.languageSchema.Etc.success,
			);

			this.props.onDocsUploaded();

			// this.toggleDocumentsModal();
			// this.setState({ areDocsSent: true });
		} catch (error) {
			toastr.error(
				this.props.languageSchema.Etc.failedUpload,
			);
		}
	}

	handleUploadedDocumentFile = (fileType, fileMeta) => {
		this.setState({ [fileType]: fileMeta });
	}

	render() {
		if (!IS_KYC_ENABLED) {
			return null;
		}

		let { user, languageSchema, loggedInUser, passportFile, selfieFile, extraFile, addressFile } = this.props;
		let isCurrentUserAdmin = loggedInUser.role && isAdmin(loggedInUser.role);
		let { isLoading} = this.state;

		let isPassportImageAvailable = Boolean(passportFile);
		let isSelfieImageAvailable = Boolean(selfieFile);
		let isExtraImageAvailable = Boolean(extraFile);
		let isAddressImageAvailable = Boolean(addressFile);
		let selfieImageUrl = selfieFile;
		let passportImageUrl = passportFile;
		let extraImageUrl = extraFile;
		let addressImageUrl = addressFile;

		let isUserOfLegalAccount = user.accountType === 'legal';

		let isPassportPhotoNeedToBeUploaded = (!isUserOfLegalAccount || user.isIndividualEntrepreneur) &&
			(!user.isPassportFileUploaded || user.passportKYCRejectionReason);

		let isSelfiePhotoNeedToBeUploaded = (!isUserOfLegalAccount || user.isIndividualEntrepreneur) &&
			(!user.isSelfieFileUploaded || user.selfieKYCRejectionReason);

		let isAddressPhotoNeedToBeUploaded = (!isUserOfLegalAccount || user.isIndividualEntrepreneur) &&
			(!user.isAddressFileUploaded || user.addressKYCRejectionReason);

		let isExtraPhotoNeedToBeUploaded = isUserOfLegalAccount &&
			(!user.isExtraFileUploaded || user.extraKYCRejectionReason);

		return (
			<div className="card">
				<Heading>{languageSchema.User.kycHeader}</Heading>

				{
					(isPassportPhotoNeedToBeUploaded || isSelfiePhotoNeedToBeUploaded || isAddressPhotoNeedToBeUploaded|| isExtraPhotoNeedToBeUploaded) &&
					<div className="flex flex-column align-items-center">
						<div>
							<DocumentsUploader
								onFileUploded={this.handleUploadedDocumentFile}
								isPassportImageUploaderDisplayed={isPassportPhotoNeedToBeUploaded}
								isSelfieImageUploaderDisplayed={isSelfiePhotoNeedToBeUploaded}
								isAddressImageUploaderDisplayed={isAddressPhotoNeedToBeUploaded}
								isExtraImageUploaderDisplayed={isExtraPhotoNeedToBeUploaded}
								accountType={user.accountType}
							/>
						</div>
						{
							(this.state.passportFile || this.state.selfieFile || this.state.addressFile || this.state.extraFile) &&
								<button
									onClick={this.applyUploadedPhotos}
									className="btn btn-outline-success mt-3"
								>
									{languageSchema.BoxBooking.sendPhotos}
								</button>
						}
					</div>
				}

				<hr />

				<div className="mb-1 d-flex">
					<div className="flex">
						{
							isCurrentUserAdmin &&
						<ToggleBlock
							text={[languageSchema.User.checkPhotosInfoBlock]}
							alertType="info"
						/>
						}
						<ToggleBlock
							text={[languageSchema.User.docRejectionDescription, languageSchema.User.userNeedToReupload]}
							alertType="warning"
						/>
					</div>
					<span className="pl-1">{languageSchema.User.bigSizeDocWarn}</span>
				</div>

				<div className="row mb-3 d-flex flex-column">
					<div className="d-flex flex-row">
						<div className={'m-3'}>
							{
								isSelfieImageAvailable && !user.selfieKYCRejectionReason &&
								<div className="text-center d-flex flex-column justify-content-between h-100">
									<CustomIframe
										customer={`${user.firstName}_${user.lastName}_selfie`}
										className="w-100"
										allow="fullscreen"
										src={selfieImageUrl}
									/>
									{
										isCurrentUserAdmin &&
									<button
										onClick={this.openRejectKYCFileModal('selfie')}
										className="btn btn-sm btn-secondary mt-3"
									>
										{languageSchema.Buttons.reject}
									</button>
									}
								</div>
							}

							{
								!isSelfieImageAvailable && !user.selfieKYCRejectionReason && user.accountType === 'individual' &&
								<div className="flex-card p-1">
									{languageSchema.User.selfieWasNotUploaded}
								</div>
							}

							{
								!isPassportImageAvailable && !user.passportKYCRejectionReason && user.accountType === 'legal' &&
								<div className="flex-card p-1">
									{languageSchema.User.legalDocWasNotUploaded}
								</div>
							}

							{
								user.selfieKYCRejectionReason &&
								<div className="flex-card">
									<div>
										<CustomIframe
											customer={`${user.firstName}_${user.lastName}_selfie`}
											className="w-100"
											allow="fullscreen"
											src={selfieImageUrl}
										/>
										<div className="d-flex flex-column p-3 border-0">
											<span>{languageSchema.User.selfieWasRejected}. </span>
											<span><b>{languageSchema.User.reason}</b>: { user.selfieKYCRejectionReason }
											</span>
										</div>
									</div>
								</div>
							}
						</div>

						<div className={'m-3 '}>
							{
								isAddressImageAvailable && !user.addressKYCRejectionReason &&
								<div className="text-center d-flex flex-column justify-content-between h-100">
									<CustomIframe
										customer={`${user.firstName}_${user.lastName}_address`}
										className="w-100"
										allow="fullscreen"
										src={addressImageUrl}
									/>
									{
										isCurrentUserAdmin &&
									<button
										onClick={this.openRejectKYCFileModal('address')}
										className="btn btn-sm btn-secondary mt-3"
									>
										{languageSchema.Buttons.reject}
									</button>
									}
								</div>
							}

							{
								!isAddressImageAvailable && !user.addressKYCRejectionReason && user.accountType === 'individual' &&
								<div className="flex-card p-1">
									{languageSchema.User.addressPhotoWasNotUploaded}
								</div>
							}

							{
								user.addressKYCRejectionReason &&
								<div className="flex-card">
									<div>
										<CustomIframe
											customer={`${user.firstName}_${user.lastName}_address`}
											className="w-100"
											allow="fullscreen"
											src={addressImageUrl}
										/>
										<div className="d-flex flex-column p-3 border-0">
											<span>{languageSchema.User.addressPhotoWasRejected}. </span>
											<span><b>{languageSchema.User.reason}</b>: { user.addressKYCRejectionReason }
											</span>
										</div>
									</div>
								</div>
							}
						</div>

						<div className={'m-3 '}>
							{
								isPassportImageAvailable && !user.passportKYCRejectionReason &&
								<div className="text-center d-flex flex-column justify-content-between h-100">
									<CustomIframe
										customer={`${user.firstName}_${user.lastName}_passport`}
										className="w-100"
										allow="fullscreen"
										src={passportImageUrl}
									/>
									{
										isCurrentUserAdmin &&
									<button
										onClick={this.openRejectKYCFileModal('passport')}
										className="btn btn-sm btn-secondary mt-3"
									>
										{languageSchema.Buttons.reject}
									</button>
									}
								</div>
							}

							{
								!isPassportImageAvailable && !user.passportKYCRejectionReason && user.accountType === 'individual' &&
								<div className="flex-card p-1">
									{languageSchema.User.passportPhotoWasNotUploaded}
								</div>
							}

							{
								user.passportKYCRejectionReason &&
								<div className="flex-card">
									<div>
										<CustomIframe
											customer={`${user.firstName}_${user.lastName}_passport`}
											className="w-100"
											allow="fullscreen"
											src={passportImageUrl}
										/>
										<div className="d-flex flex-column p-3 border-0">
											<span>{languageSchema.User.passportPhotoWasRejected}. </span>
											<span><b>{languageSchema.User.reason}</b>: { user.passportKYCRejectionReason }
											</span>
										</div>
									</div>
								</div>
							}
						</div>



						<div className={'m-3 '}>
							{
								isExtraImageAvailable && !user.extraKYCRejectionReason &&
								<div className="text-center d-flex flex-column justify-content-between h-100">
									<CustomIframe
										customer={`${user.firstName}_${user.lastName}_extra`}
										className="w-100"
										allow="fullscreen"
										src={extraImageUrl}
									/>
									{
										isCurrentUserAdmin &&
									<button
										onClick={this.openRejectKYCFileModal('extra')}
										className="btn btn-sm btn-secondary mt-3"
									>
										{languageSchema.Buttons.reject}
									</button>
									}
								</div>
							}

							{
								!isExtraImageAvailable && !user.extraKYCRejectionReason && user.accountType !== 'individual' &&
								<div className="flex-card p-1">
									{languageSchema.User.extraPhotoWasNotUploaded}
								</div>
							}

							{
								user.extraKYCRejectionReason &&
								<div className="flex-card">
									<div>
										<CustomIframe
											customer={`${user.firstName}_${user.lastName}_extra`}
											className="w-100"
											allow="fullscreen"
											src={extraImageUrl}
										/>
										<div className="d-flex flex-column p-3 border-0">
											<span>{languageSchema.User.extraPhotoWasRejected}. </span>
											<span><b>{languageSchema.User.reason}</b>: { user.extraKYCRejectionReason }
											</span>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
					{
						isCurrentUserAdmin &&
						<div className="col-md-4 text-center d-flex flex-column justify-content-between  mt-3 align-self-center">
							<div className="mb-3">
								<i className={`fas fa-4x fa-user-check ${user.isKYCVerified ? 'green' : 'red'}`}></i>
							</div>
							<div>
								{
									user.isKYCVerified ?
										<div className="mb-3">
											<button disabled={isLoading} className={`${isLoading ? 'disabled' : ''} btn-sm btn-danger`} onClick={this.setVerification(false)}>
												{isLoading ? languageSchema.Etc.wait : languageSchema.User.cancelVerification}
											</button>
										</div> :
										<div className="mb-3">
											<button
												disabled={isLoading || !(isPassportImageAvailable || isSelfieImageAvailable || isExtraImageAvailable || isAddressImageAvailable)}
												className={`${isLoading ? 'disabled' : ''} btn btn-sm btn-success`}
												onClick={this.setVerification(true)}
											>
												{isLoading ? languageSchema.Etc.wait : languageSchema.User.verify}
											</button>
										</div>
								}
							</div>
						</div>
					}
				</div>

				{ this.state.isRejectModalOpened &&
					<Modal
						show={this.state.isRejectModalOpened}
						onHide={this.closeRejectKYCFileModal}
					>
						<Formik
							initialValues={{ reason: '' }}
							onSubmit={this.handleKYCFileReject}
							validationSchema={kycRejectValidation}
							render={({ errors, touched, handleSubmit }) => {
								const isError = field => errors[field] && touched[field];

								return (
									<div>
										<Modal.Header closeButton>
											<Modal.Title>{languageSchema.User.rejectionReason}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="col-sm-12">
												<Field
													name="reason"
													component="textarea"
													className={`form-control ${isError('reason') ? 'is-invalid' : ''}`}
													placeholder={languageSchema.User.setRejectionReason}
													style={{ width: '100%' }}
												></Field>
												{
													isError('reason') &&
													<div className="invalid-feedback">
														{errors.reason}
													</div>
												}
											</div>

											<div className="mt-3 text-right">
												<button
													onClick={handleSubmit}
													type="submit"
													className="btn btn-sm btn-secondary mr-3"
												>
													{languageSchema.Buttons.reject}
												</button>
												<button
													className="btn btn-sm btn-outline-secondary mr-3"
													onClick={this.closeRejectKYCFileModal}
												>
													{languageSchema.Buttons.close}
												</button>
											</div>
										</Modal.Body>
									</div>
								);
							}}
						/>
					</Modal>
				}
			</div>
		);
	}
}
