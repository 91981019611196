export default {
	accountDetails: 'Детали аккаунта',
	accountCreation: 'Создание аккаунта',
	lockAvailability: 'Состояние замка',

	docsAttachmentInfo: 'Пользователю необходимо присоединить фото документов в личном кабинете мобильного приложения',
	userWillReceivePassword: 'Пользователь получит пароль на почту',
	passwordMayBeReset: 'Пароль может быть сброшен в форме сброса пароля',
	name: 'Имя',
	sureName: 'Фамилия',
	email: 'Email',
	emailSubject: 'Тема письма',
	emailType: 'Тип письма',

	password: 'Пароль',
	country: 'Страна',
	phone: 'Телефон',
	city: 'Город',
	address: 'Адрес',
	houseNumber: 'Дом',
	zip: 'Индекс',
	company: 'Компания',
	role: 'Роль',
	stock: 'Склад',
	withoutStock: 'Без привязки к складу',
	areYouSureYouWantToDeleteAccount: 'Вы уверены, что хотите удалить аккаунт?',
	accountEdit: 'Редактирование аккаунта',
	kycHeader: 'Удостоверение личности',
	passwordReset: 'Сброс пароля',
	resetPassword: 'Сбросить пароль',
	passwordWillBeSentToMail: 'Пароль будет выслан на почту',
	addAccount: 'Добавить аккаунт',
	contracts: 'Договоры',
	checkPhotosInfoBlock: 'Проверьте фото паспорта. Для этого нажмите на фото. Если c фотографиями и данными все в порядке - нажмите Верифицировать.',
	selfieWasNotUploaded: 'Селфи с паспортом не было загружено',
	selfieWasRejected: 'Фото селфи было отклонено.',
	reason: 'Причина',
	passportPhotoWasNotUploaded: 'Фото пасспорта не было загружено',
	passportPhotoWasRejected: 'Фото пасспорта было отклонено',
	cancelVerification: 'Отменить верифиацию',
	verify: 'Верифицировать',
	docRejectionDescription: 'Если документ не в порядке - доступ к боксу для клиента будет закрыт.',
	userNeedToReupload: 'В случае отклонения фотографии менеджером - пользователю необходимо загрузить новое фото в личном кабинете',
	rejectionReason: 'Причина отклонения',
	setRejectionReason: 'Укажите причину отклонения документа',
	sureNameRequired: 'Фамилию',
	areYouSureYouWantToResetPassword: 'Вы уверены, что хотите сбросить пароль?',
	areYouSureYouWantToRejectDocument: 'Вы уверены, что хотите отклонить документ?',
	areYouSureYouWantToCancelProfileVerification: 'Вы уверены, что хотите отменить верификацию профиля?',
	areYouSureYouWantToMarkProfileAsVerified: 'Вы уверены, что хотите отметить профиль как проверенный?',
	accountType: 'Тип аккаунта',
	individual: 'Физ. лицо',
	legal: 'Юр. лицо',
	companyName: 'Наименование юридического лица',
	registrationNumber: 'Регистрационный номер',
	companyRequired: 'Компанию',
	client: 'Клиент',
	currentClient: 'Текущий клиент',
	goToContracts: 'Перейти к договорам',
	passwordConfirmation: 'Подтверждение пароля',
	passwordChanged: 'Пароль изменен',
	passwordWasSuccessfullyChanged: 'Пароль был успешно изменен',
	areYouSureYouWantToChangePassword: 'Вы уверены, что хотите изменить пароль?',
	changePassword: 'Изменить пароль',
	isEighteenAge: 'Мне есть 18+ лет.',
	selectUser: 'Выберите пользователя',
	user: 'Пользователь',
	loginAsUser: 'Войти как пользователь',
	created: 'Время создания',
	unknownDate: 'Дата обновления неизвестна',
	importUsers: 'Импорт пользователей',
	createdAfter: 'Созданых после',
	importUsersByDate: 'Импорт пользователей по дате',
	otherContacts: 'Другие способы связи',
	addressRequired: 'Обязательно',
	houseRequired: 'Обязательно',
	pinCode: 'Пин код',
	extraDocWasNotUploaded: 'Фото свидетельства ОГРН не было загружено',
	extraPhotoWasRejected: 'Фото свидетельства ОГРН было отклонено',
	addressPhotoWasNotUploaded: 'Фото страницы прописки не было загружено',
	addressPhotoWasRejected: 'Фото страницы прописки было отклонено',
	showPassword: 'Показать пароль',
	hidePassword: 'Скрыть пароль',
	opened: 'Открыт',
	closed: 'Закрыт',
	unavailable: 'Недоступен',
	bigSizeDocWarn: 'Если документ не просматривается, то попробуйте его скачать и открыть',
	individualEntrepreneur: 'Индивидуальный предприниматель'
};
