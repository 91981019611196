import React, { useState } from 'react';
import amexBrandLogo from 'assets/images/amex_brand.png';
// import paypal from 'assets/images/paypal.png';
import visaBrandLogo from 'assets/images/visa_brand.png';
import masterCardBrandLogo from 'assets/images/mc_brand.png';
import BoxBookingContext from 'screens/BoxBookingNew/Context';
import paykeeperLogo from 'assets/images/paykeeper_logo.png';

import './index.scss';

import {
	DEF_PAYKEEPER_PROCESSOR,
	// DEF_PAYPAL_PROCESSOR,
} from 'utils/constants';

const imageDivStyle = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center'
};

export default (props) => {
	if (props.isSuperUser) {
		return null;
	}

	let boxBookingContext = React.useContext(BoxBookingContext);

	let [activeClass, setActiveClass] = useState('');

	let handleClick = (processor) => {
		setActiveClass(processor);
		boxBookingContext.togglePaymentMethod(processor);
	};

	return (
		<div className="wrapper">
			<div className={`payment-block ${props.isRow ? 'payment-block__row' : ''}`}>
				<div
					className={
						`payment-block__cards
						${activeClass === DEF_PAYKEEPER_PROCESSOR ? 'active' : ''}
						${props.isRow ? 'payment-block__cards__row' : ''}
						`}
					onClick={() => handleClick(DEF_PAYKEEPER_PROCESSOR)}
				>
					<div className="mb-3" style={imageDivStyle}>
						<img src={paykeeperLogo} alt="Paykeeper" title="Paykeeper" width="128px" style={{ margin: '0 5px' }} />
					</div>
				</div>
				{/* <div
					className={
						`payment-block__cards
						${activeClass === DEF_PAYPAL_PROCESSOR ? 'active' : ''}
						${props.isRow ? 'payment-block__cards__row' : ''}
					`}
					onClick={() => handleClick(DEF_PAYPAL_PROCESSOR)}
				>
					<img className="payment-block__image" src={paypal} alt="paypal" />
					<span>PayPal</span>
				</div>
				{/* <div
					className={
					`payment-block__cards
					${activeClass === DEF_SOFORT_PROCESSOR ? 'active' : ''}
					${props.isRow ? 'payment-block__cards__row' : ''}
					`}
					onClick={() => handleClick(DEF_SOFORT_PROCESSOR)}
					>
					<img className="payment-block__image" src={receipt} alt="receipt" />
					<span>Сheque</span>
					</div> */}
			</div>
		</div>
	);
};
