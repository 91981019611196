const initialState = {
	data: [],
	me: {},
	isAuth: false
};

export default function reducer(state = initialState, action) {

	switch (action.type) {
	case 'LOG_IN':
		return { ...state, me: action.payload.user, isAuth: true };
			
	case 'LOG_OUT':
		return { ...state, me: {}, isAuth: false };

	case 'SET_CURRENT_USER':
		return { ...state, me: action.payload, isAuth: true };

	default:
		return state;
	}

}