import * as Yup from 'yup';
import { PHONE_REGEXP } from 'utils/constants';

const validationSchema = languageSchema => Yup.object().shape({
	appealMessage: Yup.string()
		.min(1, languageSchema.validation.outOfBounds('appealMessage', languageSchema.Support, 1, 254))
		.max(254, languageSchema.validation.outOfBounds('appealMessage', languageSchema.Support, 1, 254))
		.required(languageSchema.validation.enterValue('appealMessage', languageSchema.Support)),
	phone: Yup.string()
		.matches(PHONE_REGEXP, languageSchema.validation.invalidField('phone', languageSchema.Support))
		.min(3, languageSchema.validation.validatePhone(3, 14))
		.max(14, languageSchema.validation.validatePhone(3, 14))
		.required(languageSchema.validation.enterValue('phone', languageSchema.Support)),

});

export default validationSchema;