import React from 'react';
import { connect } from 'react-redux';

function OverlayLoader(props) {
	if (props.loadings <= 0) {
		return null;
	}

	return (
		<div className="overlay">
			<div className="overlay__inner">
				<div className="overlay__content"><span className="spinner"></span></div>
			</div>
		</div>
	);
}

let mapStateToProps = state => ({
	loadings: state.common.loadings,
});

export default connect(mapStateToProps)(OverlayLoader);
