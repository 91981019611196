import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import querystring from 'querystring';

import { fetchStocks, getMonthlyIqacDocuments, sendMonthlyIqacDocumentsToEmail } from 'redux/actions';
import Loader from 'components/common/OverlayLoader';
import { getToken } from 'helpers/request';
import { apiUrl } from 'utils/constants';
import { getLanguageSchema } from 'dashboard-commons/languages';

function MonthlyBills(props) {
	const MONTHLY_BILLS_EMAIL = 'MONTHLY_BILLS_EMAIL';
	const [month, setMonth] = useState(moment().subtract(1, 'months').format('YYYY-MM'));
	const [email, setEmail] = useState(localStorage.getItem(MONTHLY_BILLS_EMAIL));
	const handleChangeMonth = (event) => setMonth(event.target.value);
	const handleChangeEmail = (event) => {
		const { value } = event.target;
		setEmail(value);
		localStorage.setItem(MONTHLY_BILLS_EMAIL, value);
	};

	const handleSubmit = () => {
		if (props.stocks.data.length <= 0) {
			console.warn('Stocks not exists');
			return;
		}

		const [stock] = props.stocks.data;
		props.getMonthlyIqacDocuments(month, stock._id);
	};

	const handleSendToEmail = () => {
		if (props.stocks.data.length <= 0) {
			console.warn('Stocks not exists');
			return;
		}

		const [stock] = props.stocks.data;
		props.sendMonthlyIqacDocumentsToEmail(month, stock._id, email);
	};

	useEffect(() => {
		if (props.stocks.data.length <= 0) {
			props.fetchStocks().catch((error) => console.warn(error.message));
		}
	}, []);

	const getInvoiceInPdfUrl = (invoiceId, boxNumber) => {
		const [stock] = props.stocks.data;

		const paymentConfig = {
			stockId: stock._id,
			invoiceId,
			boxNumber,
			language: 'EN',
		};

		const queryString = querystring.stringify(paymentConfig);
		const authToken = getToken();

		return `${apiUrl}/payments/pdf?${queryString}&authToken=${authToken}`;
	};

	return (
		<dev>
			<Loader />
			<h1>Monthly Bills</h1>
			<input
				type="month"
				value={month}
				onChange={handleChangeMonth}
			/>
			<button onClick={handleSubmit}>Get</button>

			<input
				type="text"
				value={email}
				onChange={handleChangeEmail}
			/>
			<button onClick={handleSendToEmail}>Send To Email</button>

			<div>{`All invoices: ${props.iqacdocuments.length}`}</div>
			{props.iqacdocuments.map((iqacdocument) => (
				<div key={iqacdocument.DocumentID}>
					{`(Contract ${iqacdocument.ContractNumber})`}{' '}
					{`Box: ${iqacdocument.UnitNumber}`}
					{`#${iqacdocument.InvoiceID}(${iqacdocument.DocumentID}) ${iqacdocument.DocumentType}`}
					{' '}
					<a
						className="btn btn-success mt-4"
						rel="noopener noreferrer"
						target="_blank"
						href={getInvoiceInPdfUrl(iqacdocument.DocumentID, iqacdocument.UnitNumber)}
					>
						{props.languageSchema.Etc.download}
					</a>
				</div>
			))}
		</dev>
	);
}

const mapStateToProps = (state) => ({
	iqacdocuments: state.iqacdocuments.data,
	stocks: state.stocks,
	languageSchema: getLanguageSchema(state.common.language),
});

const mapDispatchToProps = {
	getMonthlyIqacDocuments,
	sendMonthlyIqacDocumentsToEmail,
	fetchStocks,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyBills);
