import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROLES_MAP } from 'utils/constants';
import ListItem from 'components/Stock/ListItem';
import { fetchStocks, fetchCities } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';

class StockList extends Component {
	state = {
		isLoading: false
	}

	async componentDidMount() {
		const {
			fetchStocks,
		} = this.props;

		this.setState({ isLoading: true });

		await fetchStocks();

		this.setState({ isLoading: false });
	}

	render() {
		const { stocks, languageSchema, language, user } = this.props;
		const { isLoading } = this.state;

		return (
			<div>
				{
					isLoading ?
						<div className="text-center">
							<i className="fas fa-2x fa-spinner fa-spin"></i>
						</div> :
						<div>
							{
								[ROLES_MAP.superUser].includes(user.role) &&
								<div className="custom-filter mb-3">
									<div className="form-row">
										<div className="col">
											<Link to="/stocks/add" className="btn btn-success mr-2">
												{languageSchema.Stock.createStock}
											</Link>
										</div>
									</div>
								</div>
							}
							<div className="row mb-3">
								{stocks.map(stock =>
									<ListItem
										key={stock._id}
										{...stock}
										languageSchema={languageSchema}
										language={language}
										user={user}
									/>
								)}
							</div>
						</div>
				}
			</div>
		);
	}
}

const mapStateToProps = ({ stocks, common, auth }) => {
	return {
		stocks: stocks.data,
		isFetchedStocks: stocks.isFetched,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
		user: auth.me,
	};
};
export default connect(mapStateToProps, { fetchStocks, fetchCities })(StockList);
