import * as Yup from 'yup';

let validationSchema = languageSchema => Yup.object().shape({
	type: Yup.string()
		.min(4, languageSchema.validation.outOfBounds('type', languageSchema.Notification, 4, 16))
		.max(16, languageSchema.validation.outOfBounds('type', languageSchema.Notification, 4, 16))
		.required(languageSchema.validation.chooseField('type', languageSchema.Notification)),

	titleEN: Yup.string()
		.min(4, languageSchema.validation.outOfBounds('titleEN', languageSchema.Notification, 4, 60))
		.max(60, languageSchema.validation.outOfBounds('titleEN', languageSchema.Notification, 4, 60))
		.required(languageSchema.validation.enterValue('titleEN', languageSchema.Notification)),

	titleDE: Yup.string()
		.min(4, languageSchema.validation.outOfBounds('titleDE', languageSchema.Notification, 4, 60))
		.max(60, languageSchema.validation.outOfBounds('titleDE', languageSchema.Notification, 4, 60))
		.required(languageSchema.validation.enterValue('titleDE', languageSchema.Notification)),

	descriptionEN: Yup.string()
		.min(8, languageSchema.validation.outOfBounds('descriptionEN', languageSchema.Notification, 8, 256))
		.max(256, languageSchema.validation.outOfBounds('descriptionEN', languageSchema.Notification, 8, 256))
		.required(languageSchema.validation.enterValue('descriptionEN', languageSchema.Notification)),

	descriptionDE: Yup.string()
		.min(8, languageSchema.validation.outOfBounds('descriptionDE', languageSchema.Notification, 8, 256))
		.max(256, languageSchema.validation.outOfBounds('descriptionDE', languageSchema.Notification, 8, 256))
		.required(languageSchema.validation.enterValue('descriptionDE', languageSchema.Notification)),

	isActive: Yup.boolean()
		.required(languageSchema.validation.enterValue('isActive', languageSchema.Notification)),

	isGlobal: Yup.boolean()
		.required(languageSchema.validation.enterValue('isGlobal', languageSchema.Notification)),
});

export default validationSchema;
