import React, { Component } from 'react';
import Heading from 'components/UI/Heading';
import request from 'helpers/request';
import ToggleBlock from 'components/common/InfoToggler';

export default class DiscountForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			discount: props.stock.discount || 0,
			isLoading: false,
		};
	}

	applyDiscount = async () => {
		this.setState({ isLoading: true });
		try {
			await request('PUT', `stocks/${this.props.stock._id}`, { discount: this.state.discount });

			this.setState({ isLoading: false });
		} catch(error) {
			this.setState({ isLoading: false });
		}
	}

	handleChangeField = fieldName => e => this.setState({ [fieldName]: e.target.value})

	render() {
		let { languageSchema, isSuperUser } = this.props;
		let { isLoading } = this.state;

		return (
			<div className="row">
				<div className="col-md-12">
					<div className="relative flex">
						<Heading>{languageSchema.Stock.discountSettings}</Heading>

						<div className="mb-3">
							<ToggleBlock
								text={[languageSchema.Stock.discountHelpInfo]}
								alertType="info"
							/>
						</div>
					</div>


					<div className="form-group row">
						<label htmlFor="pricePerMeterQuadratic" className="col-sm-5 col-form-label">
							{languageSchema.Stock.discountPercentPerMonth}
						</label>
						<div className="col-sm-4">
							<div className="input-group mb-3">
								<input
									name="discount"
									type="number"
									className="form-control"
									value={this.state.discount}
									onChange={this.handleChangeField('discount')}
									disabled={!isSuperUser}
								/>
								<div className="input-group-append"><span className="input-group-text">%</span></div>
							</div>
						</div>
						<div>
							<button
								className="btn btn-success"
								disabled={!isSuperUser}
								onClick={this.applyDiscount}
							>
								{isLoading && <span className="spinner-border spinner-border-sm"> </span>}
								<span> {languageSchema.Buttons.apply} </span>
							</button>
						</div>
					</div>

				</div>
			</div>
		);
	}
}
