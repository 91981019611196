import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { getRoleNameFromToken } from 'utils/functions';
import { ROLES_MAP } from 'utils/constants';

import PrivateRoute from 'components/PrivateRoute';
import Authorization from 'components/Authorization';
import BaseLayout from 'components/Layout';

import HomePage from 'screens/HomePage';
import NotFoundPage from 'screens/NotFoundPage';
import LoginPage from 'screens/LoginPage';
import ResetPwdPage from 'screens/ResetPwdPage';
import CityList from 'screens/City/List';
import CityPage from 'screens/City/Page';
import CityForm from 'screens/City/Form';
import StockList from 'screens/Stock/List';
import StockPage from 'screens/Stock/Page';
import StockForm from 'screens/Stock/Form';
import StockHistory from 'screens/Stock/History';
import BoxList from 'screens/Box/List';
import BoxForm from 'screens/Box/Form';
import BoxPage from 'screens/Box/Page';
import BoxCatList from 'screens/BoxCat/List';
import BoxCatForm from 'screens/BoxCat/Form';
import BoxCatPage from 'screens/BoxCat/Page';
import UserList from 'screens/User/List';
import UserPage from 'screens/User/Page';
import UserForm from 'screens/User/Form';
import AppealList from 'screens/Appeal';
import ContractList from 'screens/Contract/List';
import MyBoxes from 'screens/MyBoxes/List';
import Support from 'screens/Support';
import BoxBooking from 'screens/BoxBookingNew';
import SignUpPage from 'screens/SignUpPage';
import PaymentSuccess from 'screens/Success';
import BoxBookingSuccess from 'screens/BoxBooking/Success';
import PaymentFailed from 'screens/Payment/Failed';
import ContractPage from 'screens/Contract/Page';
import SomethingWentWrong from 'screens/SomethingWentWrong';
//import JivoPage from 'screens/JivoPage';
import NotificationList from 'screens/Notification/List';
import LandingPage from 'screens/LandingPage';
import MonthlyBills from 'screens/MonthlyBills';
import ExternalPaymentsList from 'screens/Payment/ExternalPaymentsList';
import UsersImportList from 'screens/User/Import';
import EmailHistory from 'screens/EmailHistory/EmailHistory';
//import Test from 'screens/Test';

const Admin = Authorization(['superUser']);
const AdminsAndStaff = Authorization(['superUser', 'staff']);

const CityRoutes = () =>
	<Switch>
		<Route exact path="/cities" component={CityList} />
		<Route path="/cities/add" component={CityForm} />
		<Route exact path="/cities/:id" component={CityPage} />
	</Switch>;

const StockRoutes = () =>
	<Switch>
		<Route exact path="/stocks" component={StockList} />
		<Route path="/stocks/add" component={Admin(StockForm)} />
		<Route exact path="/stocks/:id" component={Admin(StockPage)} />
		<Route path="/stocks/:id/history" component={Admin(StockHistory)} />
	</Switch>;

const BoxRoutes = () =>
	<Switch>
		<Route exact path="/boxes" render={Admin((props) => <BoxList perPage={9} {...props} />)} />
		<Route path="/boxes/add" component={Admin(BoxForm)} />
		<Route exact path="/boxes/:id" component={BoxPage} />
	</Switch>;

const BoxCatRoutes = () =>
	<Switch>
		<Route exact path="/boxcats" component={BoxCatList} />
		<Route path="/boxcats/add" component={BoxCatForm} />
		<Route exact path="/boxcats/:id" component={BoxCatPage} />
	</Switch>;

const UserRoutes = () =>
	<Switch>
		<Route exact path="/users" component={Admin(UserList)} />
		<Route path="/users/add" component={Admin(UserForm)} />
		<Route exact path="/users/:userId/contracts" component={Admin(ContractList)} />
		<Route exact path="/users/:userId/contracts/:contractId" component={ContractPage} />
		<Route exact path="/users/:id" component={UserPage} />
	</Switch>;

const NotificationRoutes = () =>
	<Switch>
		<Route exact path="/notifications" component={NotificationList} />
	</Switch>;


const Routes = () => {
	const role = getRoleNameFromToken();
	const rootPage = role === ROLES_MAP.client ? MyBoxes : HomePage;

	return (
		<Switch>
			<PrivateRoute exact strict path="/" component={BaseLayout(rootPage)} />

			<PrivateRoute path="/cities" component={BaseLayout(Admin(CityRoutes))} />
			<PrivateRoute path="/stocks" component={BaseLayout(AdminsAndStaff(StockRoutes))} />
			<PrivateRoute path="/boxes" component={BaseLayout(BoxRoutes)} />
			<PrivateRoute path="/boxcats" component={BaseLayout(Admin(BoxCatRoutes))} />
			<PrivateRoute path="/users" component={BaseLayout(UserRoutes)} />
			<PrivateRoute path="/appeal" component={BaseLayout(Admin(AppealList))} />
			<PrivateRoute path="/my-boxes" component={BaseLayout(MyBoxes)} />
			<PrivateRoute path="/support" component={BaseLayout(Support)} />
			<PrivateRoute path="/notifications" component={BaseLayout(Admin(NotificationRoutes))} />
			<PrivateRoute path="/monthly-bills" component={BaseLayout(Admin(MonthlyBills))} />
			<PrivateRoute path="/external-payments" component={BaseLayout(Admin(ExternalPaymentsList))} />
			<PrivateRoute path="/users-import" component={BaseLayout(Admin(UsersImportList))} />
			<PrivateRoute path="/email-history" component={BaseLayout(Admin(EmailHistory))} />
			{/*
			<PrivateRoute path="/test" component={BaseLayout(Admin(Test))} />
			*/}

			<Route exact path="/login" component={LoginPage} />
			<Route exact path="/signup" component={SignUpPage} />
			<Route exact path="/reset-password" component={ResetPwdPage} />
			<Route exact path="/book" component={BoxBooking} />
			<Route exact path="/payment-success" component={PaymentSuccess} />
			<Route exact path="/payment-failed" component={PaymentFailed} />
			<Route exact path="/booking-success" component={BoxBookingSuccess} />
			<Route exact path="/something-went-wrong" component={SomethingWentWrong} />
			{/*
			<Route exact path="/jivo-contact" component={JivoPage} />
			*/}
			<Route path="/landing-page" component={LandingPage} />
			<Route exact path="/not-found" component={NotFoundPage} />

			<Route component={() => <div className="vh-100 d-flex align-items-center justify-content-center"><h1>Not Found</h1></div>} />
		</Switch>
	);
};

export default Routes;
