import React, { useState, useMemo } from 'react';
import { Modal } from 'react-bootstrap';

import request from 'helpers/request';
import DocumentsUploader from 'components/User/DocumentsUploader';
import BoxBookingContext from 'screens/BoxBookingNew/Context';


export default function (props) {
	const { isDocumentsModalOpened, setDocumentsModalOpened } = props;

	const boxBookingContext = React.useContext(BoxBookingContext);

	let userToBook = boxBookingContext.bookingMeta.user || {};

	const [passportFile, setPassportFile] = useState(null);
	const [selfieFile, setSelfieFile] = useState(null);
	const [addressFile, setAddressFile] = useState(null);
	const [extraFile, setExtraFile] = useState(null);

	const isAllFilesUploaded = useMemo(() => {
		let isAllFilesUploaded = userToBook.accountType === 'legal' ?
			extraFile?.base64 :
			passportFile?.base64 && selfieFile?.base64 && addressFile?.base64;

		if (userToBook.isIndividualEntrepreneur) {
			isAllFilesUploaded = passportFile?.base64 && selfieFile?.base64 && addressFile?.base64 && extraFile?.base64;
		}

		return Boolean(isAllFilesUploaded);
	}, [passportFile, selfieFile, extraFile, addressFile]);

	const handleUploadedDocumentFile = (fileType, fileMeta) => {
		if (fileType === 'passportFile') {
			setPassportFile(fileMeta);
		}

		if (fileType === 'selfieFile') {
			setSelfieFile(fileMeta);
		}

		if (fileType === 'extraFile') {
			setExtraFile(fileMeta);
		}

		if (fileType === 'addressFile') {
			setAddressFile(fileMeta);
		}
	};

	let isUserOfLegalAccount = userToBook && userToBook.accountType === 'legal';

	let isPassportPhotoNeedToBeUploaded = (!isUserOfLegalAccount || userToBook.isIndividualEntrepreneur) &&
		(!userToBook.isPassportFileUploaded || userToBook.passportKYCRejectionReason);

	let isSelfiePhotoNeedToBeUploaded = (!isUserOfLegalAccount || userToBook.isIndividualEntrepreneur) &&
		(!userToBook.isSelfieFileUploaded || userToBook.selfieKYCRejectionReason);

	let isAddressPhotoNeedToBeUploaded = (!isUserOfLegalAccount || userToBook.isIndividualEntrepreneur) &&
		(!userToBook.isAddressFileUploaded || userToBook.addressKYCRejectionReason);

	let isExtraPhotoNeedToBeUploaded = isUserOfLegalAccount &&
		(!userToBook.isExtraFileUploaded || userToBook.extraKYCRejectionReason);

	let applyUploadedPhotos = async (e) => {
		e.preventDefault();

		let uploadPhotosData = {};

		if (selfieFile) {
			uploadPhotosData.selfieFile = selfieFile;
		}

		if (passportFile) {
			uploadPhotosData.passportFile = passportFile;
		}

		if (extraFile) {
			uploadPhotosData.extraFile = extraFile;
		}

		if (addressFile) {
			uploadPhotosData.addressFile = addressFile;
		}

		await request('PUT', `users/${userToBook._id}`, uploadPhotosData);

		boxBookingContext.setDocsSent(true);
		setDocumentsModalOpened(false);
	};

	return (
		<Modal
			show={isDocumentsModalOpened}
			onHide={() => setDocumentsModalOpened(false)}
		>
			<Modal.Header closeButton>
				<Modal.Title>{props.languageSchema.BoxBooking.uploadDocsPhoto}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<DocumentsUploader
						onFileUploded={handleUploadedDocumentFile}
						isPassportImageUploaderDisplayed={isPassportPhotoNeedToBeUploaded}
						isSelfieImageUploaderDisplayed={isSelfiePhotoNeedToBeUploaded}
						isExtraImageUploaderDisplayed={isExtraPhotoNeedToBeUploaded}
						isAddressImageUploaderDisplayed={isAddressPhotoNeedToBeUploaded}
						accountType={boxBookingContext.bookingMeta.user.accountType}
					/>
					<div className="text-center mt-3">
						<button
							onClick={applyUploadedPhotos}
							disabled={!isAllFilesUploaded}
							className="btn btn-primary"
						>
							{props.languageSchema.BoxBooking.sendPhotos}
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}
