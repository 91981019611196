import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
	Drawer,
	Tabs,
	Radio
} from 'antd';

import { getLanguageSchema } from 'dashboard-commons/languages';
import { displayAmount, round } from 'helpers/amount';
import request from 'helpers/request';

import {
	getCurrentUserAccountTypeFromToken,
	getRoleNameFromToken,
	getCurrentUserIdFromToken,
	isAdmin
} from 'utils/functions';

import BoxBookingContext from 'screens/BoxBookingNew/Context';

import ChoiceOfArrivalDate from './ChoiceOfArrivalDate';
import PeriodSelector from './PeriodSelector';
import DocsList from './DocsList';
import PaymentMethodSelector from './PaymentMethodSelector';
import UploadDocsModal from './UploadDocsModal';
import SideBarFooter from './Footer';

import '../styles.scss';

// TODO: remove it. Deal with simple date format
function getDateString(date) {
	return `${date.getUTCFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
}

const SideBar = props => {
	const {
		selectedBox,
		languageSchema,
		language,
		selectedStockId,
		isOpened,
		close,
		openSignUpModal,
	} = props;

	const [isLoading, setLoadingState] = useState(false);
	const [currentTab, setCurrentTab] = useState('1');
	const [isDocumentsModalOpened, setDocumentsModalOpened] = useState(false);

	const boxBookingContext = React.useContext(BoxBookingContext);

	useEffect(() => {
		const userToBook = boxBookingContext.bookingMeta.user;

		if (currentTab === '2' && !userToBook) {
			openSignUpModal();
			setCurrentTab('1');
		}


		if (currentTab === '2' && userToBook && !boxBookingContext.bookingMeta.areDocsSent) {
			let isUserOfLegalAccount = userToBook.accountType === 'legal';

			let isPassportPhotoNeedToBeUploaded = (!isUserOfLegalAccount || userToBook.isIndividualEntrepreneur) &&
				(!userToBook.isPassportFileUploaded || userToBook.passportKYCRejectionReason);

			let isSelfiePhotoNeedToBeUploaded = (!isUserOfLegalAccount || userToBook.isIndividualEntrepreneur) &&
				(!userToBook.isSelfieFileUploaded || userToBook.selfieKYCRejectionReason);

			let isAddressPhotoNeedToBeUploaded = (!isUserOfLegalAccount || userToBook.isIndividualEntrepreneur) &&
				(!userToBook.isAddressFileUploaded || userToBook.addressKYCRejectionReason);

			let isExtraPhotoNeedToBeUploaded = isUserOfLegalAccount &&
				!userToBook.isExtraFileUploaded || userToBook.extraKYCRejectionReason;

			let isPhotosNeedToBeUploaded = isPassportPhotoNeedToBeUploaded || isSelfiePhotoNeedToBeUploaded || isAddressPhotoNeedToBeUploaded || isExtraPhotoNeedToBeUploaded;

			if (isPhotosNeedToBeUploaded) {
				setDocumentsModalOpened(true);
			}
		}
	}, [currentTab]);

	const sideBarStep1BottomRef = useRef(null);

	const {
		bookingMeta: {
			arrivalDate,
			selectedContractDuration,
			selectedInsuranceSchemeId,
			areDocsConfirmed,
			user,
			paymentMethod
		}
	} = boxBookingContext;

	const isStep1Completed = Boolean(arrivalDate && selectedContractDuration);
	const isStep2Completed = areDocsConfirmed;
	const accountType = getCurrentUserAccountTypeFromToken();
	const isSuperUser = isAdmin(getRoleNameFromToken());

	useEffect(() => {
		if (selectedContractDuration) {
			if (sideBarStep1BottomRef && sideBarStep1BottomRef.current) {
				sideBarStep1BottomRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
	});

	const handleBookWithoutPayment = async () => {
		try {
			setLoadingState(true);

			let reservationParams = {
				stockId: selectedStockId,
				boxId: selectedBox.UnitID,
				arrivalDate,
				contractDuration: selectedContractDuration,
				insuranceSchemeId: selectedInsuranceSchemeId,
				userId: getCurrentUserIdFromToken(),
			};

			let result = await request('POST', 'boxes/reserve', reservationParams);

			setLoadingState(false);

			if (result.redirectionUrl) {
				window.location = result.redirectionUrl;
			}
		} catch (error) {
			setLoadingState(false);
		}
	};

	const handleBookWithPayment = async () => {
		try {
			setLoadingState(true);

			let bookingParams = {
				stockId: selectedStockId,
				invoicingPeriod: boxBookingContext.bookingMeta.invoicingPeriod,
				boxId: selectedBox.UnitID,
				arrivalDate: getDateString(arrivalDate),
				boxSize: selectedBox.PhysicalSize,
				contractDuration: selectedContractDuration,
				insuranceSchemeId: selectedInsuranceSchemeId,
				userId: getCurrentUserIdFromToken(),
				paymentMethod,
				unitNumber: selectedBox.UnitNumber,
			};

			let bookedBox = await request('POST', 'boxes/book', bookingParams);

			setLoadingState(false);

			let amount = round(boxBookingContext.bookingMeta.selectedBox.MonthRate * boxBookingContext.bookingMeta.invoicingPeriod);
			let discountPercent = boxBookingContext.bookingMeta.discountPercent;
			let discountAmount = round(amount * discountPercent / 100);
			let discountedPrice = round(amount - discountAmount);
			let vatAmount = round((discountedPrice) * boxBookingContext.bookingMeta.vatRate / 100);
			let totalAmount = round(discountedPrice + vatAmount);

			let paymentData = {
				amount,
				boxId: bookedBox.id,
				userId: boxBookingContext.bookingMeta.user._id,
				discountPercent,
				discountAmount,
				currency: boxBookingContext.bookingMeta.selectedStock.currency,
				vatRate: boxBookingContext.bookingMeta.vatRate,
				vatAmount,
				totalAmount,
				processor: boxBookingContext.bookingMeta.paymentMethod,
				arrivalDate: boxBookingContext.bookingMeta.arrivalDate ? getDateString(boxBookingContext.bookingMeta.arrivalDate) : null
			};

			let paymentResult = await request('POST', 'payments', paymentData);

			if (paymentResult.redirectionUrl) {
				window.location = paymentResult.redirectionUrl;
			}
		} catch (error) {
			setLoadingState(false);
		}
	};

	const isCubicBox = selectedBox && selectedBox.UnitNumber.includes('KB');

	let userToBook = boxBookingContext.bookingMeta.user || {};

	let isPassportPhotoNeedToBeUploaded = userToBook.accountType !== 'legal' &&
		(!userToBook.isPassportFileUploaded || userToBook.passportKYCRejectionReason);

	let isSelfiePhotoNeedToBeUploaded = userToBook.accountType !== 'legal' &&
		(!userToBook.isSelfieFileUploaded || userToBook.selfieKYCRejectionReason);

	let isAddressPhotoNeedToBeUploaded = userToBook.accountType !== 'legal' &&
		(!userToBook.isSelfieFileUploaded || userToBook.selfieKYCRejectionReason);

	let isExtraPhotoNeedToBeUploaded = userToBook.accountType === 'legal' &&
		(!userToBook.isExtraFileUploaded || userToBook.extraKYCRejectionReason);

	let isPhotosNeedToBeUploaded = isPassportPhotoNeedToBeUploaded || isSelfiePhotoNeedToBeUploaded || isAddressPhotoNeedToBeUploaded || isExtraPhotoNeedToBeUploaded;

	let isNeedToDisplayUploadDocsButton = boxBookingContext.bookingMeta.areDocsSent ?
		false :
		(isPhotosNeedToBeUploaded && !isDocumentsModalOpened);

	let getMinContractDurationByDiscount = (discountPercent) => {
		if (discountPercent === 5) {
			return 3;
		}

		if (discountPercent === 10) {
			return 6;
		}


		if (discountPercent === 15) {
			return 11;
		}

		return boxBookingContext.bookingMeta.selectedContractDuration;
	};

	let handleDiscountSelection = (e) => {
		let discountPercent = e.target.value;

		boxBookingContext.setDiscountPercent(discountPercent);

		let contractDuration = getMinContractDurationByDiscount(discountPercent);

		boxBookingContext.setContractDuration(contractDuration);

		let invoicingPeriod = discountPercent ?
			contractDuration :
			1;

		boxBookingContext.setInvoicingPeriod(invoicingPeriod);
	};

	return (
		<Drawer
			width={(window.innerWidth > 500) ? 450 : '100%'}
			placement="right"
			onClose={close}
			visible={isOpened}
			footer={
				<SideBarFooter
					languageSchema={languageSchema}
					currentTab={currentTab}
					setTab={setCurrentTab}
					user={user}
					areDocsUploaded={!isNeedToDisplayUploadDocsButton}
				/>
			}
			title={
				<div>
					{
						selectedBox &&
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<div className="text-center">
								{languageSchema.BoxBooking.box} <b>{selectedBox.UnitNumber}</b>
								{
									<p className='mb-0 small'>
										{selectedBox.PhysicalSize} {languageSchema.Etc.metersShortLetter}<sup>{isCubicBox ? 3 : 2}</sup>
									</p>
								}

							</div>

							<div className="text-center">
								<b>{displayAmount(round(selectedBox.MonthRate + selectedBox.MonthRate * boxBookingContext.bookingMeta.vatRate / 100), boxBookingContext.bookingMeta.selectedStock.currency)}</b> / <br />
								{languageSchema.BoxBooking.month}
							</div>
						</div>
					}
				</div>
			}
		>
			{!_.isEmpty(selectedBox) &&
				<div>
					<Tabs
						onTabClick={setCurrentTab}
						activeKey={currentTab}
						type="card"
						size="small"
					>
						<Tabs.TabPane
							tab={languageSchema.BoxBooking.bookingParams}
							key="1"
						>
							<ChoiceOfArrivalDate
								title={languageSchema.BoxBooking.choiceOfArrivalDateTitle}
								text={languageSchema.BoxBooking.choiceOfArrivalDateText}
								placeholderText={languageSchema.BoxBooking.choiceOfArrivalDatePlaceholder}
							/>

							<PeriodSelector
								monthRate={selectedBox.MonthRate}
								insuranceMonthlyAmount={0}
								languageSchema={languageSchema}
								language={language}
								accountType={accountType}
								currency={boxBookingContext.bookingMeta.selectedStock.currency}
							/>

							{/*
							<div>
								<div>Получить скидку за предоплату:</div>
								<div className="flex-center">
									<Radio.Group
										onChange={handleDiscountSelection}
										value={boxBookingContext.bookingMeta.discountPercent}
									>
										<Radio.Button value={0}>Без скидки</Radio.Button>
										<Radio.Button value={5}>5% {(boxBookingContext.bookingMeta.discountPercent === 5) && ('За 3 месяца')}</Radio.Button>
										<Radio.Button value={10}>10% {(boxBookingContext.bookingMeta.discountPercent === 10) && ('За 6 месяцев')}</Radio.Button>
										<Radio.Button value={15}>15% {(boxBookingContext.bookingMeta.discountPercent === 15) && ('За 11 месяцев')}</Radio.Button>
									</Radio.Group>
								</div>
							</div>
							*/}


							{/*
								<InsuranceSelector
									stockId_sm={selectedStockId}
								/>
								*/}


							<div ref={sideBarStep1BottomRef} />
						</Tabs.TabPane>

						<Tabs.TabPane
							disabled={!isStep1Completed}
							tab={languageSchema.BoxBooking.docsAgreement}
							key="2"
						>
							<DocsList
								languageSchema={languageSchema}
								language={language}
								getContractDetailsForContractPdf={() => {}}
							/>

							{
								isNeedToDisplayUploadDocsButton &&
								<div className="text-center mt-2 mb-2">
									<button className="btn btn-primary" onClick={() => setDocumentsModalOpened(true)}>
										Загрузить паспортные данные
									</button>
								</div>
							}
						</Tabs.TabPane>

						<Tabs.TabPane
							disabled={!isStep1Completed || !isStep2Completed}
							tab={languageSchema.BoxBooking.reserveOrPay}
							key="3"
						>
							<>
								<h4 className="mb-3">{languageSchema.BoxBooking.payment}</h4>
								{/*
									<div>
										<button
											style={{ fontSize: '1em', width: '100%' }}
											type='button'
											className={'btn btn-success'}
											onClick={handleBookWithoutPayment}
											disabled={isLoading || (!isSuperUser && (!arrivalDate || !areDocsConfirmed))}
										>
											{
												isLoading ?
													languageSchema.Etc.wait :
													languageSchema.BoxBooking.bookWithoutPayment
											}
										</button>

										<div className="mt-2">
											<Alert
												message={
													<div>
														<span style={{ fontSize: 18, textAlign: 'center' }}><b>{languageSchema.BoxBooking.andPayLater}</b></span> <br />
														<span>
															{languageSchema.BoxBooking.payLaterDesctiption}
														</span>
													</div>
												}
												type="info"
											/>
										</div>
									</div>

									<div style={{ fontSize: '24px', textAlign: 'center', marginTop: '15px', marginBottom: '15px' }}>
										{languageSchema.Etc.or}
									</div>
								*/}
								<div>
									<PaymentMethodSelector
									/>
									<button
										style={{ fontSize: '1em', width: '100%' }}
										type='button'
										className={'btn btn-success'}
										onClick={handleBookWithPayment}
										disabled={isLoading || (!isSuperUser && (!arrivalDate || !areDocsConfirmed || !paymentMethod))}
									>
										{
											isLoading ?
												languageSchema.Etc.wait :
												languageSchema.BoxBooking.bookAndPay
										}
									</button>
								</div>
							</>
						</Tabs.TabPane>
					</Tabs>
				</div>
			}

			{
				isDocumentsModalOpened &&
					<UploadDocsModal
						isDocumentsModalOpened={isDocumentsModalOpened}
						setDocumentsModalOpened={setDocumentsModalOpened}
						languageSchema={languageSchema}
					/>
			}
		</Drawer>
	);
};


let mapStateToProps = state => ({
	language: state.common.language,
	languageSchema: getLanguageSchema(state.common.language),
});

export default connect(mapStateToProps)(SideBar);
