import request from 'helpers/request';

const endpoint = 'payments';

export const fetchPayments = (page=0, limit=100) => async (dispatch) => {
	const payments = await request('GET', `${endpoint}?page=${page}&limit=${limit}`);
	dispatch({ type: 'FETCH_PAYMENTS', payload: payments });
};

export const fetchPaymentsCount = () => async (dispatch) => {
	const { count } = await request('GET', `${endpoint}/count`);
	dispatch({ type: 'FETCH_PAYMENTS_COUNT', payload: count });
};
