import moment from 'moment';

import { ACCOUNT_TYPES_MAP } from 'utils/constants';

export function pow(number, power) {
	return Number(number) ** Number(power);
}

export function getUserName (user) {
	return user.accountType === ACCOUNT_TYPES_MAP.legal ?
		user.company :
		`${user.firstName} ${user.lastName}`;
}

export const cookieExpiryDate = () => {
	let expiryDate = moment().add(1, 'months').format('MM-DD-YYYY');

	return moment(expiryDate).toDate();
};



