export default {
	createdAt: 'erstellt',
	success: 'erfolgreich',
	loading: 'herunterladen...',
	yes: 'ja',
	no: 'nein',
	wait: 'Warten Sie, bitte ...',
	lastUpdate: 'letzte Aktualisierung',
	noUpdates: 'keine Aktualisierungen',
	choose: 'Auswählen',
	currency: 'Währung',
	all: 'alle',
	settings: 'Einstellungen',
	notSet: 'nicht angegeben',
	reset: 'zurücksetzen',
	from: 'vom',
	to: 'bis zum',
	send: 'senden',
	noItems: 'Die Liste ist leer',
	searchPlaceholder: 'Suche...',
	metersShortLetter: 'm',
	monthShort: 'Mon.',
	max: 'max',
	months: 'Monat(-e)',
	select: 'Auswählen...',
	monthsWord: 'Monate',
	payOnce: 'Für den gesamten Zeitraum',
	once: 'Pro...',
	monthly: 'mtl.',
	toHome: 'Mein Account',
	areYouSureYouWantToSaveChanges: 'Möchten Sie die Änderungen speichern?',
	download: 'Herunterladen',
	somethingWentWrong: 'Oops, etwas ist schief gelaufen...',
	somethingWentWrongDescription: 'Probieren Sie es nochmal oder wenden Sie sich an das Support-Team',
	or: 'Oder',
	stepOne: 'Schritt 1',
	stepTwo: 'Schritt 2',
	stepThree: 'Schritt 3',
	from3rdMonth: 'Ab dem 3. Monat',
	cancel: 'Kündigen',
	heading: 'Überschrift',
	subHeading: 'Unterposition',
	normal: 'Üblich'
};
