export default {
	paymentSuccess: 'Оплата прошла успешно',
	choosePaymentMethodString: 'Выберите способ оплаты',
	paymentFailed: 'Оплата не прошла',
	paymentPending: 'Оплата обрабатывается',
	paymentPendingDescription: 'Ваш платеж имеет статус: "Обработка платежа". Этот статус означает, что платежная платформа в настоящее время проверяет, может ли ваш платеж быть завершен или нет.',
	processorTransactionId: 'Номер транзакции',
	paymentDetails: 'Детали оплаты',
	cycleBillingDay: 'День оплаты',
	price: 'Цена',
	cardHolder: 'Держатель карты',
	truncatedCardNumber: 'Номер карты',
	myBoxes: 'Мои боксы',
	book: 'Бронирование',
	tryPaymentLater: 'Ой, что-то пошло не так. <br /> Пожалуйста, проверьте свои данные и повторите попытку позже.',
	errorReason: 'Описание ошибки',
	checkEmail: 'Пожалуйста, проверьте электронную почту, мы отправили подтверждение. Письмо может находиться в папке спам',
	includeVAT: 'С учетом НДС',
	vat: 'НДС',
	pay: 'Оплатить',
	totalToPayString: 'Итого к оплате',
	threeDSecureRedirect: 'Вы можете быть перенаправлены на страницу прохождения 3D-secure верификации',
	poweredByStripe: 'Разработано Stripe',
	payByCard: 'Картой',
	invoiceNumber: 'Номер счета',
	amount: 'Сумма',
	system: 'Cистема',
	date: 'Дата'
};
