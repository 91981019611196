import decodeJwt from 'jwt-decode';
import moment from 'moment';
import { getToken } from 'helpers/request';
import { ROLES_MAP,  USA_DATE_FORMAT, EURO_DATE_FORMAT } from 'utils/constants';

export const isDevelompent = () => window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

export const getApiToken = () => localStorage.getItem('accessToken');
export const isTokenExist = () => !!getApiToken();

export const isTokenValid = () => {
	const decodedToken = getDecodedToken();

	if (!decodedToken || (decodedToken && Date.now() >= decodedToken.exp * 1000)) {
		return false;
	}

	return true;
};

export const getCurrentStockId = stock => 'stockId_sm' in stock ? stock.stockId_sm : stock._id;

export const getCurrentStockIdByBox = box => 'stockId_sm' in box ? box.stockId_sm : box.stockId;

export const getPrettyLockStatus = (status, languageSchema) => {
	switch (status) {
	case 'opened':
		return { title: languageSchema.Stock[status], color: 'green' };

	case 'closed':
		return { title: languageSchema.Stock[status], color: 'red' };

	default:
		return { title: 'N/A', color: 'gray' };
	}
};

export const getPageCount = (count, perPage) => Math.ceil(count / perPage);

const getDecodedToken = () => {
	try {
		const token = getToken();

		if (!token) {
			return null;
		}

		return decodeJwt(token);
	} catch (error) {
		return null;
	}
};

export const getRoleNameFromToken = () => {
	const decodedToken = getDecodedToken();
	if (!decodedToken) {
		return null;
	}

	return decodedToken.role;
};

export const getCurrentUserIdFromToken = () => {
	const decodedToken = getDecodedToken();
	if (!decodedToken) {
		return null;
	}

	return decodedToken.id;
};

export const getCurrentUserAccountTypeFromToken = () => {
	const decodedToken = getDecodedToken();
	if (!decodedToken) {
		return null;
	}

	return decodedToken.accountType;
};

export const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

export const isAdmin = role => [ROLES_MAP.superUser].includes(role);

export const isClient = role => role === ROLES_MAP.client;

export const getUserName = user => user.accountType === 'legal' ?
	user.company :
	`${user.firstName} ${user.lastName}`;

/**
 * Create invoice Url from parameters
 *
 * @param {string} user
 * @param {string} contract
 * @param {string} invoice
 * @returns {string}
 */
export function createUserInvoiceUrl(user, contract, invoice) {
	return `/users/${user}/contracts/${contract}#activeInvoice=${invoice}`;
}

export function getStorageValue(key, defaultValue) {
	const saved = localStorage.getItem(key);
	const initial = saved !== null ? JSON.parse(saved) : defaultValue;
	return initial;
}


export const formatDateFromUSAToEuroFormat = dateInUSAFormat =>
	moment(dateInUSAFormat, USA_DATE_FORMAT).format(EURO_DATE_FORMAT);

const signatures = {
	JVBERi0: 'application/pdf',
	R0lGODdh: 'image/gif',
	R0lGODlh: 'image/gif',
	iVBORw0KGgo: 'image/png',
	'/9j/': 'image/jpg',
};

export const getMimeType = (base64)=>{
	for(const sign in signatures)if(base64.startsWith(sign))return signatures[sign];
};

export const scrollToDiv = (div, offset = 0) => {
	const section = document.querySelector(div);

	const y = section.getBoundingClientRect().top + window.pageYOffset + offset;

	window.scrollTo({top: y, behavior: 'smooth'});
};