import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Segmented } from 'antd';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';

import { mapIsDragging } from 'redux/actions';
import { displayAmount, round, getCurrencySign } from 'helpers/amount';
import { getLanguageSchema } from 'dashboard-commons/languages';
import BoxBookingContext from 'screens/BoxBookingNew/Context';
import BoxesMap from 'components/Stock/Map/BookingBoxesMap';
import { STEP_1_ANCHOR } from 'utils/constants';
import { scrollToDiv } from 'utils/functions';

const floorsMap = {
	'YA': '1й этаж',
	'YB': '2й этаж',
	'YC': '3й этаж'
};

const BoxesList = (props) => {
	const { fetchedBoxes, selectedSizeGroup, languageSchema } = props;
	const boxBookingContext = React.useContext(BoxBookingContext);
	const [viewers, setViewers] = useState({});
	let isMobile = window.innerWidth < 900;

	let [viewMode, setViewMode] = useState(isMobile ? 'list' : 'map_and_list');

	let handleChangeViewMode = (viewMode) => {
		setViewMode(viewMode);

		Object.keys(viewers).map(viewerId => {
			viewers[viewerId].zoom(1, 200, 1);
		});
	};

	let groupedBoxes = _.groupBy(fetchedBoxes, box => box.PhysicalSize);
	let filteredBoxes = _.pick(groupedBoxes, selectedSizeGroup.sizeCodes);

	const handleBoxSelection = (box) => () => {
		boxBookingContext.setSelectedBox(box);
		boxBookingContext.setPureRate(box.MonthRate);

		if (isMobile || viewMode === 'map') {
			boxBookingContext.setSideBarOpened(true);
		}
	};

	const selectModeOptions = [
		{
			label: (
				<div className="inline-flex vertical-centered">
					<div>
						<BarsOutlined /> { ' | '}
						<AppstoreOutlined />
					</div>
					<div>{languageSchema.BoxBooking.mapAndList}</div>
				</div>
			),
			value: 'map_and_list',
			isMobileHidden: true,
		},
		{
			label: (
				<div className="inline-flex vertical-centered">
					<AppstoreOutlined /> {' '}
					<div>{languageSchema.BoxBooking.map}</div>
				</div>
			),
			value: 'map',
		},
		{
			label: (
				<div className="inline-flex vertical-centered">
					<BarsOutlined /> {' '}
					<div>{languageSchema.BoxBooking.list}</div>
				</div>
			),
			value: 'list',
		},
	].filter(option => {
		if (isMobile && option.isMobileHidden) {
			return false;
		}

		return true;
	});

	return (
		<div className='pt-4 px-2'>
			<div className="mb-2 mt-2" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: 'column' }}>
				<div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}>
					{languageSchema.BoxBooking.step3SelectABox}
				</div>
				
				<div className="back-button" onClick={() => scrollToDiv(STEP_1_ANCHOR, -75)}>&larr; {languageSchema.Buttons.back}</div>
				<div className="flex-center flex w-100">
					<Segmented
						value={viewMode}
						onChange={handleChangeViewMode}
						options={selectModeOptions}
					/>
				</div>
			</div>

			<div id="boxes_map" style={{ marginLeft: '-25px', marginRight: '-25px' }} className="row">
				{
					(viewMode === 'map_and_list' || viewMode === 'map') &&
					<div style={{ overflow: 'hidden', minHeight: '550px' }} className={`col-md-${viewMode === 'map_and_list' ? 9 : 12}`}>
						<BoxesMap
							stockId={boxBookingContext.bookingMeta.selectedStockId}
							selectedSizeCodes={selectedSizeGroup.sizeCodes}
							selectBox={handleBoxSelection}
							selectedBoxUnitNumber={boxBookingContext.bookingMeta.selectedBox && boxBookingContext.bookingMeta.selectedBox.UnitNumber}
							yourChoice={languageSchema.BoxBooking.yourChoice}
							freeBoxesOfTheRightSize={languageSchema.BoxBooking.freeBoxesOfTheRightSize}
							occupiedBoxes={languageSchema.BoxBooking.occupiedBoxes}
							step3SelectABoxOnTheMap={languageSchema.BoxBooking.step3SelectABoxOnTheMap}
							objects={boxBookingContext.bookingMeta.selectedStock.maps}
							boxes={fetchedBoxes}
							languageSchema={languageSchema}
							setViewers={setViewers}
							viewers={viewers}
							onTouchStart={() => props.mapIsDragging(true)}
							onTouchEnd={() => props.mapIsDragging(false)}
						/>
					</div>
				}
				{
					(viewMode === 'map_and_list' || viewMode === 'list') &&
					<div className={`col-md-${viewMode === 'map_and_list' ? 3 : 12} mt-4 pt-4 table-scroll`}>
						<div className="mb-5" id="boxesListWrapper">
							{
								selectedSizeGroup.sizeCodes.sort((a, b) => {
									if (a < b) {
										return -1;
									}

									return 1;
								}).map(key => (filteredBoxes[key]
									.filter(box => {
										// let isBoxShouldBeHidden = box.statusCode !== '1';
										let isBoxShouldBeHidden = false; //box.statusCode !== '1';

										return !isBoxShouldBeHidden && !box.IsOccupied && !box.IsReserved;
									}))
									.map(box => {
										let isActive = boxBookingContext.bookingMeta.selectedBox &&
											boxBookingContext.bookingMeta.selectedBox.UnitNumber === box.UnitNumber;

										let isCubicBox = box.UnitNumber.includes('KB');

										return (
											<div
												key={box.UnitNumber}
												id={box.UnitNumber}
												className={`border-card ${isActive && 'active_item'}`}
												style={{ maxWidth: '600px', margin: '10px auto' }}
											>
												<div
													className="border-card_box_info"
													disabled={box.IsOccupied === 1}
													onClick={handleBoxSelection(box)}
												>
													<div className={`content-wrapper flex-wrap vertical-centered ${viewMode === 'list' ? 'flex-space-between' : 'flex-center'}`}>
														<div className="label-group fixed">
															<p className="title text-center m-0"><span style={{ fontSize: '18px', fontWeight: '500', color: '#337ab7' }}>{box.UnitNumber}</span>
																{
																	viewMode === 'list' &&
																	<>
																		<br />
																		({ floorsMap[box.UnitNumber.slice(0,2)] })
																	</>
																}
															</p>
														</div>
														<div className="label-group">
															<div className="title">
																<span style={{ fontSize: '18px', fontWeight: '500', color: viewMode === 'list' ? '#337ab7' : 'black' }}>{box.PhysicalSize} {languageSchema.Etc.metersShortLetter}<sup>{isCubicBox ? 3 : 2}</sup></span>
															</div>
															{
																viewMode === 'list' &&
																<p className="caption m-0">Площадь</p>
															}
														</div>

														<div className="label-group mx-2 text-center">
															<span style={{ fontSize: '18px', color: '#25a549' }}><b>{displayAmount(box.MonthRate + round(box.MonthRate * boxBookingContext.bookingMeta.vatRate / 100))}</b></span>
															{getCurrencySign(boxBookingContext.bookingMeta.selectedStock.currency)}/мес.
														</div>
													</div>
												</div>

												{
													isActive &&
												<div className="btn btn-primary" onClick={boxBookingContext.setSideBarOpened}>
													{languageSchema.BoxBooking.continue} &gt;&gt;
												</div>
												}
											</div>
										);
									}))
							}
						</div>
					</div>
				}
			</div>
		</div>
	);
};

let mapStateToProps = state => ({
	languageSchema: getLanguageSchema(state.common.language),
});

let mapDispatchToProps = {
	mapIsDragging
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxesList);
