import React from 'react';

import './Card.scss';

function Card(props) {

	const { grid = 4, bcgImage } = props;

	const height = bcgImage ? '200px' : 'auto';
	return (
		<div className={`col-sm-${grid} my-3`} style={{ minHeight: height }}>
			<div className="card" style={{ height: 'auto', backgroundImage: `url(${bcgImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
				<div className="card-body d-flex flex-column justify-content-between">
					{props.children}
				</div>
			</div>
		</div>
	);
}

export default Card;
