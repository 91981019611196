import React from 'react';

import Table from 'components/UI/Table';
import Heading from 'components/UI/Heading';
import NoItems from 'components/common/NoItems';

import ListItem from './ListItem';

class EndingContractsList extends React.PureComponent {
	renderList = topDebtors => (
		<Table
			centerHeader
			header={[
				this.props.languageSchema.User.client,
				this.props.languageSchema.Stock.stock,
				this.props.languageSchema.Contract.contractNumber,
				this.props.languageSchema.Contract.debt,
			]}
		>
			{topDebtors.map(contract =>
				<ListItem key={contract._id} contract={contract}/>
			)}
		</Table>
	);

	render() {
		let { languageSchema, topDebtors } = this.props;
		let isDataToDisplayPresented = 0 < topDebtors.length;

		return (
			<div className="card">
				<Heading>{languageSchema.HomePage.topDebtors}</Heading>
				<div className="mb-3" style={{ fontSize: '14px' }}>
					{
						isDataToDisplayPresented ?
							this.renderList(topDebtors) :
							<div className="center"><NoItems isLogo={false}/></div>
					}
				</div>
			</div>
		);
	}
}

export default EndingContractsList;
