import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Modal, DatePicker, Button } from 'antd';
import moment from 'moment';
import { parseUrl } from 'query-string';
import { InfoCircleTwoTone } from '@ant-design/icons';

import { connect } from 'react-redux';
import { openBox } from 'redux/actions';
import { toastr } from 'react-redux-toastr';

import Card from 'components/UI/Card';
import request from 'helpers/request';

const ListItem = props => {
	const { box, stock, fetchBoxes,  languageSchema } = props;

	const router = useLocation();
	const history = useHistory();

	const [isModalOpen, setIsModalOpen] = useState(() => {
		const { boxId, action } = parseUrl(router.search).query;
		return action === 'end' && box.UnitNumber === boxId;
	});
	const [boxContract, setBoxContract] = useState(null);
	const [isContractTerminating, setIsContractTerminating] = useState(false);
	const [leaveDate, setLeaveDate] = useState(null);

	const isBoxPaid = box.paidAhead >= 0;

	useEffect(() => {
		request('GET', `contracts/${box.ContractID}`)
			.then((currentContract) => {
				setBoxContract(currentContract);
				setLeaveDate(box.leaveDate ? moment(box.leaveDate, 'DD/MM/YYYY HH:mm') : moment(`${currentContract.EndDate} 12:00`, 'MM/DD/YYYY HH:mm'));
			})
			.catch(({ message }) => console.error('message', message));
	}, []);

	useEffect(() => {
		if(boxContract) {
			let { EndDate: endDate } = boxContract;
			let expectedEndDateMoment = expectedEndDateMoment = moment(moment().add(7, 'd').format('MM/DD/YYYY'));
			let endDateMoment;

			if (endDate.includes('/')) {
				endDateMoment = moment(endDate, 'MM/DD/YYYY');
			} else {
				endDateMoment = moment(moment(endDate, 'DD.MM.YYYY').format('MM/DD/YYYY'));
			}

			setIsContractTerminating(expectedEndDateMoment.isSameOrAfter(endDateMoment));
		}
	}, [boxContract]);

	const openBox = (box) => async () => {
		toastr.confirm(props.languageSchema.Stock.areYouSureYouWantToOpenBox, {
			onOk: () => {
				props.openBox(box);
			}
		});
	};

	const extendBox = () => {
		toastr.confirm(props.languageSchema.Contract.extendContract, {
			onOk: handleExtendContractSubmit
		});
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleExtendContractSubmit = async () => {
		try {
			await request('POST', `boxes/${box.UnitID}/extend`,  {
				userId: box.internalUserId,
				UnitNumber: box.UnitNumber,
				boxId: box._id,
			});

			fetchBoxes(box.internalUserId);
		}	catch(e) {
			console.error('message', e.message);
		}
	};

	const handleEndDateSubmit = async () => {
		try {
			if(boxContract) {
				const dateString = leaveDate.format('DD/MM/YYYY HH:mm');

				await request('POST', `boxes/${box.UnitID}/leave`, {
					userId: box.internalUserId,
					UnitNumber: box.UnitNumber,
					boxId: box._id,
					leaveDate: dateString,
				});
				fetchBoxes(box.internalUserId);
			}

			setIsModalOpen(false);
			history.replace(router.pathname);
		} catch (error) {
			console.error('message', error.message);
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<Card>
			<div className="card-title d-flex justify-content-between flex-wrap">
				<div>
					<Link
						style={{ textDecoration: 'underline' }}
						to={{
							pathname: `/boxes/${box._id}`,
							state: {
								ContractID: box.ContractID,
								invoices: box.invoices,
								stockId: stock._id,
								boxId: box._id,
							}
						}}
					>
						<h5>{languageSchema.BoxBooking.box} {box.UnitNumber}</h5>
					</Link>
				</div>
				<div>
					<Link
						className="btn btn-sm my-box-btn btn-outline-secondary"
						to={{
							pathname: `/users/${box.internalUserId}/contracts/${box.ContractID}`,
						}}
					>
						{languageSchema.Contract.contractDetails}
					</Link>
				</div>
			</div>
			<div className="card-text">
				<div>
					{languageSchema.Contract.boxsize}: <b>{box.PhysicalSize}{languageSchema.Etc.metersShortLetter}²</b>
					{
						box.Depth && box.Width && box.Height &&
						<span className="text-bold">{'	 '}({box.Height}m x {box.Depth}m x {box.Width}m) </span>
					}
				</div>
			</div>
			<div className="d-flex justify-content-between flex-wrap">
				<div>
					<div className={`${!isBoxPaid ? 'font-red' : ''}`}>
						{languageSchema.Contract.paidInDays}: {isBoxPaid ? box.paidAhead : 0}
					</div>
				</div>
				<div>
					<button style={{ marginLeft: '5px' }}
						className="btn btn-md btn-success vertical-flex"
						onClick={openBox(box)}
					>
						<i className={'fas fa-key'}> {' '} </i>
						{' '} {languageSchema.Buttons.open}
					</button>
				</div>

			</div>
			<div className="flex align-items-center my-2 p-2 rounded" style={{background: '#cee5f8', color: '#6978a0', width: 'fit-content'}}>
				<InfoCircleTwoTone style={{fontSize: '20px', marginRight: '5px'}}/>
				<span style={{fontSize: '12px'}}>{languageSchema.Stock.notifyOpenLock}</span>
			</div>

			{
				box.leaveDate && <div>
					<span className='font-green'>{languageSchema.Etc.endDate}: {box.leaveDate}</span>
				</div>
			}

			{isContractTerminating && <div className='my-2 d-flex flex-column'>
				<Button type="primary" onClick={showModal} className="mb-2">
					{languageSchema.Buttons.notifyLeave}
				</Button>
				{box.isExtended ?
					<span className='font-green'>{languageSchema.Etc.extensionDescription}</span>
					:
					<Button type="primary" onClick={extendBox}>
						{languageSchema.Buttons.notifyExtend}
					</Button>
				}
			</div>}

			<Modal title={languageSchema.Etc.endDate} open={isModalOpen} onOk={handleEndDateSubmit} onCancel={handleCancel}>
				{
					<div className='flex align-center'>
						<div className='mr-2'>{languageSchema.Etc.chooseEndDate} </div>
						<DatePicker showTime value={leaveDate} onChange={setLeaveDate} format={'DD/MM/YYYY HH:mm'}/>
					</div>
				}
			</Modal>
		</Card>
	);
};


let mapStateToProps = ({ common }) => {
	return {
		language: common.language,
	};
};

let mapDispatchToProps = {
	openBox,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
