import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Table from 'components/UI/Table';
import { fetchBoxCats, regroupBoxes } from 'redux/actions';
import { serverUrl } from 'utils/constants';

class BoxCatList extends Component {
	componentDidMount() {
		const {
			isFetched,
			fetchBoxCats
		} = this.props;

		if (!isFetched) {
			fetchBoxCats();
		}
	}

	handleClick = () => {
		this.props.regroupBoxes();
	}

	render() {
		const { boxcats } = this.props;
		
		return (
			<div>
				<div className="custom-filter mb-3">
					<div className="form-row">
						<div className="col">
							<label className="d-block">Опции</label>
							<Link to="/boxcats/add" className="btn btn-success mr-2">Добавить категорию</Link>
						</div>
					</div>
				</div>
				<div className="mb-3">
					<Table header={['#', 'Название', 'Синоним', 'Описание', 'Размеры', 'Цена за м²', 'Изображение', 'Опции']}>
						{boxcats.map((boxcat, i) => {
							const sizeLabel = boxcat.minSize === boxcat.maxSize
								? `${boxcat.minSize} м²`
								: `от ${'minSize' in boxcat ? boxcat.minSize : '?'} до ${'maxSize' in boxcat ? boxcat.maxSize : '?'} м²`;

							const boxCatImgUrl = boxcat.image ? `${serverUrl}/${boxcat.image}` : null;

							return (
								<tr key={boxcat._id}>
									<th scope="row">{i + 1}</th>
									<td>{boxcat.name || '-'}</td>
									<td>{boxcat.alias || '-'}</td>
									<td>{boxcat.description || '-'}</td>
									<td width="15%">{sizeLabel}</td>
									<td width="15%">{boxcat.pricePerMeterQuadratic || '-'} руб</td>
									<td width="15%" className="text-center">
										{boxCatImgUrl ? <img src={boxCatImgUrl} alt="" height="100" /> : '-' }
									</td>
									<td>
										<Link to={`/boxcats/${boxcat._id}`} className="btn btn-sm btn-outline-secondary">Изменить</Link>
									</td>
								</tr>
							);
						})}
					</Table>
				</div>
				<div className="mb-3">
					<button className="btn btn-outline-secondary mr-2" onClick={this.handleClick}>Перегруппировать боксы</button>
					(если размеры были обновлены)
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ boxcats }) => {
	return {
		boxcats: boxcats.data,
		isFetched: boxcats.isFetched,
	};
};
export default connect(mapStateToProps, { fetchBoxCats, regroupBoxes })(BoxCatList);
