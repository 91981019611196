import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchNotifications } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import NotificationItem from './NotificationItem';

let SystemNotifications = (props) => {
	let { user, notifications, fetchNotifications, language } = props;

	useEffect(() => {
		try {
			let globalStatus = user && user._id ? 'all' : 'global';

			fetchNotifications('active', globalStatus);
		} catch (e) {
			console.error(e);
		}
	}, [user]);

	return (
		<div className="system-notification">
			{
				notifications
					.filter(notifcation => {
						let isActive = notifcation.isActive;
						let forAuthorizedUsers = (!notifcation.isGlobal && !(user && user._id));

						if (!isActive || forAuthorizedUsers) {
							return false;
						}

						return true;
					})
					.map(notification => (
						<NotificationItem notification={notification} language={language} key={notification.id} />
					))
			}
		</div>
	);
};

let mapStateToProps = (state) => ({
	user: state.auth.me,
	languageSchema: getLanguageSchema(state.common.language),
	notifications: state.notifications.data,
	language: state.common.language,
});

let mapDispatchToProps = {
	fetchNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemNotifications);
