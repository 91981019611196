import { connect } from 'react-redux';
import { addDays } from 'date-fns';

import { setArrivalDate } from 'redux/actions/boxbooking';

import ChoiceOfArrivalDate from './ChoiceOfArrivalDate';

const today = new Date();
const maxDate = addDays(today, 29);

const mapStateToProps = (state) => ({
	arrivalDate: state.boxbooking.arrivalDate,
	today,
	maxDate,
	language: state.common.language,
});

const mapDispatchToProps = (dispatch) => ({
	setArrivalDate: (arrivalDate) => dispatch(setArrivalDate(arrivalDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceOfArrivalDate);
