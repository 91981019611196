import React from 'react';
import { Button, Modal } from 'antd';

import history from 'utils/history';


function RejectedDocsModal(props) {
	const { languageSchema, user, isModalOpen, setIsModalOpen } = props;

	const redirectToProfile = () => {
		history.push(`/users/${user._id}`);
	};

	return (
		<Modal
			title={<h5 className="text-center">{languageSchema.SignUp.rejectedDocuments}</h5>}
			open={isModalOpen}
			onCancel={() => setIsModalOpen(false)}
			footer={[
				<Button key="submit" type="primary" onClick={redirectToProfile}>
					{languageSchema.SignUp.redirectToUploadDocuments}
				</Button>,
			]}
		>
			<div className='flex flex-column'>
				<h6>{languageSchema.User.rejectionReason}:</h6>
				<div className='flex flex-column'>
					{user.selfieKYCRejectionReason &&
						<div className='p-2'>
							<span className='font-weight-bold pr-1'>{languageSchema.SignUp.selfieWithPassport}:</span>{user.selfieKYCRejectionReason}
						</div>}

					{user.passportKYCRejectionReason &&
						<div className='p-2'>
							<span className='font-weight-bold pr-1'>{languageSchema.SignUp.passportPhoto}:</span>{user.passportKYCRejectionReason}
						</div>}

					{user.extraKYCRejectionReason &&
						<div className='p-2'>
							<span className='font-weight-bold pr-1'>{languageSchema.SignUp.extraDocumentPhoto}:</span>{user.extraKYCRejectionReason}
						</div>}

					{user.addressKYCRejectionReason &&
						<div className='p-2'>
							<span className='font-weight-bold pr-1'>{languageSchema.SignUp.addressDocumentPhoto}:</span>{user.addressKYCRejectionReason}
						</div>}
				</div>
			</div>
		</Modal>
	);
}



export default RejectedDocsModal;
