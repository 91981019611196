import React from 'react';
import { Link } from 'react-router-dom';

import { ACCOUNT_TYPES_MAP } from 'utils/constants';
import { displayAmount } from 'helpers/amount';

export default function ListItem(props) {
	let getUserName = (user) => user.accountType === ACCOUNT_TYPES_MAP.legal ?
		user.company :
		`${user.firstName} ${user.lastName}`;

	return (
		<tr className="text-center" key={props.contract._id}>
			<td>
				{
					props.contract.userId ?
						(<Link
							to={{
								pathname: `/users/${props.contract.userId._id}`,
							}}
						>
							{getUserName(props.contract.userId)}
						</Link>) :
						'-'
				}
			</td>

			<td>
				<Link to={`/boxes?stockId=${props.contract.stock._id}`}>
					{props.contract.stock.name}
				</Link>
			</td>

			<td>
				{
					props.contract.userId ?
						(<Link
							to={{
								pathname: `/users/${props.contract.userId._id}/contracts/${props.contract.contractid}`,
								state: {
									ContractID: props.contract.contractid,
									stockId: props.contract.stock._id,
								},
							}}
						>
							{props.contract.ContractNumber}
						</Link>) :
						props.contract.ContractNumber
				}
			</td>

			<td className="red" style={{ fontSize: '18px' }}>
				<b>{displayAmount(props.contract.debt, props.contract.stock.currency)}</b>
			</td>
		</tr>
	);
}
