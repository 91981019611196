import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getLanguageSchema } from 'dashboard-commons/languages';
import Table from 'components/UI/Table';
import { fetchCities } from 'redux/actions';
import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

class CityListScreen extends Component {
	componentDidMount() {
		const {
			isFetched,
			fetchCities
		} = this.props;

		if (!isFetched) {
			fetchCities();
		}
	}


	render() {
		const { cities, language: lang, languageSchema } = this.props;
		let language = lang.toLowerCase();

		return (
			<div className="card">
				<div className="custom-filter mb-3">
					<div className="form-row">
						<div className="col">
							<Link to="/cities/add" className="btn btn-success mr-2">
								{languageSchema.Cities.addCity}
							</Link>
						</div>
					</div>
				</div>
				<div className="mb-3">
					<Table header={['#', languageSchema.Cities.city, languageSchema.Cities.country, languageSchema.Cities.options]}>
						{cities.map((city, i) => {
							return (
								<tr key={city._id}>
									<th scope="row">{i + 1}</th>
									<td>{city.city || '-'}</td>
									<td>{countries.getName(city.country, language) || '-'}</td>
									<td>
										<Link
											to={`/cities/${city._id}`}
											className="btn btn-sm btn-outline-secondary"
										>
											{languageSchema.Cities.edit}
										</Link>
									</td>
								</tr>
							);
						})}
					</Table>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ cities, common }) => {
	return {
		cities: cities.data,
		isFetched: cities.isFetched,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
	};
};
export default connect(mapStateToProps, { fetchCities })(CityListScreen);
