import React from 'react';
import StockMarkerLogo from './stock.png'; // Cat resolve file that place on top of src

export default function StockSelectOption({ stock }) {
	let isMobile = window.innerWidth < 900;

	const styles = React.useMemo(() => {
		return {
			stockCard: {
				display: 'flex',
				flexDirection: isMobile ? 'column' : 'row',
				alignItems: 'center',
				justifyContent: isMobile ? 'center' : 'space-between',
				marginTop: 15,
				padding: isMobile ? 20 : 0,
				border: '2px solid rgb(60 173 38 / 55%)',
				overflow: 'hidden',
				zIndex: 20,
				paddingLeft: isMobile ? 20 : 30,
				position: 'relative',
			},
			workHoursContainer: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: '#25a549',
				padding: 30,
				paddingRight: 70,
				position: 'relative'
			},
		};
	}, [isMobile]);

	if (!stock) {
		return null;
	}

	return (
		<div>
			<div style={styles.stockCard}>

				<div style={{ display: 'flex', flexDirection :isMobile ? 'column' : 'row', alignItems: 'center', zIndex: 10 }}>
					<div style={{ margin: '15px' }}>
						<img alt="Stock Marker Logo" src={StockMarkerLogo} height="70" width="70"/>
					</div>
					<div style={{
						textAlign: isMobile ? 'center' : null,
					}}>
						<div className="green" style={{ fontSize: '22px', fontWeight: 'bold' }}>{stock.name}</div>
						<div>{stock.address}</div>
						<div style={{ fontWeight: 'bold' }}>
							{stock.cityId.city}, <i style={{ margin: '3px' }} className="fas fa-phone"></i>{stock.phone}
						</div>
					</div>
				</div>


				{
					!isMobile ?
						<div style={styles.workHoursContainer}>
							<div style={{
								position: 'absolute',
								transform: 'rotate(50deg) translateY(50px)',
								width: 150,
								left: -50,
								height: '400px',
								backgroundColor: '#25a549',
								zIndex: 1,
							}}></div>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 13, zIndex: 10 }}>
								<i style={{ margin: '3px', marginRight: '5px', marginTop: '10px', color: '#fff' }} className="fas fa-2x fa-clock"></i>
								<div style={{ fontSize: '20px', color: '#fff' }}>9:00 - 21:00</div>
							</div>
							<div style={{ borderRight: '1px solid lightgrey', marginLeft: '15px', marginRight: '15px', zIndex: 10 }}> </div>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#fff', zIndex: 10 }}>
								<i style={{ margin: '3px', color: '#fff' }} className="fas fa-phone"></i>{stock.phone}
							</div>
						</div> : null
				}
			</div>
		</div>
	);
}
