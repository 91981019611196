import React from 'react';
import { Field } from 'formik';

function FormCheckBox(props) {
	const {
		name, checked, label, placeholder,
		errors, touched, readOnly, disabled, onChange
	} = props;

	let isError = field => errors[field] && touched[field];

	let fieldProps = {};

	if (onChange) {
		fieldProps.onChange = onChange;
	}

	return (
		<div className="form-group row">
			<label htmlFor={name} className="col-sm-3 col-form-label">{label}</label>
			<div className="col-sm-9 text-center">
				<Field
					type="checkbox"
					className={`${isError(name) ? 'is-invalid' : ''}`}
					id={name}
					placeholder={placeholder}
					checked={checked || ''}
					readOnly={readOnly}
					disabled={disabled}
					{...fieldProps}
				/>
				{isError(name) && <div className="invalid-feedback">{errors[name]}</div>}
			</div>
		</div>
	);
}

export default FormCheckBox;
