export default {
	cities: 'Städte',
	stocks: 'Lager',
	accounts: 'Accounts',
	roles: 'Rollen/Funktionen',
	reports: 'Berichte',
	appeals: 'Schreiben',
	edit: 'Bearbeiten',
	exit: 'Ausloggen',
	changePassword: 'Das Passwort ändern',
	boxes: 'Lagerräume',
	myBoxes: 'Meine Lagerräume',
	profile: 'Profil',
	support: 'Kundendienst',
	book: 'Buchen',
	toHome: 'Startseite',
	topDebtors: 'Top-10 Schuldner',
	notifications: 'Notifications',
	monthlyBills: 'Monatliche Rechnungen',
	Subscriptions: 'Abonnements',
	externalPayments: 'Zahlungen',
	notSentInvoicesEmails: 'Nicht gesendete Rechnungsmitteilungen',
	notificationsOfPenalty: 'Benachrichtigungen über Strafen',
	issueTheFirstPenaltyInvoice: 'die erste Strafrechnung ausstellen',
	unknownNotification: 'unbekannte Benachrichtigung',
	markAsCompleted: 'als erledigt markieren',
	completed: 'Vollendet',
	usersImport: 'Benutzer importieren',
	import: 'Importieren',
	emailHistory: 'E-Mail-Verlauf'
};
