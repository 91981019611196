export default {
	accountDetails: 'Dane dotyczące konta',
	accountCreation: 'Stwórz konto',
	lockAvailability: 'Stan zamka',
	docsAttachmentInfo: 'Użytkownik musi dołączyć zdjęcie dokumentów w koncie osobistym aplikacji mobilnej',
	userWillReceivePassword: 'Użytkownik otrzyma hasło do poczty',
	passwordMayBeReset: 'Hasło może zostać zresetowane w formie resetu hasła',
	name: 'Imię',
	sureName: 'Nazwisko',
	email: 'Email',
	emailSubject: 'Temat e-mail',
	emailType: 'Typ e-maila',

	password: 'Hasło',
	country: 'Kraj',
	phone: 'Numer telefonu',
	city: 'Miejscowość',
	address: 'Ulica',
	zip: 'Kod pocztowy',
	pesel: 'PESEL (lub numer ID/paszportu w przypadku braku PESEL)',
	company: 'Firma',
	role: 'Rola',
	stock: 'Magazyn',
	withoutStock: 'Bez odniesienia do magazynu',
	areYouSureYouWantToDeleteAccount: 'Czy jesteś pewien, że chcesz usunąć konto?',
	accountEdit: 'Edytuj konto',
	kycHeader: 'Dokument tożsamości',
	passwordReset: 'Resetuj hasło',
	resetPassword: 'Resetuj hasło',
	passwordWillBeSentToMail: 'Hasło zostanie wysłane na adres e-mail',
	addAccount: 'Dodaj konto',
	contracts: 'Umowy',
	checkPhotosInfoBlock: 'Sprawdź zdjęcie paszportowe. Aby to zrobić, kliknij na zdjęcie. Jeśli wszystko jest w porządku ze zdjęciami i danymi, kliknij Sprawdź. ',
	selfieWasNotUploaded: 'Selfie z paszportem nie zostało załadowane',
	selfieWasRejected: 'Zdjęcie selfie zostało odrzucone.',
	reason: 'Powód',
	passportPhotoWasNotUploaded: 'Zdjęcie paszportowe nie zostało przesłane',
	passportPhotoWasRejected: 'Zdjęcie paszportowe zostało odrzucone',
	cancelVerification: 'Anulowanie weryfikacji',
	verify: 'zweryfikuj',
	docRejectionDescription: 'Jeśli dokument nie jest w porządku, dostęp do skrzynki dla klienta zostanie zamknięty.',
	userNeedToReupload: 'Jeśli menedżer odrzuci zdjęcie, użytkownik musi dołączyć nowe zdjęcie na swoim koncie',
	rejectionReason: 'Powód odrzucenia',
	setRejectionReason: 'Określenie powodu odrzucenia dokumentu',
	sureNameRequired: 'Nazwisko',
	areYouSureYouWantToResetPassword: 'Czy jesteś pewien, że chcesz zresetować hasło?',
	areYouSureYouWantToRejectDocument: 'Czy na pewno chcesz odrzucić dokument?',
	areYouSureYouWantToCancelProfileVerification: 'Czy na pewno chcesz anulować weryfikację profilu?',
	areYouSureYouWantToMarkProfileAsVerified: 'Czy na pewno chcesz oznaczyć profil jako zweryfikowany?',
	accountType: 'Rezerwuję boks jako:',
	individual: 'Osoba prywatna',
	legal: 'Firma',
	companyName: 'Nazwa firmy',
	registrationNumber: 'NIP',
	companyRequired: 'Firma',
	client: 'Klient',
	currentClient: 'Aktualny klient',
	goToContracts: 'Przejdź do umów',
	passwordConfirmation: 'Potwierdzanie hasła',
	passwordChanged: 'Hasło zostało zmienione',
	passwordWasSuccessfullyChanged: 'Hasło zostało pomyślnie zmienione',
	areYouSureYouWantToChangePassword: 'Czy jesteś pewien, że chcesz zmienić hasło?',
	changePassword: 'Zmiana hasła',
	isEighteenAge: 'Mam 18 lat',
	houseNumber: 'Nr',
	entranceNumber: 'piętro',
	apartmentNumber: 'numer domu',
	loginAsUser: 'Zaloguj się jako użytkownik',
	created: 'Data utworzenia',
	unknownDate: 'Data aktualizacji - nieznana',
	importUsers: 'Import użytkowników',
	createdAfter: 'Stworzony po',
	importUsersByDate: 'Importuj użytkowników według daty',
	otherContacts: 'Pozostałe kontakty',
	addressRequired: 'Wymagane',
	salutation: 'Pozdrowienie',
	man: 'Pan',
	woman: 'Pani',
	pinCode: 'kod pin',
	houseRequired: 'Wymagane',
	showPassword: 'Pokaż hasło',
	hidePassword: 'Ukryj hasło',
	opened: 'Otwarte',
	closed: 'Zamknięte',
};
