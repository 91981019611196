export default {
	create: 'Anlegen',
	back: 'Zurück',
	save: 'Speichern',
	delete: 'Löschen',
	settings: 'Einstellungen',
	open: 'Öffnen',
	apply: 'Anwenden',
	edit: 'Bearbeiten',
	reject: 'Ablehnen',
	close: 'Schließen'
};
