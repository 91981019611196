import React from 'react';
import { Field, Form } from 'formik';

function SupportForm(props) {
	const { values, touched, errors, isSubmitting, languageSchema } = props;
	const isError = field => errors[field] && touched[field];

	return (
		<Form>
			<div className="form-group">
				<label htmlFor="email" className="col-form-label">{languageSchema.Support.email}</label>
				<div>
					<Field
						type="text"
						className={`form-control ${isError('email') ? 'is-invalid' : ''}`}
						id="email"
						value={values.email}
						disabled
					/>
					{
						isError('email') &&
						<div className="invalid-feedback">
							{errors.email}
						</div>
					}
				</div>
			</div>
			<div className="form-group">
				<label htmlFor="phone" className="col-form-label">{languageSchema.Support.phone}</label>
				<div>
					<Field
						type="text"
						className={`form-control ${isError('phone') ? 'is-invalid' : ''}`}
						id="phone"
						value={values.phone}
						placeholder="+431231231231"
					/>
					{
						isError('phone') &&
						<div className="invalid-feedback">
							{errors.phone}
						</div>
					}
				</div>
			</div>
			<div className="form-group">
				<label htmlFor="name" className="col-form-label">{languageSchema.Support.name}</label>
				<div>
					<Field
						type="text"
						className={`form-control ${isError('name') ? 'is-invalid' : ''}`}
						id="name"
						disabled
						value={values.name}
					/>
					{
						isError('name') &&
						<div className="invalid-feedback">
							{errors.name}
						</div>
					}
				</div>
			</div>
			<div className="form-group">
				<label htmlFor="appealMessage" className="col-form-label">{languageSchema.Support.appealMessage}</label>
				<div>
					<Field
						component="textarea"
						numberOfLines={4}
						multiline
						className={`form-control ${isError('appealMessage') ? 'is-invalid' : ''}`}
						id="appealMessage"
						value={values.appealMessage}
					/>
					{
						isError('appealMessage') &&
						<div className="invalid-feedback">
							{errors.appealMessage}
						</div>
					}
				</div>
			</div>

			<div>
				<div className="col-auto">
					<button type="submit" className="btn btn-sm btn-secondary mr-3" disabled={isSubmitting}>
						{isSubmitting ? languageSchema.Etc.wait : languageSchema.Etc.send}
					</button>
				</div>
			</div>

			<hr />
		</Form>
	);
}

export default SupportForm;
