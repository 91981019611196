import React from 'react';
import ImageUploading from 'react-images-uploading';
import { connect } from 'react-redux';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { ENV } from 'utils/constants';

import DocsUploadRulesImage from 'assets/images/docs_example.png';
import './index.scss';
import CustomIframe from './CustomIFrame';

function DocumentsUploader(props) {
	let { languageSchema, onFileUploded, accountType } = props;

	let [passportPhotos, setPassportPhotos] = React.useState([]);
	let [selfieFiles, setSelfieFiles] = React.useState([]);
	let [addressFiles, setAddressFiles] = React.useState([]);
	let [extraFiles, setExtraFiles] = React.useState([]);

	let onChangePassportPhoto = (files) => {
		setPassportPhotos(files);

		let [passportFile] = files;

		let file = passportFile ?
			{ type: passportFile.file.type, base64: passportFile.imageUrl } :
			null;

		onFileUploded('passportFile', file);
	};

	let onChangeSelfieWithPassportPhoto = (files) => {
		setSelfieFiles(files);

		let [selfieFile] = files;

		let file = selfieFile ?
			{ type: selfieFile.file.type, base64: selfieFile.imageUrl } :
			null;

		onFileUploded('selfieFile', file);
	};

	let onChangeAddressPhoto = (files) => {
		setAddressFiles(files);

		let [addressFile] = files;

		let file = addressFile ?
			{ type: addressFile.file.type, base64: addressFile.imageUrl } :
			null;

		onFileUploded('addressFile', file);
	};

	let onChangeExtraPhoto = (files) => {
		setExtraFiles(files);

		let [extraFile] = files;

		let file = extraFile ?
			{ type: extraFile.file.type, base64: extraFile.imageUrl } :
			null;

		onFileUploded('extraFile', file);
	};

	let filesMap = {
		passportPhoto: {
			label: languageSchema.SignUp.passportPhoto,
			uploadHandler: onChangePassportPhoto,
			uploadedValue: passportPhotos,
			isUploaderDisplayedPropertyName: 'isPassportImageUploaderDisplayed',
		},
		selfie: {
			label: languageSchema.SignUp.selfieWithPassport,
			uploadHandler: onChangeSelfieWithPassportPhoto,
			uploadedValue: selfieFiles,
			isUploaderDisplayedPropertyName: 'isSelfieImageUploaderDisplayed',
		},
		address: {
			label: languageSchema.SignUp.addressDocumentPhoto,
			uploadHandler: onChangeAddressPhoto,
			uploadedValue: addressFiles,
			isUploaderDisplayedPropertyName: 'isAddressImageUploaderDisplayed',
		},
		extraPhoto: {
			label: languageSchema.SignUp.extraDocumentPhoto,
			uploadHandler: onChangeExtraPhoto,
			uploadedValue: extraFiles,
			isUploaderDisplayedPropertyName: 'isExtraImageUploaderDisplayed',
			isLegalAndIE: true
		}

	};

	let filesToRender = Object.keys(filesMap)
		.filter(fileType => props[filesMap[fileType].isUploaderDisplayedPropertyName]);

	return (
		<div className="row">
			{
				filesToRender.includes('selfie') &&
				<div>
					<div className="text-center">
						<img
							src={DocsUploadRulesImage}
							alt="Rentabox"
							title="Rentabox"
							width="75%"
						/>
					</div>

					<div className="px-2">
						<p style={{ marginBottom: '5px' }}>Убедитесь, что селфи с главным разворотом паспорта отвечает требованиям ниже:</p>
						<div>
							1) фотография должна быть четкой (не размытой) <br />
							2) паспорт можно разместить, как на примере или в одном из красных прямоугольников; <br />
							3) ВСЕ данные паспорта (номер, ФИО, место выдачи и пр.) хорошо читаются на фото и не закрыты руками и др. частями тела <br />
							4) лицо и паспорт должны полностью просматриваться на фото <br />
						</div>
					</div>
				</div>
			}

			<div className="m-3">Фотографии всех документов должны быть четкими, а информация на них должна полностью просматриваться</div>

			<div className="text-center mx-3">{languageSchema.Etc.acceptsFiles('pdf, jpg, jpeg, png, webp, jpe')}</div>
			{
				filesToRender
					.map(fileType =>
						<div className="col-md-6" key={fileType}>
							<div className="upload_label">{filesMap[fileType].label}:</div>
							<ImageUploading
								value={filesMap[fileType].uploadedValue}
								onChange={filesMap[fileType].uploadHandler}
								dataURLKey="imageUrl"
								allowNonImageType={true}
								acceptType={['pdf', 'jpg', 'jpeg', 'png', 'webp', 'jpe']}
							>
								{({
									imageList,
									onImageUpload,
									onImageUpdate,
									onImageRemove,
									isDragging,
									dragProps
								}) => (
									<div>
										{
											0 < imageList.length ?
												imageList.map((image, index) => (
													<div key={index} className="image-item">
														{
															ENV === 'development' ?
																<>Файловый сервер локально не настроен</> :
																<CustomIframe className="image_preview" src={image.imageUrl} alt="" />
														}
														<div className="image_preview_buttons_wrapper">
															<div
																className="btn btn-sm btn-secondary mr-3"
																onClick={() => onImageUpdate(index)}
															>
														Заменить
															</div>

															<div
																className="btn btn-sm btn-outline-secondary mr-3"
																onClick={() => onImageRemove(index)}
															>
																{languageSchema.SignUp.remove}
															</div>
														</div>
													</div>
												)) :
												<div
													className="upload_button"
													style={isDragging ? { color: 'red' } : null}
													onClick={onImageUpload}
													{...dragProps}
												>
													<i className="fas fa-upload fa-3x"/> <br />
													{languageSchema.SignUp.clickOrDrag}
												</div>
										}
									</div>
								)}
							</ImageUploading>
						</div>
					)
			}
		</div>
	);
}

const mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
	language: state.common.language,
});

export default connect(mapStateToProps)(DocumentsUploader);
