import React from 'react';

export default function TextLine({ left, right }) {
	return (
		<div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
			<div style={{ fontSize: 16, }}>
				{left}:
			</div>
			<div style={{ fontSize: 18, fontWeight: '600', textAlign: 'right' }}>
				{right}
			</div>
		</div>
	);
}
