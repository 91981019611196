import { w3cwebsocket as WebSocket } from 'websocket';

import request from 'helpers/request';
import { webSocketServerAddress } from 'utils/constants';
import { appealAdded, appealHandled } from './actions/contact';
import { boxOpened, boxClosed } from './actions/boxes';
import { pushNotification, updateNotificationByWS } from './actions/notifications';
import { getState } from './store';


export const init = (dispatch) => {
	const client = new WebSocket(webSocketServerAddress);

	client.onopen = () => {
		console.log('----------- WebSocket Client is Connected ----------------');
	};

	client.onmessage = (message) => {
		let parsedData = JSON.parse(message.data);

		if (!parsedData.actionType) {
			return {};
		}

		let actionHandler = getActionHandler(parsedData.actionType);

		return actionHandler.handle(dispatch, parsedData.payload, client);
	};
};

function getActionHandler(action) {
	let handler = {};
	let actionsMap = {
		'APPEAL_ADDED': appealAdded,
		'APPEAL_HANDLED': appealHandled,
		'SET_CONNECTION_ID': setConnectionId,
		'BOX_OPENED': boxOpened,
		'BOX_CLOSED': boxClosed,
		'PUSH_NOTIFICATION': pushNotification,
		'UPDATE_NOTIFICATION': updateNotificationByWS,
	};

	if (!actionsMap[action]) {
		handler.handle = () => { };

		return handler;
	}

	handler.handle = (dispatch, data, client) => actionsMap[action](data, client)(dispatch, getState);

	return handler;
}

function setConnectionId(data, client) {
	return async () => {
		let { connectionId } = data;

		localStorage.setItem('socketConnectionId', connectionId);

		let authToken = localStorage.getItem('accessToken');
		let me = await request('GET', 'auth/me');

		client.send(JSON.stringify(
			{
				actionType: 'SET_CONNECTION_USER_META',
				payload: { userDetails: me, authToken, connectionId },
			})
		);
	};
}
