const initialState = {
	count: 0,
	data: [],
	isFetched: false,
	isFetchedCount: false,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
	case 'FETCH_PAYMENTS':
		return { ...state, data: action.payload, isFetched: true };

	case 'FETCH_PAYMENTS_COUNT':
		return { ...state, count: action.payload, isFetchedCount: true };

	default:
		return state;
	}
}
