import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import SupportForm from 'components/Support/Form';
import Contact from 'components/Support/Contact';
import Heading from 'components/UI/Heading';
import request from 'helpers/request';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { ACCOUNT_TYPES_MAP } from 'utils/constants';

import validationSchema from './validation';

class SupportFormAdd extends React.Component {
	state = {
		isRequesting: false,
	};

	handleSubmit = async (values, { setFieldValue, setFieldTouched }) => {
		try {
			this.setState({ isRequesting: true });

			await request('POST', 'appeal', values);

			toastr.success(this.props.languageSchema.Etc.success);

			setFieldValue('appealMessage', '');
			setFieldTouched('appealMessage', false);
			this.setState({ isRequesting: false });
		} catch (error) {
			this.setState({ isRequesting: false });
		}
	}

	render() {
		let { languageSchema, user } = this.props;
		let { isRequesting } = this.state;

		let getUserName = () => user.accountType === ACCOUNT_TYPES_MAP.legal ?
			user.company :
			`${user.firstName} ${user.lastName}`;

		return (
			<div className="row py-3">
				<div className="col-md-6">
					<div className="card">
						<Heading>{languageSchema.Support.leaveAppeal}</Heading>
						<Formik
							initialValues={{
								email: user.email,
								phone: user.phone,
								name: getUserName(),
								userId: user._id,
								appealMessage: '',
								type: 'message'
							}}
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema(languageSchema)}
							render={props =>
								<SupportForm
									{...props}
									languageSchema={languageSchema}
									isSubmitting={isRequesting}
									sendAppeal={this.handleSubmit}
									user={user}
								/>
							}
						/>
					</div>
				</div>
				<Contact languageSchema={languageSchema} />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
	user: state.auth.me,
});

export default connect(mapStateToProps)(SupportFormAdd);
