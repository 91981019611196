import React from 'react';
import BoxBookingContext from 'screens/BoxBookingNew/Context';
import { displayAmount, round } from 'helpers/amount';

import {
	Button,
	Popover,
	Switch
} from 'antd';

export default function (props) {
	let { currentTab, setTab, languageSchema, user, areDocsUploaded } = props;
	let boxBookingContext = React.useContext(BoxBookingContext);

	let {
		bookingMeta: {
			arrivalDate,
			selectedContractDuration,
			areDocsConfirmed,
		},
		setVatRate,
	} = boxBookingContext;

	let isOneEuroForFirstMonthActionApplied = false;
	// 1 < boxBookingContext.bookingMeta.selectedContractDuration;

	let handleContinueClick = () => {
		setTab(String(Number(currentTab) + 1));
	};

	let handleAccountTypeChange = (isChecked) => {
		let vatRateToSet = isChecked ? 20 : 0;

		setVatRate(vatRateToSet);
	};

	let handleBackClick = () => {
		setTab(String(Number(currentTab) - 1));
	};

	// let isStep1Completed = arrivalDate && selectedContractDuration && selectedInsuranceSchemeId;
	let isStep1Completed = arrivalDate && selectedContractDuration;
	let isStep2Completed = areDocsConfirmed && areDocsUploaded;

	let isContinueButtonDisabled =
		(currentTab === '1' && !isStep1Completed) ||
		(currentTab === '2' && !isStep2Completed);

	let getPricesDetails = () => {
		let {
			selectedStock,
			pureMonthRate,
			selectedContractDuration,
			insuranceAmount,
			discountAmount,
			discountPercent,
			vatRate,
			vatAmount,
			invoicingPeriod
		} = boxBookingContext.bookingMeta;

		return (
			<div>
				<p className="d-flex justify-content-between">
					<span>Стоимость за 1 месяц: </span>
					<span>{displayAmount(round(pureMonthRate + pureMonthRate * vatRate / 100), selectedStock.currency)}</span>
				</p>
				<p className="d-flex justify-content-between">
					<span>Сервисный период: </span>
					<span>{invoicingPeriod} мес.</span>
				</p>
				<p className="d-flex justify-content-between">
					<span>{languageSchema.BoxBooking.discount} ({discountPercent}%): </span>
					<span>-{displayAmount(discountAmount, selectedStock.currency)}</span>
				</p>
				{
					isOneEuroForFirstMonthActionApplied &&
					<p className="d-flex justify-content-between">
						<span>{languageSchema.BoxBooking.oneEuroForFirstMonth}:</span>
						<span>{displayAmount(1, selectedStock.currency)}</span>
					</p>
				}
				{/* Deposit line */}
				{
					false &&
					<p className="d-flex justify-content-between">
						<span>{languageSchema.BoxBooking.deposit}: </span>
						<span>{displayAmount(0, selectedStock.currency)}</span>
					</p>
				}

			</div>
		);
	};

	return (
		<div className="sidebar_footer__buttons_wrapper">
			<div>
				{
					1 < currentTab &&
					<Button onClick={handleBackClick}>
						&lt; {props.languageSchema.BoxBooking.back}
					</Button>
				}
			</div>
			{
				boxBookingContext.bookingMeta.selectedContractDuration &&
				boxBookingContext.bookingMeta.totalToPay &&
				<div>
					<div className="d-flex vertical-centered">
						<span style={{ fontSize: '18px' }}>К оплате:</span>
						<span className="sidebar_footer__total_price">
							{displayAmount(
								boxBookingContext.bookingMeta.totalToPay,
								boxBookingContext.bookingMeta.selectedStock.currency
							)}
						</span>
						<Popover
							zIndex={100000}
							content={getPricesDetails()}
							title={languageSchema.BoxBooking.details}
							overlayStyle={{ width: '270px' }}
						>
							<div className="ml-2 btn btn-sm btn-outline-secondary">
								{languageSchema.BoxBooking.details}
							</div>
						</Popover>
					</div>
					{/* in Russia VAT is included to individual and legal users */}
					{
						false &&
						!user &&
						<div>
							<Switch
								onChange={handleAccountTypeChange}
								checkedChildren={languageSchema.User.legal}
								unCheckedChildren={languageSchema.User.legal}
							/>
						</div>
					}
					{
						isOneEuroForFirstMonthActionApplied &&
						<>
							<span style={{ fontWeight: 'normal', fontSize: '15px', lineHeight: '0.5px' }}>
								{props.languageSchema.BoxBooking.forTwoInitialMonths}
							</span>
							<br />
						</>
					}
					<br />
				</div>

			}

			<div>
				{
					currentTab < 3 &&
					<Button
						type="primary"
						onClick={handleContinueClick}
						disabled={isContinueButtonDisabled}
					>
						{props.languageSchema.BoxBooking.continue} &gt;
					</Button>
				}
			</div>
		</div>
	);
}
