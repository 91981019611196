export default {
	enter: 'Login',
	forgotPassword: 'Forgot your password?',
	yourEmail: 'Your Email',
	password: 'Password',
	wait: 'Wait ...',
	enterEmail: 'Enter email',
	enterPassword: 'Enter password',
	goToBooking: 'Go to booking'
};
