import React from 'react';
import Checkbox from 'react-simple-checkbox';
import BoxBookingContext from 'screens/BoxBookingNew/Context';
import { apiUrl } from 'utils/constants';
// import Questionnaire from './Questionnaire';
// import restrictedItemsImg from 'assets/images/restricted_items.jpg';

export default function DocsList(props) {
	let { languageSchema} = props;
	let boxBookingContext = React.useContext(BoxBookingContext);


	return (
		<div className="row">
			<h4>{languageSchema.BoxBooking.docsAgreement}</h4>
			<div className="col-md-12">
				<div
					className={'mb-2'}
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						borderRadius: props.isError ? '5px' : 0,
						border: props.isError ? '1px solid #F93822' : 'none',
						padding: props.isError ? '5px' : 0,
						boxShadow: props.isError ? '0 9px 17px 1px rgb(137 51 51 / 30%)' : 'none',
					}}
				>
					<div style={{ marginRight: '7px', marginTop: '-13px' }}>
						<Checkbox
							color="#3cab26"
							size={3}
							tickAnimationDuration={100}
							backAnimationDuration={100}
							borderThickness={2}
							checked={boxBookingContext.bookingMeta.areDocsConfirmed}
							onChange={boxBookingContext.setDocsConfirmed}
						/>
					</div>

					<div style={{ lineHeight: '1.2' }}>
						{languageSchema.BoxBooking.iHaveReadDocsAndAgree}<span style={{ fontSize: '20px' }} className="red">*</span>:
					</div>
				</div>
				<div>
					<div><a rel="noopener noreferrer" target="_blank" href={`${apiUrl}/contracts/pdf`}>- Договор (шаблон)</a></div>
					<div><a rel="noopener noreferrer" target="_blank" href="https://rentabox-b24d3a92.s3.eu-central-1.amazonaws.com/Стандартные-условия.pdf">- Стандартные условия</a></div>
					<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2022/05/requisites_rentabox.pdf">- Реквизиты</a></div>
					<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2022/05/Правила-безопасности.pdf">- Правила безопасности использования грузового подъемника</a></div>
					<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2022/05/Согласие-на-обработку_перс_данных.pdf">- Согласие на обработку персональных данных</a></div>
					<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2019/11/conf_issues_.pdf">- Политика конфденцальности</a></div>
					<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2022/11/conf_issues_my_rentabox.pdf">- Положение о политике конфиденциальности при работе в личном кабинете на сайте</a></div>
				</div>
				{/*<Questionnaire {...props}/> */}
			</div>
		</div>
	);
}
