import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openStockEquipment } from 'redux/actions';
import countries from 'i18n-iso-countries';
import { toastr } from 'react-redux-toastr';
import { ROLES_MAP } from 'utils/constants';

import { isAdmin } from 'utils/functions';
import Card from 'components/UI/Card';
import request from 'helpers/request';


import enLocale from 'i18n-iso-countries/langs/en.json';
import deLocale from 'i18n-iso-countries/langs/de.json';
import ruLocale from 'i18n-iso-countries/langs/ru.json';

countries.registerLocale(enLocale);
countries.registerLocale(deLocale);
countries.registerLocale(ruLocale);

function ListItem(props) {
	const { languageSchema, user, language: lang, internalEquipments, offlineAppUrl, name
		//hasBoxes
	} = props;

	useEffect(() => {
		handleCheckHealth();
	}, []);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isStockOffline, setStockOffline] = useState(false);

	const isCurrentUserAdmin = user.role && isAdmin(user.role);
	const language = lang.toLowerCase();

	const cityName = props.cityId ? props.cityId.city : props.city;
	const countryName = props.cityId ? props.cityId.country : props.country;

	const handleOpenEquipment = (equipment) => () =>
		toastr.confirm(
			`${languageSchema.Stock.areYouSureYouWantToRun} "${equipment.name}" ?`,
			{
				onOk: () => {
					props.openStockEquipment(props._id, equipment.id);
				},
			}
		);

	const handleCheckHealth = async () => {
		const { isOk } = await request('GET', `stocks/${props._id}/availability`);

		if (user.role === ROLES_MAP.staff) {
			setIsModalOpen(!isOk);
		}

		setStockOffline(!isOk);
	};

	const redirectToOfflineApp = () => {
		window.location.replace(offlineAppUrl);
	};

	return (
		<Card>
			<h5 className="card-title d-flex justify-content-between">
				<div>{props.name}</div>
			</h5>
			{/* hasBoxes && */}
			<div>
				<div className="badge badge-success">
					{languageSchema.Stock.boxRenting}
				</div>
				{
					isStockOffline &&
					<div className="badge badge-warning badge-2">
						Нет связи со складом
					</div>
				}
			</div>
			{/* */}
			<p className="card-text">
				{props.address}<br />
				{cityName}, {countries.getName(countryName, language)}
			</p>
			<div className="d-flex justify-content-between">
				{
					isCurrentUserAdmin &&
					<div>
						<Link to={`/stocks/${props._id}`} className="btn btn-sm btn-outline-secondary mr-2">
							{languageSchema.Buttons.settings}
						</Link>
						<Link to={`/stocks/${props._id}/history`} className="btn btn-sm btn-outline-secondary">
							<span>{languageSchema.Stock.history}</span>
							<FontAwesomeIcon icon="history" />
						</Link>
					</div>
				}
				{
					isCurrentUserAdmin &&
					<div>
						<Link to={`/boxes?stockId=${props._id}`} className="btn btn-sm btn-outline-primary mr-2">
							{languageSchema.Stock.boxes}
						</Link>
					</div>
				}
			</div>
			{
				internalEquipments &&
				<>
					<div className="w-100"><hr /></div>
					<div className="inline-flex flex-wrap">
						{internalEquipments.map((equipment) => (
							<div key={equipment._id} className="mt-2">
								<button
									variant="outline-dark"
									className="btn btn-outline-success btn-md mx-2 vertical-flex"
									onClick={handleOpenEquipment(equipment)}
									style={{ fontSize: '12px'}}
								>
									<i className={'fas fa-key'}> </i> {equipment.name}
								</button>
							</div>
						))}
					</div>
				</>
			}
			{
				user.role === ROLES_MAP.staff &&
					<Modal
						title={`${languageSchema.Stock.stockConnectionIsLost} ${name}!`}
						open={isModalOpen}
						onCancel={() => setIsModalOpen(false)}
						footer={[
							<Button key="submit" type="primary" onClick={redirectToOfflineApp}>
								{languageSchema.Stock.redirectToOfflineApp}
							</Button>,
						]}
					>
						<span>{languageSchema.Stock.followSteps}</span>
						<div className='font-weight-bold d-flex flex-column mt-2'>
							<span>1. {languageSchema.Stock.turnOffMobileNet}</span>
							<span>2. {languageSchema.Stock.connectToWiFi}</span>
							<span>3. {languageSchema.Stock.clickOfflineAppButton}</span>
						</div>
					</Modal>
			}
		</Card>
	);
}

let mapDispatchToProps = {
	openStockEquipment,
};

export default connect(null, mapDispatchToProps)(ListItem);
