import React, { Component } from 'react';
import Heading from 'components/UI/Heading';
import request from 'helpers/request';
import { getCurrencySign } from 'helpers/amount';

export default class InsuranceSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			insuranceCoverageAmount: props.stock.insuranceCoverageAmount || 0,
			insuranceMonthlyAmount: props.stock.insuranceMonthlyAmount || 0,
			isLoading: false,
		};
	}

	saveInsuranceForm = async () => {
		this.setState({ isLoading: true });
		try {
			await request('PUT', `stocks/${this.props.stock._id}`, {
				insuranceCoverageAmount: this.state.insuranceCoverageAmount,
				insuranceMonthlyAmount: this.state.insuranceMonthlyAmount,
			});

			this.setState({ isLoading: false });
		} catch(error) {
			this.setState({ isLoading: false });
		}
	}

	handleChangeField = fieldName => e => this.setState({ [fieldName]: e.target.value})

	render() {
		let { languageSchema, isSuperUser } = this.props;
		return (
			<div className="row">
				<div className="col-md-12">
					<Heading>{languageSchema.Stock.insurance}</Heading>

					<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
						<label htmlFor="insuranceCoverageAmount" className="col-sm-5 col-form-label">
							{languageSchema.Stock.insuranceCoverageAmount}:
						</label>
						<div className="col-sm-5">
							<div className="input-group">
								<input
									name="insuranceCoverageAmount"
									type="number"
									className="form-control"
									value={this.state.insuranceCoverageAmount}
									disabled={!isSuperUser}
									onChange={this.handleChangeField('insuranceCoverageAmount')}
								/>
								<div className="input-group-append">
									<span className="input-group-text">
										{getCurrencySign(this.props.stock.currency)}
									</span>
								</div>
							</div>
						</div>
					</div>

					<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
						<label htmlFor="insuranceMonthlyAmount" className="col-sm-5 col-form-label">
							{languageSchema.Stock.insuranceMonthlyAmount}:
						</label>
						<div className="col-sm-5">
							<div className="input-group">
								<input
									name="insuranceMonthlyAmount"
									type="number"
									className="form-control"
									value={this.state.insuranceMonthlyAmount}
									disabled={!isSuperUser}
									onChange={this.handleChangeField('insuranceMonthlyAmount')}
								/>
								<div className="input-group-append">
									<span className="input-group-text">
										{getCurrencySign(this.props.stock.currency)}
									</span>
								</div>
							</div>
						</div>
					</div>

					<div style={{ textAlign: 'center' }}>
						<button
							className="btn btn-success"
							onClick={this.saveInsuranceForm}
							disabled={!isSuperUser}
						>
							{this.state.isLoading && <span className="spinner-border spinner-border-sm"> </span>}
							<span> {languageSchema.Buttons.apply} </span>
						</button>
					</div>

				</div>
			</div>
		);
	}
}
