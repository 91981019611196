import React, { Component } from 'react';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import Table from 'components/UI/Table';
import { Link } from 'react-router-dom';

import { DATE_FORMAT } from 'utils/constants';
import request from 'helpers/request';

export default class AppealList extends Component {
	handleAppeal = (appeal) => async () => {
		toastr.confirm(this.props.languageSchema.Appeal.areYouSureAppealIsHandled, {
			onOk: async () => {
				await request('PUT', `appeal/${appeal._id}`);
				await this.props.fetchAppealsWithFilter();
			},
		});
	}

	render() {
		let { appeals, languageSchema } = this.props;

		return (
			<Table
				centerHeader
				header={[
					languageSchema.Appeal.name,
					languageSchema.Appeal.contactNumber,
					languageSchema.Appeal.email,
					languageSchema.Appeal.type,
					languageSchema.Appeal.status,
					languageSchema.Appeal.date,
					languageSchema.Appeal.messageText,
					''
				]}
			>
				{appeals.map(appeal => {
					return (
						<tr key={appeal._id}>
							<td className="text-center">
								{
									appeal.userId ? 
										<Link to={`/users/${appeal.userId}`}>
											{appeal.name}
										</Link> : 
										appeal.name
								}
							</td>
							<td className="text-center">{appeal.phone}</td>
							<td className="text-center">{appeal.email}</td>
							<td className="text-center">{appeal.type}</td>
							<td className="text-center">{appeal.status}</td>
							<td className="text-center">
								{moment(appeal.created).format(DATE_FORMAT)} <br />
								<small>{moment(appeal.created).format('HH:mm:ss')}</small>
							</td>
							<td style={{ maxWidth: '400px', overflowX: 'auto' }}>{appeal.appealMessage}</td>
							<td>
								{
									appeal.status === 'pending' ?
										<button
											className="btn btn-sm btn-warning"
											onClick={this.handleAppeal(appeal)}>
											{languageSchema.Appeal.handle}
										</button> :
										<button
											disabled className="btn btn-sm btn-light green"
										>
											{languageSchema.Appeal.handled}
										</button>
								}
							</td>
						</tr>
					);
				})}
			</Table>
		);
	}
}
