export default {
	leaveAppeal: 'Leave appeal message',
	requestACallback: 'Callback',
	email: 'Email',
	phone: 'Contact phone numbmer',
	name: 'Name',
	appealMessage: 'Appeal message',
	callback: 'Сall me back',
	enterYourMessage: 'Enter your message',
	invalidCharacters: 'Invalid characters',
	invalidPhoneBounds: 'Phone number must be between 3 and 14 characters',
	messageInvalidBounds: 'Message must be between 1 and 250 characters',
	contacts: 'Contacts',
	address: 'Address',
	web: 'Web-page',
	writeEmail: 'Write us E-mail',
	callUs: 'Call us',
	callWithWhatsApp: 'Contact using WhatsApp',
};
