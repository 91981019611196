import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { setBackofficeLanguage, updateUser, setCurrentUserDetails } from 'redux/actions';
import countries from 'i18n-iso-countries';
import { languages, LANGUAGE_TO_COUNTRY_CODE_MAP } from 'utils/constants';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));


class LanguagePicker extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

		changeHandler = async language => {
			let { me, isAuth } = this.props;

			await this.props.setBackofficeLanguage(language);

			if (isAuth && me.language !== language) {
				await this.props.updateUser(me._id, { language });

				this.props.setCurrentUserDetails();
			}

		}

		render() {
			// let { language: currentLanguage, dark } = this.props;

			return null;

			// return (
			// 	<div>
			// 		<Dropdown>
			// 			<Dropdown.Toggle size="md" variant={dark ? 'dark' : 'outline'} id="dropdown-basic">
			// 				<img src={`https://flagcdn.com/w20/${LANGUAGE_TO_COUNTRY_CODE_MAP[currentLanguage].toLowerCase()}.png`} />
			// 				{' '} <span style={{ color: dark ? 'white' : 'black' }}>{languages[currentLanguage]}</span>
			// 			</Dropdown.Toggle>
			//
			// 			<Dropdown.Menu>
			// 				{
			// 					Object.keys(languages).map(
			// 						language => (
			// 							<Dropdown.Item key={language} eventKey={language} onSelect={this.changeHandler}>
			// 								<img src={`https://flagcdn.com/w20/${LANGUAGE_TO_COUNTRY_CODE_MAP[language].toLowerCase()}.png`} />
			// 								{' '}{languages[language]}
			// 							</Dropdown.Item>
			// 						))
			// 				}
			// 			</Dropdown.Menu>
			// 		</Dropdown>
			// 	</div>
			// );
		}
}

const mapStateToProps = (state) => ({
	isAuth: state.auth.isAuth,
	me: state.auth.me,
	language: state.common.language,
});

const mapDispatchToProps = {
	setBackofficeLanguage,
	updateUser,
	setCurrentUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker);
