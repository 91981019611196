import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ru, de } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ru', ru);
registerLocale('de', de);

export default (props) => {
	let { language, disabled, ...datePickerProps } = props;

	return (
		<DatePicker
			locale={language.toLowerCase()}
			dateFormat="dd/MM/yyyy"
			disabled={Boolean(disabled)}
			{...datePickerProps}
		/>
	);
};
