import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchAppeals } from 'redux/actions';
import Heading from 'components/UI/Heading';
import List from 'components/Appeal/List';
import {getLanguageSchema} from 'dashboard-commons/languages';

class AppealList extends Component {
	state = {
		status: 'pending',
	}

	async componentDidMount() {
		await this.fetchAppealsWithFilter();
	}

	handleFilterChange = (filterName) => async (e) => {
		let value = e.target.value;

		this.setState({ [filterName]: value });

		await this.fetchAppealsWithFilter({ [filterName]: value });
	}

	fetchAppealsWithFilter = async (params = { status: this.state.status }) => {
		await this.props.fetchAppeals(params);
	};

	render() {
		const { appeals, languageSchema } = this.props;

		return (
			<div className="card">
				<Heading>
					<div>
						{languageSchema.Appeal.appeals}
					</div>
				</Heading>

				<div className="row mb-3">
					<div className="col-3">
						<label> {languageSchema.Appeal.appealStatus}: </label>
						<select
							type="select"
							name="status"
							placeholder={languageSchema.Appeal.status}
							className="form-control"
							onChange={this.handleFilterChange('status')}
						>
							<option value="pending"> {languageSchema.Appeal.waitingForHandle} </option>
							<option value="handled"> {languageSchema.Appeal.handled} </option>
						</select>
					</div>
					<div className="col-md-3 vertical-centered">
						<div className="horizontal vertical-centered">
							<div>
								{languageSchema.Appeal.appealsDisplayed}:
							</div>
							<div style={{ margin: '15px', fontSize: '25px' }}>
								<b>{appeals.length}</b>
							</div>
						</div>
					</div>
				</div>
				<List appeals={appeals} languageSchema={languageSchema} fetchAppealsWithFilter={this.fetchAppealsWithFilter} />
			</div>
		);
	}
}

const mapStateToProps = ({ appeals, common }) => {
	return {
		appeals: appeals.data,
		languageSchema: getLanguageSchema(common.language)
	};
};

const mapDispatchToProps = { fetchAppeals };

export default connect(mapStateToProps, mapDispatchToProps)(AppealList);
