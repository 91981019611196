import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import MaskedInput from 'react-text-mask';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { displayAmount } from 'helpers/amount';
import Table from 'components/UI/Table';
import _ from 'lodash';

import { getPrettyLockStatus } from 'utils/functions';
import { fetchBoxes, clearCurrentBoxes, saveLockId, fetchStocks, openBox, } from 'redux/actions';


class BoxListScreen extends Component {
	state = {
		editable: false,
		boxExternalId: null,
		lockId: '',
		searchByBoxNumber: '',
		searchByLockId: '',
		searchByBoxSize: '',
		filteredBoxes: null,
	}

	MaskedInputRef = React.createRef();

	componentDidMount() {
		this.props.fetchBoxes(this.props.stockId);
		this.props.fetchStocks();
	}

	componentWillUnmount() {
		this.props.clearCurrentBoxes();
	}

	componentDidUpdate(prevProps) {
		let { boxes } = this.props;
		let isBoxesChanged = _.differenceWith(prevProps.boxes, boxes, _.isEqual).length;
		if (isBoxesChanged) {
			this.transformResultData();
		}
	}

	setEditMode = (lockId, boxExternalId) => {
		this.setState({
			editable: true,
			boxExternalId,
			lockId: lockId || '',
		}, () => {
			this.MaskedInputRef.current.inputElement.focus();
		});
	}

	saveChanges = async (box) => {
		const { stockId } = this.props;
		const { lockId } = this.state;

		if (lockId.includes('_') || lockId.length === 0) {
			this.setState({
				editable: false,
				boxExternalId: null,
				lockId: ''
			});
			return;
		}

		try {
			await this.props.saveLockId({ ...box, lockId, stockId });
			await this.props.fetchBoxes(this.props.stockId);

			this.setState({
				editable: false,
				boxExternalId: null,
				lockId: ''
			});
		} catch (error) {
			console.log(error);
		}

	}

	handleChangeLockId = (e) => {
		let { value } = e.target;
		this.setState({ lockId: value });
	}

	handleChangeSearchField = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value }, this.transformResultData);
	}

	transformResultData = () => {
		const { boxes } = this.props;
		const { searchByBoxNumber, searchByLockId, searchByBoxSize } = this.state;

		let filteredBoxes = [...boxes];

		if (searchByBoxNumber.length > 0) {
			filteredBoxes = filteredBoxes.filter(
				box => box.UnitNumber && box.UnitNumber.toLowerCase().includes(searchByBoxNumber.toLowerCase())
			);
		}

		if (searchByLockId.length > 0) {
			filteredBoxes = filteredBoxes.filter(
				box => box.lockId && box.lockId.toLowerCase().includes(searchByLockId.toLowerCase())
			);
		}

		if (searchByBoxSize.length > 0) {
			filteredBoxes = filteredBoxes.filter(
				box => box.size && box.size.toString().includes(searchByBoxSize.toLowerCase())
			);
		}

		this.setState({ filteredBoxes });
	}

	openBox = (box) => async () => {
		toastr.confirm(this.props.languageSchema.Stock.areYouSureYouWantToOpenBox, {
			onOk: () => {
				this.props.openBox(box);
			}
		});
	}

	getUserName = (user) => {
		let { firstName, lastName, company, accountType } = user;
		let userNameMapper = {
			individual: `${firstName} ${lastName}`,
			legal: company,
		};

		return userNameMapper[accountType];
	}

	render() {
		const { boxes, stock, languageSchema } = this.props;
		const { editable, boxExternalId, lockId, filteredBoxes } = this.state;

		const renderingBoxes = filteredBoxes ? filteredBoxes : boxes;

		return (
			<div className="card">
				<h1 className="mt-5">
					{stock.name}. {languageSchema.Stock.totalBoxes}: <b>{boxes.length}</b>
				</h1>

				<div className="row my-5">
					<div className="col">
						<input
							type="search" name="searchByBoxNumber"
							placeholder={languageSchema.Stock.boxNumber} className="form-control"
							onChange={this.handleChangeSearchField}
						/>
					</div>
					<div className="col">
						<input
							type="search" name="searchByBoxSize"
							placeholder={languageSchema.Stock.size} className="form-control"
							onChange={this.handleChangeSearchField}
						/>
					</div>
					<div className="col">
						<input
							type="search" name="searchByLockId"
							placeholder={languageSchema.Stock.lockNumber} className="form-control"
							onChange={this.handleChangeSearchField}
						/>
					</div>
				</div>

				<Table
					header={[
						languageSchema.Stock.boxNumber,
						languageSchema.Stock.size,
						languageSchema.Stock.monthRate,
						languageSchema.Stock.lockNumber,
						languageSchema.Stock.currentUser,
						languageSchema.Stock.control,
					]}
				>
					{renderingBoxes.map(box => {
						let editableRow = editable && box.UnitID === boxExternalId;
						let status = getPrettyLockStatus(box.lockStatus, languageSchema);

						return (
							<tr key={box.UnitID}>
								<td>
									{box.UnitNumber}
									<span className={`icon-status ${status.color}`}>{status.title}</span>
								</td>
								<td>{box.PhysicalSize} m²</td>
								<td>{displayAmount(box.MonthRate, stock.currency)}</td>
								<td>
									{editableRow ?
										<MaskedInput
											className="form-control form-control-sm"
											placeholder="_:__:__"
											mask={[/\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
											ref={this.MaskedInputRef}
											onChange={this.handleChangeLockId}
											value={lockId}
										/> :
										<>{box.lockId || languageSchema.Stock.notSet}</>
									}
								</td>
								<td>
									{box.userInfo?.id ?
										<Link to={`/users/${box.userInfo.id}`}>
											{box.userInfo.fullName}
										</Link> :
										<span> - </span>
									}
								</td>
								<td>
									{editableRow ?
										<button
											className="btn btn-sm btn-light"
											onClick={() => { this.saveChanges(box); }}
										>
											{languageSchema.Buttons.save}
										</button> :
										<button
											className="btn btn-sm btn-light"
											onClick={() => { this.setEditMode(box.lockId, box.UnitID); }}
										>
											{languageSchema.Buttons.edit}
										</button>
									}{' '}
									{box.lockId &&
										<Link
											to={{
												pathname: `/boxes/${box._id}`,
												state: {
													ContractID: box.ContractID,
													invoices: box.invoices,
													stockId: stock._id,
													boxId: box._id,
												}
											}}
											className="btn btn-sm btn-light">
											{languageSchema.Stock.history}
										</Link>
									}

									{box.lockId &&
										<button style={{ marginLeft: '5px' }}
											className="btn btn-sm btn-success"
											onClick={this.openBox(box)}
										>
											<i className={'fas fa-key'}> {' '} </i>
											{' '} {languageSchema.Buttons.open}
										</button>
									}
								</td>
							</tr>
						);
					})}
				</Table>
			</div>
		);
	}
}

const mapStateToProps = ({ boxes, stocks, common }, props) => {
	const currentStockId = qs.parse(props.location.search).stockId;
	const currentStock = stocks.data.find(stock => stock._id === currentStockId);

	return {
		boxes: boxes.data,
		stockId: currentStockId,
		stock: currentStock || {},
		languageSchema: getLanguageSchema(common.language)
	};
};

const mapDispatchToProps = { fetchBoxes, clearCurrentBoxes, saveLockId, fetchStocks, openBox };

export default connect(mapStateToProps, mapDispatchToProps)(BoxListScreen);
