import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

import LoginForm from 'components/Auth/LoginForm';
import { logIn, logOut, setCurrentUserDetails, setError } from 'redux/actions';

import { getLanguageSchema } from 'dashboard-commons/languages';

class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};
	}

	componentDidMount() {
		this.props.logOut();
	}

	handleSubmit = async (values) => {
		try {
			this.setState({ loading: true });
			console.log('values', values);

			let loginResult = await this.props.logIn(values);

			await this.props.setCurrentUserDetails();

			this.props.onLogInCompleted(loginResult);
		} catch(error) {
			console.log(error);
		} finally {
			this.setState({ loading: false });
		}
	}

	render() {
		const { languageSchema, isVisible, onCancel, onSignUpAction } = this.props;

		return (
			<Modal visible={isVisible} footer={null} onCancel={onCancel}>
				<LoginForm
					languageSchema={languageSchema}
					location={this.props.location}
					onSignUpAction={onSignUpAction}
					onSubmit={this.handleSubmit}
					loading={this.state.loading}
				/>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
});

export default connect(mapStateToProps, { logIn, logOut, setCurrentUserDetails, setError })(LoginPage);
