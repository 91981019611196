import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Container } from 'reactstrap';
import { Table, Input } from 'antd';

import request from 'helpers/request';
import { getLanguageSchema } from 'dashboard-commons/languages';

const EmailHistory = ({ languageSchema }) => {
	const [emails, setEmails] = useState([]);
	const isMobile = window.innerWidth < 900;

	const columns = [
		{ title: languageSchema.User.email, dataIndex: 'to', key: 'to', width: '30%' },
		{
			title: isMobile ? `${languageSchema.User.emailType} / ${languageSchema.User.emailSubject}` : languageSchema.User.emailType,
			dataIndex: 'emailMessageType',
			key: 'emailType',
			render: (emailType, obj) => <span>{emailType} / {obj.subject}</span>,
			width: '30%',
		},
		{
			title: languageSchema.Payment.date,
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
			width: '20%',
		},
	];

	if (!isMobile) {
		columns.push({
			title: languageSchema.User.emailSubject,
			dataIndex: 'subject',
			key: 'subject',
			width: '20%',
		});
	}

	const handleSearchByEmail = useCallback((e) => {
		const value = e.target.value;

		if (value.length > 3) {
			fetchEmails(value);
		}
	}, []);

	const fetchEmails = async (to) => {
		const emails = await request('GET', 'email-history/search', { to: to });

		setEmails(emails);
	};

	useEffect(() => {
		fetchEmails('');
	}, []);

	return (
		<Container>
			<div className={isMobile ? 'mt-2 mb-4 w-100': 'my-3 w-50'}><Input placeholder="Email" onChange={handleSearchByEmail} /></div>
			<Table
				columns={columns}
				dataSource={emails}
			/>
		</Container>
	);
};

const mapStateToProps = ({ common }) => ({
	languageSchema: getLanguageSchema(common.language),
});

export default connect(mapStateToProps, null)(EmailHistory);
