const initialState = {
	data: [],
	isFetched: false
};

export default function reducer(state = initialState, action) {

	let cities;

	switch (action.type) {
	case 'FETCH_CITIES':
		return { ...state, data: action.payload.sort((a, b) => a.size - b.size), isFetched: true };

	case 'CREATE_CITY':
		return { ...state, data: [...state.data, action.payload] };

	case 'UPDATE_CITY':
		cities = state.data.map(city => (city._id === action.payload._id) ? { ...city, ...action.payload } : city);
		return { ...state, data: cities };

	case 'DELETE_CITY':
		cities = state.data.filter(city => city._id !== action.payload);
		return { ...state, data: cities };

	default:
		return state;
	}

}