import request from 'helpers/request';
import { apiStartLoading, apiEndLoading } from './common';
import { IQACDOCUMENT_SET_DATA } from '../actionTypes';

const endpoint = 'iqacdocument';

export const setIqacDocumentAttribute = (documentAttribute, documentId, stockId) => async (/* dispatch */) => {
	await request('PUT', `${endpoint}/${documentId}/stock/${stockId}`, documentAttribute);
};

export const getMonthlyIqacDocuments = (month, stockId) => async (dispatch) => {
	try {
		dispatch(apiStartLoading());
		const response = await request('GET', `${endpoint}/monthly-bills?stockId=${stockId}&month=${month}`);
		dispatch({ type: IQACDOCUMENT_SET_DATA, payload: response });
	} catch (error) {
		console.error(error.message);
	} finally {
		dispatch(apiEndLoading());
	}
};

export const sendMonthlyIqacDocumentsToEmail = (month, stockId, email) => async (dispatch) => {
	try {
		dispatch(apiStartLoading());
		await request('POST', `${endpoint}/email-monthly-bills`, { stockId, month, email });
	} catch (error) {
		console.error(error.message);
	} finally {
		dispatch(apiEndLoading());
	}
};
