import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import NoItems from 'components/common/NoItems';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { InfoCircleTwoTone } from '@ant-design/icons';

import request from 'helpers/request';
import { openStockEquipment } from 'redux/actions';
import BoxItem from './BoxItem';
import DocsAgreement from './DocsAgreement';

function ListItem(props) {
	const { languageSchema, boxes, stock, user } = props;
	const [accountBalance, setAccountBalance] = useState(0.0);
	const [isShowPassword, setShowPassword] = useState(false);
	const [personalInvoices, setPersonalInvoices] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const cityName = stock.cityId ? stock.cityId.city : stock.city;
	const countryName = stock.cityId ? stock.cityId.country : stock.country;

	const handleOpenEquipment = (equipment) => () =>
		toastr.confirm(
			`${languageSchema.Stock.areYouSureYouWantToRun} "${equipment.name}" ?`,
			{
				onOk: () => {
					props.openStockEquipment(stock._id, equipment.id);
				},
			}
		);

	const handleCheckHealth = async () => {
		const { isOk } = await request('GET', `stocks/${stock._id}/availability`);

		setIsModalOpen(!isOk);
	};

	useEffect(() => {
		handleCheckHealth();

		request('GET', 'iqacdocument/account-balance').then(
			({ accountBalance }) => {
				if (accountBalance !== undefined) {
					setAccountBalance(accountBalance);
				}
			}
		);
		request('GET', `users/${user._id}/personal-invoices`).then(
			(personalInvoices) => {
				setPersonalInvoices(personalInvoices.filter(({ debt }) => debt > 0));
			}
		);
	}, []);

	const redirectToOfflineApp = () => {
		window.location.replace(stock.offlineAppUrl);
	};

	return (
		<div className="container">
			{personalInvoices?.length ? (
				<div
					className="mt-4 mb-4 pt-3 px-5 text-center"
					style={{
						border: '1px solid #ffd200',
					}}
				>
					<Link to={`/users/${user._id}#userInvoices`}>
						<h5
							dangerouslySetInnerHTML={{
								__html: languageSchema.HomePage.unpaidDebts('#1890ff'),
							}}
						/>
					</Link>
				</div>
			) : null}
			<div className="row">
				<div className="col-md-12">
					<div className="card">
						<h5 className="card-title d-flex justify-content-between">
							<div>
								{languageSchema.Stock.stock} {stock.name}
							</div>
						</h5>
						<h5>
							{accountBalance} ({languageSchema.Stock.accountBalance})
						</h5>
						<p className="card-text">
							{stock.address}, {stock.zip}
							<br />
							{cityName}, {countryName}
						</p>

						{!user.isAgreedWithDocs && (
							<DocsAgreement user={user} languageSchema={languageSchema} />
						)}
						<div className="form-inline">
							<div className="mb-2">Wi-Fi:</div>
							<input
								className="form-control mb-2 mr-sm-2"
								value={stock.wifiNetworkName || ''}
							/>
						</div>
						<div className="form-inline">
							<button
								className="btn btn-outline-primary mb-2"
								onClick={() => setShowPassword(!isShowPassword)}
							>
								{languageSchema.Stock.showWifiPassword}
							</button>
							<div className="form-check mb-2 mr-sm-2" />
							{isShowPassword && (
								<input
									className="form-control mb-2 mr-sm-2"
									value={stock.wifiPassword}
								/>
							)}
						</div>
						<div
							className="flex align-items-center my-3 p-2 rounded"
							style={{
								background: '#cee5f8',
								color: '#6978a0',
								width: 'fit-content',
							}}
						>
							<InfoCircleTwoTone
								style={{ fontSize: '20px', marginRight: '5px' }}
							/>
							<span style={{ fontSize: '12px' }}>
								{languageSchema.Stock.notifyOpenDoor}
							</span>
						</div>
						<div className="inline-flex flex-wrap">
							{stock.internalEquipments &&
								stock.internalEquipments.map((equipment) => (
									<div key={equipment._id}>
										<button
											variant="outline-dark"
											className="btn btn-outline-success btn-md mx-2 vertical-flex"
											onClick={handleOpenEquipment(equipment)}
										>
											<i className={'fas fa-key'}> </i> {equipment.name}
										</button>
									</div>
								))}
						</div>
						<hr />
						<h5 className="card-title d-flex justify-content-between">
							{languageSchema.Stock.boxes}
						</h5>

						{boxes.length === 0 ? (
							<NoItems />
						) : (
							<div className="inline-flex flex-wrap">
								{boxes.map((box) => (
									<BoxItem
										key={box._id}
										box={box}
										languageSchema={languageSchema}
										stock={stock}
										fetchBoxes={props.fetchBoxes}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
			<Modal
				title={`${languageSchema.Stock.stockConnectionIsLost} ${stock.name}!`}
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				footer={[
					<Button key="submit" type="primary" onClick={redirectToOfflineApp}>
						{languageSchema.Stock.redirectToOfflineApp}
					</Button>,
				]}
			>
				<span>{languageSchema.Stock.followSteps}</span>
				<div className='font-weight-bold d-flex flex-column mt-2'>
					<span>1. {languageSchema.Stock.turnOffMobileNet}</span>
					<span>2. {languageSchema.Stock.connectToWiFi}</span>
					<span>3. {languageSchema.Stock.clickOfflineAppButton}</span>
				</div>
			</Modal>
		</div>
	);
}

let mapDispatchToProps = {
	openStockEquipment,
};

export default connect(null, mapDispatchToProps)(ListItem);
