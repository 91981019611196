import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import request from 'helpers/request';
import Designer from 'components/common/Designer';
import { getLanguageSchema } from 'dashboard-commons/languages';

class MapConstructor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			objects: props.objects
		};
	}

	setObjects = objects => {this.setState({ objects });};

	saveMap = async () => {
		await request('POST', `stocks/${this.props.stockId}/map`, {
			mapId: this.props.mapId,
			objects: this.state.objects
		});

		toastr.success(
			this.props.languageSchema.Etc.success,
		);
	}

	render() {
		let { languageSchema } = this.props;

		return (
			<div>

				<div className="mb-3" style={{overflowY: 'scroll', overflowX: 'scroll'}}>
					<Designer
						width={1700} height={790}
						background={'#ece2be'}
						objects={this.state.objects}
						onUpdate={this.setObjects}
					/>

				</div>
				<div>
					<button className="btn btn-primary" onClick={this.saveMap}>
						{languageSchema.Stock.save}
					</button>
				</div>
			</div>
		);
	}
}

let mapStateToProps = state => ({
	languageSchema: getLanguageSchema(state.common.language)
});

export default connect(mapStateToProps)(MapConstructor);
