import React, { useState, useEffect } from 'react';
import moment from 'moment';
import querystring from 'querystring';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';


import { displayAmount, round } from 'helpers/amount';
import { getToken } from 'helpers/request';
import { apiUrl, DATE_FORMAT } from 'utils/constants';
import { isAdmin } from 'utils/functions';
import IqacDocumentProperty from '../../components/Contract/IqacDocumentProperty';
import TextLine from './TextLine';
import { fetchStocks, setIqacDocumentAttribute } from 'redux/actions';
import request from 'helpers/request';

function InvoiceListItem(props) {
	const {
		invoice, payment, languageSchema, currency,
		language, contract, boxNumber
	} = props;

	const { search } = useLocation();
	const query =  React.useMemo(() => new URLSearchParams(search), [search]);


	const isNeedToBePaid = Number(invoice.debt) > 0;
	const invoiceId = invoice.DocumentId || invoice.DocumentID;	// SM dirrerent fields format
	const isCurrentUserAdmin = props.user.role && isAdmin(props.user.role);
	const [documentDate, setDocumentDate] = useState(moment(invoice.DocumentDate).format('YYYY-MM-DD'));
	const [accountingDate, setAccountingDate] = useState(moment(invoice.AccountingDate).format('YYYY-MM-DD'));

	useEffect(() => {
		// download stocks if not exists
		if (props.stocks.data.length <= 0) {
			props.fetchStocks().catch((error) => console.warn(error.message));
		}

		const invoiceIdFromQuery = query.get('invoiceId');

		// scroll to needed invoice
		if (invoiceIdFromQuery) {
			document.getElementById(invoiceIdFromQuery).scrollIntoView();
		}
	}, []);

	const submitIqacDocumentAttribute = (attribute) => {
		if (props.stocks.data.length <= 0) {
			console.warn('Stocks not exists');
			return;
		}

		const [stock] = props.stocks.data;

		props.setIqacDocumentAttribute(attribute, invoiceId, stock._id);
	};

	const getInvoiceInPdfUrl = () => {
		const paymentConfig = {
			invoiceId,
			language,
			stockId: contract.stockId,
			boxNumber
		};

		const queryString = querystring.stringify(paymentConfig);
		const authToken = getToken();

		return `${apiUrl}/payments/pdf?${queryString}&authToken=${authToken}`;
	};

	const handleManualPay = async () => {
		await props.manualPay();
		await props.fetchInvoices();
	};

	const sendInvoiceEmail = async () => {
		await request('POST', 'notifications/invoices-emailing', {
			stockId: contract.stockId,
			contractId: contract.contractId_sm,
			invoiceId: invoice.DocumentID,
		});

		props.sendInvoiceEmailCallback();
	};

	return (
		<div id={invoice.DocumentID}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="card-header">
				<h2 className="mb-0">
					<button className="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse${invoice.DocumentID}`} aria-controls={`collapse${invoice.DocumentID}`}>
						<div style={{ flexDirection: 'row', alignItems: 'center' }}>
							{
								isNeedToBePaid &&
								<i className="fas fa-exclamation-triangle font-red"></i>
							}
							{
								payment && !isNeedToBePaid &&
								<i className="fas fa-check font-green"></i>
							}
							<span style={{ fontSize: 17 }}>
								{`#${invoice.InvoiceID}(${invoice.DocumentID})`} {props.isSuperUser && invoice.DocumentType}
							</span>
						</div>
					</button>
				</h2>

				<div className="horizontal-centered">
					{
						props.isSuperUser && props.invoiceEmailSending &&
						<div className="mx-2">
							<span style={{ position: 'initial' }} className="badge badge-info white">
								Last email sent: {moment(props.invoiceEmailSending.lastSending).format('YYYY-MM-DD')} <br />
								at {moment(props.invoiceEmailSending.lastSending).format('hh:mm')}
							</span>
						</div>
					}
					{
						!payment && props.isSuperUser &&
						<div>
							<button onClick={sendInvoiceEmail} className="btn btn-secondary btn-sm mx-2">
								{languageSchema.Contract.sentInvoiceEmailNotification}
							</button>

						</div>
					}
					{
						!payment && props.isSuperUser &&
						<button onClick={handleManualPay} className="btn btn-success btn-sm">
							{languageSchema.Contract.invoiceIsPaid}
						</button>
					}
				</div>
			</div>

			<div id={`collapse${invoice.DocumentID}`} className={`mb-4 collapse ${query.get('invoiceId') === invoice.DocumentID ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordionExample">
				<div style={{ padding: '20px' }}>
					<TextLine
						left={languageSchema.Contract.amount}
						right={`${displayAmount(round(invoice.Amount), currency)}`}
					/>

					<TextLine
						left={languageSchema.Contract.debt}
						right={`${displayAmount(invoice.debt, currency)}`}
					/>

					{
						invoice.DocumentType === 'Invoice' &&
						<TextLine
							left={languageSchema.Contract.vat}
							right={`${displayAmount(round(invoice.VATAmount), currency)}`}
						/>
					}

					{
						!isCurrentUserAdmin &&
						<TextLine
							left={languageSchema.Contract.documentDate}
							right={`${moment(invoice.DocumentDate).format(DATE_FORMAT)}`}
						/>
					}
					{
						isCurrentUserAdmin &&
						<IqacDocumentProperty
							left={languageSchema.Contract.accountingDate}
							right={accountingDate}
							onChange={setAccountingDate}
							onSubmit={() => submitIqacDocumentAttribute({ accountingDate })}
						/>
					}
					{
						isCurrentUserAdmin &&
						<IqacDocumentProperty
							left={languageSchema.Contract.documentDate}
							right={documentDate}
							onChange={setDocumentDate}
							onSubmit={() => submitIqacDocumentAttribute({ documentDate })}
						/>
					}
					{
						payment &&
						<TextLine
							left={languageSchema.Contract.paymentDate}
							right={`${moment(payment.DocumentDate).format(DATE_FORMAT)}`}
						/>
					}
					{
						(invoice.InvoiceComment || invoice.Description) &&
						<TextLine
							left={languageSchema.Contract.comment}
							right={invoice.InvoiceComment || invoice.Description}
						/>
					}

					<div className="text-center mt-2">
						{
							invoice.DocumentType === 'Invoice' &&
							<a className="btn btn-success mx-2" rel="noopener noreferrer" target="_blank" href={getInvoiceInPdfUrl()}>
								{languageSchema.Etc.download}
							</a>
						}

						{
							isNeedToBePaid && !isCurrentUserAdmin &&
							<button
								type="button"
								className={'btn btn-info mx-2'}
								onClick={() => props.handlePayButton({
									userId: props.user._id,
									DocumentID: invoice.DocumentId || invoice.DocumentID,
									boxId: contract?.boxId?._id,
									amount: round(invoice.debt - (invoice.VATAmount || 0)),
									totalAmount: invoice.debt,
									vatAmount: invoice.VATAmount || 0,
									discountAmount: 0,
									discountPercent: 0,
									currency: contract.CurrencyCode,
									processor: 'paykeeper',
								})}
							>
								{languageSchema.BoxBooking.payment}
							</button>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.auth.me,
	stocks: state.stocks,
});

const mapDispatchToProps = {
	setIqacDocumentAttribute,
	fetchStocks,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListItem);
