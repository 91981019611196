export default {
	contractDetails: 'Szczegóły umowy',
	BoxRentalAgreement: 'Umowa najmu boksu',
	termsOfUse: 'Warunki korzystania',
	tenantStatements: 'Oświadczenia najemcy',
	invoices: 'Faktury do umowy',
	contractNumber: 'Numer umowy',
	contractStatus: 'Status umowy',
	userContracts: 'Umowy użytkownika',
	attention: 'Uwaga',
	closingContractConition: 'Umowa może być zamknięta dopiero po zabraniu przez klienta rzeczy z boksu.',
	square: 'Metraż',
	period: 'Okres ',
	startDate: 'Data poczatku',
	closingDate: 'Data zamknięcia',
	paidInDays: 'Dni opłacone',
	paymentsFrequency: 'Fakturownie co | miesiąc(e)',
	monthlyCost: 'Koszt za miesiąc',
	periodCost: 'Kwota brutto',
	periodCostNetto: 'Kwota netto',
	planningLeavingDate: 'Planowana data opuszczenia',
	from: 'od',
	to: 'do',
	simple: 'Normalny',
	discount: 'zniżka',
	all: 'Wszystko',
	opened: 'Otwarte',
	closed: 'Zamknięte',
	amount: 'Kwota',
	amountBrutto: 'Kwota brutto',
	amountNetto: 'Kwota netto',
	debt: 'Zadłużenie',
	vat: 'VAT',
	accountingDate: 'Data księgowania',
	documentDate: 'Data dokumentu',
	paymentDate: 'Data płatności',
	comment: 'Komentarz',
	withDiscount: 'ze zniżka',
	inclVAT: 'z VAT',
	areYouSureYouWantToCloseContract: 'Czy na pewno chcesz zamknąć umowę?',
	contractEndingDate: 'Data końcowa',
	endingContracts: 'Umowy zakończone',
	didNotNotified: 'Klient nie poinformował o wyjezdzie',
	invoiceIsPaid: 'Oznacz jako opłacony',
	sentInvoiceEmailNotification: 'Wyślij e-mail',
	subscriptions: 'Subskrypcje',
	transactions: 'Transakcje',
	date: 'Data',
	unitAccess: 'Dostęp do urządzenia',
	boxsize: 'Rozmiar boksu ',
	download: 'Ściągnij',
	insuranceCoverageAmount: 'Kwota ubezpieczenia',
	insuranceAmount: 'Suma ubezpieczenia',
	regularPayment: 'Kwota Najmu',
	otherInvoices: 'Inne faktury',
	zeroPaidDaysDescription: 'Aby mieć dostęp do magazynu, kliknij w "szczegóły umowy" i opłać faktury.',
	uploadApplication: 'Pobierz wniosek o zawarcie umowy',
	insuranceConditions: 'Program ubezpieczeniowy'
};
