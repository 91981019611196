import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined, DownloadOutlined } from '@ant-design/icons';

import { getMimeType } from 'utils/functions';

const PdfViewer = ({src, customer, isFileLarge}) => {
	const [isFullScreen, setIsFullScreen] = useState(false);

	const toggleFullScreen = () => {
		if (!isFileLarge) {
			setIsFullScreen(!isFullScreen);
		}
	};

	return (
		<div
			className={`${
				isFullScreen ? 'pdf-viewer full-screen' : 'pdf-viewer'
			} d-flex flex-row h-100 justify-content-end`}
		>
			{!isFileLarge && <iframe src={src} title="PDF Viewer"></iframe>}
			<div className={`d-flex flex-column ${isFullScreen ? 'pdf-viewer-close' : ''}`}>
				{isFullScreen ? (
					<FullscreenExitOutlined disabled={isFileLarge} onClick={toggleFullScreen} />
				) : (
					<FullscreenOutlined onClick={toggleFullScreen} />
				)}
				<a download={`${customer}_docs.pdf`} href={src}><DownloadOutlined /></a>
			</div>
		</div>
	);
};

const CustomIframe = (props) => {
	let { src, customer } = props;

	const [mediaType, setMediaType] = useState('application/pdf');
	const [isFileLarge, setIsFileLarge] = useState(false);

	useEffect(() => {
		if (src) {
			getFileSize();
			const mimeType = src.includes('base64') && getMimeType(src.split('base64,')[1]);

			if (mimeType) {
				setMediaType(mimeType);
			}
		}
	}, [src]);

	const getFileSize = async () => {
		try {
			const file = await fetch(src);
			const fileBlob = await file.blob();

			setIsFileLarge((fileBlob.size / 1048576).toFixed(2) > 2);
		} catch(error) {
			console.error('Cant get file size');
		}
	};

	const renderMediaViewer = () => {
		if (mediaType) {
			if (mediaType === 'application/pdf') {
				return <PdfViewer className="w-100" isFileLarge={isFileLarge} customer={customer} src={src} type="application/pdf" />;
			} else {
				return <Image src={src} className="custom-iframe-image"/>;
			}
		}
	};

	return <div className="custom-iframe-wrapper">{renderMediaViewer()}</div>;
};

export default CustomIframe;
