export default {
	bookABox: 'Lagerraum buchen',
	chooseABox: 'Wählen Sie eine Box aus',
	stock: 'Standorte',
	address: 'Adresse',
	zip: 'Zip',
	selectStock: 'LAGER AUSWÄHLEN',
	boxesAvailable: 'Boxen verfügbar',
	totalBoxesNumberAtStock: 'Gesamtzahl der Kartons auf Lager',
	sizes: 'Größen',
	contractDuration: 'MINDESTVERTRAGSLAUFZEIT',
	paymentFrequency: 'Zahlungshäufigkeit',
	payRarePayLess: 'Zahlen Sie selten - zahlen Sie weniger!',
	payRareHelpBlock: 'Je seltener die Zahlungshäufigkeit - desto größer der Rabatt!',
	totalToPay: 'Summe zu zahlen',
	includedInsurance: 'inkl. Vers.',
	inclVAT: 'inkl. MwSt.',
	notInclVAT: 'MwSt nicht enthalten',
	toBookABox: 'Um eine Lagerraum zu buchen, bitte,',
	authorize: 'Anmelden',
	iAgree: 'Durch Klicken auf Bestätigen akzeptieren Sie die folgenden Dokumente:',
	contractAgreementDoc: 'Lagerraum Mietvertrag (Vorschau)',
	stockRoutine: 'Hausordnung',
	privacyPolicy: 'Datenschutzerklärung',
	companyRules: 'AGB',
	contractTerms: 'Allgemeine Vertragsbedingungen',
	notAvailable: 'Nicht verfügbar',
	step1SelectTheSizeOfTheBox: 'Schritt 1. Wählen Sie die Größe des Lagerraums.',
	step2SelectTheSizeOfTheBox: 'Schritt 2. Wählen Sie die Größe des Lagerraums.',
	step3SelectABoxOnTheMap: 'Schritt 3. Wählen Sie einen Lagerraum auf der Karte aus.',
	month: 'Monat',
	forWholePeriod: 'Gesamtsumme',
	saveAmount: 'Sie sparen',
	discount: 'Rabatt',
	cancel: 'Reservierung stornieren',
	areYouSureToCancelReservation: 'Möchten Sie die Reservierung wirklich stornieren ?',
	insuranceAmount: (amount) => `Über unsere Basis-Lagerversicherung sind Ihre Gegenstände in Höhe von ${amount} EUR,- versichert.`,
	reserveWithRequiredPayment: 'Buchen Sie mit obligatorischer Zahlung',
	reserveWithPayPal: 'Buchen Sie mit PayPal',
	reserveViaBankTransfer: 'Buchen Sie per Banküberweisung',
	iHaveReadDocsAndAgree: 'Ich habe folgende Vertragszusätze gelesen und akzeptiere diese vollinhaltlich.',
	sizesMeta: {
		s: 'Ausreichend für den Hausrat bis zu 50 m² oder für das Archiv bis zu 80 Kartons',
		m: 'Ausreichend für den Hausrat von 55 m² bis 100 m² oder für das Archiv von 85 bis 160 Kartons',
		l: 'Ausreichend für den Hausrat von 100 m² bis 200 m² oder für das Archiv von 165 bis 320 Kartons',
		xl: 'Ausreichend für den Hausrat von 200 m² bis 300 m² oder für das Archiv von 330 bis 480 Kartons'
	},
	sizesMetaTBDE: {
		s: 'Ausreichend für den Hausrat bis zu 50 m² oder für das Archiv bis zu 80 Kartons',
		m: 'Ausreichend für den Hausrat von 55 m² bis 100 m² oder für das Archiv von 85 bis 160 Kartons',
		l: 'Ausreichend für den Hausrat von 100 m² bis 200 m² oder für das Archiv von 165 bis 320 Kartons',
		xl: 'Ausreichend für den Hausrat von 200 m² bis 300 m² oder für das Archiv von 330 bis 480 Kartons'
	},
	tariffs: {
		basic: 'Monatliche Rate für einen unbefristeten Vertrag mit 21 Tagen Kündigungsfrist.',
		smart: 'Durchschnittliche monatliche Rate bei einem befristeten Vertrag über eine Laufzeit von 6 bis 11 Monaten. Die 30 % Rabatt werden für den 6. Monat berechnet.',
		max: 'Durchschnittliche monatliche Rate bei einem befristeten Vertrag über 12 Monate. Der Rabatt von 65 % wird für den 12. Monat berechnet.',
	},
	insuranceCoverage: 'Versicherungsschutz',
	englishWordOr: 'oder',
	yourChoice: 'Ausgewählt',
	freeBoxesOfTheRightSize: 'Verfügbar',
	occupiedBoxes: 'Besetzt',
	continue: 'Weiter',
	reservations: 'Reservieren',
	bookWithoutPayment: 'Kostenlos reservieren',
	bookAndPay: 'Mieten & Bezahlen',
	choiceOfArrivalDateTitle: 'Einzugsdatum',
	choiceOfArrivalDateText: 'Ab wann benötigen Sie Ihr Lagerabteil',
	choiceOfArrivalDatePlaceholder: 'Wählen Sie ein Datum',
	successTitle: ['Gratulieren!', 'Das Lagerabteil wurde erfolgreich gemietet.'],
	successText: 'Prüfen Sie bitte Ihr Emailpostfach inkl. den Spamordner und unterschreiben Sie den Mietvertrag. Das Lagerabteil steht beim Zahlungseingang, zum Mietbeginn zur Verfügung.',
	selectUser: 'Wähle den Benutzer',
	user: 'Benutzer',
	docsAgreement: 'Einverständniserklärungen',
	payment: 'Ihre Zahlweise',
	openEndedContractNote: 'Es handelt sich um einen unbefristeten Vertrag, dieser läuft bis zu deiner Kündigung. Die gewählte Laufzeit stellt deine Kündigungsoption dar.',
	openEndedContractNoteTopBox: 'Es handelt sich um einen unbefristeten Vertrag mit einer Mindestvertragslaufzeit. Nach Ablauf der Mindestvertragslaufzeit verlängert sich der Vertrag automatisch monatlich, bis dieser mit einer Frist von 14 Tagen gekündigt wird.',
	back: 'Zurück',
	insuranceWillBeAdded: 'zzgl Vers',
	automaticPaymentsText: 'Automatische monatliche Zahlung',
	searchHeader: 'Diese Karte durchsuchen',
	cityOrStockName: 'Unsere Standorte',
	sendPhotos: 'Foto senden',
	uploadDocsPhoto: 'Dokumentenfotos hochladen',
	bookingByTheAdministrator: 'Eine Buchung durch den Administrator ist untersagt! Melden Sie sich als Benutzer an.',
	chooseYourInsurance: 'Wählen Sie Ihre Versicherung',
	valueOfGoods: 'Warenwert',
	oneEuroForFirstMonth: '1. Monatsmiete - 1 Euro',
	boxNumber: 'Einheitsnummer',
	minimalRentPeriodDescription: 'Mindestmietdauer 2 Monate',
	forTwoInitialMonths: 'Für die ersten 2 Monate',
	twoInitialMonthsInsuranceDescription: 'inkl. Lagerversicherung',
	price: 'Preis',
	prices: 'Preise',
	selectBoxSize: 'LAGERRAUM WÄHLEN',
	enterDataAndPayment: 'DATENEINGABE & BEZAHLUNG',
	bookingParams: 'DATENEINGABE',
	reserveOrPay: 'BEZAHLEN',
	andPayLater: 'Und später bezahlen',
	payLaterDesctiption: 'Sie erhalten eine Rechnung per Post und können von Ihrem persönlichen Konto bezahlen',
	contactSource: 'Wie wurden Sie auf uns aufmerksam?',
	contactSourceDescription: 'Werbeträger',
	reasonForStorage: 'Aufbewahrungsgrund',
	areYouSureToCreateAContract: 'Möchten Sie wirklich einen Vertrag erstellen?',
	enterIntoContractExplanation: `Mit Klick auf „Vertrag abschließen“ führen Sie die Vertragserstellung durch.
Sie erhalten einen Link zur Vertragsunterzeichnung per E-Mail und eine erste Rechnung.
Nachdem Sie die Rechnung bezahlt haben, wird Ihnen der gesamte Zugriff auf das Lager und Ihre Box gewährt.`,
	reservationId: 'Reservierung',
	expires: 'Läuft ab',
	box: 'Lagerraum',
	startRentDate: 'Beginn der Miete',
	endRentDate: 'Enddatum der Miete',
	initialPayment: 'Anzahlung',
	enterIntoContract: 'Vertrag eingehen',
	details: 'Einzelheiten',
	rentalCost: 'Mietkosten',
	insurance: 'Versicherungsbetrag',
	deposit: 'Kaution',
	vatAmount: 'MwSt',
	boxLeaseAgreement: 'Box-Leasingvertrag mit Rentabox LLC',
	mapAndList: 'Karte & Liste',
	map: 'Karte',
	list: 'Liste',
	step3SelectABox: 'Schritt 3. Wählen Sie einen Lagerraum',
	boxesFromAnotherCategory: 'Andere Größenkategorie',
	selectOnMap: 'Wählen',
	move: 'Bewegen',
	zoomIn: 'Vergrößern',
	zoomOut: 'Verkleinern',
	stretch: 'Strecken',
	haveAnAccount: 'Habe bereits ein Konto?',
	useAutoPayments: 'Automatische Zahlungen zulassen',
	oneEuroActionDescription: 'Unsere 1€ Aktion ist nur für Neukunden bei einer Mindestmietdauer von 2 Monaten gültig.',
	oneEuroActionWasRemoved: `
		<h4>Sehr geehrte Damen und Herren,</h4>
		die 1€ Aktion wurde aus Ihrer Buchung entfernt, da diese nur für Neukunden bei einer Mindestmietdauer von 2 Monaten gültig ist.<br />
		Wir bitten um Verständnis.<br /><br />
		Mit freundlichen Grüßen<br />
		Ihr Top Box Team!<br />
	`,
	andDeposit: 'und Kaution',
	depositWillBeReturned: (amount) => `Die Kaution (${amount} €)  wird Ihnen nach Vertragsende zurückerstattet.`,
	includedInsuranceFull: 'Inkl. Lagerversicherung'
};
