import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import ContractList from 'components/Contract/List';
import { getLanguageSchema } from 'dashboard-commons/languages';

import request from 'helpers/request';

class List extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			contracts: [],
			boxes: [],
		};
	}

	async componentDidMount() {
		await Promise.all([
			this.fetchContracts(),
			this.fetchUser(),
			this.fetchBoxes(),
		]);
	}

	fetchContracts = async (query = {}) => {
		let { userId } = this.props.match.params;
		let contracts = await request('GET', `users/${userId}/contracts`, {
			status: 'all',
			...query,
		});

		this.setState({ contracts });
	};

	fetchBoxes = async () => {
		let { userId } = this.props.match.params;
		let boxes = await request('GET', `users/${userId}/boxes`);

		this.setState({ boxes });
	};

	fetchUser = async () => {
		let { userId } = this.props.match.params;
		let user = await request('GET', `users/${userId}`);

		this.setState({ user });
	};

	terminateContract = async (stockId, contractId, boxId_sm) => {
		toastr.confirm(
			this.props.languageSchema.Contract.areYouSureYouWantToCloseContract,
			{
				onOk: async () => {
					await request(
						'PUT',
						`stocks/${stockId}/contracts/${contractId}/terminate?boxId_sm=${boxId_sm}`
					);

					await this.fetchContracts({ status: 'opened' });
				},
			}
		);
	};

	savePlannedLeaveDate = async (stockId, contractId, plannedLeaveDate) => {
		await request('PUT', `stocks/${stockId}/contracts/${contractId}`, {
			plannedLeaveDate,
		});

		await this.fetchContracts();
	};

	handleFilterChange = async (e) => {
		let status = e.target.value;

		await this.fetchContracts({ status });
	};

	render() {
		const { contracts, user, boxes } = this.state;
		const { languageSchema, language } = this.props;

		if (!user) {
			return null;
		}

		return (
			<div className="card">
				<div className="col-3 pl-0 mb-2">
					<label> {languageSchema.Contract.contractStatus}: </label>
					<select
						type="select"
						name="status"
						className="form-control"
						onChange={this.handleFilterChange}
					>
						<option value="all"> {languageSchema.Contract.all} </option>
						<option value="opened"> {languageSchema.Contract.opened} </option>
						<option value="closed"> {languageSchema.Contract.closed} </option>
					</select>
				</div>
				<ContractList
					contracts={contracts}
					boxes={boxes}
					user={user}
					terminateContract={this.terminateContract}
					savePlannedLeaveDate={this.savePlannedLeaveDate}
					languageSchema={languageSchema}
					language={language}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ common }) => {
	return {
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
	};
};
export default connect(mapStateToProps)(withRouter(List));
