import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getLanguageSchema } from 'dashboard-commons/languages';
import request from 'helpers/request';
import Table from 'components/UI/Table';

function UserImport(props) {
	const [stocks, setStocks] = useState([]);
	const [siteId, setSiteId] = useState(null);
	const [users, setUsers] = useState([]);
	//const [latestImportDate, setLatestImportDate] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		fetchStocks();
		//fetchLatestImportDate();
	}, []);

	useEffect(() => {
		fetchUsers();
	}, [siteId]);

	const fetchStocks = () => {
		setIsLoading(true);
		request('GET', 'stocks')
			.then((stocks) => {
				setStocks(stocks.map((stock) => {
					delete stock.maps;
					delete stock.internalEquipments;

					return stock;
				}));

				if (!siteId && stocks.length) {
					setSiteId(stocks[0].stockId_sm);
				}
			})
			.catch(({ message }) => console.error(message))
			.finally(() => setIsLoading(false));
	};

	const handleChangeStock = (event) => {
		setSiteId(event.target.value);
	};

	const fetchUsers = () => {
		setIsLoading(true);
		request('GET', `users/external?siteId=${siteId}`)
			.then(setUsers)
			.catch(({ message }) => console.error(message))
			.finally(() => setIsLoading(false));
	};

	/*
	const fetchLatestImportDate = () => {
		setIsLoading(true);
		request('GET', 'users/import-by-date')
			.then((data) => {
				if (data?.latestImportAt) {
					setLatestImportDate(data?.latestImportAt);
				}
			})
			.catch(({ message }) => console.error(message))
			.finally(() => setIsLoading(false));

	};

	const importUser = (customerId) => {
		request('POST', 'users/import-by-ids', { customerIds: [customerId] })
			.then(() => fetchUsers())
			.catch((error) => console.error(error.message));

	};
	*/

	const importUsersBySiteId = () => {
		setIsLoading(true);

		request('POST', `users/import-by-site-id?siteId=${siteId}`)
			.then(() => fetchUsers(siteId))
			.catch(({ message }) => console.error(message))
			.finally(() => setIsLoading(false));
	};

	if (isLoading) {
		return (
			<div className="text-center">
				<div className="spinner-border" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<div className="card">
			<div className="custom-filter mb-3">
				<div className="form-row">
					<div className="col">
						<label htmlFor="stocks">{props.languageSchema.Stock.stock}:</label>
						<select id="stocks" className="form-select" name="stocks" onChange={handleChangeStock}>
							{stocks.map((stock) => <option key={stock.stockId_sm} value={stock.stockId_sm}>{stock.name}</option>)}
						</select>
					</div>
					<div className="col">
						<button type="button" className="btn btn-primary" onClick={() => importUsersBySiteId()}>
							{'Импортировать пользователей склада'}
						</button>
						{/*latestImportDate ?
							<div>{latestImportDate}</div> :
							<div>{props.languageSchema.User.unknownDate}</div>
						*/}
					</div>
				</div>
			</div>
			<div className="mb-3" style={{ fontSize: '14px' }}>
				<Table header={[
					'#',
					props.languageSchema.User.name,
					props.languageSchema.User.email,
					props.languageSchema.User.phone,
					props.languageSchema.User.created,
					props.languageSchema.User.otherContacts,
				]}>
					{users.map((user, i) => (
						<tr key={user.customerId}>
							<th scope="row">{i + 1}</th>
							<td>
								{user.firstName} {user.lastName}
							</td>
							<td>
								{user.email}
							</td>
							<td>
								{user.phone}
							</td>
							<td>
								{user.created}
							</td>
							<td>
								{user.otherPhonesAndEmails ? user.otherPhonesAndEmails.join(', ') : '-'}
							</td>
						</tr>
					))}
				</Table>
			</div>
		</div>
	);
}

const mapStateToProps = ({ common }) => ({
	languageSchema: getLanguageSchema(common.language),
});

export default connect(mapStateToProps)(UserImport);
