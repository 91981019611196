import * as Yup from 'yup';
import { individualUserFields, legalUserFields } from 'screens/User/validation';

const validationSchema = (languageSchema, accountType) => {
	let individualUserValidationSchema = Yup.object().shape(individualUserFields(languageSchema));
	let legalUserValidationSchema = Yup.object().shape(legalUserFields(languageSchema));

	let validationSchemas = {
		legal: legalUserValidationSchema,
		individual: individualUserValidationSchema,
	};

	return validationSchemas[accountType] || {};
};

export default validationSchema;
