export default {
	contractDetails: 'Сontract details',
	BoxRentalAgreement: 'Contract details',
	termsOfUse: 'Terms of use',
	tenantStatements: 'Tenant\'s statements',
	invoices: 'Contract invoices',
	contractNumber: 'Contract number',
	contractStatus: 'Contract status',
	userContracts: 'User Contracts',
	attention: 'Attention',
	closingContractConition: 'The contract can be closed only after the client takes their things from the box.',
	square: 'Area',
	period: 'Period',
	startDate: 'Start Date',
	closingDate: 'Closing Date',
	paidInDays: 'Paid, days',
	paymentsFrequency: 'Invoicing every | month(s)',
	monthlyCost: 'Cost per month',
	periodCost: 'Per period',
	periodCostNetto: 'Per period netto',
	planningLeavingDate: 'Planned departure date',
	from: 'from',
	to: 'by',
	simple: 'Normal',
	discount: 'discount',
	all: 'All',
	opened: 'Opened',
	closed: 'Closed',
	amount: 'Amount',
	amountBrutto: 'Kwota brutto',
	amountNetto: 'Kwota netto',
	debt: 'Debt',
	vat: 'VAT',
	accountingDate: 'Accounting Date',
	documentDate: 'Document Date',
	paymentDate: 'Payment date',
	comment: 'Comment',
	withDiscount: 'with discount',
	inclVAT: 'incl. VAT',
	areYouSureYouWantToCloseContract: 'Are you sure you want to close contract?',
	contractEndingDate: 'Ending date',
	endingContracts: 'Ending contracts',
	didNotNotified: 'Client didn\'t notify about departure.',
	invoiceIsPaid: 'Mark as paid',
	sentInvoiceEmailNotification: 'Send email',
	subscriptions: 'Subscriptions',
	transactions: 'Transactions',
	date: 'Date',
	unitAccess: 'Unit access',
	boxsize: 'Box size',
	download: 'Open',
	insuranceCoverageAmount: 'Insurance coverage amount',
	insuranceAmount: 'Insurance amount',
	regularPayment: 'Regular payment',
	netto: 'netto',
	brutto: 'brutto',
	otherInvoices: 'Other invoices',
	zeroPaidDaysDescription: 'To have access to the warehouse, click on "contract details" and pay the invoices.',
	uploadApplication: 'Upload application',
	insuranceConditions: 'Insurance conditions'
};
