import React from 'react';
import { connect } from 'react-redux';

import { round } from 'helpers/amount';
import { DEF_CONTRACT_DURATIONS } from 'utils/constants';
import { generateMonthsWord } from 'helpers/words';
import { getCurrencySign } from 'helpers/amount';
import { setContractDuration } from 'redux/actions/boxbooking';
import BoxBookingContext from 'screens/BoxBookingNew/Context';
import Slider from 'components/common/Slider';

import './PeriodSelector.css';

function PeriodItem(props) {
	return (
		<div className="border rounded mb-3 period-item p-1">
			<div
				onClick={props.onClick}
				className='align-items-center'
				style={{
					borderWidth: '3px !important',
					borderRadius: '5px',
					display: 'flex',
					justifyContent: 'space-between',
					cursor: 'pointer'
				}}
			>
				<div className='p-1 flex align-items-center'>
					<input
						className='my-input align-middle'
						type='radio'
						checked={props.isSelected}
					/>
					<div className='p-2'>
						{props.contractDuration.value} {generateMonthsWord(props.contractDuration.value, props.language)}
					</div>
				</div>

				{/*
					<div className='text-right'>
						<div>{props.monthRate} {getCurrencySign(props.currency)} {props.languageSchema.Etc.monthly}</div>
					</div>
				*/}
			</div>

			{props.insuranceMonthlyAmount !== 0 &&
				<div className="text-right" style={{ fontSize: '12px', marginTop: '-11px', color: '#b2b2b2' }}>
					{props.languageSchema.BoxBooking.includedInsurance} {props.insuranceMonthlyAmount} {getCurrencySign(props.currency)}<br />
				</div>
			}
		</div>
	);
}

function PeriodSelector(props) {
	const boxBookingContext = React.useContext(BoxBookingContext);

	let getDiscountPercent = (contractDuration) => {
		if (!contractDuration) {
			return 0;
		}

		let contrDuration = contractDuration;

		if (0 < contrDuration && contrDuration < 3) {
			return 0;
		}

		if (3 <= contrDuration && contrDuration <= 5) {
			return 5;
		}

		if (6 <= contrDuration && contrDuration <= 10) {
			return 10;
		}

		return 15;
	};

	let getCalculatedMonthRate = (contractDuration) => {
		let discountPercent = getDiscountPercent(contractDuration);
		let discountAmount = round(discountPercent * props.monthRate / 100);
		let rateWithDiscount = round(props.monthRate - discountAmount);

		return rateWithDiscount;
	};

	let handleContractDurationChange = (contractDuration) => {
		// let discountPercent = getDiscountPercent(contractDuration);

		boxBookingContext.setContractDuration(contractDuration);

		boxBookingContext.setInvoicingPeriod(1);
		boxBookingContext.setDiscountPercent(0);
	};

	let handleInvoicingPeriodChange = (invoicingPeriod) => () => {
		boxBookingContext.setInvoicingPeriod(invoicingPeriod);
		boxBookingContext.setDiscountPercent(getDiscountPercent(invoicingPeriod));
	};

	let discountPercent = getDiscountPercent(boxBookingContext.bookingMeta.selectedContractDuration);

	return (
		<>
			<div className="row" style={{ fontSize: '20px' }}>
				{props.languageSchema.BoxBooking.contractDuration}(мес.):
			</div>

			<Slider
				items={[1,2,3,4,5,6,7,8,9,10,11]}
				onChange={handleContractDurationChange}
				defaultValue={boxBookingContext.bookingMeta.selectedContractDuration}
			/>

			{/*DEF_CONTRACT_DURATIONS.map((contractDuration) => (
				<PeriodItem
					key={contractDuration.value}
					contractDuration={contractDuration}
					onClick={() => handleContractDurationChange(contractDuration.value)}
					isSelected={contractDuration.value === boxBookingContext.bookingMeta.selectedContractDuration}
					monthRate={getCalculatedMonthRate(contractDuration.value)}
					insuranceMonthlyAmount={props.insuranceMonthlyAmount}
					language={props.language}
					languageSchema={props.languageSchema}
					accountType={props.accountType}
					currency={props.currency}
				/>
			))*/}

			{/*<small><sup>*</sup>{props.languageSchema.BoxBooking.openEndedContractNote}</small>*/}
			{
				1 < boxBookingContext.bookingMeta.selectedContractDuration &&
				<>
					<div className="border rounded mb-3 period-item p-1">
						<div
							onClick={handleInvoicingPeriodChange(1)}
							className='align-items-center'
							style={{
								borderWidth: '3px !important',
								borderRadius: '5px',
								display: 'flex',
								justifyContent: 'space-between',
								cursor: 'pointer'
							}}
						>
							<div className='p-1 flex align-items-center'>
								<input
									className='my-input align-middle'
									type='radio'
									checked={ boxBookingContext.bookingMeta.invoicingPeriod === 1 }
								/>
								<div className='p-2'>
									Платить ежемесячно
								</div>
							</div>

						</div>
					</div>

					<div className="border rounded mb-3 period-item p-1">
						<div
							onClick={handleInvoicingPeriodChange(boxBookingContext.bookingMeta.selectedContractDuration)}
							className='align-items-center'
							style={{
								borderWidth: '3px !important',
								borderRadius: '5px',
								display: 'flex',
								justifyContent: 'space-between',
								cursor: 'pointer'
							}}
						>
							<div className='p-1 flex align-items-center'>
								<input
									className='my-input align-middle'
									type='radio'
									checked={ boxBookingContext.bookingMeta.invoicingPeriod === boxBookingContext.bookingMeta.selectedContractDuration }
								/>
								<div className='p-2'>
									Оплатить сразу за {boxBookingContext.bookingMeta.selectedContractDuration} {generateMonthsWord(boxBookingContext.bookingMeta.selectedContractDuration, props.language)} { 0 < discountPercent ? <><br /><span>и получить скидку <b>{discountPercent}%</b></span></> : '' }
								</div>
							</div>
						</div>
					</div>
				</>
			}
		</>
	);
}

export default PeriodSelector;
