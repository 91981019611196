import SuccessUI from './SuccessUI';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLanguageSchema } from 'dashboard-commons/languages';

const mapStateToProps = ({ common }) => {
	const languageSchema = getLanguageSchema(common.language);
	return {
		title: languageSchema.Payment.paymentSuccess,
		text: languageSchema.Payment.checkEmail,
		link: languageSchema.Payment.myBoxes,
	};
};

export default connect(mapStateToProps)(withRouter(SuccessUI));
