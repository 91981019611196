const initialState = {
	count: 0,
	data: [],
	isFetched: false,
	isFetchedCount: false,
};

export default function reducer(state = initialState, action) {
	let users;

	switch (action.type) {
	case 'FETCH_USERS': {
		return { ...state, data: action.payload, isFetched: true };
	}

	case 'FETCH_USERS_COUNT': {
		return { ...state, count: action.payload, isFetchedCount: true };
	}

	case 'FETCH_USER': {
		return { ...state, data: action.payload };
	}

	case 'CREATE_USER': {
		let alreadyExistedUser = state.data.find(user => user.email === action.payload.email);
		if (alreadyExistedUser) {
			return state;
		}

		return { ...state, data: [...state.data, action.payload] };
	}

	case 'UPDATE_USER': {
		if (0 < state.data.length) {
			users = state.data.map(user => (user._id === action.payload._id) ? { ...user, ...action.payload } : user);
				
			return { ...state, data: users };
		}

		return state;
	}
	case 'DELETE_USER': {
		users = state.data.filter(user => user._id !== action.payload);
		return { ...state, data: users };
	}

	default:
		return state;
	}

}
