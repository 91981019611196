import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';

import { ROLES_MAP } from 'utils/constants';
import BoxHistory from 'components/Box/History';
import Heading from 'components/UI/Heading';
import { fetchBox, openBox, clearCurrentBox, fetchBoxHistory, fetchStocks } from 'redux/actions';
import { getPrettyLockStatus } from 'utils/functions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import InternalEquipments from 'components/Stock/InternalEquipments';

class BoxPage extends Component {
	state = {
		contract: {},
		isContractLoading: false,
		invoices: [],
		areInvoicesLoading: false,
	};

	async componentDidMount() {
		const {
			match: { params: { id: boxId } },
			fetchBox,
			fetchBoxHistory,
			fetchStocks,
		} = this.props;

		await Promise.all([
			fetchBox(boxId),
			fetchBoxHistory(boxId),
			fetchStocks()
		]);
	}

	componentWillUnmount() {
		this.props.clearCurrentBox();
	}

	handleClickToOpen = async () => {
		const { box, openBox, fetchBoxHistory, languageSchema } = this.props;

		toastr.confirm(languageSchema.Stock.areYouSureYouWantToOpenBox, {
			onOk: async () => {
				await openBox(box);
				await fetchBoxHistory(box._id);
			}
		});
	}

	render() {
		const { box, boxHistory, languageSchema, user, currentStock } = this.props;

		if (!box) {
			return null;
		}

		const { lockStatus, userId, ContractID, ContractNumber } = box;

		const isLockUnavailable = lockStatus === 'unavailable';
		const status = getPrettyLockStatus(lockStatus, languageSchema);
		const ADMIN_ROLES = [ROLES_MAP.superUser];
		let isUserAdmin = user.role && ADMIN_ROLES.includes(user.role);

		return (
			<div>
				<div className="row py-3 justify-content-md-center">
					<div className={'col-md-6 col-md-offset-3'}>
						<div className="card">
							<Heading className="mb-3 d-flex justify-content-between align-items-start">
								<div>{languageSchema.Stock.box} {box.UnitNumber}({box.size} м²)</div>
							</Heading>

							<table className="table table-borderless">
								<tbody>
									<tr>
										<th scope="row" width="30%">{languageSchema.Stock.lockStatus}</th>
										<td className={`font-${status.color}`}>{status.title}</td>
										<td className="text-right">
											{
												isUserAdmin &&
												<button
													disabled={isLockUnavailable}
													className={`btn btn-outline-secondary btn-md
														${isLockUnavailable ? 'disabled' : 'green'}`
													}
													onClick={this.handleClickToOpen}
												>
													<i className={`fas fa-lock green ${isLockUnavailable ? '' : 'green'}`}> </i> {languageSchema.Buttons.open}
												</button>
											}
										</td>
									</tr>
									{
										userId &&
										<tr>
											<td>{languageSchema.User.currentClient}</td>
											<td>
												<Link
													to={{
														pathname: `/users/${userId._id}`,
													}}
												>
													{
														userId.firstName ?
															`${userId.firstName} ${userId.lastName}` :
															userId.company
													}
												</Link>
											</td>
											<td></td>
										</tr>
									}
									{
										userId &&
										<tr>
											<td>
												{languageSchema.Contract.contractNumber}:
											</td>
											<td>
												<Link
													to={{
														pathname: `/users/${userId._id}/contracts/${ContractID}`,
													}}
												>
													#{ContractNumber}
												</Link>
											</td>
											<td></td>
										</tr>
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div className="row py-3 justify-content-md-center">
					<div className={'col-md-6 col-md-offset-3'}>
						<div className="card">
							{
								currentStock.internalEquipments &&
								<InternalEquipments stock={currentStock} />
							}
						</div>
					</div>
				</div>

				<div className="row py-3 justify-content-md-center">
					<div className="col-md-6 col-md-offset-3">
						<div className="card">
							<Heading>	{languageSchema.Stock.history} </Heading>
							<BoxHistory history={boxHistory} type="box" />
						</div>
					</div>
				</div>

			</div>
		);
	}
}

const mapStateToProps = ({ auth, common, boxes: { current: box, boxHistory }, stocks, boxcats }) => {
	let currentStock = {};
	if (box) {
		currentStock = stocks.data.find(stock => stock._id === box.stockId);
	}

	return {
		user: auth.me,
		box,
		currentStock: currentStock || {},
		boxcats,
		boxHistory,
		languageSchema: getLanguageSchema(common.language)
	};
};
const actions = {
	fetchBox,
	openBox,
	clearCurrentBox,
	fetchBoxHistory,
	fetchStocks,
};
export default connect(mapStateToProps, actions)(BoxPage);
