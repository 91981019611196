import React, { useState } from 'react';
import { connect } from 'react-redux';
import restrictedItemsImg from 'assets/images/restricted_items.jpg';
import Checkbox from 'react-simple-checkbox';
import querystring from 'querystring';
import request from 'helpers/request';
import {
	setCurrentUserDetails
} from 'redux/actions';
import { ROLES_MAP, apiUrl } from 'utils/constants';
import { getToken } from 'helpers/request';

function DocsAgreement(props) {

	const { user, languageSchema, setCurrentUserDetails, contractId } = props;
	const [isAgreed, setIsAgreed] = useState(user.isAgreedWithDocs);

	const toggleAggreementWithDocs = async () => {
		setIsAgreed(!isAgreed);

		await request('PUT', `users/${user._id}`, { isAgreedWithDocs: !user.isAgreedWithDocs });
		// because user is being taken from redux, need to update it there also
		await setCurrentUserDetails();
	};

	const getContractInPdfUrl = () => {
		const authToken = getToken();
		const query = querystring.stringify({ contractId, userId: user._id });

		return `${apiUrl}/contracts/pdf?${query}&authToken=${authToken}`;
	};

	if (user.role === ROLES_MAP.superUser) {
		return null;
	}

	return (
		<div className="d-flex my-2 mb-5 flex-wrap">
			<div>
				<h4>{languageSchema.BoxBooking.docsAgreement}</h4>
				<div className="col-md-12">
					<div
						className={'mb-2'}
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							borderRadius: props.isError ? '5px' : 0,
							border: props.isError ? '1px solid red' : 'none',
							padding: props.isError ? '5px' : 0,
							boxShadow: props.isError ? '0 9px 17px 1px rgb(137 51 51 / 30%)' : 'none',
						}}
					>
						<div style={{ marginRight: '7px', marginTop: '-13px' }}>
							<Checkbox
								color="#3cab26"
								size={3}
								tickAnimationDuration={100}
								backAnimationDuration={100}
								borderThickness={2}
								checked={isAgreed}
								onChange={toggleAggreementWithDocs}
							/>
						</div>

						<div style={{ lineHeight: '1.2' }}>
							{languageSchema.BoxBooking.iHaveReadDocsAndAgree}<span style={{ fontSize: '20px' }} className="red">*</span>:
						</div>
					</div>
					<div>
						<div>
							<a href={getContractInPdfUrl()} rel="noopener noreferrer" target="_blank">
								{languageSchema.BoxBooking.boxLeaseAgreement}
							</a>
						</div>
						<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2022/05/requisites_rentabox.pdf">- Реквизиты</a></div>
						<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2022/05/Правила-безопасности.pdf">- Правила безопасности использования грузового подъемника</a></div>
						<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2022/05/Согласие-на-обработку_перс_данных.pdf">- Согласие на обработку персональных данных</a></div>
						<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2019/11/conf_issues_.pdf">- Политика конфденцальности</a></div>
						<div><a rel="noopener noreferrer" target="_blank" href="https://www.rentabox.ru/wp-content/uploads/2022/11/conf_issues_my_rentabox.pdf">- Положение о политике конфиденциальности при работе в личном кабинете на сайте</a></div>
					</div>
				</div>
			</div>
			<div>
				<img className="w-100" style={{ maxWidth: '400px' }} src={restrictedItemsImg} alt="Docs Agreement" />
			</div>

			<hr />
		</div>
	);
}


const mapDispatchToProps = {
	setCurrentUserDetails
};

export default connect(null, mapDispatchToProps)(DocsAgreement);
