import React, { useState } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

import request from 'helpers/request';
import SignUpForm from 'components/SignUp/Form';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { ACCOUNT_TYPES_MAP } from 'utils/constants';
import validationSchema from './validation';

function SignUpPage(props) {
	let { languageSchema } = props;

	let individualFormFields = {
		password: '',
		email: '',
		firstName: '',
		lastName: '',
		country: 'RU',
		city: '',
		phone: '',
		address: '',
		zip: '',
		company: '',
		isAdvertisingAgree: true,
		language: props.language,
		passportFile: null,
		selfieFile: null
	};

	let legalFormFields = {
		password: '',
		email: '',
		country: 'RU',
		city: '',
		phone: '',
		address: '',
		zip: '',
		company: '',
		registrationNumber: '',
		isAdvertisingAgree: true,
		language: props.language,
	};

	let initialValues = {
		accountType: '',
		...individualFormFields,
		...legalFormFields,
	};

	let [accountType, setAccountType] = useState(ACCOUNT_TYPES_MAP.individual);

	let getDataFields = (accType) => {
		let fields = {
			individual: [...Object.keys(individualFormFields), 'accountType'],
			legal: [...Object.keys(legalFormFields), 'accountType'],
		};

		return fields[accType];
	};

	let handleSubmitUserForm = async (values) => {
		try {
			let accountData = _.pick(values, getDataFields(accountType));

			await request('POST', 'auth/sign-up', accountData);

			toastr.success(
				languageSchema.SignUp.youHaveRegistered,
				languageSchema.SignUp.checkEmail(values.email)
			);

			props.history.push(`/login?email=${values.email}`);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-md-8 offset-md-2 mt-4">
					<div className="card">
						<Formik
							initialValues={initialValues}
							onSubmit={handleSubmitUserForm}
							validationSchema={validationSchema(languageSchema, accountType)}
							render={props => (
								<SignUpForm
									languageSchema={languageSchema}
									{...props}
									setAccountType={setAccountType}
								/>
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
	language: state.common.language,
});

export default connect(mapStateToProps)(withRouter(SignUpPage));
