export default {
	leaveAppeal: 'Оставить сообщение',
	requestACallback: 'Обратный звонок',
	email: 'Email',
	phone: 'Контактный номер телефона',
	name: 'Имя',
	appealMessage: 'Текст обращения',
	callback: 'Обратный звонок',
	enterYourMessage: 'Введите сообщение',
	invalidCharacters: 'Введены недопустимые символы',
	invalidPhoneBounds: 'Длина номера должна быть от 3 до 14 символов',
	messageInvalidBounds: 'Длина сообщения должна быть 1 до 250 символов',
	contacts: 'Контакты',
	address: 'Адрес',
	web: 'Сайт',
	writeEmail: 'Написать e-mail',
	callUs: 'Позвонить нам',
	callWithWhatsApp: 'Связаться с нами в WhatsApp',
};
