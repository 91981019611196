import React from 'react';

import Table from 'components/UI/Table';
import Heading from 'components/UI/Heading';
import NoItems from 'components/common/NoItems';

import ListItem from './ListItem';

class EndingContractsList extends React.PureComponent {
	renderList = notSentInvoicesEmails => (
		<Table
			centerHeader
			header={[
				'Stock',
				'Client',
				'Contract Number',
				'Invoice Id'
			]}
		>
			{
				notSentInvoicesEmails.map(invoice =>
					<ListItem
						key={invoice.DocumentID}
						invoice={invoice}
						languageSchema={this.props.languageSchema}
					/>
				)
			}

		</Table>
	);

	render() {
		let { languageSchema, notSentInvoicesEmails } = this.props;

		return (
			<div className="card">
				<Heading>
					{languageSchema.HomePage.notSentInvoicesEmails}
				</Heading>

				<div className="mb-3" style={{ fontSize: '14px' }}>
					{
						0 < notSentInvoicesEmails.length ?
							this.renderList(notSentInvoicesEmails) :
							<div className="center"><NoItems isLogo={false} /></div>
					}
				</div>
			</div>
		);
	}
}

export default EndingContractsList;
