import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import CityForm from 'components/City/Form';
import Heading from 'components/UI/Heading';
import { fetchCities, updateCity, deleteCity } from 'redux/actions';
import { timeout } from 'utils/functions';
import { getLanguageSchema } from 'dashboard-commons/languages';

import validationSchema from './validation';

class CityPage extends React.Component {
	state = {
		isSuccess: false,
	}

	componentDidMount() {
		const {
			isFetchedCities,
			fetchCities
		} = this.props;

		if (!isFetchedCities) {
			fetchCities();
		}
	}

	handleSubmit = async (values, { setSubmitting }) => {
		toastr.confirm(this.props.languageSchema.Etc.areYouSureYouWantToSaveChanges, {
			onOk: async () => {
				try {
					await this.props.updateCity(values);
					this.setState({ isSuccess: true }, async () => {
						await timeout(2000);
						this.setState({ isSuccess: false });
					});
				} finally {
					setSubmitting(false);
				}
			},
			onCancel: () => setSubmitting(false)
		});
	}

	handleClickDelete = () => {
		const {
			history: { push },
			city,
			deleteCity,
			languageSchema,
		} = this.props;

		toastr.confirm(languageSchema.Cities.areYouSureYouWantToDeleteCity, {
			onOk: () => {
				deleteCity(city._id);
				push('/cities');
			}
		});

	}

	render() {
		const { city, language, languageSchema } = this.props;

		if (!city) {
			return (
				<div>{languageSchema.Etc.loading}</div>
			);
		}

		return (
			<div className="row py-3">
				<div className="col-md-6 offset-md-3">
					<div className="card">
						<Heading>{languageSchema.Cities.editingCity}</Heading>
						<Formik
							initialValues={city}
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema(languageSchema)}
							render={props =>
								<CityForm type="edit"
									isSuccess={this.state.isSuccess}
									{...props}
									languageSchema={languageSchema}
									language={language}
								/>
							}
						/>
						<hr />
						<div className="d-flex justify-content-end mt-3">
							<button className="btn btn-danger btn-sm" onClick={this.handleClickDelete}>
								{languageSchema.Buttons.delete}
							</button>
						</div>

					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ cities, common }, props) => {
	const currentId = props.match.params.id;
	const currentCity = cities.data.find(city => city._id === currentId);
	return {
		city: currentCity,
		isFetchedCities: cities.isFetched,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
	};
};
const mapDispatchToProps = {
	fetchCities, updateCity, deleteCity
};
export default connect(mapStateToProps, mapDispatchToProps)(CityPage);
