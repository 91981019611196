import {
	BOX_BOOKING_SET_ARIVIAL_DATE,
	BOX_BOOKING_TOGGLE_CONTRACT_ACCEPT,
	BOX_BOOKING_SET_ERROR,
	BOX_BOOKING_DEL_ERROR,
	BOX_BOOKING_SET_CONTRACT_DURATION,
	BOX_BOOKING_TOGGLE_PAYMENT_PERIOD
} from '../actionTypes';

export const setArrivalDate = (arrivalDate) => ({
	type: BOX_BOOKING_SET_ARIVIAL_DATE,
	payload: { arrivalDate }
});

export const toggleContractAccept = () => ({
	type: BOX_BOOKING_TOGGLE_CONTRACT_ACCEPT,
});

export const setBoxBookingError = (errorType) => ({
	type: BOX_BOOKING_SET_ERROR,
	payload: { errorType }
});

export const delBoxBookingError = () => ({
	type: BOX_BOOKING_DEL_ERROR
});

export const setContractDuration = (contractDuration) => ({
	type: BOX_BOOKING_SET_CONTRACT_DURATION,
	payload: { contractDuration }
});

export const togglePaymentPeriod = () => ({
	type: BOX_BOOKING_TOGGLE_PAYMENT_PERIOD
});
