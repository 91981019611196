export default {
	1: 'Что-то пошло не так. Пожалуйста, попробуйте позже',
	2: 'Сервер замков на складе недоступен',

	1000: 'Email обязателен',
	1001: 'Введите правильный email',
	1002: 'Пароль обязателен',
	1003: 'Поле обязательно',
	1004: 'Недопустимое количество символов',
	1005: 'Недопустимый формат',

	1077: 'Нет текущих счетов',

	5000: 'У пользователя есть незакрытые договоры. Пожалуйста, проверьте их',

	6001: 'К сожалению, бокс уже занят. Пожалуйста, выберите другой или обратитесь в службу поддержки',
	6002: 'Неизвестный пользователь',
	6003: 'необходимо указать бокс или склад',
	6004: 'Пользователь не найден',
	6005: 'Бокс не найден',
	6006: 'Склад не найден',
	6007: 'Этот бокс не принадлежит выбранному складу. Пожалуйста выберите другой',
	6008: 'IP адрес обязателен',
	6009: 'Вам необходимо подключиться к сети Wi-Fi склада, чтобы открыть дверь',
	6010: 'К сожалению, нет доступных боксов по выбранным параметрам',
	6011: 'Номер замка уже используется',
	6013: 'Неверная конфигурация номера замка',
	6014: 'Вы не согласились с договором оферты, а также приложениями к нему.',

	7000: 'Нет фактической оплаты',
	7001: 'Ваш профиль не подтвержден. Вы не можете выполнить это действие',
	7002: 'Нельзя открыть склад в нерабочее время',
	7003: 'Дата начала договора еще не наступила',
	7005: 'Договор еще не начался',
	7006: 'У вас есть некоторые неоплаченные счета, пожалуйста, оплатите их. Найти их вы сможете на странице вашего профиля',
	7007: 'У вас есть просрочка оплаты, пожалуйста, свяжитесь с администратором.',
	7008: 'Срок действия договора истёк',
	9000: 'Пользователь с таким ящиком уже существует.',
	9001: 'Вы ввели неверные данные.',
	9003: 'Пожалуйста, авторизируйтесь',

	unrecognized: 'Что-то пошло не так. Пожалуйста, попробуйте позже.',
	error: 'Ошибка',
};
