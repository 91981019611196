import React from 'react';

const InfoToggler = (props) => {
	const {text = [], alertType} = props;

	let btnIconsConfig = {
		warning: 'fas fa-exclamation-triangle text-warning',
		info: 'fas fa-info-circle info text-info',
		danger: 'fas fa-exclamation-circle text-danger',
		success: 'fas fa-check-circle text-success',
	};

	let textClass = `alert alert-${alertType} alert-dismissible fade show dropdown-alert toggler-body`;

	return (
		<div className="info-toggler">
			<div className="toggler">
				<i className={btnIconsConfig[alertType]}></i>
				<div style={{ position: 'absolute', left: 0 }} className={textClass} role="alert">
					{
						Array.isArray(text) ?
							text.map(row => (
								<p dangerouslySetInnerHTML={{ __html: row }} key={row} className="alert-paragraph"></p>)
							) :
							text
					}
				</div>
			</div>
		</div>
	);
};

export default InfoToggler;
