import request from 'helpers/request';

const endpoint = 'boxcategories';

export const fetchBoxCats = () => async (dispatch) => {
	const boxcats = await request('GET', endpoint);
	dispatch({ type: 'FETCH_BOXCATS', payload: boxcats });
};

export const createBoxCat = boxcat => async (dispatch) => {
	const createdBoxCat = await request('POST', endpoint, boxcat);
	dispatch({ type: 'CREATE_BOXCAT', payload: createdBoxCat });
};

export const updateBoxCat = (boxcatId, boxcat) => async (dispatch) => {
	const updatedBoxCat = await request('PUT', `${endpoint}/${boxcatId}`, boxcat);
	dispatch({ type: 'UPDATE_BOXCAT', payload: updatedBoxCat });
};

export const deleteBoxCat = boxcatId => async (dispatch) => {
	await request('DELETE', `${endpoint}/${boxcatId}`);
	dispatch({ type: 'DELETE_BOXCAT', payload: boxcatId });
};