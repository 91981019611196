import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { init as initSocket } from 'redux/socket';

import { isTokenExist } from 'helpers/request';
import { setCurrentUserDetails } from 'redux/actions';

class PrivateRoute extends React.Component {
	async componentDidMount() {
		await this.props.setCurrentUserDetails();

		initSocket(this.props.dispatch);
	}

	render() {
		let { children, ...rest } = this.props;

		return (
			<Route
				{...rest}
				render={props => (
					isTokenExist() ?
						{children} :
						<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				)}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	error: state.common.error,
});

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		...bindActionCreators({ setCurrentUserDetails }, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
