export default {
	createdAt: 'Utworzony',
	success: 'Sukces',
	loading: 'Ładowanie ...',
	yes: 'Tak',
	no: 'Nie',
	wait: 'Czekaj ...',
	lastUpdate: 'Ostatnia aktualizacja ',
	noUpdates: 'Nie odnotowano żadnych aktualizacji',
	choose: 'Wybierz',
	currency: 'Waluta',
	all: 'Wszystko',
	settings: 'Ustawienia',
	notSet: 'Nie określono',
	reset: 'Reset',
	from: 'od',
	to: 'do',
	send: 'Wyślij',
	noItems: 'List jest pusty',
	areYouSureYouWantToSaveChanges: 'Czy na pewno chcesz zapisać zmiany?',
	searchPlaceholder: 'Wyszukiwanie...',
	metersShortLetter: 'm',
	monthShort: 'mies.',
	max: 'Max',
	months: 'miesiąc(-e)',
	select: 'Wybierz...',
	monthsWord: 'Miesiące',
	payOnce: 'Za cały okres',
	once: 'Raz na',
	monthly: 'Za miesiąc',
	toHome: 'do strony głównej',
	download: 'Pobierz PDF',
	somethingWentWrong: 'Ups, coś poszło nie tak...',
	somethingWentWrongDescription: 'Spróbuj ponownie. <br/> Lub skontaktuj się z pomocą techniczną.',
	or: 'lub',
	stepOne: 'Krok 1',
	stepTwo: 'Krok 2',
	stepThree: 'Krok 3',
	from3rdMonth: 'od 3 miesiąca',
	optional: 'Opcjonalnie',
	cancel: 'Аnulować',
	heading: 'Pozycja',
	subHeading: 'Podpozycją',
	normal: 'Normalny',
	of: 'z',
	remains: 'Pozostaje',
	clear: 'Wyczyść',
	areYouSure: 'Jesteś pewny?',
};
