import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import rentaboxLogo from 'assets/images/logo_blue_text.png';
import { Button, Form, Input } from 'antd';
import { errorCodesMap } from '../../utils/constants';

const LoginForm = (props) => {
	const {
		languageSchema,
		location = {},
		onSubmit,
		onSignUpAction,
		loading = false,
	} = props;

	let emailFromQueryString = location.search ?
		qs.parse(location.search).email :
		null;

	let emailValidationRules = useMemo(() => [
		{
			type: 'email',
			message: languageSchema.errors[errorCodesMap.enterValidEmail],
		},
		{
			required: true,
			message: languageSchema.errors[errorCodesMap.emailIsRequired],
		},
	], [languageSchema]);

	let passwordValidationRules = useMemo(() => [
		{ required: true, message: languageSchema.errors[errorCodesMap.passwordIsRequired] }
	], [languageSchema]);

	return (
		<Form
			name="basic"
			initialValues={{ remember: true, ['email']: emailFromQueryString }}
			onFinish={onSubmit}
			autoComplete="on"
			disabled={loading}
		>
			<div className="text-center mb-3">
				<Link className="navbar-brand" to="/">
					<img
						src={rentaboxLogo}
						alt="Rentabox"
						title="Rentabox"
						width="75%"
					/>
				</Link>
			</div>
			<Form.Item
				id="email"
				name="email"
				rules={emailValidationRules}
			>
				<Input size="large" placeholder={languageSchema.LogIn.yourEmail} />
			</Form.Item>

			<Form.Item
				id="password"
				name="password"
				rules={passwordValidationRules}
			>
				<Input.Password size="large" placeholder={languageSchema.LogIn.password} />
			</Form.Item>

			<div style={{ textDecoration: 'underline' }} className="d-flex justify-content-between mb-5">
				<Link to='/reset-password'>
					{languageSchema.LogIn.forgotPassword}
				</Link>
			</div>

			<Form.Item style={{ marginBottom: 0 }}>
				<Button
					style={{
						fontSize: '20px',
						width: '100%',
						backgroundColor: '#28a745',
						borderColor: '#28a745',
						height: 44,
					}}
					type="primary"
					htmlType="submit"
				>
					{ loading ? languageSchema.LogIn.wait : languageSchema.LogIn.enter }
				</Button>
			</Form.Item>
			<div>
				{
					onSignUpAction &&
					<>
						<div style={{
							textTransform: 'uppercase',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							padding: '30px 0',
							color: '#283049',
							fontSize: '28px',
							fontWeight: 600
						}}>
							{languageSchema.Etc.or}
						</div>

						<button
							onClick={onSignUpAction}
							style={{
								backgroundColor: '#283049',
								fontSize: '20px',
								width: '100%'
							}}
							className="btn btn-primary d-block"
						>
							{languageSchema.SignUp.signUp}
						</button>
					</>
				}
			</div>
		</Form>
	);
};

export default LoginForm;
