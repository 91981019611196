import {
	BOX_BOOKING_SET_ARIVIAL_DATE,
	BOX_BOOKING_TOGGLE_CONTRACT_ACCEPT,
	BOX_BOOKING_SET_ERROR,
	BOX_BOOKING_DEL_ERROR,
	BOX_BOOKING_SET_CONTRACT_DURATION,
	BOX_BOOKING_TOGGLE_PAYMENT_PERIOD
} from '../actionTypes';

const initialState = {
	contractDuration: { value: 11, discount: 15 },
	invoicingPeriod: { value: 11, discount: 15 },
	isPaymentForEntirePeriod: true,
	arrivalDate: null,
	areDocsConfirmed: false,
	errors: {},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
	case BOX_BOOKING_SET_ARIVIAL_DATE:
		return Object.assign({}, state, { arrivalDate: action.payload.arrivalDate, errors: {} });
	case BOX_BOOKING_TOGGLE_CONTRACT_ACCEPT:
		return Object.assign({}, state, { areDocsConfirmed: !state.areDocsConfirmed, errors: {} });
	case BOX_BOOKING_SET_ERROR:
		return Object.assign({}, state, { errors: { [action.payload.errorType]: true } });
	case BOX_BOOKING_DEL_ERROR:
		return Object.assign({}, state, { errors: {} });
	case BOX_BOOKING_SET_CONTRACT_DURATION:
		return Object.assign({}, state, {
			contractDuration: action.payload.contractDuration,
			invoicingPeriod: state.isPaymentForEntirePeriod ? action.payload.contractDuration : { value: 1, discount: 0 }
		});
	case BOX_BOOKING_TOGGLE_PAYMENT_PERIOD:
		return Object.assign({}, state, {
			isPaymentForEntirePeriod: !state.isPaymentForEntirePeriod,
			invoicingPeriod: state.isPaymentForEntirePeriod ? { value: 1, discount: 0 } : state.contractDuration
		});
	default:
		return state;
	}
}
