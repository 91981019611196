const FREE = 0;
const DRAG = 1;
const SCALE = 2;
const ROTATE = 3;
const DRAW = 4;
const TYPE = 5;
const EDIT_OBJECT = 6;

export const modes = {
	FREE,
	DRAG,
	SCALE,
	ROTATE,
	DRAW,
	TYPE,
	EDIT_OBJECT
};

