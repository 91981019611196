import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import querystring from 'querystring';

//import CallbackButton from 'components/Support/CallBackButton';

import rentaboxLogo from 'assets/images/logo_blue_text.png';
import { getLanguageSchema } from 'dashboard-commons/languages';

const PaymentFailed = (props) => {
	let { languageSchema, location } = props;
	let { search } = location;
	let paymentParams = querystring.parse(search.slice(1));

	return (
		<div className="row mt-5">
			<div className="card col-md-6 offset-md-3">
				<div className="text-center mb-3">
					<img
						src={rentaboxLogo}
						alt="Rentabox"
						title="Rentabox"
						width="40%"
					/>
				</div>
				<hr />
				<div className="text-center text-secondary">
					<i className="fas fa-exclamation-circle text-danger fs-9"></i>
					<h2 className="mt-4 mb-2 text-danger">
						{languageSchema.Payment.paymentFailed}
					</h2>
					{
						paymentParams.errorReason ?
							<div className="mb-4 mt-2">
								{languageSchema.Payment.errorReason}: <br/>
								<span> <b>{paymentParams.errorReason}</b></span>
							</div> :
							<div className="mb-4 mt-2" dangerouslySetInnerHTML={{ __html: languageSchema.Payment.tryPaymentLater }}>
							</div>
					}

				</div>
				<div className="text-center">
					<Link to="/book" className="btn btn-lg btn-outline-success">
						{languageSchema.Payment.book}
					</Link>
				</div>
			</div>

			{/* FIXME: CallbackButton
			<div className="callback-btn-wrapper">
				<CallbackButton languageSchema={languageSchema}/>
			</div>
			*/}
		</div>
	);
};

let mapStateToProps = ({ common }) => {
	return {
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
	};
};

export default connect(mapStateToProps)(PaymentFailed);
