export default {
	cities: 'Miasta',
	stocks: 'Magazyny',
	accounts: 'Konta',
	roles: 'Role',
	reports: 'Raporty',
	appeals: 'Apelacje',
	edit: 'Edytuj',
	exit: 'Wyjście',
	changePassword: 'Zmień hasło',
	boxes: 'Boksy',
	profile: 'Profil',
	myBoxes: 'Moje boksy',
	support: 'Wsparcie',
	book: 'Rezerwacja',
	toHome: 'Strona główna',
	topDebtors: 'Największe dłużności',
	notifications: 'Powiadomienie',
	monthlyBills: 'Miesięczne rachunki',
	Subscriptions: 'Subskrypcje',
	externalPayments: 'Płatności',
	notSentInvoicesEmails: 'Niewysłane maile z fakturami',
	notificationsOfPenalty: 'Powiadomienie o karze',
	issueTheFirstPenaltyInvoice: 'Wystaw pierwszą fakturę karną',
	unknownNotification: 'Nieznane powiadomienie',
	markAsCompleted: 'Oznacz jako ukończone',
	completed: 'Zakończony',
	usersImport: 'Importuj użytkowników',
	import: 'Importuj',
	emailHistory: 'Historia e-maili',
	unpaidDebts: (span_color) => `Masz nieopłacone <span style="color: ${span_color}">faktury</span>.`,
};
