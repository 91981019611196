export default {
	stock: 'Lager',
	boxes: 'Lagerräume',
	createStock: 'Lager hinzufügen',
	stockEditing: 'Lager bearbeiten',
	history: 'Chronik',
	box: 'Lagerraum',
	name: 'Name ',
	city: 'Stadt',
	address: 'Adresse',
	phone: 'Telefonnummer',
	zip: 'Postleitzahl',
	hasBoxes: 'hat Boxen',
	boxRenting: 'Boxvermietung',
	controlSystem: 'Lager-Verwaltungssystem',
	currency: 'Zahlungswährung',
	currencyRequired: 'Zahlungswährung',
	maxContractDuration: 'Maximale Vertragslaufzeit (Mon.)',
	routerIp: 'IP-Adresse des Lagers',
	locksServerPort: 'Server Port der Schlösser',
	databaseServer: 'Datenbank-Server',
	databasePort: 'Datenbank-Port',
	databaseName: 'Datenbank-Name',
	databaseUser: 'Datenbank-Benutzer',
	databasePassword: 'Datenbank-Passwort',
	addEquipment: 'einen Bestandteil hinzufügen',
	equipmentId: 'Bestandteil-ID',
	control: 'Steuerung',
	discountSettings: 'Rabatt-Einstellungen',
	discountHelpInfo: 'Dieser Betrag wird mit jeder Abschlusszahlung-Monat erhöht. <br /> <br />\n				<b>Beispiel:</b><br />\n				 Der Kunde möchte einen Vertrag für <b> 12 Monate abschließen </b>\n				 und wählt Teilzahlung alle 3 Monate </b>. Der Rabatt beträgt <b>2%</b>. <br />\n				Dann beträgt der Gesamtrabatt für 4 Monate-Zahlung:<br />\n				 <b>4 * 2% = 8%</b>		vom monatlichen Mietpreis für den Lagerraum.',
	discountPercentPerMonth: 'Rabattprozentsatz für jeden Monat der einmaligen Zahlung:',
	feeSettings: 'Verzugszinsen-Einstellungen',
	feeInfoBlock: 'Die Verzugszinsen werden von der Grundssumme berechnet und jeden Tag erhöht. ',
	dailyFeeDescription: 'Zuschlagssatz für jeden unbezahlten Tag',
	freeFeeDays: 'während der ersten nicht zurechnen',
	days: 'Tage',
	contractEndedFeeLabel: 'Zuschlagssatz für jeden Tag nach Vertragsablauf',
	stockHistory: 'Chronik des Lagers',
	date: 'Datum',
	idOrSize: 'ID oder Größe',
	action: 'Ausführung',
	noHistoryRecords: 'Keine Chronik vorhanden',
	account: 'Account',
	opened: 'offen',
	closed: 'geschlossen',
	accountType: 'Accounttype',
	areYouSureYouWantToOpenBox: 'Wollen Sie wirklich den Lagerraum öffnen?',
	lockStatus: 'Status des Schlosses',
	totalBoxes: 'Lagerräume, insgesamt',
	boxNumber: 'Lagerraum-Nummer',
	size: 'Größe',
	lockNumber: 'Schlossnummer',
	monthRate: 'Monatsmiete',
	notSet: 'nicht eingestellt',
	currentUser: 'der aktuelle Nutzer',
	boxAvailable: 'verfügbar',
	areYouSureYouWantToOpenStock: 'Wollen Sie wirklich das Lager entfernen?',
	areYouSureYouWantToOpen: 'Wollen Sie wirklich öffnen?',
	areYouSureYouWantToDelete: 'Möchten Sie das Lager wirklich löschen?',
	insurance: 'Versicherung',
	insuranceCoverageAmount: 'Versicherungsbetrag',
	insuranceMonthlyAmount: 'Monatliche Prämie',
	vat: 'MwSt.',
	legalUsersVATRate: 'MwSt. (Unternehmen)',
	individualUsersVATRate: 'MwSt. (Privatpersonen)',
	wifiPassword: 'Wi-Fi passwort',
	showWifiPassword: 'Wi-Fi passwort anzeigen',
	accessDenied: 'Der Zugang ist verwehrt',
	accessAllowed: 'Der Zugang ist erlaubt',
	coordinates: 'Koordinaten',
	latitude: 'Breite',
	longitude: 'Längengrad',
	stockId_sm: 'Standorte ID SM',
	save: 'Speichern',
	boxesMapConstructor: 'Boxen Kartenkonstruktor',
	accountBalance: 'Kontostand',
	height: 'Höhe',
	width: 'Breite',
	depth: 'Tiefe',
	square: 'Quadrat',
	boxSpace: 'Quadrat',
	areYouSureYouWantToRun: 'Sind Sie sicher, dass Sie ausführen möchten ',
	openingTime: 'Öffnungszeit',
	closingTime: 'Geschäftsschluss',
	wifiNetworkName: 'WLAN-Netzwerkname',
	gate: 'Tore',
	notifyOpenDoor: 'Um die Tür zu öffnen, muss man sich an das WLAN des Lagers anschließen und das VPN ausschalten!',
	notifyOpenLock: 'Um das Schloss zu öffnen, muss man sich an das WLAN des Lagers anschließen und das VPN ausschalten!'
};
