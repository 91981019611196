export default {
	enter: 'Einloggen',
	forgotPassword: 'Passwort vergessen?',
	yourEmail: 'Ihre E-Mail-Adresse',
	password: 'Passwort',
	wait: 'Warten Sie einen Augenblick ...',
	enterEmail: 'E-Mail-Adresse eingeben',
	enterPassword: 'Passwort eingeben',
	goToBooking: 'Gehe zur Buchung'
};
