import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_PAN } from 'react-svg-pan-zoom';
import { AutoSizer } from 'react-virtualized';
import { mapIsDragging } from 'redux/actions';


window.addEventListener('touchstart', function(event) {
	event.preventDefault(); // <-- for map
});

const SVGRenderer = (props) => {
	let {
		objects,
		onMouseDown,
		onRender,
		objectRefs,
		onMouseOver = () => {},
		objectTypes,
		setViewers,
		viewers,
		mapName,
		selectedBoxUnitNumber,
		selectedSizeCodes,
		clickedUnitNumber,
		language,
		isMapDragging,
		debouncedZoom,
		onTouchStart,
		onTouchEnd,
	} = props;

	let [tool, setTool] = useState(TOOL_PAN);
	let [value, setValue] = useState(INITIAL_VALUE);
	let [boxesElements, setBoxesElements] = useState(null);
	let Viewer = useRef(null);

	const getObjectComponent = (type) => {
		return objectTypes[type];
	};

	const renderObject = (object, index) => {
		let Renderer = getObjectComponent(object.type);

		return (
			<Renderer onRender={(ref) => {
				objectRefs = {
					...objectRefs,
					[index]: ref
				};
			}}
			onMouseOver={onMouseOver.bind(this, index)}
			object={object}	key={index} index={index} />
		);
	};

	useEffect(() => {
		let isMobile = window.innerWidth < 900;

		if (isMobile) {
			Viewer.setPointOnViewerCenter(200, 30, 0.4);
		} else {
			Viewer.setPointOnViewerCenter(0, 20, 0.55);
		}


		setViewers({
			...viewers,
			[mapName]: Viewer
		});
	}, []);


	useEffect(() => {
		if (!isMapDragging) {
			setBoxesElements(objects.map(renderObject));
		}
	}, [selectedBoxUnitNumber, selectedSizeCodes, clickedUnitNumber, language, isMapDragging, debouncedZoom]);

	const changeTool = (nextTool) => {
		if (nextTool === 'zoom-in') {
			return Viewer.zoomOnViewerCenter(1.1);
		}

		if (nextTool === 'zoom-out') {
			return Viewer.zoomOnViewerCenter(0.9);
		}

		return setTool(nextTool);
	};

	let handleOnMouseUp = () => {
		if (isMapDragging) {
			props.mapIsDragging(false);
		}
	};

	return (
		<div style={{  minWidth: '320px', minHeight: '335px', flexGrow: 2, marginLeft: '-10px' }}>
			<div onMouseUp={handleOnMouseUp} style={{ width: '100%', height: '100%', minHeight: window.innerHeight * 0.65, }}>
				<AutoSizer>
					{({ width, height }) => {
						return (
							<ReactSVGPanZoom
								width={width}
								height={height}
								ref={viewer => (Viewer = viewer)}
								tool={'auto'}
								onChangeTool={tool => changeTool(tool)}
								value={value}
								onChangeValue={setValue}
								background="#FFF"
								detectAutoPan={false}
								scaleFactorMin={0.3}
								detectPinchGesture={true}
								detectWheel={true}
								toolbarProps={{
									position: 'none'
								}}
								miniatureProps={{
									position: 'right'
								}}
								onTouchMove={onTouchStart ? onTouchStart : () => {}}
								onTouchEnd={onTouchEnd ? onTouchEnd : () => {}}
							>
								<svg onMouseDown={onMouseDown}
									ref={onRender}
									width={900}
									height={height}
									isRoot={true}
								>
									{ boxesElements }
								</svg>
							</ReactSVGPanZoom>
						);}}
				</AutoSizer>
			</div>
		</div>
	);
};

export const styles = {
	canvas: {
		backgroundSize: 400
	},
	grid: {
		backgroundSize: 'auto'
	}
};


const mapStateToProps = ({ common, boxes }) => ({
	isMapDragging: boxes.isMapDragging,
	language: common.language,
});

let mapDispatchToProps = {
	mapIsDragging
};

export default connect(mapStateToProps, mapDispatchToProps)(SVGRenderer);
