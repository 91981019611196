import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import CityForm from 'components/City/Form';
import Heading from 'components/UI/Heading';
import { createCity } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { timeout } from 'utils/functions';

import validationSchema from './validation';

class CityFormScreen extends React.Component {
	state = {
		isSuccess: false,
	}

	handleSubmit = async (values, { setSubmitting }) => {
		try {
			await this.props.createCity(values);
			this.setState({ isSuccess: true }, async () => {
				await timeout(2000);
				this.setState({ isSuccess: false });
			});
		} catch(error) {
			throw error;
		} finally {
			setSubmitting(false);
		}
	}

	render() {
		const { isSuccess } = this.state;
		const { language, languageSchema } = this.props;

		return (
			<div className="row py-3">
				<div className="col-md-6 offset-md-3">
					<div className="card">
						<Heading>{languageSchema.Cities.addCity}</Heading>
						<Formik
							initialValues={{ country: '', city: '' }}
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema(languageSchema)}
							render={props =>
								<CityForm
									type="create"
									isSuccess={isSuccess}
									{...props}
									languageSchema={languageSchema}
									language={language}
								/>
							}
						/>

					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ common }) => ({
	languageSchema: getLanguageSchema(common.language),
	language: common.language,
});

export default connect(mapStateToProps, { createCity })(CityFormScreen);
