import React from 'react';
import { connect } from 'react-redux';

import Header from 'components/Header';
//import { getLanguageSchema } from 'dashboard-commons/languages';
//import { isClient } from 'utils/functions';
//import CallBackButton from 'components/Support/CallBackButton';

function Layout(Component) {
	class ChildComponent extends React.Component {
		render() {
			//let { currentUser, languageSchema } = this.props;
			//let isUserClient = isClient(currentUser.role);

			return (
				<div>
					<Header />
					<div className="long-container">
						{/* FIXME: CallbackButton
						{
							isUserClient &&
							<div className="callback-btn-wrapper">
								<CallBackButton languageSchema={languageSchema}/>
							</div>
						}
						*/}
						<Component />
					</div>
				</div>
			);
		}
	}

	let mapStateToProps = (state) => ({
		currentUser: state.auth.me,
		//languageSchema: getLanguageSchema(state.common.language),
	});

	return connect(mapStateToProps)(ChildComponent);
}

export default Layout;
