import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLanguageSchema } from 'dashboard-commons/languages';

import LanguagePicker from 'components/common/LanguagePicker';

import list from './headerList';
import { getRoleNameFromToken } from 'utils/functions';
import logo from 'assets/images/logo_white_text.png';
import './Header.scss';

const Header = ({ user, languageSchema }) => {
	const role = getRoleNameFromToken();
	const currentList = list.filter(point => point.permissions.includes(role));

	return (
		<header className="header mb-3">
			<nav className="navbar navbar-expand-lg">
				<div className="container-fluid">
					<Link to="/" className="navbar-brand">
						<img className="logo" src={logo} alt=""/>
					</Link>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span style={{ color: 'white' }} className="fas fa-bars"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav mr-auto">
							{currentList.map((point, index) => (
								<li className="nav-item" key={index}>
									{point.isDisabled ?
										<Link to={point.href} className="nav-link disabled">
											{languageSchema.HomePage[point.alias]}
										</Link> :
										<NavLink to={point.href} className="nav-link" activeClassName="active">
											{point.icon && <i className={`fas fa-${point.icon}`}/> } {languageSchema.HomePage[point.alias]}
										</NavLink>
									}
								</li>
							))}
						</ul>

						<LanguagePicker dark/>

						<ul className="navbar-nav align-items-center">
							{user &&
								<li className="nav-item dropdown">
									<Link to={`/users/${user._id}`} className="nav-link">
										{user.email}
									</Link>
								</li>
							}
							<li className="nav-item">
								<Link to='/login' className="btn btn-sm btn-outline-secondary">
									<span>{languageSchema.HomePage.exit}</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	);
};

const mapStateToProps = ({ auth, common }) => ({
	user: auth.me,
	languageSchema: getLanguageSchema(common.language),
});

export default connect(mapStateToProps)(Header);
