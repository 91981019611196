import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import LoginForm from 'components/Auth/LoginForm';
import { logIn, logOut, setCurrentUserDetails, setError } from 'redux/actions';
import { setCookie } from 'helpers/request';
import {  ROLES_MAP } from 'utils/constants';
import { getRoleNameFromToken, createUserInvoiceUrl } from 'utils/functions';

import { getLanguageSchema } from 'dashboard-commons/languages';

class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};
	}

	// componentDidMount() {
	// 	this.props.logOut();
	// }

	handleSubmit = async (values) => {
		try {
			let redirectUrl = '/';

			this.setState({ loading: true });

			let { refreshToken } = await this.props.logIn(values);

			setCookie('refreshToken', refreshToken);

			await this.props.setCurrentUserDetails();

			let role = getRoleNameFromToken();

			console.log('ROLE IN LGIN', role);
			if (this.props.bookingCache) {
				redirectUrl = '/book';
			} else if (this.props.invoiceCache) {
				const { user, contract, invoice } = this.props.invoiceCache;
				redirectUrl = createUserInvoiceUrl(user, contract, invoice);
			} else if (role === ROLES_MAP.client) {
				redirectUrl = '/my-boxes';
			} else if (role === ROLES_MAP.staff) {
				redirectUrl = '/stocks';
			}

			return this.props.history.push(redirectUrl);
		} catch(error) {
			console.log(error);
		} finally {
			this.setState({ loading: false });
		}
	}

	render() {
		const { languageSchema } = this.props;

		return (
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
				<div className="container">
					<div className="row">
						<div className="col-md-5 offset-md-3">
							<div className="card">
								<LoginForm
									languageSchema={languageSchema}
									location={this.props.location}
									onSubmit={this.handleSubmit}
									loading={this.state.loading}
								/>
								{
									<div>
										<div style={{
											textTransform: 'uppercase',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											padding: '30px 0',
											color: '#283049',
											fontSize: '28px',
											fontWeight: 600
										}}>
											{languageSchema.Etc.or}
										</div>

										<Link
											to='/book'
											style={{
												backgroundColor: '#283049',
												fontSize: '20px'
											}}
											className="btn btn-primary d-block horizontal-centered"
										>
											{languageSchema.LogIn.goToBooking}
											<i className='ml-2 fas fa-box-open'></i>
										</Link>
									</div>
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
	bookingCache: state.cache.booking,
	invoiceCache: state.cache.invoice,
});

export default connect(mapStateToProps, { logIn, logOut, setCurrentUserDetails, setError })(LoginPage);
