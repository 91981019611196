export default {
	createdAt: 'Создано',
	success: 'Успешно',
	loading: 'Загрузка...',
	yes: 'Да',
	no: 'Нет',
	wait: 'Подождите...',
	lastUpdate: 'Посл. обновл.',
	noUpdates: 'Обновлений не было',
	choose: 'Выберите',
	currency: 'Валюта',
	all: 'Все',
	settings: 'Настройки',
	notSet: 'Не указано',
	reset: 'Сбросить',
	from: 'от',
	to: 'до',
	send: 'Отправить',
	noItems: 'Список пуст',
	areYouSureYouWantToSaveChanges: 'Вы уверены, что хотите сохранить изменения?',
	searchPlaceholder: 'Поиск...',
	metersShortLetter: 'м',
	monthShort: 'мес.',
	max: 'Макс.',
	months: 'месяцa(-ев)',
	select: 'Выбрать...',
	monthsWord: 'месяцы',
	payOnce: 'За весь период',
	once: 'Раз в',
	monthly: 'в мес.',
	toHome: 'Личный кабинет',
	download: 'Скачать',
	somethingWentWrong: 'Ой, что-то пошло не так...',
	somethingWentWrongDescription: 'Пожалуйста, поробуйте снова. <br/> Или обратитесь в поддержку.',
	or: 'Или',
	stepOne: 'Шаг 1',
	stepTwo: 'Шаг 2',
	stepThree: 'Шаг 3',
	from3rdMonth: 'с 3го месяца',
	chooseEndDate: 'Выберите дату и время выезда:',
	endDate: 'Дата выезда',
	cancel: 'Отменить',
	extendContract: 'Продлить договор',
	heading: 'Заголовок',
	subHeading: 'Подзаголовок',
	normal: 'Обычный',
	minValueIs: (value) => `Минимальное значение ${value}`,
	maxValueIs: (value) => `Максимальное значение ${value}`,
	withoutSpaces: 'Не должно содержать пробелов',
	acceptsFiles: (value) => `Допустимый формат файлов: ${value}`,
	extensionDescription: 'Заявка на продление уже отправлена',
	failedUpload: 'Ошибка при загрузке документов'
};
