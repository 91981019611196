import React, { useEffect, useState } from 'react';

let NotificationItem = (props) => {
	let { notification, language } = props;

	let [dispay, setDisplay] = useState('block');

	useEffect(() => setDisplay('block'), [notification]);

	let onCloseNotification = () => setDisplay('none');

	return (
		<div
			className={`alert alert-dismissible alert-${notification.type} d-${dispay}`} role="alert">
			<h4 className="alert-heading">{notification[`title${language}`]}</h4>
			<p>{notification[`description${language}`]}</p>
			<button type="button" className="close" onClick={onCloseNotification}>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	);
};

export default NotificationItem;
