export default {
	user: 'Klient',
	admin: 'Admin',
	manager: 'Manager',
	super: 'Superadmin',
	addRole: 'Dodaj role',
	name: 'Temat',
	alias: 'Pseudonim',
	description: 'Opis',
	permissions: 'Funkcje',
	roleEdit: 'Edytuj role',
	areYouSureYouWantToDeleteRole: 'Czy jesteś pewien, że chcesz usunąć rolę?'
};
