export default {
	accountDetails: 'Account details',
	accountCreation: 'Create an account',
	lockAvailability: 'Lock Status',
	docsAttachmentInfo: 'The user needs to attach a photo of documents in the personal account of the mobile application',
	userWillReceivePassword: 'The user will receive a password for the mail',
	passwordMayBeReset: 'Password can be reset in the form of password reset',
	name: 'Name',
	sureName: 'Last Name',
	email: 'Email',
	emailSubject: 'Email Subject',
	emailType: 'Email type',
	password: 'Password',
	country: 'Country',
	phone: 'Phone',
	city: 'City',
	address: 'Address',
	houseNumber: 'House',
	zip: 'Zip',
	pesel: 'PESEL (or ID/passport number in the absence of PESEL)',
	company: 'Company',
	role: 'Role',
	stock: 'Stock',
	withoutStock: 'Without reference to the stock',
	areYouSureYouWantToDeleteAccount: 'Are you sure you want to delete the account?',
	accountEdit: 'Edit account',
	kycHeader: 'Identity Card',
	passwordReset: 'Reset password',
	resetPassword: 'Reset Password',
	passwordWillBeSentToMail: 'Password will be sent to the mail',
	addAccount: 'Add Account',
	contracts: 'Contracts',
	checkPhotosInfoBlock: 'Check passport photo. To do this, click on the photo. If everything is fine with photos and data, click Verify. ',
	selfieWasNotUploaded: 'Selfie with passport was not loaded',
	selfieWasRejected: 'Selfie photo was declined.',
	reason: 'Reason',
	passportPhotoWasNotUploaded: 'Passport photo was not uploaded',
	passportPhotoWasRejected: 'Passport photo was rejected',
	cancelVerification: 'Cancel verification',
	verify: 'Verify',
	docRejectionDescription: 'If the document is not fine, access to the box for the client will be closed.',
	userNeedToReupload: 'If the manager rejects the photo, the user needs to attach a new photo in his account',
	rejectionReason: 'Reason for rejection',
	setRejectionReason: 'Specify the reason for rejecting the document',
	sureNameRequired: 'Last Name',
	areYouSureYouWantToResetPassword: 'Are you sure you want to reset password?',
	areYouSureYouWantToRejectDocument: 'Are you sure you want to Reject document?',
	areYouSureYouWantToCancelProfileVerification: 'Are you sure you want to cancel profile verification?',
	areYouSureYouWantToMarkProfileAsVerified: 'Are you sure you want to mark profile as verified?',
	accountType: 'Account type',
	individual: 'Individual',
	legal: 'Legal',
	companyName: 'Company name',
	registrationNumber: 'Registration number',
	companyRequired: 'Company',
	client: 'Client',
	currentClient: 'Current client',
	goToContracts: 'Go to contracts',
	passwordConfirmation: 'Password confirmation',
	passwordChanged: 'Password was changed',
	passwordWasSuccessfullyChanged: 'Password was successfully changed',
	areYouSureYouWantToChangePassword: 'Are you sure, you want to change password?',
	changePassword: 'Change password',
	isEighteenAge: 'I am 18+ years old.',
	loginAsUser: 'Login as user',
	created: 'Date of creation',
	unknownDate: 'Update date unknown',
	importUsers: 'Import Users',
	createdAfter: 'Created after',
	importUsersByDate: 'Import users by date',
	otherContacts: 'Other contacts',
	addressRequired: 'Required',
	houseRequired: 'Required',
	salutation: 'Salutation',
	man: 'Mr.',
	woman: 'Mrs.',
	pinCode: 'Pin code',
	showPassword: 'Show password',
	hidePassword: 'Hide password',
	opened: 'Opened',
	closed: 'Closed',
};
