export const props = {
	name: '',
	cityId: '',
	address: '',
	zip: '',
	phone: '',
	maxContractDuration: '',
	currency: '',
	hasBoxes: false,
	routerIp: '',
	servicePort: '',
	internalEquipments: [],
	wifiNetworkName: ''
};

export default {
	props,
};
