import React from 'react';
import { Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { serverUrl, DATE_FORMAT } from 'utils/constants';
import { isDevelompent } from 'utils/functions';

function BoxCatForm(props) {
	const {
		type, isSuccess, handleFileInput, tempImage,
		values, touched, errors, isSubmitting, setFieldValue } = props;

	const isError = field => errors[field] && touched[field];
	const isEdit = type === 'edit';
	const buttonText = isEdit ? 'Сохранить' : 'Создать';

	const fileName = (values.preview && values.preview.name) || 'Выбор превью ...';

	const isImageExist = !!values.image;
	const imageSrc = tempImage ? tempImage : (isImageExist ? `${serverUrl}/${values.image}` : null);

	return (
		<Form>
			<div className="form-group row">
				<label htmlFor="name" className="col-sm-3 col-form-label">Название</label>
				<div className="col-sm-9">
					<Field
						name="name"
						type="text"
						className={`form-control ${isError('name') ? 'is-invalid' : ''}`}
						value={values.name}
					/>
					{isError('name') && <div className="invalid-feedback">{errors.name}</div>}
				</div>
			</div>
			<div className="form-group row">
				<label htmlFor="alias" className="col-sm-3 col-form-label">Алиас</label>
				<div className="col-sm-9">
					<Field
						name="alias"
						type="text"
						className={`form-control ${isError('alias') ? 'is-invalid' : ''}`}
						value={values.alias}
					/>
					{isError('alias') && <div className="invalid-feedback">{errors.alias}</div>}
				</div>
			</div>
			<div className="form-group row">
				<label htmlFor="minSize" className="col-sm-3 col-form-label">Размеры ОТ</label>
				<div className="col-sm-9">
					<div className="input-group">
						<Field
							name="minSize"
							type="number"
							className={`form-control ${isError('minSize') ? 'is-invalid' : ''}`}
							value={values.minSize}
						/>
						<div className="input-group-append">
							<span className="input-group-text">м²</span>
						</div>
						{isError('minSize') && <div className="invalid-feedback">{errors.minSize}</div>}
					</div>
				</div>
			</div>
			<div className="form-group row">
				<label htmlFor="maxSize" className="col-sm-3 col-form-label">Размеры ДО</label>
				<div className="col-sm-9">
					<div className="input-group">
						<Field
							name="maxSize"
							type="number"
							className={`form-control ${isError('maxSize') ? 'is-invalid' : ''}`}
							value={values.maxSize}
						/>
						<div className="input-group-append">
							<span className="input-group-text">м²</span>
						</div>
						{isError('maxSize') && <div className="invalid-feedback">{errors.maxSize}</div>}
					</div>
				</div>
			</div>
			<div className="form-group row">
				<label htmlFor="pricePerMeterQuadratic" className="col-sm-3 col-form-label">Цена за кв. метр</label>
				<div className="col-sm-9">
					<div className="input-group">
						<Field
							name="pricePerMeterQuadratic"
							type="number"
							className={`form-control ${isError('pricePerMeterQuadratic') ? 'is-invalid' : ''}`}
							value={values.pricePerMeterQuadratic}
						/>
						<div className="input-group-append">
							<span className="input-group-text">руб</span>
						</div>
						{isError('pricePerMeterQuadratic') && <div className="invalid-feedback">{errors.pricePerMeterQuadratic}</div>}
					</div>
				</div>
			</div>
			<div className="form-group row">
				<label htmlFor="description" className="col-sm-3 col-form-label">Описание</label>
				<div className="col-sm-9">
					<Field
						name="description"
						component="textarea"
						className={`form-control ${isError('description') ? 'is-invalid' : ''}`}
						value={values.description}
					/>
					{isError('description') && <div className="invalid-feedback">{errors.description}</div>}
				</div>
			</div>
			<div className="form-group row">
				<label className="col-sm-3 col-form-label">Превью</label>
				<div className="col-sm-9">
					<div className="custom-file">
						<input id="preview" name="preview" type="file" accept="image/*" className="form-control"
							onChange={(event) => {
								let file = event.currentTarget.files[0];
								setFieldValue('preview', file);
								handleFileInput(file);
							}} required={!isImageExist} />
						<label className="custom-file-label" htmlFor="preview">{fileName}</label>
						{isError('preview') && <div className="invalid-feedback">{errors.preview}</div>}
					</div>
					{imageSrc && <img src={imageSrc} alt="" height="200" className="mt-3" />}
				</div>
			</div>
			{isEdit &&
				<div className="form-group row">
					<label htmlFor="created" className="col-sm-3 col-form-label">Создано</label>
					<div className="col-sm-9">
						<input type="text" readOnly className="form-control-plaintext" id="created"
							value={values.created && moment(values.created).format(DATE_FORMAT)} />
					</div>
				</div>
			}
			<div className="row">
				<div className="col-auto">
					<button type="submit" className="btn btn-sm btn-secondary mr-3" disabled={isSubmitting}>
						{isSubmitting ? 'Подождите ...' : buttonText}
					</button>
					{/* need to edit!! */}
					<Link to='/boxcats' className="btn btn-sm btn-outline-secondary">Назад</Link>
				</div>
				{isEdit &&
					<div className="col text-right">
						<i>{values.updated ?
							'Последнее обн. ' + moment(values.updated).format(DATE_FORMAT) :
							'Обновлений не было'}</i>
					</div>
				}
			</div>
			{isSuccess &&
				<div className="alert alert-success mt-3" role="alert">
					Успешно
				</div>
			}
			{isDevelompent &&
				<pre className="mt-3">
					{JSON.stringify(values, null, 2)}
				</pre>
			}
		</Form>
	);
}

export default BoxCatForm;
