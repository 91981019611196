import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'react-redux-toastr/src/styles/index.scss';
import './assets/styles/index.scss';

import App from './screens/App/';
import store from './redux/store';

if (process.env.REACT_APP_NODE_ENV === 'production') {
	let tagManagerArgs = {
		gtmId: 'GTM-NTH3BQD'
	};

	TagManager.initialize(tagManagerArgs);
}

// debug mode - export store to window props
// if (process.env.NODE_ENV === 'development') {
// 	window._store = store;
// }

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>, MOUNT_NODE);

if (module.hot) {
	module.hot.accept();
}
