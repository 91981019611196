import request from 'helpers/request';

export let fetchNotifications = (activeStatus, globalStatus) => async (dispatch) => {
	let fetchedNotifications = await request('GET', `notifications?activeStatus=${activeStatus}&globalStatus=${globalStatus}`);

	dispatch({ type: 'FETCH_NOTIFICATIONS', payload: fetchedNotifications });
};

export let createNotification = (data) => async (dispatch) => {
	let newNotification = await request('POST', 'notifications', data);

	dispatch({ type: 'CREATE_NOTIFICATION', payload: newNotification });
};

export let updateNotification = (id, data) => async (dispatch) => {
	let updatedNotification = await request('PUT', `notifications/${id}`, data);

	dispatch({ type: 'UPDATE_NOTIFICATION', payload: updatedNotification });
};

export let pushNotification = (data) => (dispatch) => dispatch({ type: 'PUSH_NOTIFICATION', payload: data });

export let updateNotificationByWS = (data) => (dispatch) => dispatch({ type: 'UPDATE_NOTIFICATION', payload: data });
