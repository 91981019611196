import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setInvoiceCache } from 'redux/actions';
import { isTokenExist } from 'helpers/request';
import { createUserInvoiceUrl } from 'utils/functions';

const LandingPage = (props) => {
	useEffect(() => {
		const parsedUrl = new URL(window.location.href);
		const user = parsedUrl.searchParams.get('user');
		const contract = parsedUrl.searchParams.get('contract');
		const invoice = parsedUrl.searchParams.get('invoice');

		props.setInvoiceCache({ user, contract, invoice });

		if (isTokenExist()) {
			props.history.push(createUserInvoiceUrl(user, contract, invoice));
			return;
		}

		props.history.push('/login');
	}, []);

	return <div></div>;
};

const mapDispatchToProps = (dispatch) => ({
	setInvoiceCache: (params) => dispatch(setInvoiceCache(params)),
});

export default connect(null, mapDispatchToProps)(LandingPage);
