export default {
	enter: 'Zaloguj się',
	forgotPassword: 'Zapomniełeś hasła?',
	yourEmail: 'Twój e-mail',
	password: 'Hasło',
	wait: 'Czekaj ...',
	enterEmail: 'Wprowadz e-mail',
	enterPassword: 'Wprowadź hasło',
	goToBooking: 'Zarezerwuj'
};
