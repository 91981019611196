import React from 'react';

import './Heading.scss';

const Heading = (props) => {
	return (
		<div className="heading">
			<h3 className="title">
				{props.children}
			</h3>
		</div>
	);
};

export default Heading;
