let initialState = {
	booking: null,
	invoice: null,
};

export default function reducer(state = initialState, action) {

	switch (action.type) {
	case 'SET_BOOKING_CACHE':
		return { booking: action.payload, invoice: null };
	case 'SET_INVOICE_CACHE':
		return { booking: null, invoice: action.payload };

	default:
		return state;
	}
}
