export default {
	cities: 'Cities',
	stocks: 'Stocks',
	accounts: 'Accounts',
	roles: 'Roles',
	reports: 'Reports',
	appeals: 'Appeals',
	edit: 'Edit',
	exit: 'Exit',
	changePassword: 'Change password',
	boxes: 'Boxes',
	profile: 'Profile',
	myBoxes: 'My boxes',
	support: 'Support',
	book: 'Book',
	toHome: 'Home',
	topDebtors: 'Top-10 debtors',
	notifications: 'Notifications',
	monthlyBills: 'Monthly Bills',
	Subscriptions: 'Subscriptions',
	externalPayments: 'Payments',
	notSentInvoicesEmails: 'Not sent invoices emails',
	notificationsOfPenalty: 'Notifications of penalty',
	issueTheFirstPenaltyInvoice: 'Issue the first penalty invoice',
	unknownNotification: 'Unknown notification',
	markAsCompleted: 'mark as completed',
	completed: 'Completed',
	usersImport: 'Import Users',
	import: 'Import',
	emailHistory: 'Email History',
	unpaidDebts: (span_color) => `You have unpaid <span style="color: ${span_color}">debts</span>`,
};
