export function formatRequest(data) {
	let result = data;
	// TODO: make	real copy

	result = convertAmountsToInvidisibleFormat(result);

	return result;
}

export function formatResponse(data) {
	let result = data;
	// TODO: make	real copy

	result = formatAmountsToBaseFormat(result);

	return result;
}

function convertAmountsToInvidisibleFormat(data) {
	if (Array.isArray(data)) {
		return data.map(convertAmountsToInvidisibleFormat);
	}

	let result = { ...data };

	Object.keys(result).forEach(property => {
		// TODO: add check on currency here; To get participles multiplier
		if (
			property.includes('amount') ||
			property.includes('Amount') ||
			property === 'Rate' ||
			property === 'rate'
		) {
			result[property] = Number(result[property]) * 100;
		}
	});

	return result;
}

function formatAmountsToBaseFormat(data) {
	if (Array.isArray(data)) {
		return data.map(formatAmountsToBaseFormat);
	}

	let result = { ...data };

	Object.keys(result).forEach(property => {
		// TODO: add check on currency here; To get participles multiplier
		if (
			property.includes('amount') ||
			property.includes('Amount') ||
			property === 'MonthRate' ||
			property === 'Rate' ||
			property === 'rate' ||
			property === 'totalToPayPerPeriod' ||
			property === 'debt'
		) {
			result[property] = Number(result[property]) / 100;
		}
	});

	return result;
}
