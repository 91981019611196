export const apiHost =
	process.env.NODE_ENV === 'development' ?
		'localhost' :
		(process.env.REACT_APP_API_HOST || 'rentabox-ru.jkl.pw');

export const serverUrl =
	process.env.NODE_ENV === 'development' ?
		'http://localhost:5656' :
		`https://${apiHost}`;

export const apiUrl = `${serverUrl}/api`;

export const webSocketServerAddress =
	process.env.NODE_ENV === 'development' ?
		`ws://${apiHost}:8000/socket` :
		`wss://${apiHost}/socket`;

export const IS_KYC_ENABLED = process.env.REACT_APP_IS_KYC_ENABLED === 'true';

export const httpMethodsMap = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
};

export const DEFAULT_APP_LANGUGAE = process.env.REACT_APP_START_LANGUAGE || 'RU';

const langs = {
	EN: 'English',
	DE: 'Deutsch',
	RU: 'Русский',
};

export const STEP_1_ANCHOR = '#stocks_map';
export const STEP_2_ANCHOR = '#size-group';
export const STEP_3_ANCHOR = '#boxes-list';

export const languages = langs;

export const LANGUAGE_TO_COUNTRY_CODE_MAP = {
	EN: 'GB',
	DE: 'DE',
	RU: 'RU',
};

export const errorCodesMap = {
	somethingWentWrong: 1,
	emailIsRequired: 1000,
	enterValidEmail: 1001,
	passwordIsRequired: 1002,
	fieldIsRequired: 1003,
	invalidSymbolsAmount: 1004,
	invalidFormat: 1005,

	userHasActiveContracts: 5000,

	invalidDataProvided: 9001,
	sessionIsFinished: 9003,
	enterValue: 'enterValue',
	outOfBounds: 'outOfBounds',
	invalidField: 'invalidField',
	invalidCharacters: 'invalidCharacters',
	chooseField: 'chooseField',

	enterYourMessage: 'enterYourMessage',
	invalidPhoneBounds: 'invalidPhoneBounds',
	messageInvalidBounds: 'messageInvalidBounds',
};

export const PHONE_REGEXP = /^[+]?[-\s./0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export const ACCOUNT_TYPES_MAP = {
	individual: 'individual',
	legal: 'legal',
};

export const CURRENCIES_MAP = {
	EUR: 'EUR',
};

// TODO: replace DATE_FORMAT with USA_DATE_FORMAT.
// then USA_DATE_FORMAT should not be used at all. it is used by SM
export const DATE_FORMAT = 'DD/MM/YYYY';
export const USA_DATE_FORMAT = 'MM/DD/YYYY';
export const EURO_DATE_FORMAT = 'DD.MM.YYYY';

export const MIN_RENT_MONTHS_FOR_DISCOUNT = 2;

export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const DEF_STRIPE_PROCESSOR = 'stripe';
export const DEF_PAYPAL_PROCESSOR = 'payPal';
export const DEF_SOFORT_PROCESSOR = 'sofort';
export const DEF_PAYKEEPER_PROCESSOR = 'paykeeper';

export const DEF_ARRIVAL_DATE_ERROR = 'DEF_ARRIVAL_DATE_ERROR';
export const DEF_DOCS_CONFIRM_ERROR = 'DEF_DOCS_CONFIRM_ERROR';
export const DEF_PAYMENT_PROC_ERROR = 'DEF_PAYMENT_PROC_ERROR';

export const DEF_CONTRACT_DURATIONS = [
	{
		value: 11,
		discount: 15
	},
	{
		value: 6,
		discount: 10
	},
	{
		value: 3,
		discount: 5
	},
	{
		value: 1,
		discount: 0
	}
];

export const ROLES_MAP = {
	superUser: 'superUser',
	client: 'client',
	staff: 'staff'
};

export const LOCAL_STORAGE_KEYS = {
	BOX_END_DATE: 'box-end-date'
};

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const ENV = process.env.NODE_ENV;
