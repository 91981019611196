import * as Yup from 'yup';
import { PHONE_REGEXP } from 'utils/constants';

export const individualUserFields = languageSchema => ({
	email: Yup.string()
		.trim()
		.email(languageSchema.validation.invalidField('email', languageSchema.User))
		.min(6, languageSchema.validation.outOfBounds('email', languageSchema.User, 6, 129))
		.max(129, languageSchema.validation.outOfBounds('email', languageSchema.User, 6, 129))
		.required(languageSchema.validation.enterValue('email', languageSchema.User)),

	phone: Yup.string()
		.trim()
		.matches(PHONE_REGEXP, languageSchema.validation.invalidField('phone', languageSchema.User))
		.min(3, languageSchema.validation.validatePhone(3, 14))
		.max(14, languageSchema.validation.validatePhone(3, 14))
		.required(languageSchema.validation.enterValue('phone', languageSchema.User)),

	city: Yup.string()
		.trim()
		.min(1, languageSchema.validation.outOfBounds('city', languageSchema.User, 1, 30))
		.max(30, languageSchema.validation.outOfBounds('city', languageSchema.User, 1, 30))
		.required(languageSchema.validation.enterValue('city', languageSchema.User)),

	country: Yup.string()
		.trim()
		.min(1, languageSchema.validation.outOfBounds('country', languageSchema.User, 1, 60))
		.max(60, languageSchema.validation.outOfBounds('country', languageSchema.User, 1, 60))
		.required(languageSchema.validation.enterValue('country', languageSchema.User)),

	firstName: Yup.string()
		.trim()
		.min(1, languageSchema.validation.outOfBounds('name', languageSchema.User, 1, 30))
		.max(30, languageSchema.validation.outOfBounds('name', languageSchema.User, 1, 30))
		.required(languageSchema.validation.enterValue('name', languageSchema.User)),

	lastName: Yup.string()
		.trim()
		.min(1, languageSchema.validation.outOfBounds('sureName', languageSchema.User, 1, 30))
		.max(30, languageSchema.validation.outOfBounds('sureName', languageSchema.User, 1, 30))
		.required(languageSchema.validation.enterValue('sureNameRequired', languageSchema.User)),

	//address: Yup.string()
	//	.trim()
	//	.min(1, languageSchema.validation.outOfBounds('address', languageSchema.User, 1, 128))
	//	.max(128, languageSchema.validation.outOfBounds('address', languageSchema.User, 1, 128)),

	//zip: Yup.string()
	//	.trim()
	//	.min(4, languageSchema.validation.outOfBounds('zip', languageSchema.User, 4, 16))
	//	.max(16, languageSchema.validation.outOfBounds('zip', languageSchema.User, 4, 16)),

	company: Yup.string()
		.trim()
		.min(10, languageSchema.validation.outOfBounds('company', languageSchema.User, 1, 128))
		.max(128, languageSchema.validation.outOfBounds('company', languageSchema.User, 1, 128)),
});

export const legalUserFields = languageSchema => ({
	email: Yup.string()
		.trim()
		.email(languageSchema.validation.invalidField('email', languageSchema.User))
		.min(6, languageSchema.validation.outOfBounds('email', languageSchema.User, 6, 129))
		.max(129, languageSchema.validation.outOfBounds('email', languageSchema.User, 6, 129))
		.required(languageSchema.validation.enterValue('email', languageSchema.User)),

	phone: Yup.string()
		.trim()
		.matches(PHONE_REGEXP, languageSchema.validation.invalidField('phone', languageSchema.User))
		.min(3, languageSchema.validation.validatePhone(3, 14))
		.max(14, languageSchema.validation.validatePhone(3, 14))
		.required(languageSchema.validation.enterValue('phone', languageSchema.User)),

	city: Yup.string()
		.trim()
		.min(1, languageSchema.validation.outOfBounds('city', languageSchema.User, 1, 30))
		.max(30, languageSchema.validation.outOfBounds('city', languageSchema.User, 1, 30))
		.required(languageSchema.validation.required()),

	country: Yup.string()
		.trim()
		.min(1, languageSchema.validation.outOfBounds('country', languageSchema.User, 1, 30))
		.max(30, languageSchema.validation.outOfBounds('country', languageSchema.User, 1, 30))
		.required(languageSchema.validation.enterValue('country', languageSchema.User)),

	//address: Yup.string()
	//	.trim()
	//	.min(1, languageSchema.validation.outOfBounds('address', languageSchema.User, 1, 128))
	//	.max(128, languageSchema.validation.outOfBounds('address', languageSchema.User, 1, 128))
	//	.required(languageSchema.validation.required()),

	//zip: Yup.string()
	//	.trim()
	//	.min(4, languageSchema.validation.outOfBounds('zip', languageSchema.User, 4, 16))
	//	.max(16, languageSchema.validation.outOfBounds('zip', languageSchema.User, 4, 16)),

	company: Yup.string()
		.trim()
		.min(1, languageSchema.validation.outOfBounds('company', languageSchema.User, 1, 128))
		.max(128, languageSchema.validation.outOfBounds('company', languageSchema.User, 1, 128))
		.required(languageSchema.validation.enterValue('companyRequired', languageSchema.User)),

	registrationNumber: Yup.string()
		.trim()
		.min(1, languageSchema.validation.outOfBounds('registrationNumber', languageSchema.User, 1, 30))
		.max(30, languageSchema.validation.outOfBounds('registrationNumber', languageSchema.User, 1, 30)),
});

const validationSchema = (languageSchema, accountType) => {
	let individualUserValidationSchema = Yup.object().shape({
		...individualUserFields(languageSchema),
		role: Yup.string()
			.required(languageSchema.validation.chooseField('role', languageSchema.User)),
	});

	let legalUserValidationSchema = Yup.object().shape({
		...legalUserFields(languageSchema),
		role: Yup.string()
			.required(languageSchema.validation.chooseField('role', languageSchema.User)),
	});

	let validationSchemas = {
		legal: legalUserValidationSchema,
		individual: individualUserValidationSchema,
	};

	return validationSchemas[accountType] || {};
};

export let validationChangePasswordSchema = languageSchema => Yup.object().shape({
	password: Yup.string()
		.trim()
		.min(8, languageSchema.validation.outOfBounds('password', languageSchema.User, 8, 60))
		.max(60, languageSchema.validation.outOfBounds('password', languageSchema.User, 8, 60))
		.required(languageSchema.validation.enterValue('password', languageSchema.User)),

	passwordConfirmation: Yup.string()
		.trim()
		.min(8, languageSchema.validation.outOfBounds('passwordConfirmation', languageSchema.User, 8, 60))
		.max(60, languageSchema.validation.outOfBounds('passwordConfirmation', languageSchema.User, 8, 60))
		.required(languageSchema.validation.enterValue('passwordConfirmation', languageSchema.User)),
});

export default validationSchema;
