import React from 'react';
import TextLine from './TextLine';

function IqacDocumentProperty (props) {
	const handleChange = (event) => {
		event.preventDefault();
		props.onChange(event.target.value);
	};

	const right = (
		<>
			<input type="date" value={props.right} onChange={handleChange} />&nbsp;
			<button onClick={props.onSubmit} className="btn btn-success">Set</button>
		</>
	);

	return <TextLine left={props.left} right={right} />;
}

export default IqacDocumentProperty;
