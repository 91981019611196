import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getLanguageSchema } from 'dashboard-commons/languages';

import Checkbox from 'react-simple-checkbox';

import sizeSImage from 'assets/images/size3.png';
import sizeMImage from 'assets/images/size4.png';
import sizeLImage from 'assets/images/size5.png';
import sizeXLImage from 'assets/images/size6.png';

let sizesGroups = {
	s: {
		key: 's',
		min: 0.5,
		max: 5,
		image: sizeSImage,
	},
	m: {
		key: 'm',
		min: 5.5,
		max: 10,
		image: sizeMImage,
	},
	l: {
		key: 'l',
		min: 10.5,
		max: 21,
		image: sizeLImage,
	},
	xl: {
		key: 'xl',
		min: 21.5,
		max: 30,
		image: sizeXLImage,
	},
};

const SizeGroups = (props) => {
	const {
		selectedStockId, languageSchema,
		selectedSizeGroup, setSelectedSizeGroup, fetchedBoxes
	} = props;

	const getActivityClass = size => (!_.isEmpty(selectedSizeGroup) && selectedSizeGroup.sizeGroup === size) ? 'selected' : '';
	const isChecked = sizeGroup => !_.isEmpty(selectedSizeGroup) && selectedSizeGroup.sizeGroup === sizeGroup;

	return (
		<div className="mt-5">
			<div className="mb-2" style={{ fontSize: '20px', fontWeight: 'bold' }} >
				{languageSchema.BoxBooking.step2SelectTheSizeOfTheBox}
			</div>
			<div disabled={_.isEmpty(selectedStockId)} className='row pt-4'>
				{
					Object.keys(sizesGroups).map((el, index) => (
						<div
							className="col-md-3 flex-vertical-center"
							key={sizesGroups[el].min}
						>
							<div
								className={`group_size_selector mb-3 py-2 ${getActivityClass(el)}`}
								onClick={() => setSelectedSizeGroup({
									sizeGroup: el,
									sizeCodes: _.uniq(fetchedBoxes
										.filter(({ PhysicalSize }) => sizesGroups[el].min <= PhysicalSize && PhysicalSize <= sizesGroups[el].max)
										.map(({ PhysicalSize }) => PhysicalSize))
								})}
								style={{ cursor: 'pointer' }}
							>
								<div style={{ display: 'flex' }}>
									<img src={sizesGroups[el].image} />
								</div>
								<div style={{ margin: '1em' }}>
									<Checkbox
										color="#25a549"
										size={4}
										tickAnimationDuration={40}
										backAnimationDuration={40}
										borderThickness={2}
										checked={isChecked(el)}
										// onChange={() => {}}
									/>
								</div>
								<div className="sizes_label text-center">
									{
										index === 0 ?
											<div>
												1m<sup>3</sup> - {sizesGroups[el].max} m<sup>2</sup> <br />
											</div> :
											<div>
												{sizesGroups[el].min} - {sizesGroups[el].max} m<sup>2</sup> <br />
											</div>
									}
									<div style={{ fontSize: '16px', margin: '10px auto', width: '90%' }}>
										{languageSchema.BoxBooking.sizesMeta[el]}
									</div>
								</div>
							</div>
						</div>
					))
				}
			</div>
			<div id="size-group"/>
		</div>
	);
};

let mapStateToProps = state => ({
	languageSchema: getLanguageSchema(state.common.language),
});

export default connect(mapStateToProps)(SizeGroups);
