import React from 'react';

import './Table.scss';

const Table = ({ children, header, centerHeader}) => {

	return (
		<div className="card-body">
			<div className="table-responsive">
				<table className="table table-hover">
					<thead className={`font-green ${centerHeader ? 'text-center' : ''}`}>
						<tr>
							{header.map((col,i) => <th key={i} scope="col">{col}</th>)}
						</tr>
					</thead>
					<tbody>
						{children}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Table;
