export default {
	bookABox: 'Бронирование бокса',
	chooseABox: 'Выбрать бокс',
	stock: 'Cклад',
	address: 'Адрес',
	zip: 'Индекс',
	selectStock: 'Выбор склада',
	boxesAvailable: 'Доступно боксов',
	totalBoxesNumberAtStock: 'Всего боксов на складе',
	sizes: 'Размеры',
	contractDuration: 'Срок аренды',
	paymentFrequency: 'Частота оплат',
	payRarePayLess: 'Платите реже - платите меньше.',
	payRareHelpBlock: 'Чем реже периодичность оплат - тем больше скидка!',
	totalToPay: 'Итого к оплате',
	includedInsurance: 'Включая страховой взнос',
	inclVAT: 'Включая НДС',
	notInclVAT: 'Без НДС',
	toBookABox: 'Чтобы арендовать бокс, пожалуйста,',
	authorize: 'Авторизуйтесь',
	iAgree: 'Нажимая Подтвердить, Вы принимаете следующие документы:',
	contractAgreementDoc: 'Договор аренды бокса (предварительный просмотр)',
	stockRoutine: 'Распорядок склада',
	privacyPolicy: 'Политика конфиденциальности',
	companyRules: 'Общий распорядок компании',
	contractTerms: 'Общие условия договора',
	notAvailable: 'Не доступен',
	step1SelectTheSizeOfTheBox: 'Шаг 1. Выберите размер бокса',
	step1SelectTheStock: 'Шаг 1. Выбор склада',
	step2SelectTheSizeOfTheBox: 'Шаг 2. Выберите размер бокса',
	step3SelectABoxOnTheMap: 'Шаг 3. Выберите бокс на карте',
	month: 'месяц',
	forWholePeriod: 'За весь период',
	saveAmount: 'Выгода',
	discount: 'Скидка',
	cancel: 'Отменить бронирование',
	areYouSureToCancelReservation: 'Вы уверены, что хотите отменить бронирование ?',
	insuranceAmount: (amount) => `Ваши вещи будут застрахованы на ${amount} EUR`,
	reserveWithRequiredPayment: 'Оплатить',
	reserveWithPayPal: 'Забронировать через PayPal',
	reserveViaBankTransfer: 'Зарезервировать через банковский перевод',
	iHaveReadDocsAndAgree: 'Я прочитал договор, а также дополнения к нему и полностью их принимаю.',
	sizesMeta: {
		s: 'Предметы домашнего обихода, которые занимают около 30 м² или архивируют до 300 файлов',
		m: 'Предметы домашнего обихода площадью около 50 м² или архив до 500 файлов',
		l: 'Предметы домашнего обихода площадью около 80 м² или архив до 800 файлов',
		xl: 'Предметы домашнего обихода площадью около 100 м² или архив до 1000 файлов'
	},
	tariffs: {
		basic: 'Тариф действует при заключении договора на неопределенный срок с периодом уведомления за 21 день..',
		smart: 'Среднемесячная сумма при заключении срочного договора на срок от 6 до 11 месяцев. Скидка 30% рассчитывается на 6-й месяц.',
		max: 'Среднемесячная сумма при заключении срочного договора сроком на 12 месяцев. Скидка 65% рассчитана на 12-й месяц.',
	},
	insuranceCoverage: 'страховое покрытие',
	englishWordOr: 'или',
	yourChoice: 'Выбранный бокс',
	freeBoxesOfTheRightSize: 'Бокс выбранной группы свободен',
	occupiedBoxes: 'Бокс занят',
	continue: 'Далее',
	reservations: 'Забронировать',
	bookWithoutPayment: 'Забронировать без оплаты',
	bookAndPay: 'Забронировать и оплатить',
	choiceOfArrivalDateTitle: 'Дата заезда',
	choiceOfArrivalDateText: 'Когда вам понадобится бокс',
	choiceOfArrivalDatePlaceholder: 'Выберите дату',
	successTitle: ['Поздравляем!', 'Бокс успешно зарезервирован.'],
	successText: 'Пожалуйста, проверьте свою электронную почту и подпишите договор. Письмо может быть в папке со спамом. Бокс будет доступен с даты указаной в договоре и после оплаты счета.',
	selectUser: 'Выберите пользователя',
	user: 'Пользователь',
	docsAgreement: 'Пользовательское соглашение',
	payment: 'Оплата',
	openEndedContractNote: 'Это бессрочный договор, который будет действовать до тех пор, пока вы его не отмените',
	back: 'Назад',
	insuranceWillBeAdded: '+страховка',
	automaticPaymentsText: 'Автоматический ежемесячный платеж',
	searchHeader: 'Поиск по карте',
	cityOrStockName: 'Город или склада',
	sendPhotos: 'Отправить фото',
	uploadDocsPhoto: 'Загрузка фото документов',
	bookingByTheAdministrator: 'Бронирование администратором запрещено! Авторизуйтесь как пользователь.',
	chooseYourInsurance: 'Выберите вашу страховку',
	valueOfGoods: 'Стоимость хранимых вещей',
	oneEuroForFirstMonth: '1й месяц за 1 Евро',
	boxNumber: 'Номер бокса',
	minimalRentPeriodDescription: 'Минимальный срок хранения - 2 месяца',
	forTwoInitialMonths: 'За первые два месяца',
	price: 'Стоимость',
	prices: 'Цены',
	selectCity: 'Выберите город',
	selectBoxSize: 'Размер бокса',
	enterDataAndPayment: 'Оплата',
	bookingParams: 'Выбор параметров',
	reserveOrPay: 'Оплата',
	contactSource: 'Откуда о нас узнали',
	reasonForStorage: 'Причина хранения',
	areYouSureToCreateAContract: 'Вы уверены, что хотите заключить договор?',
	enterIntoContractExplanation: `Нажимая "Заключить договор" вы заключите договор.
		Вы получите ссылку для подписания договора на Ваш емейл, а также первоначальные счет на оплату.
		После оплаты счета вы получите доступ к складу и Вашему боксу.`,
	reservationId: 'ID резервирования',
	expires: 'Истекает',
	box: 'Бокс',
	startRentDate: 'Начало аренды',
	endRentDate: 'Конец аренды',
	initialPayment: 'Первоначальный платеж',
	enterIntoContract: 'Заключить договор',
	details: 'Детали',
	rentalCost: 'Стоимость аренды',
	insurance: 'Страховка',
	deposit: 'Депозит',
	vatAmount: 'НДС',
	boxLeaseAgreement: 'Договор аренды бокса с ООО Рентабокс',
	mapAndList: 'Карта & список',
	map: 'Карта',
	list: 'Список',
	step3SelectABox: 'Выберите бокс',
	boxesFromAnotherCategory: 'Боксы другой категории размера',
	selectOnMap: 'Выбрать',
	move: 'Двигать',
	zoomIn: 'Приблизить',
	zoomOut: 'Отдалить',
	stretch: 'Растянуть',
	needHelp: 'Нужна помощь?',
	haveAnAccount: 'Вы зарегистрированы?'
};
