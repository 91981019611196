export default {
	contractDetails: 'Детали договора',
	extendContract: 'Вы уверен, что хотите продлить договор?',
	invoices: 'Счета',
	contractNumber: 'Номер договора',
	contractStatus: 'Статус договора',
	userContracts: 'Договоры пользователя',
	attention: 'Внимание',
	closingContractConition: 'Договор может быть закрыт только после того, как клиент заберет свои вещи из бокса.',
	square: 'Площадь',
	period: 'Период',
	startDate: 'Дата заключения',
	closingDate: 'Дата закрытия',
	paidInDays: 'Оплачено, дней',
	paymentsFrequency: 'Оплата каждые(й) | мес',
	monthlyCost: 'Стоимость в месяц',
	periodCost: 'За период',
	planningLeavingDate: 'Планируемая дата выезда',
	from: 'с',
	to: 'по',
	simple: 'Обычная',
	discount: 'скидка',
	all: 'Все',
	opened: 'Открыт',
	closed: 'Закрыт',
	amount: 'Сумма',
	debt: 'Долг',
	vat: 'НДС',
	accountingDate: 'Отчетная дата',
	documentDate: 'Дата документа',
	paymentDate: 'Дата оплаты',
	comment: 'Комментарий',
	withDiscount: 'со скидкой',
	inclVAT: 'с учетом НДС',
	areYouSureYouWantToCloseContract: 'Вы уверены, что хотите закрыть договор?',
	contractEndingDate: 'Дата окончания',
	endingContracts: 'Заканчивающиеся договоры',
	didNotNotified: 'Клиент не уведомлял о выезде',
	invoiceIsPaid: 'Инвойс оплачен',
	sentInvoiceEmailNotification: 'Отправить Email',
	subscriptions: 'Подписки',
	transactions: 'Tранзакции',
	date: 'Дата',
	unitAccess: 'Доступ к устройству',
	boxsize: 'Размер бокса',
	download: 'Скачать',
	insuranceCoverageAmount: 'Сумма страхового покрытия',
	insuranceAmount: 'Страховой взнос',
	regularPayment: 'Регулярный платеж',
	userInvoices: 'Прочие счета'
};
