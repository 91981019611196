export default {
	create: 'Stwórz',
	back: 'Wróć',
	save: 'Zapisz',
	delete: 'Usuń',
	settings: 'Ustawienia',
	open: 'Otwórz',
	apply: 'Aplikuj',
	edit: 'Edytuj',
	reject: 'Odrzuć',
	close: 'Zamknij'
};
