import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { toastr } from 'react-redux-toastr';
import { Link, Redirect } from 'react-router-dom';
import { Table } from 'antd';
import moment from 'moment';

import { getLanguageSchema } from 'dashboard-commons/languages';
import { timeout, getCurrentUserIdFromToken } from 'utils/functions';
import UserForm from 'components/User/Form';
import ResetPwdForm from 'components/Auth/ResetPwdForm';
import ChangePwdForm from 'components/User/ChangePwdForm';
import Heading from 'components/UI/Heading';
import { validationChangePasswordSchema } from './validation';
import KYCForm from 'components/User/KYCForm';
import PersonalInvoices from 'components/User/PersonalInvoices';
import Loader from 'components/common/OverlayLoader';
import {
	fetchUser,
	updateUser,
	deleteUser,
	resetUserPassword,
} from 'redux/actions';
import { ROLES_MAP } from 'utils/constants';
import request from 'helpers/request';

class UserPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			authToken: null,
			isSuccess: false,
			passportFile: null,
			accountType: null,
			selfieFile: null,
			extraFile: null,
			addressFile: null,
			user: null,
			personalInvoices: [],
			sendedUnpaidInvoiceEmailNotifications: [],
			emails: [],
			loading: false,
			isCurrentUserAdmin: false,
		};
	}

	async componentDidMount() {
		const { match, loggedInUser } = this.props;
		let ADMIN_ROLES = [ROLES_MAP.superUser];

		let userId = match.params.id;

		this.setState({
			isCurrentUserAdmin:
				loggedInUser.role && ADMIN_ROLES.includes(loggedInUser.role),
		});
		
		this.fetchUser(userId);
		this.fetchPhotos(userId);
		await this.fetchPersonalInvoices();

		if (this.props.loggedInUser.role === ROLES_MAP.superUser) {
			await this.fetchEmailSendings();
		}
	}

	fetchEmails = async (email) => {
		if (email && this.props.loggedInUser.role === ROLES_MAP.superUser) {
			request('GET', 'email-history/search', { to: email }).then((data) =>
				this.setState({ emails: data })
			);
		}
	};

	fetchPhotos = (userId) => {
		request('GET', `users/${userId}/photos`).then(
			({ passportFile, selfieFile, extraFile, addressFile }) => {
				this.setState({ passportFile, selfieFile, extraFile, addressFile });
			}
		);
	};

	fetchUser = async (userId) => {
		let user = await request('GET', `users/${userId}`);
		this.setState({ user }, () => this.fetchEmails(user.email));
		this.setAccountType(user.accountType);
	};

	fetchPersonalInvoices = async () => {
		let userId = this.props.match.params.id;

		let personalInvoices = await request(
			'GET',
			`users/${userId}/personal-invoices`
		);

		this.setState({ personalInvoices });
	};

	fetchEmailSendings = async () => {
		let userId = this.props.match.params.id;

		let sendedUnpaidInvoiceEmailNotifications = await request(
			'GET',
			'notifications/invoices-emailing',
			{
				userId,
				invoicesIds: this.state.personalInvoices.map(
					(invoice) => invoice.DocumentID
				),
			}
		);

		this.setState({ sendedUnpaidInvoiceEmailNotifications });
	};

	fetchData = async () => {
		await Promise.all([
			this.fetchPersonalInvoices(),
			this.state.isCurrentUserAdmin && this.fetchEmailSendings(),
		]);
	};

	handleDeleteClick = () => {
		const {
			history: { push },
			deleteUser,
			languageSchema,
		} = this.props;

		toastr.confirm(languageSchema.User.areYouSureYouWantToDeleteAccount, {
			onOk: async () => {
				await deleteUser(this.state.user._id);
				push('/users');
			},
		});
	};

	handleSubmitUserForm = async (values) => {
		toastr.confirm(
			this.props.languageSchema.Etc.areYouSureYouWantToSaveChanges,
			{
				onOk: async () => {
					try {
						const { updateUser } = this.props;

						this.setLoading(true);

						await updateUser(this.state.user._id, values);

						this.setState({ isSuccess: true }, async () => {
							await timeout(2000);
							this.setState({ isSuccess: false });
						});

						if (this.state.isCurrentUserAdmin) {
							await this.fetchUser(this.state.user._id);
						}
					} finally {
						this.setLoading(false);
					}
				},
				onCancel: () => this.setLoading(false),
			}
		);
	};

	handleSubmitResetForm = async (values, { setSubmitting }) => {
		const { resetUserPassword, languageSchema } = this.props;

		toastr.confirm(
			this.props.languageSchema.User.areYouSureYouWantToResetPassword,
			{
				onOk: async () => {
					try {
						setSubmitting(true);

						await resetUserPassword(values);
						toastr.success(
							languageSchema.User.passwordReset,
							languageSchema.User.passwordWillBeSentToMail
						);
					} finally {
						setSubmitting(false);
					}
				},
				onCancel: () => setSubmitting(false),
			}
		);
	};

	handleSubmitPasswordChange = async (
		values,
		{ setSubmitting, setFieldError }
	) => {
		let { updateUser, languageSchema } = this.props;

		if (values.password !== values.passwordConfirmation) {
			setFieldError(
				'password',
				languageSchema.validation.validatePasswordConfirmation()
			);
			setFieldError(
				'passwordConfirmation',
				languageSchema.validation.validatePasswordConfirmation()
			);
			setSubmitting(false);

			return;
		}

		toastr.confirm(languageSchema.User.areYouSureYouWantToChangePassword, {
			onOk: async () => {
				try {
					setSubmitting(true);

					await updateUser(this.state.user._id, values);

					toastr.success(
						languageSchema.User.passwordChanged,
						languageSchema.User.passwordWasSuccessfullyChanged
					);
				} finally {
					setSubmitting(false);
				}
			},
			onCancel: () => setSubmitting(false),
		});
	};

	setAccountType = (accountType) => this.setState({ accountType });
	setLoading = (loading) => this.setState({ loading });

	onDocsUploaded = () => {
		let userId = this.props.match.params.id;

		this.fetchUser(userId).then(() => this.fetchPhotos(userId));
	};

	render() {
		const {
			isSuccess,
			personalInvoices,
			accountType,
			user,
			loading,
			isCurrentUserAdmin,
		} = this.state;
		const { fetchUser, languageSchema, language, loggedInUser } = this.props;

		const currentUserId = getCurrentUserIdFromToken();

		if (!user) {
			return null;
		}

		let { email } = user;

		let isCurrentUserOwner = currentUserId === user._id;

		if (user._id && !isCurrentUserOwner && !isCurrentUserAdmin) {
			return <Redirect to="/" />;
		}

		return (
			<div className="container">
				<Loader />
				<div className="row">
					<div className="col-md-6">
						<div className="card">
							<Heading>{languageSchema.User.accountDetails}</Heading>
							{isCurrentUserAdmin && (
								<Link
									to={{
										pathname: `/users/${user._id}/contracts`,
									}}
									className="text-right"
								>
									{languageSchema.User.goToContracts}
								</Link>
							)}

							<UserForm
								type="edit"
								onSubmit={this.handleSubmitUserForm}
								languageSchema={languageSchema}
								currentUser={loggedInUser}
								language={language}
								isSuccess={isSuccess}
								accountType={accountType}
								client={user}
								setAccountType={this.setAccountType}
								loading={loading}
							/>
							<hr />
						</div>
					</div>

					<div className="col-md-6">
						<div>
							<KYCForm
								languageSchema={languageSchema}
								user={user}
								fetchUser={fetchUser}
								loggedInUser={loggedInUser}
								passportFile={this.state.passportFile}
								selfieFile={this.state.selfieFile}
								extraFile={this.state.extraFile}
								addressFile={this.state.addressFile}
								onDocsUploaded={this.onDocsUploaded}
							/>
						</div>

						{0 < personalInvoices.length && (
							<PersonalInvoices
								invoices={personalInvoices}
								sendedUnpaidInvoiceEmailNotifications={
									this.state.sendedUnpaidInvoiceEmailNotifications
								}
								fetchData={this.fetchData}
							/>
						)}

						<div>
							<Formik
								initialValues={{ email }}
								enableReinitialize
								onSubmit={this.handleSubmitResetForm}
								render={(props) => (
									<ResetPwdForm
										{...props}
										user={user}
										languageSchema={languageSchema}
									/>
								)}
							/>
							{user._id !== currentUserId && (
								<>
									<hr />
									<div className="d-flex justify-content-end mt-3">
										<button
											className="btn btn-danger btn-sm"
											onClick={this.handleDeleteClick}
										>
											{languageSchema.Buttons.delete}
										</button>
									</div>
								</>
							)}
						</div>

						{user._id === loggedInUser._id && (
							<div>
								<Formik
									initialValues={{ password: '', passwordConfirmation: '' }}
									onSubmit={this.handleSubmitPasswordChange}
									validationSchema={validationChangePasswordSchema(
										languageSchema
									)}
									render={(props) => (
										<ChangePwdForm
											{...props}
											user={user}
											languageSchema={languageSchema}
										/>
									)}
								/>
							</div>
						)}
					</div>
				</div>
				{isCurrentUserAdmin && Boolean(this.state.emails.length) && (
					<div className="card mt-3 mb-3">
						<Heading>{languageSchema.HomePage.emailHistory}</Heading>
						<Table
							columns={[
								{
									title: languageSchema.User.email,
									dataIndex: 'to',
									key: 'to',
									width: '30%',
								},
								{
									title: languageSchema.User.emailSubject,
									dataIndex: 'subject',
									key: 'subject',
									width: '20%',
								},
								{
									title: languageSchema.User.emailType,
									dataIndex: 'emailMessageType',
									key: 'emailType',
									width: '10%',
								},
								{
									title: languageSchema.Payment.date,
									dataIndex: 'createdAt',
									key: 'createdAt',
									render: (text) => (
										<span>{moment(text).format('DD-MM-YYYY')}</span>
									),
									width: '10%',
								},
							]}
							dataSource={this.state.emails}
							pagination={{ pageSize: 5 }}
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ auth, users, common }) => {
	const [currentUser] = users.data;

	return {
		user: currentUser,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
		loggedInUser: auth.me,
	};
};

const mapDispatchToProps = {
	fetchUser,
	updateUser,
	deleteUser,
	resetUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
