import React from 'react';
import { Form, Field } from 'formik';
import Heading from 'components/UI/Heading';

let ChangePwdForm = (props) => {
	let { values, touched, errors, isSubmitting, languageSchema } = props;

	let isError = field => errors[field] && touched[field];

	return (
		<Form>
			<div className="card">
				<div className="form-group">
					<Heading>{languageSchema.User.changePassword}</Heading>
					<label htmlFor="password">{languageSchema.User.password}:</label>
					<Field
						id="password"
						type="password"
						className={`form-control ${isError('password') ? 'is-invalid' : ''}`}
						value={values.password}
						placeholder={languageSchema.User.password}
					/>
					{isError('password') &&
												<div className="invalid-feedback">
													{errors.password}
												</div>}
				</div>
				<div className="form-group">
					<label htmlFor="passwordConfirmation">{languageSchema.User.passwordConfirmation}:</label>
					<Field
						id="passwordConfirmation"
						type="password"
						className={`form-control ${isError('passwordConfirmation') ? 'is-invalid' : ''}`}
						value={values.passwordConfirmation}
						placeholder={languageSchema.User.passwordConfirmation}
					/>
					{isError('passwordConfirmation') &&
												<div className="invalid-feedback">
													{errors.passwordConfirmation}
												</div>}
				</div>
				<div className="d-flex justify-content-between">
					<button type="submit" className="btn btn-sm btn-secondary" disabled={isSubmitting}>
						{isSubmitting ? languageSchema.Etc.wait : languageSchema.User.changePassword}
					</button>
				</div>
			</div>
		</Form>
	);
};

export default ChangePwdForm;
