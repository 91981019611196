import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { formatDateFromUSAToEuroFormat } from 'utils/functions';
import { DATE_FORMAT, EURO_DATE_FORMAT } from 'utils/constants';
import DatePicker from 'components/common/DatePicker';

import 'react-datepicker/dist/react-datepicker.css';


const PlanningLeaveDateButton = ({ onClick, plannedLeaveDate }) => (
	<button className="btn btn-sm" onClick={onClick}>
		{
			plannedLeaveDate ?
				<>
					<div>
						{moment(plannedLeaveDate).format(EURO_DATE_FORMAT)}
					</div>
					<i className="fas fa-edit" />
				</> :
				<i className="fas fa-edit" />
		}
	</button>
);

export default class ContractListItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isSettingLeaveDate: false,
			plannedLeaveDate: props.contract.NoticeLeaveDate ?
				moment(props.contract.NoticeLeaveDate).toDate() :
				null,
			isDateChanged: false,
		};
	}

	handlePlanningLeaveDateChange = date => {
		this.setState({
			plannedLeaveDate: date,
			isDateChanged: moment(date) !== moment(this.state.plannedLeaveDate),
		});
	}

	terminateContract = (stockId, contractId, boxId_sm) => async () => {
		await this.props.terminateContract(stockId, contractId, boxId_sm);
	}

	savePlannedLeaveDate = (stockId, contractId) => async () => {
		this.setState({ isSettingLeaveDate: true });

		await this.props.savePlannedLeaveDate(stockId, contractId, this.state.plannedLeaveDate);

		this.setState({ isSettingLeaveDate: false, isDateChanged: false });
	}


	render() {
		let { contract, languageSchema, language } = this.props;

		let contractStartDate = contract.StartDate.includes('/') ? formatDateFromUSAToEuroFormat(contract.StartDate) : contract.StartDate;
		let contractEndDate = contract.EndDate.includes('/') ? formatDateFromUSAToEuroFormat(contract.EndDate) : contract.EndDate;

		return (
			<tr>
				<td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
					<Link
						style={{ textDecoration: 'underline' }}
						to={`/boxes?stockId=${contract.stockId}`}
					>
						{contract.stock.name}
					</Link>
				</td>
				<td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
					<Link
						style={{ textDecoration: 'underline' }}
						to={{
							pathname: `contracts/${contract.ContractID}`,
							state: {
								ContractID: contract.ContractID,
								stockId: contract.stockId,
							},
						}}
					>
						{contract.ContractNumber}
					</Link>
				</td>
				<td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
					{contract.PhysicalSize} {languageSchema.Etc.metersShortLetter}² <br />
					<small>({contract.UnitNumber})</small>
				</td>
				<td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
					<div>{contractStartDate} - {contractEndDate}</div>
				</td>
				<td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{contract.LeaveDate ? moment(contract.LeaveDate).format(EURO_DATE_FORMAT) : ' - '}</td>
				<td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
					{contract.paidAhead >= 0 ? contract.paidAhead : 0}
				</td>
				<td>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
						<div>
							<DatePicker
								language={language}
								selected={this.state.plannedLeaveDate}
								onChange={this.handlePlanningLeaveDateChange}
								customInput={<PlanningLeaveDateButton plannedLeaveDate={this.state.plannedLeaveDate} />}
							/>
						</div>
						{
							this.state.isDateChanged &&
							<button
								className="btn btn-sm btn-success"
								onClick={this.savePlannedLeaveDate(contract.stockId, contract.ContractID)}
							>
								{
									this.state.isSettingLeaveDate ?
										<i className="fas fa-spinner fa-spin"></i> :
										<i className="fas fa-save"> { '	' } </i>
								}
								<span>{languageSchema.Buttons.apply}</span>
							</button>
						}
					</div>
				</td>
				<td>
					{!contract.LeaveDate ?
						<button
							style={{ marginLeft: '5px' }}
							className="btn btn-sm btn-outline-warning"
							onClick={this.terminateContract(contract.stockId, contract.ContractID, contract.unitid)}
						>
							{languageSchema.Buttons.close}
						</button> :
						<button style={{ marginLeft: '5px'	}} disabled className={'btn btn-sm btn-outline-secondary disabled green'}>
							{languageSchema.Contract.closed}
						</button>
					}
				</td>
			</tr>
		);
	}
}
