import React, { useCallback } from 'react';
import moment from 'moment';
import { Button, Form, Select, Row } from 'antd';
import { Link } from 'react-router-dom';

import { ACCOUNT_TYPES_MAP, IS_KYC_ENABLED, DATE_FORMAT, ROLES_MAP, errorCodesMap } from 'utils/constants';
import { isAdmin } from 'utils/functions';
import LegalUserFormFields from 'components/User/LegalUserFormFields';
import IndividualUserFormFields from 'components/User/IndividualUserFormFields';

function UserForm(props) {
	let {
		onSubmit,
		type,
		languageSchema,
		currentUser,
		language,
		client,
		isSuccess,
		accountType,
		setAccountType,
		loading = false,
	} = props;

	let isCurrentUserAdmin = currentUser.role && isAdmin(currentUser.role);
	let isEditForm = type === 'edit';
	let buttonText = isEditForm ? languageSchema.Buttons.save : languageSchema.Buttons.create;

	let getFormTypeSelectOptions = useCallback(() => Object.keys(ACCOUNT_TYPES_MAP)
		.map((accountType) => ({
			value: accountType,
			label: languageSchema.User[accountType],
		})), [languageSchema]);

	let getUserRoleSelectOptions = useCallback(() => [ROLES_MAP.client, ROLES_MAP.superUser, ROLES_MAP.staff]
		.map((role) => ({
			value: role,
			label: role,
		})), []);

	let isDisabled = (isEditForm && !isCurrentUserAdmin) || loading;

	let renderFormFields = (type, props) => ({
		[ACCOUNT_TYPES_MAP.legal]: <LegalUserFormFields {...props} />,
		[ACCOUNT_TYPES_MAP.individual]: <IndividualUserFormFields {...props} />,
	}[type] || null);

	return (
		<Form
			disabled={isDisabled}
			initialValues={client}
			onFinish={(values) => {
				onSubmit({...values, accountType});
			}}
			autoComplete="off"
		>
			{
				!isEditForm && IS_KYC_ENABLED &&
				<div className="mb-3">
					<div className="alert alert-info alert-dismissible fade show" role="alert">
						{languageSchema.User.docsAttachmentInfo}
					</div>
				</div>
			}

			<div className="form-group">
				<label className="col-form-label">
					{languageSchema.User.accountType}
				</label>
				<div>
					<Select
						style={{ width: '100%' }}
						value={accountType}
						onChange={setAccountType}
						disabled={isEditForm || loading}
						options={getFormTypeSelectOptions()}
					/>
				</div>
			</div>
			<div className="form-group ">
				<label htmlFor="role" className=" col-form-label">
					{languageSchema.User.role}
					{isCurrentUserAdmin && !isEditForm && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<Form.Item
					name="role"
					rules={[
						{
							required: true,
							message: languageSchema.errors[errorCodesMap.fieldIsRequired],
						}
					]}
				>
					<Select
						placeholder={languageSchema.Etc.choose}
						options={getUserRoleSelectOptions()}
					/>
				</Form.Item>
			</div>

			{
				renderFormFields(accountType, {
					isCurrentUserAdmin,
					isEditForm,
					language,
					languageSchema,
				})
			}
			{
				isEditForm && isCurrentUserAdmin &&
				<div className="form-group ">
					<label htmlFor="created" className=" col-form-label">{languageSchema.Etc.createdAt}</label>
					<div>
						<input
							type="text"
							readOnly
							className="form-control-plaintext"
							id="created"
							value={client.created && moment(client.created).format(DATE_FORMAT)} />
					</div>
				</div>
			}

			{
				isSuccess &&
				<div className="alert alert-success mt-3" role="alert">
					{languageSchema.Etc.success}
				</div>
			}

			{
				accountType && isCurrentUserAdmin &&
				<Row>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							{loading ? languageSchema.Etc.wait : buttonText}
						</Button>
					</Form.Item>
					<Link to="/users" className="ml-3">
						<Button >
							{languageSchema.Buttons.back}
						</Button>
					</Link>
				</Row>
			}
		</Form >
	);
}

export default UserForm;
