import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { getLanguageSchema } from 'dashboard-commons/languages';
import request from 'helpers/request';

import { ROLES_MAP } from 'utils/constants';
import OverlayLoader from 'components/common/OverlayLoader';
import EndingContractsList from 'components/HomePage/EndingContracts/List';
import TopDebtors from 'components/HomePage/TopDebtors/List';
import NotSentInvoiceEmails from 'components/HomePage/NotSentInvoiceEmails/List';

import list from './menuList';
import './HomePage.scss';

class HomePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			endingContracts: [],
			topDebtors: [],
			notSentInvoicesEmails: []
		};
	}

	UNSAFE_componentWillMount() {
		if (!this.props.user?._id) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		console.log('this.props.user', this.props.user);
		if(this.props.user?.role === ROLES_MAP.staff) {
			return <Redirect to={{ pathname: '/stocks' }} />;
		}
	}

	async componentDidMount() {
		// this.setState({ isLoading: true });
		//
		// let [endingContracts, topDebtors, notSentInvoicesEmails] =
		// 	await Promise.all([
		// 		this.fetchEndingContracts(),
		// 		this.fetchTopDebtors(),
		// 		this.fetchNotSendedInvoicesEmails()
		// 	]);
		//
		// this.setState({
		// 	endingContracts,
		// 	topDebtors,
		// 	notSentInvoicesEmails,
		// 	isLoading: false
		// });
	}

	fetchEndingContracts = () => request('GET', 'statistics/contracts', { status: this.state.status });
	fetchTopDebtors = () => request('GET', 'statistics/debtors');
	fetchNotSendedInvoicesEmails = () => request('GET', 'statistics/invoice-emailing');

	render() {
		let { endingContracts, topDebtors, notSentInvoicesEmails } = this.state;
		let { languageSchema } = this.props;

		return (
			<div className="homepage long-container">
				<OverlayLoader />

				<div className="row mb-3">
					{list.map((item, i) =>
						<Link key={i} to={item.link} className="col-sm-4 my-3" style={{ minHeight: '200px' }}>
							<div className="card card-home" style={{ height: '100%', backgroundImage: `url(${item.image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
								<div className="card-body d-flex flex-column justify-content-between">
									<div className="card-title">
										{this.props.languageSchema.HomePage[item.alias]}
									</div>
								</div>
							</div>
						</Link>
					)}
				</div>

				<div className="row">
					<div className="col-md-6">
						<EndingContractsList endingContracts={endingContracts} languageSchema={languageSchema} />

						<NotSentInvoiceEmails
							notSentInvoicesEmails={notSentInvoicesEmails}
							languageSchema={languageSchema}
						/>
					</div>

					<div className="col-md-6">
						<TopDebtors topDebtors={topDebtors} languageSchema={languageSchema} />
					</div>

				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, common }) => ({
	user: auth.me,
	languageSchema: getLanguageSchema(common.language),
});

export default connect(mapStateToProps)(HomePage);
