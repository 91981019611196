import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { DATE_FORMAT, ACCOUNT_TYPES_MAP } from 'utils/constants';
import InfoToggler from 'components/common/InfoToggler';

export default function ListItem(props) {
	let { contract, languageSchema } = props;

	let getUserName = (user) => user.accountType === ACCOUNT_TYPES_MAP.legal ?
		user.company :
		`${user.firstName} ${user.lastName}`;

	return (
		<tr className="text-center" key={contract._id}>
			<td>
				<Link to={`/boxes?stockId=${contract.stock._id}`}>
					{contract.stock.name}
				</Link>
			</td>
			<td>
				{
					contract.userId ?
						(<Link
							to={{
								pathname: `/users/${contract.userId._id}/contracts/${contract.contractid}`,
								state: {
									ContractID: contract.contractid,
									stockId: contract.stock._id,
								},
							}}
						>
							{contract.ContractNumber}
						</Link>) :
						contract.ContractNumber
				}
			</td>

			<td>
				{
					contract.userId ?
						(<Link
							to={{
								pathname: `/users/${contract.userId._id}`,
							}}
						>
							{getUserName(contract.userId)}
						</Link>) :
						'-'
				}
			</td>

			<td>
				{
					contract.EndDate ?
						moment(contract.EndDate).format(DATE_FORMAT) :
						'-'
				}
			</td>

			<td>
				{
					contract.NoticeLeaveDate ?
						moment(contract.NoticeLeaveDate).format(DATE_FORMAT) :
						<InfoToggler alertType="warning" text={languageSchema.Contract.didNotNotified} />
				}
			</td>
		</tr>
	);
}
