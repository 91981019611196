export default {
	stock: 'Stock',
	createStock: 'Add stock',
	stockEditing: 'Stock editing',
	history: 'History',
	box: 'Box',
	boxes: 'Boxes',
	name: 'Title',
	city: 'City',
	address: 'Address',
	phone: 'Phone',
	zip: 'Index',
	hasBoxes: 'Has boxes',
	boxRenting: 'Box renting',
	controlSystem: 'Stock management system',
	currency: 'Settlement currency',
	currencyRequired: 'Settlement currency',
	maxContractDuration: 'Maximum contract duration (months)',
	routerIp: 'stock IP address',
	locksServerPort: 'Lock server port',
	databaseServer: 'Database server',
	databasePort: 'Database Port',
	databaseName: 'Database names',
	databaseUser: 'Database user',
	databasePassword: 'Database Password',
	addEquipment: 'Add item of equipment',
	equipmentId: 'item ID',
	control: 'Management',
	discountSettings: 'Discount Settings',
	discountHelpInfo: `This value will be multiplied every month by a one-time payment. <br /> <br />
		<b> Example: </b> <br />
		The user is about to enter into a contract for <b> 12 months </b>
		and selects a payment for every <b> 4 months </b>. The discount value is set to <b> 2% </b>. <br />
		Then the total discount for the 4-month payment will be: <br />
		<b> 4 * 2% = 8% </b> of the monthly cost of renting a box.`,
	discountPercentPerMonth: 'Percentage of discount for each one-time month of payment:',
	feeSettings: 'Settings of penalties',
	feeInfoBlock: 'The penalty percentage is added every day. It is calculated from the original amount. ',
	dailyFeeDescription: 'Percentage of premium for each unpaid day',
	freeFeeDays: 'Do not charge first',
	days: 'days',
	contractEndedFeeLabel: 'Percentage of premium for every day after the end of the contract',
	stockHistory: 'Stock History',
	date: 'Date',
	idOrSize: 'ID or Size',
	action: 'Action',
	noHistoryRecords: 'History is empty',
	account: 'Account',
	opened: 'Open',
	closed: 'Closed',
	accountType: 'Account Type',
	areYouSureYouWantToOpenBox: 'Are you sure you want to open the box?',
	lockStatus: 'Lock status',
	totalBoxes: 'Total Boxes',
	boxNumber: 'Boxing number',
	size: 'Size',
	lockNumber: 'Lock number',
	monthRate: 'Cost / month',
	notSet: 'Not set',
	currentUser: 'Current user',
	boxAvailable: 'Free',
	areYouSureYouWantToOpenStock: 'Are you sure you want to open Stock?',
	areYouSureYouWantToOpen: 'Are you sure you want to open',
	areYouSureYouWantToDelete: 'Are you sure you want to delete stock?',
	insurance: 'Insurance',
	insuranceCoverageAmount: 'Insurance amount',
	insuranceMonthlyAmount: 'Insurance amount to pay per month',
	vat: 'VAT',
	legalUsersVATRate: 'VAT for legal users',
	individualUsersVATRate: 'VAT for individual users',
	wifiPassword: 'Wi-Fi password',
	showWifiPassword: 'Show Wi-Fi password',
	accessDenied: 'Access Denied',
	accessAllowed: 'Access Allowed',
	coordinates: 'Сoordinates',
	latitude: 'Latitude',
	longitude: 'Longitude',
	stockId_sm: 'Stock ID SM',
	save: 'Save',
	boxesMapConstructor: 'Boxes map constructor',
	accountBalance: 'Account balance',
	addFloor: 'Add floor',
	floor: 'Floor',
	height: 'Height',
	width: 'Width',
	depth: 'Depth',
	square: 'Square',
	boxSpace: 'Box space',
	areYouSureYouWantToRun: 'Are you sure you want to run ',
	openingTime: 'Opening time',
	closingTime: 'Closing time',
	wifiNetworkName: 'Wi-Fi network name',
	gate: 'Gate'
};
