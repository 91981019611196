import { IQACDOCUMENT_SET_DATA } from '../actionTypes';

const initialState = {
	data: [],
	isLoading: false,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
	case IQACDOCUMENT_SET_DATA:
		return Object.assign({}, state, { data: action.payload });
	default:
		return state;
	}
}
