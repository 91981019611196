export default {
	enter: 'Войти',
	forgotPassword: 'Забыли пароль?',
	yourEmail: 'Ваш Email',
	password: 'Пароль',
	wait: 'Подождите ...',
	enterEmail: 'Введите email',
	enterPassword: 'Введите пароль',
	goToBooking: 'Перейти к бронированию'
};
