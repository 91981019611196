import request, { removeCookie, setCookie, setToken, unsetToken } from 'helpers/request';

export const logIn = ({ email, password }) => async (dispatch) => {
	console.log('{ email, password }', { email, password });
	const authResult = await request('POST', 'auth/login', { email, password });

	setToken(authResult.token);

	setCookie('refreshToken', authResult.refreshToken);

	dispatch({ type: 'LOG_IN', payload: { user: authResult.user, token: authResult.token } });

	return authResult;
};

export const logOut = () => async (dispatch) => {
	unsetToken();
	
	removeCookie('refreshToken');

	dispatch({ type: 'LOG_OUT' });
};

export const setCurrentUserDetails = (queryToken) => async (dispatch) => {
	try {
		if (queryToken) {
			setToken(queryToken);
		}

		const me = await request('GET', 'auth/me');

		dispatch({ type: 'SET_CURRENT_USER', payload: me });

		return me;
	} catch (error) {
		return null;
	}
};

export const resetUserPassword = values => async (dispatch) => {
	await request('PUT', 'auth/reset-password', { passwordResetEmail: values.email });

	dispatch({ type: 'RESET_PASSWORD' });
};

export const logInAsUser = (userId) => async (dispatch) => {
	const authResult = await request('POST', `auth/login-as-user?userId=${userId}`);

	setToken(authResult.token);
	dispatch({ type: 'LOG_IN', payload: { user: authResult.user, token: authResult.token } });

	return authResult;
};
