import React from 'react';

import Heading from 'components/UI/Heading';

let Contact = (props) => {
	let { languageSchema } = props;
	let contactData = {
		email: 'info@rentabox.ru',
		address: '115201 г. Москва, ул. Котляковская, д. 3, стр. 1',
		phone: '+7 (495) 106-18-47',
		web: {
			label: 'www.rentabox.ru',
			link: 'https://www.rentabox.ru'
		},
		name: 'ООО "Рентабокс"',
	};

	return (
		<div className="col-md-6">
			<div className="card">
				<Heading>{languageSchema.Support.contacts}</Heading>
				<div className="col contact-form">
					<div className="h6">{contactData.name}</div>
					<div className="form-group">
						<label htmlFor="address" className="col-form-label">{languageSchema.Support.address}</label>
						<div id="address">{contactData.address}</div>
					</div>

					<div className="form-group">
						<label htmlFor="email" className="col-form-label">{languageSchema.Support.email}</label>
						<div id="email" className="d-flex justify-content-between align-items-center">
							<div>{contactData.email}</div>
							<a href={`mailto:${contactData.email}`} className="btn btn-sm btn-danger" target="_blank" rel="noopener noreferrer">
								<i className="far fa-envelope-open mr-1"></i>
								{languageSchema.Support.writeEmail}
							</a>
						</div>
					</div>

					<div className="form-group">
						<label htmlFor="web" className="col-form-label">{languageSchema.Support.web}</label>
						<div id="web">
							<a href={contactData.web.link} target="_blank" rel="noopener noreferrer">{contactData.web.label}</a>
						</div>
					</div>

					<div className="form-group">
						<label htmlFor="phone" className="col-form-label">{languageSchema.Support.phone}</label>
						<div id="phone" className="d-flex justify-content-between align-items-center">
							<div>{contactData.phone}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
