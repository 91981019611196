import React from 'react';
import Icon from '../Icon';

import Vector from './Vector';

export default class RectWithText extends Vector {
	static meta = {
		icon: <Icon icon={'rectangle-with-text'} size={40} />,
		initial: {
			width: 5,
			height: 5,
			stroke: '#646464',
			strokeWidth: 2,
			radius: 5,
			fill: 'lightgray',
			blendMode: 'normal',
			rotate: 0,
			text: 'Type some text...',
			fontWeight: 'normal',
			fontStyle: 'normal',
			textDecoration: 'none',
			textColor: 'white',
			fontSize: 20,
			fontFamily: 'Open Sans'
		}
	};

	setTextToCenter = () => {
		let { object } = this.props;
		let { x, y, width, height } = object;

		let centeredX = x + width / 2;
		let centeredY = y + height / 2;

		return {
			x: centeredX,
			y: centeredY,
			'dominant-baseline': 'middle',
			'text-anchor': 'middle'
		};
	}

	render() {
		let {object} = this.props;
		let {rotate, textColor, stroke, strokeWidth, radius, ...restOfAttributes} = this.getObjectAttributes();

		return (
			<g>
				<rect
					style={this.getStyle()}
					{...restOfAttributes}
					rx={object.radius}
					width={object.width}
					height={object.height}
					rotate={rotate}
					stroke={stroke}
					strokeWidth={strokeWidth}
					radius={radius}
				/>

				<text style={this.getStyle()}
					{...restOfAttributes}
					textAnchor="right"
					fontSize={object.fontSize}
					fontFamily={object.fontFamily}
					fill={textColor}
					{...this.setTextToCenter()}
				>
					{object.text}
				</text>
			</g>
		);
	}
}
