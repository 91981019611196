import React, { useState } from 'react';
import { round } from 'helpers/amount';

import BoxBookingContext from './index.js';

export default function BoxBookingContextProvider({ children }) {
	let [selectedStockId, setStockId] = useState(null);
	let [selectedStock, setStock] = useState(null);
	let [selectedSizeCode, setSizeCode] = useState(null);
	let [selectedSizeGroup, setSizeGroup] = useState(null);
	let [selectedBox, setSelectedBox] = useState(null);
	let [pureMonthRate, setPureRate] = useState(null);
	let [arrivalDate, setArrivalDate] = useState(null);
	let [selectedContractDuration, setContractDuration] = useState(1);
	let [invoicingPeriod, setInvoicingPeriod] = useState(1);
	let [selectedInsuranceSchemeId, setSelectedInsuranceSchemeId] = useState(null);
	let [insuranceMonthRate, setInsuranceMonthRate] = useState(0);
	let [discountPercent, setDiscountPercent] = useState(0);
	let [vatRate, setVatRate] = useState(20); // RUssia has 20% vat
	let [areDocsConfirmed, setDocsConfirmed] = useState(null);
	let [contactSource, setСontactSource] = useState(null);
	let [reasonForStorage, setReasonForStorage] = useState(null);
	let [user, setUser] = useState(null);
	let [isSidebarOpened, setSideBarOpened] = useState(false);
	let [paymentMethod, togglePaymentMethod] = useState(null);
	let [areDocsSent, setDocsSent] = useState(false);

	// TODO: configure this param globally in system
	let isOneEuroForFirstMonthActionApplied = false;
	// 1 < selectedContractDuration;

	let monthRate = round(pureMonthRate + pureMonthRate * vatRate / 100);

	let amountForInitialPeriod = monthRate * invoicingPeriod;
	let insuranceAmount = insuranceMonthRate;

	let discountAmount = round(amountForInitialPeriod * discountPercent / 100);

	if (isOneEuroForFirstMonthActionApplied) {
		let oneEuroMonthRate = 1;

		amountForInitialPeriod = amountForInitialPeriod + oneEuroMonthRate;
		insuranceAmount = insuranceAmount * 2; // for 2 months
	}

	amountForInitialPeriod = round(amountForInitialPeriod - discountAmount + insuranceAmount);

	// let vatAmount = round((amountForInitialPeriod) * vatRate / 100);

	// amount + VAT + deposit
	let depositAmount = 0; // no need for now for Russia, but actually it is pureMonthRate;

	let totalToPay = round(amountForInitialPeriod + depositAmount);

	return (
		<BoxBookingContext.Provider
			value={{
				bookingMeta: {
					selectedStockId,
					selectedSizeCode,
					selectedSizeGroup,
					selectedBox,
					pureMonthRate,
					arrivalDate,
					selectedContractDuration,
					selectedInsuranceSchemeId,
					insuranceMonthRate,
					insuranceAmount,
					discountAmount,
					discountPercent,
					vatRate,
					totalToPay,
					areDocsConfirmed,
					selectedStock,
					contactSource,
					reasonForStorage,
					user,
					isSidebarOpened,
					paymentMethod,
					areDocsSent,
					invoicingPeriod
				},
				setStockId,
				setStock,
				setSizeCode,
				setSizeGroup,
				setSelectedBox,
				setPureRate,
				setArrivalDate,
				setContractDuration,
				setSelectedInsuranceSchemeId,
				setInsuranceMonthRate,
				setDiscountPercent,
				setVatRate,
				setDocsConfirmed,
				setСontactSource,
				setReasonForStorage,
				setUser,
				setSideBarOpened,
				togglePaymentMethod,
				setDocsSent,
				setInvoicingPeriod
			}}
		>
			{children}
		</BoxBookingContext.Provider>
	);
}
