export default {
	leaveAppeal: 'Die Nachricht senden',
	requestACallback: 'Rückruf anfordern',
	email: 'E-Mail Adresse',
	phone: 'Telefonnummer',
	name: 'Name',
	appealMessage: 'Ihre Nachricht',
	callback: 'Rückruf',
	enterYourMessage: 'Geben Sie Ihre Nachricht ein',
	invalidPhoneBounds: 'Die Telefonnummer muss von 3 bis 14 Zeichen erhalten',
	messageInvalidBounds: 'Die Nachricht darf max. 250 Zeichen enthalten',
	contacts: 'Kontakte',
	address: 'Adresse',
	web: 'Webseite',
	writeEmail: 'eine E-Мail schreiben',
	callUs: 'Uns anrufen',
	callWithWhatsApp: 'Per WhatsApp kontaktieren',
};
