import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';

import request from 'helpers/request';
import { getLanguageSchema } from 'dashboard-commons/languages';
//import { getStorageValue } from 'utils/functions';
//import Table from 'components/UI/Table';
//import Search from 'components/common/Search';
//import Pagination from 'components/UI/Pagination';

//const DEF_PAYMENTS_CURRENT_PAGE = 'DEF_PAYMENTS_CURRENT_PAGE';
//const DEF_PAYMENTS_PER_PAGE = 100;

const PAGE_SIZE = 100;
const currentPage = 1;

function Status({ text }) {
	const colors = {
		success: 'green',
		canceled: 'blue'
	};
	const color = colors[text] ? colors[text] : 'black';

	return <span style={{ color, border: 'solid' }}>{text}</span>;
}

function ExternalPaymentsList(props) {
	const [payments, setPayments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const columns = [
		{
			title: '#',	// TODO: translate
			dataIndex: 'id'
		},
		{
			title: props.languageSchema.Payment.amount,
			dataIndex: 'pay_amount',
		},
		{
			title: props.languageSchema.User.client,	// TODO: translate
			dataIndex: 'clientid',
			render: (text, record) => <Link to={`/users/${record.userId}/contracts`}>{text}</Link>
		},
		{
			title: props.languageSchema.BoxBooking.box,
			dataIndex: 'unitName',
			render: (text, record) => <Link to={`/boxes/${record.boxId}`}>{text}</Link>
		},
		{
			title: 'iqacDocumentId',	// TODO: translate
			dataIndex: 'iqacDocumentId'
		},
		{
			title: props.languageSchema.Appeal.status,
			dataIndex: 'status',
			render: (text) => <Status text={text} />,
		},
		{
			title: 'Cоздан', // TODO: translate
			dataIndex: 'pending_datetime'
		},
		{
			title: 'Подтверждён',	// TODO: translate
			dataIndex: 'success_datetime'
		}
	];

	useEffect(() => {
		setIsLoading(true);
		request('GET', 'payments/external-payments')
			.then(setPayments)
			.finally(() => setIsLoading(false));
	}, []);

	return (
		<div>
			<Table className="table-responsive"
				loading={isLoading}
				columns={columns}
				dataSource={payments}
				pagination={{
					pageSize: PAGE_SIZE,
					current: currentPage,
				}}>
			</Table>
		</div>
	);
}

let mapStateToProps = ({ common }) => ({
	languageSchema: getLanguageSchema(common.language),
});

export default connect(mapStateToProps)(ExternalPaymentsList);
