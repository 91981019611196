export const apiStartLoading = () => ({ type: 'START_LOADING' });
export const apiEndLoading = () => ({ type: 'END_LOADING' });
export const setError = error => {
	return { type: 'ERROR', payload: { error } };
};

export const setBackofficeLanguage = (language) => async (dispatch) => {
	localStorage.setItem('language', language);

	dispatch({
		type: 'SET_LANGUAGE', payload: { language }
	});
};
