import * as Yup from 'yup';
import { PHONE_REGEXP } from 'utils/constants';

const validationSchema = languageSchema => Yup.object().shape({
	name: Yup.string()
		.min(4, languageSchema.validation.outOfBounds('name', languageSchema.Stock, 4, 254))
		.max(254, languageSchema.validation.outOfBounds('name', languageSchema.Stock, 4, 254))
		.required(languageSchema.validation.enterValue('name', languageSchema.Stock)),

	phone: Yup.string()
		.matches(PHONE_REGEXP, languageSchema.validation.invalidField('phone', languageSchema.Stock))
		.min(3, languageSchema.validation.validatePhone(3, 14))
		.max(14, languageSchema.validation.validatePhone(3, 14))
		.required(languageSchema.validation.enterValue('phone', languageSchema.Stock)),

	cityId: Yup.string()
		.max(30, languageSchema.validation.outOfBounds('city', languageSchema.Stock, 1, 30))
		.required(languageSchema.validation.required()),

	address: Yup.string()
		.max(128, languageSchema.validation.outOfBounds('address', languageSchema.Stock, 1, 128))
		.required(languageSchema.validation.required()),

	zip: Yup.string()
		.trim()
		.min(4, languageSchema.validation.outOfBounds('zip', languageSchema.Stock, 4, 16))
		.max(16, languageSchema.validation.outOfBounds('zip', languageSchema.Stock, 4, 16))
		.required(languageSchema.validation.enterValue('zip', languageSchema.Stock)),

	currency: Yup.string()
		.min(2, languageSchema.validation.outOfBounds('currency', languageSchema.Stock, 2, 4))
		.max(4, languageSchema.validation.outOfBounds('currency', languageSchema.Stock, 2, 4))
		.required(languageSchema.validation.enterValue('currencyRequired', languageSchema.Stock)),

	maxContractDuration: Yup.string()
		.min(1, languageSchema.validation.outOfBounds('maxContractDuration', languageSchema.Stock, 1, 3))
		.max(3, languageSchema.validation.outOfBounds('maxContractDuration', languageSchema.Stock, 1, 3))
		.required(languageSchema.validation.enterValue('maxContractDuration', languageSchema.Stock)),

	wifiPassword: Yup.string()
		.min(10, languageSchema.validation.outOfBounds('wifiPassword', languageSchema.Stock, 10, 100))
		.max(100, languageSchema.validation.outOfBounds('wifiPassword', languageSchema.Stock, 10, 100)),

	wifiNetworkName: Yup.string().nullable()
		.min(6, languageSchema.validation.outOfBounds('wifiNetworkName', languageSchema.Stock, 10, 100))
		.max(100, languageSchema.validation.outOfBounds('wifiNetworkName', languageSchema.Stock, 10, 100)),
});

export default validationSchema;
