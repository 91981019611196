import React, { useMemo } from 'react';
import { errorCodesMap, PHONE_REGEXP } from '../../utils/constants';
import { Form, Input, Checkbox } from 'antd';
import AntFormCountryPicker from '../common/AntFormCountryPicker';

export default function LegalUserFormFields(props) {
	let {
		isEditForm, isCurrentUserAdmin, languageSchema, language,
	} = props;

	let isNeedToShowRedStar = !isEditForm;

	let adminPasswordText = isEditForm ?
		languageSchema.User.passwordMayBeReset :
		languageSchema.User.userWillReceivePassword;

	let requiredFieldRules = useMemo(() => [
		{ required: true, message: languageSchema.errors[errorCodesMap.fieldIsRequired] }
	], [languageSchema]);

	let emailValidationRules = useMemo(() => [
		{
			type: 'email',
			message: languageSchema.errors[errorCodesMap.enterValidEmail],
		},
		{
			required: true,
			message: languageSchema.errors[errorCodesMap.emailIsRequired],
		},
	], [languageSchema]);

	let passwordValidationRules = useMemo(() => [
		{
			required: true,
			message: languageSchema.errors[errorCodesMap.passwordIsRequired],
		},
		{
			pattern: /^\S+$/,
			message: languageSchema.Etc.withoutSpaces,
		},
		{
			min: 6,
			message: languageSchema.Etc.minValueIs(6),
		},
	], [languageSchema]);

	let zipValidationRules = useMemo(() => [
		{
			required: true,
			message: languageSchema.errors[errorCodesMap.fieldIsRequired],
		},
		{
			max: 16,
			message: languageSchema.Etc.maxValueIs(16),
		},
		{
			min: 4,
			message: languageSchema.Etc.minValueIs(4),
		},
	], [languageSchema]);

	let phoneValidationRules = useMemo(() => [
		{
			required: true,
			message: languageSchema.errors[errorCodesMap.fieldIsRequired]
		},
		{
			pattern: PHONE_REGEXP,
			message: languageSchema.errors[errorCodesMap.invalidFormat],
		},
	], [languageSchema]);

	return (
		<div>
			<div className="">
				<div>
					<Form.Item
						id="isIndividualEntrepreneur"
						name="isIndividualEntrepreneur"
						valuePropName="checked"
					>
						<Checkbox> {languageSchema.User.individualEntrepreneur} </Checkbox>
					</Form.Item>
				</div>
			</div>
			<div className="form-group">
				<label htmlFor="company" className=" col-form-label">
					{languageSchema.User.companyName}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<Form.Item
					id="company"
					name="company"
					rules={requiredFieldRules}
				>
					<Input />
				</Form.Item>
			</div>

			<div className="form-group">
				<label htmlFor="registrationNumber" className=" col-form-label">
					{languageSchema.User.registrationNumber}
				</label>
				<Form.Item
					id="registrationNumber"
					name="registrationNumber"
				>
					<Input />
				</Form.Item>
			</div>

			<div className="form-group">
				<label htmlFor="country" className="col-form-label">
					{languageSchema.User.country}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<AntFormCountryPicker
					id="country"
					name="country"
					languageSchema={languageSchema}
					language={language}
					rules={requiredFieldRules}
				/>
			</div>

			<div className="form-group">
				<label htmlFor="city" className=" col-form-label">
					{languageSchema.User.city}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<Form.Item
					id="city"
					name="city"
					rules={requiredFieldRules}
				>
					<Input />
				</Form.Item>
			</div>

			<div className="form-group">
				<label htmlFor="address" className=" col-form-label">
					{languageSchema.User.address}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<Form.Item
					id="address"
					name="address"
					rules={requiredFieldRules}
				>
					<Input />
				</Form.Item>
			</div>

			<div className="form-group">
				<label htmlFor="zip" className=" col-form-label">
					{languageSchema.User.zip}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<Form.Item
					id="zip"
					name="zip"
					rules={zipValidationRules}
				>
					<Input />
				</Form.Item>
			</div>

			<div className="form-group">
				<label htmlFor="phone" className="col-form-label">
					{languageSchema.User.phone}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<Form.Item
					id="phone"
					name="phone"
					rules={phoneValidationRules}
				>
					<Input placeholder="+431231231231"/>
				</Form.Item>
			</div>

			<div className="form-group">
				<label htmlFor="email" className=" col-form-label">
					{languageSchema.User.email}
					{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
				</label>
				<Form.Item
					id="email"
					name="email"
					rules={emailValidationRules}
				>
					<Input />
				</Form.Item>
			</div>

			{
				!isEditForm &&
				<div className="form-group">
					<label htmlFor="password" className=" col-form-label">
						{languageSchema.User.password}
						{isNeedToShowRedStar && <span style={{ color: 'red', fontSize: '22px' }}>*</span>}
					</label>
					<Form.Item
						id="password"
						name="password"
						rules={passwordValidationRules}
					>
						<Input.Password />
					</Form.Item>
				</div>
			}

			{
				isCurrentUserAdmin &&
				<div className="form-group	align-items-center">
					<label className=" col-form-label">{languageSchema.User.password}</label>
					<span>&#32;{adminPasswordText}</span>
				</div>
			}

		</div>
	);
}
