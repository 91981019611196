export default {
	accountDetails: 'Account-Details',
	lockAvailability: 'Status sperren',
	accountCreation: 'einen Account erstellen',
	docsAttachmentInfo: 'Der Benutzer muss das Foto der Personaldokumente im persönlichem Bereich der mobilen Anwendung herunteralden',
	userWillReceivePassword: 'Der Benutzer bekommt das Passwort per E-Mail',
	passwordMayBeReset: 'Das Passwort kann zurückgesetzt werden',
	name: 'Vorname',
	sureName: 'Nachname',
	email: 'E-Mail-Adresse',
	emailSubject: 'E-Mail-Betreff',
	emailType: 'E-Mail-Typ',
	password: 'Passwort',
	country: 'Land',
	phone: 'Telefonnummer',
	city: 'Stadt',
	address: 'Straße',
	houseNumber: 'Hausnummer',
	zip: 'Postleitzahl',
	company: 'Firmenname',
	role: 'Rolle',
	stock: 'Lager',
	withoutStock: 'ohne Lager',
	areYouSureYouWantToDeleteAccount: 'Wollen Sie wirklich den Account löschen?',
	accountEdit: 'den Account bearbeiten',
	kycHeader: 'Personaldokument',
	passwordReset: 'Passwortzurücksetzung',
	resetPassword: 'Passwort zurücksetzen',
	passwordWillBeSentToMail: 'Das Passwort wird per E-Mail versendet',
	addAccount: 'den Account hinzufügen',
	contracts: 'Verträge',
	checkPhotosInfoBlock: 'Prüfen Sie das Foto Ihres Passes. Dafür klicken sie auf das Foto. Wenn die Fotos und die Daten in Ordnung sind, klicken Sie verifizieren.',
	selfieWasNotUploaded: 'Das Selfie mit dem Pass wurde nicht hochgeladen',
	selfieWasRejected: 'Das Selfie wurde abgelehnt.',
	reason: 'Grund',
	passportPhotoWasNotUploaded: 'Das Foto Ihres Passes wurde nicht hochgeladen',
	passportPhotoWasRejected: 'Das Foto Ihres Passes wurde abgelehnt',
	cancelVerification: 'Verifikation deaktivieren',
	verify: 'verifizieren',
	docRejectionDescription: 'Wenn das Personaldokument nicht in Ordnung ist, wird der Zugang in den Lagerraum für den Kunden geschlossen.',
	userNeedToReupload: 'Wenn das Foto vom Manager abgelehnt wurde, muss der Benutzer ein anderes Foto im persönlichem Bereich hinzufügen',
	rejectionReason: 'Grund der Ablehnung',
	setRejectionReason: 'Nennen Sie den Ablehnungsgrund des Personaldokuments',
	sureNameRequired: 'Nachname',
	areYouSureYouWantToResetPassword: 'Sind Sie sicher, dass Sie Ihr Passwort ändern möchten?',
	areYouSureYouWantToRejectDocument: 'Sind Sie sicher, dass Sie das Dokument ablehnen möchten?',
	areYouSureYouWantToCancelProfileVerification: 'Möchten Sie die Profilüberprüfung wirklich abbrechen?',
	areYouSureYouWantToMarkProfileAsVerified: 'Möchten Sie das Profil wirklich als verifiziert markieren?',
	accountType: 'Accounttype',
	individual: 'Privatpersonen',
	legal: 'Unternehmen',
	companyName: 'Firmenname',
	registrationNumber: 'Steuernummer',
	client: 'Kunde',
	currentClient: 'Der aktuelle Kunde',
	goToContracts: 'Zu Kontrakten übergehen',
	passwordConfirmation: 'Passwortbestätigung',
	passwordChanged: 'Ihr Passwort wurde geändert ',
	passwordWasSuccessfullyChanged: 'Ihr Passwort wurde erfolgreich geändert',
	areYouSureYouWantToChangePassword: 'Sind Sie sicher, dass Sie Ihr Passwort ändern möchten?',
	changePassword: 'Passwort ändern',
	isEighteenAge: 'Volljährigkeit ist die Voraussetzung für die Anmietung.',
	loginAsUser: 'Als Benutzer anmelden',
	created: 'Erstelldatum',
	unknownDate: 'Aktualisierungsdatum unbekannt',
	importUsers: 'Benutzer importieren',
	createdAfter: 'Erstellt nach',
	importUsersByDate: 'Benutzer nach Datum importieren',
	otherContacts: 'Andere Kontakte',
	companyRequired: 'Geben Sie Ihren Firmennamen ein',
	zipRequired: 'Geben Sie Ihre Postleitzahl ein',
	addressRequired: 'Geben Sie Ihre Straße ein',
	cityRequired: 'Geben Sie Ihre Stadt ein',
	phoneRequired: 'Geben Sie Ihre Telefonnummer ein',
	emailRequired: 'Geben Sie Ihre E-Mailadresse ein',
	houseRequired: 'Geben Sie Ihre Hausnummer ein',
	salutation: 'Anrede',
	man: 'Herr',
	woman: 'Frau',
	pinCode: 'Geheimzahl',
	showPassword: 'Passwort anzeigen',
	hidePassword: 'Passwort verbergen'
};
