import { toastr } from 'react-redux-toastr';
import request from 'helpers/request';
import { ROLES_MAP } from 'utils/constants';

export const appealAdded = (data) => async (dispatch, getState) => {
	let { auth: { me } } = getState();
	let authToken = localStorage.getItem('accessToken');

	if (me.role !== ROLES_MAP.client && authToken && data.type === 'callback') {
		toastr.success(
			`Call Request to: ${data.phone}`,
			`Name: ${data.name}`,
			{
				position: 'top-center',
				attention: true,
				onAttentionClick: (id) => {
					toastr.remove(id);
				},
				timeOut: 0,
				transitionIn: 'fadeIn',
				transitionOut: 'fadeOut'
			}
		);
	}

	dispatch({ type: 'APPEAL_ADDED', payload: { data }});
};

export const appealHandled = (appeal) => (dispatch) => {
	dispatch({ type: 'APPEAL_HANDLED', payload: appeal });
};

export const fetchAppeals = (filter) => async (dispatch) => {
	let appeals = await request('GET', 'appeal', filter);

	dispatch({ type: 'FETCH_APPEALS', payload: appeals });
};
