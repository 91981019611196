import React from 'react';
import {modes} from '../constants';
import Icon from '../Icon';

import Vector from './Vector';
import BezierEditor from '../editors/BezierEditor';

export default class Path extends Vector {
	static meta = {
		initial: {
			fill: '#e3e3e3',
			closed: false,
			rotate: 0,
			moveX: 0,
			moveY: 0,
			path: [],
			stroke: 'gray',
			strokeWidth: 1,
			text: 'Type some text...',
			fontWeight: 'normal',
			fontStyle: 'normal',
			textDecoration: 'none',
			textColor: 'white',
			fontSize: 20,
			fontFamily: 'Open Sans'
		},
		mode: modes.DRAW_PATH,
		icon: <Icon icon={'polygon-with-text'} size={30} />,
		editor: BezierEditor
	};

	buildPath(object) {
		let {path} = object;

		let curves = path.map(({x1, y1, x2, y2, x, y}) => (
			`C ${x1} ${y1}, ${x2} ${y2}, ${x} ${y}`
		));

		let instructions = [
			`M ${object.moveX} ${object.moveY}`,
			...curves
		];

		if (object.closed) {
			instructions = [
				...instructions, 'Z'
			];
		}

		return instructions.join('\n');
	}

	getTransformMatrix({rotate, x, y, moveX, moveY}) {
		return `
			translate(${x - moveX} ${y - moveY})
			rotate(${rotate} ${x} ${y})
		`;
	}

	setTextToCenter = () => {
		let { object } = this.props;

		let sortedX = object.path.map(line => line.x).sort((el1, el2) => el2 - el1);
		let sortedX1 = object.path.map(line => line.x1).sort((el1, el2) => el2 - el1);
		let sortedX2 = object.path.map(line => line.x2).sort((el1, el2) => el2 - el1);

		let sortedY = object.path.map(line => line.y).sort((el1, el2) => el2 - el1);
		let sortedY1 = object.path.map(line => line.y1).sort((el1, el2) => el2 - el1);
		let sortedY2 = object.path.map(line => line.y2).sort((el1, el2) => el2 - el1);

		let [maxX] = sortedX;
		let [minX] = sortedX.reverse();

		let [maxX1] = sortedX1;
		let [minX1] = sortedX1.reverse();

		let [maxX2] = sortedX2;
		let [minX2] = sortedX2.reverse();

		let [maxY] = sortedY;
		let [minY] = sortedY.reverse();

		let [maxY1] = sortedY1;
		let [minY1] = sortedY1.reverse();

		let [maxY2] = sortedY2;
		let [minY2] = sortedY2.reverse();

		let maxBaseX = Math.max(maxX, maxX1, maxX2);
		let minBaseX = Math.min(minX, minX1, minX2);

		let maxBaseY = Math.max(maxY, maxY1, maxY2);
		let minBaseY = Math.min(minY, minY1, minY2);

		let centeredX = minBaseX + (maxBaseX - minBaseX)/2;
		let centeredY = minBaseY + (maxBaseY - minBaseY)/2;

		return {
			x: centeredX,
			y: centeredY,
			'dominant-baseline': 'middle',
			'text-anchor': 'middle'
		};
	}

	render() {
		let {object} = this.props;
		let fill = object.closed ? object.fill : 'transparent';

		// todo: deal with destructuring and getObjectAttributes. Not clear real properties
		let {
			// eslint-disable-next-line
			rotate,
			textColor,
			// eslint-disable-next-line
			stroke,
			// eslint-disable-next-line
			strokeWidth,
			// eslint-disable-next-line
			radius,
			...restOfAttributes
		} = this.getObjectAttributes();

		return (
			<g>
				<path
					style={this.getStyle(object)}
					{...this.getObjectAttributes()}
					d={this.buildPath(object)}
					fill={fill}
				/>
				<text style={this.getStyle()}
					{...restOfAttributes}
					textAnchor="right"
					fontSize={object.fontSize}
					fontFamily={object.fontFamily}
					fill={textColor}
					{...this.setTextToCenter()}
				>
					{object.text}
				</text>
			</g>
		);
	}
}
