export default {
	stock: 'Склад',
	createStock: 'Добавить склад',
	stockEditing: 'Редактирование склада',
	history: 'История',
	box: 'Бокс',
	boxes: 'Боксы',
	name: 'Название',
	city: 'Город',
	address: 'Адрес',
	phone: 'Телефон',
	zip: 'Индекс',
	hasBoxes: 'Есть боксы',
	boxRenting: 'Аренда боксов',
	controlSystem: 'Система управления складом',
	currency: 'Валюта расчетов',
	currencyRequired: 'Валюту расчетов',
	maxContractDuration: 'Максимальный срок действия договора (мес.)',
	routerIp: 'IP-адрес склада',
	locksServerPort: 'Порт сервера замков',
	offlineAppUrl: 'Ссылка на оффлайн приложение',
	databaseServer: 'Сервер базы данных',
	databasePort: 'Порт базы данных',
	databaseName: 'Названия базы данных',
	databaseUser: 'Пользователь базы данных',
	databasePassword: 'Пароль базы данных',
	addEquipment: 'Добавить элемент оборудования',
	equipmentId: 'ID элемента',
	control: 'Управление',
	discountSettings: 'Настройки скидок',
	discountHelpInfo: `Это значение будет приумножаться каждым месяцем единовременной оплаты. <br /> <br />
				<b>Пример:</b><br />
				 Пользователь собирается заключить договор на <b>12 месяцев </b>
				 и выбирает оплату за каждые <b> 4 месяца </b>. Значение скидки установлено <b>2%</b>. <br />
				Тогда общая скидка за 4х-месячную оплату составит:<br />
				 <b>4 * 2% = 8%</b>		от месячной стоимость аренды бокса.`,
	discountPercentPerMonth: 'Cкидка за каждый единовременный месяц оплаты:',
	feeSettings: 'Настройки неустоек',
	feeInfoBlock: 'Процент неустойки добавляется каждый день. Высчитывается от первоначальной суммы. ',
	dailyFeeDescription: 'Процент надбавки за каждый неоплаченный день',
	freeFeeDays: 'Не начислять первые',
	days: 'дней',
	contractEndedFeeLabel: 'Процент надбавки за каждый день после окончания договора',
	stockHistory: 'История склада',
	date: 'Дата',
	idOrSize: 'ID или Размер',
	action: 'Действие',
	noHistoryRecords: 'История пуста',
	account: 'Аккаунт',
	opened: 'Открыт',
	closed: 'Закрыт',
	accountType: 'Тип аккаунта',
	areYouSureYouWantToOpenBox: 'Вы уверены, что хотите открыть бокс?',
	lockStatus: 'Статус замка',
	totalBoxes: 'Всего боксов',
	boxNumber: 'Номер бокса',
	size: 'Размер',
	lockNumber: 'Номер замка',
	monthRate: 'Стоимость/мес.',
	notSet: 'Не задано',
	currentUser: 'Текущий пользователь',
	boxAvailable: 'Свободен',
	areYouSureYouWantToOpenStock: 'Вы уверены, что хотите открыть склад?',
	areYouSureYouWantToOpen: 'Вы уверены что хотите открыть',
	areYouSureYouWantToDelete: 'Вы уверены, что хотите удалить склад?',
	insurance: 'Страхование',
	insuranceCoverageAmount: 'Сумма страхования',
	insuranceMonthlyAmount: 'Страховой взнос в месяц',
	vat: 'НДС',
	legalUsersVATRate: 'НДС для юридических лиц',
	individualUsersVATRate: 'НДС для физических лиц',
	wifiPassword: 'Wi-Fi пароль',
	showWifiPassword: 'Показать Wi-Fi пароль',
	accessDenied: 'Доступ запрещён',
	accessAllowed: 'Доступ разрешён',
	coordinates: 'Координаты',
	latitude: 'Широта',
	longitude: 'Долгота',
	stockId_sm: 'ID склада SM',
	save: 'Сохранить',
	boxesMapConstructor: 'Конструктор расположения боксов',
	accountBalance: 'Баланс счёта',
	addFloor: 'Добавить этаж',
	floor: 'Этаж',
	height: 'Ширина',
	width: 'Длина',
	depth: 'Глубина',
	square: 'Площадь',
	boxSpace: 'Площадь',
	areYouSureYouWantToRun: 'Вы уверены, что хотите выполнить ',
	openingTime: 'Время открытия',
	closingTime: 'Время закрытия',
	wifiNetworkName: 'Имя сети Wi-Fi',
	gate: 'Ворота',
	notifyOpenDoor: 'Чтобы открыть дверь, необходимо подключиться к Wi-Fi склада и отключить VPN!',
	notifyOpenLock: 'Чтобы открыть замок, необходимо подключиться к Wi-Fi склада и отключить VPN! ',
	redirectToOfflineApp: 'Перейти в оффлайн приложение',
	followSteps: 'Выполните последовательно следующие действия:',
	turnOffMobileNet: 'Отключите мобильный интернет',
	connectToWiFi: 'Подключитесь к Wi-Fi cклада',
	clickOfflineAppButton: 'Нажмите на кнопку перейти в оффлайн приложение',
	stockConnectionIsLost: 'Уважаемые клиенты, в случаях проблем с интернетом на складе'
};
