import React, { useState } from 'react';
import _ from 'lodash';


import Loader from 'components/common/OverlayLoader';
import StocksListItem from './StocksListItem';
import { useHistory, useLocation } from 'react-router-dom';
import { parseUrl } from 'query-string';
import { Modal } from 'antd';
import request from 'helpers/request';

const StockList = (props) => {
	const router = useLocation();
	const history = useHistory();

	const { boxes, stocks, languageSchema, user } = props;
	const grouppedBoxes = _.groupBy(boxes, 'stockId');

	const [isExtendModalOpen, setIsExtendModalOpen] = useState(parseUrl(router.search).query.action === 'extend');

	const handleExtendContractSubmit = async () => {
		const box = _.find(boxes, {UnitNumber: parseUrl(router.search).query.boxId});
		try {
			await request('POST', `boxes/${box.UnitID}/extend`,  {
				userId: box.internalUserId,
				UnitNumber: box.UnitNumber,
			});
		}	catch(e) {
			console.error('message', e.message);
		}
		setIsExtendModalOpen(false);
		history.replace(router.pathname);
	};



	return (
		<div>
			<Loader />
			<div className="mb-3">
				{stocks.map(
					(stock) =>
						grouppedBoxes[stock._id] && (
							<StocksListItem
								key={stock._id}
								stock={stock}
								languageSchema={languageSchema}
								user={user}
								boxes={grouppedBoxes[stock._id]}
								fetchBoxes={props.fetchBoxes}
							/>
						)
				)}
			</div>
			<Modal title={languageSchema.Etc.extendContract} open={isExtendModalOpen} okText={languageSchema.Etc.yes} cancelText={languageSchema.Etc.no} onOk={handleExtendContractSubmit} onCancel={() => setIsExtendModalOpen(false)}>
				{
					<div className='flex align-center'>
						<div className='mr-2'>{languageSchema.Contract.extendContract} </div>
					</div>
				}
			</Modal>
		</div>
	);
};

export default StockList;
