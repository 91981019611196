import React from 'react';

import Layout from 'components/Layout';

function NotFoundPage() {
	return (
		<div>
				Requested resurce is Not Found
		</div>
	);
}

export default Layout(NotFoundPage);
