import React from 'react';
import { connect } from 'react-redux';

import { ACCOUNT_TYPES_MAP } from 'utils/constants';
import UserForm from 'components/User/Form';
import Heading from 'components/UI/Heading';
import { createUser } from 'redux/actions';
import {getLanguageSchema} from 'dashboard-commons/languages';
import { fetchStocks } from 'redux/actions';
import { timeout } from 'utils/functions';

class UserFormAdd extends React.Component {
	state = {
		isSuccess: false,
		accountType: ACCOUNT_TYPES_MAP.individual,
		loading: false,
	}

	componentDidMount() {
		const {
			isFetchedStocks,
			fetchStocks,
		} = this.props;

		if (!isFetchedStocks) {
			fetchStocks();
		}
	}

	handleSubmit = async (values) => {
		try {
			this.setLoading(true);
			await this.props.createUser({
				...values, language: this.props.language, isAdvertisingAgree: true
			});

			this.setState({ isSuccess: true }, async () => {
				await timeout(1500);
				this.setState({ isSuccess: false });
				this.props.history.push('/users');
			});
		} catch (error) {
			console.log(error);
		} finally {
			this.setLoading(false);
		}
	}

	setAccountType = accountType => this.setState({ accountType });
	setLoading = loading => this.setState({ loading });

	render() {
		const { languageSchema, user: currentUser, language } = this.props;
		let { accountType, loading } = this.state;

		return (
			<div className="row py-3">
				<div className="col-md-6 offset-md-3">
					<div className="card">
						<Heading>{languageSchema.User.accountCreation}</Heading>
						<UserForm
							type="create"
							onSubmit={this.handleSubmit}
							isSuccess={this.state.isSuccess}
							languageSchema={languageSchema}
							language={language}
							currentUser={currentUser}
							accountType={accountType}
							setAccountType={this.setAccountType}
							loading={loading}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, stocks, common }) => {
	return {
		isFetchedStocks: stocks.isFetched,
		languageSchema: getLanguageSchema(common.language),
		language: common.language,
		user: auth.me,
	};
};

export default connect(mapStateToProps, { fetchStocks, createUser })(UserFormAdd);
