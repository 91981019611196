export default {
	create: 'Create',
	back: 'Back',
	save: 'Save',
	delete: 'Delete',
	settings: 'Settings',
	open: 'Open',
	apply: 'Apply',
	edit: 'Edit',
	reject: 'Reject',
	close: 'Close'
};
