import React, { useState } from 'react';

import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

let getMarks = (items) => items.map(i => ({ value: i, label: i }));

let StyledSlider = styled(Slider)({
	color: '#25a549',
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 12,
		background: 'unset',
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: '50% 50% 50% 0',
		backgroundColor: '#52af77',
		transformOrigin: 'bottom left',
		transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(45deg)',
		},
	},
});

export default function CustomizedSlider(props) {
	let { items, defaultValue } = props;

	let [value, setValue] = useState(defaultValue);

	let [minItem] = items;
	let maxItem = items[items.length - 1];

	let handleChange = (e) => {
		setValue(e.target.value);
		props.onChange(e.target.value);
	};

	return (
		<StyledSlider
			valueLabelDisplay="auto"
			value={value}
			getAriaValueText={(v) => `${v} мес.`}
			step={1}
			min={minItem}
			max={maxItem}
			onChange={handleChange}
			marks={getMarks(items)}
		/>
	);
}
