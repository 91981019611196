import request from 'helpers/request';

const endpoint = 'boxes';

export const fetchBoxes = stockId => async (dispatch) => {
	const boxes = await request('GET', endpoint, { method: 'all', stockId });
	dispatch({ type: 'FETCH_BOXES', payload: boxes });
};

export const fetchBox = boxId => async (dispatch) => {
	const box = await request('GET', `${endpoint}/${boxId}`);
	dispatch({ type: 'FETCH_BOX', payload: box });
};

export const fetchBoxHistory = boxId => async (dispatch) => {
	const boxHistory = await request('GET', `${endpoint}/${boxId}/history`);
	dispatch({ type: 'FETCH_BOX_HISTORY', payload: boxHistory });
};

export const createBox = box => async (dispatch) => {
	const createdBox = await request('POST', endpoint, box);
	dispatch({ type: 'CREATE_BOX', payload: createdBox });
};

export const updateBox = box => async (dispatch) => {
	const updatedBox = await request('PUT', `${endpoint}/${box._id}`, box);
	dispatch({ type: 'UPDATE_BOX', payload: updatedBox });
};

export const deleteBox = boxId => async (dispatch) => {
	await request('DELETE', `${endpoint}/${boxId}`);
	dispatch({ type: 'DELETE_BOX', payload: boxId });
};

export const checkBoxingLock = boxId => async (dispatch) => {
	const lockStatus = await request('GET', `${endpoint}/${boxId}/check`);
	dispatch({ type: 'CHECK_BOXING_LOCK', payload: lockStatus });
};

export const openBox = box => async (dispatch) => {
	const actionStatus = await request('PUT', `${endpoint}/${box._id}/open`);

	dispatch({ type: 'OPEN_BOX', payload: actionStatus });
};

export const boxOpened = boxId => async (dispatch) => {
	dispatch({ type: 'BOX_OPENED', payload: { boxId } });
};

export const boxClosed = boxId => async (dispatch) => {
	dispatch({ type: 'BOX_CLOSED', payload: { boxId } });
};

export const fetchBoxesFromSM = stockId => async (dispatch) => {
	const boxes = await request('GET', `sm/${endpoint}`, { stockId });
	dispatch({ type: 'FETCH_BOXES', payload: boxes });
};

export const regroupBoxes = () => async (dispatch) => {
	await request('PUT', `${endpoint}/regroup`);
	dispatch({ type: 'REGROUP_BOXES' });
};

export const clearCurrentBox = () => ({type: 'CLEAR_CURRENT_BOX' });
export const clearCurrentBoxes = () => ({type: 'CLEAR_CURRENT_BOXES' });

export const saveLockId = (data) => async (dispatch) => {
	await request('PUT', `${endpoint}/${data._id}`, data);
	dispatch({ type: 'CREATE_BOX_IN_OWN_DB' });
};

export const mapIsDragging = (isDragging) => async (dispatch) => {
	dispatch({ type: 'MAP_IS_DRAGGING', payload: { isDragging } });
};
