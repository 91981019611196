import React from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'antd';
import { Link } from 'react-router-dom';

import { ACCOUNT_TYPES_MAP } from 'utils/constants';
import LegalUserFormFields from 'components/User/LegalUserFormFields';
import IndividualUserFormFields from 'components/User/IndividualUserFormFields';

import rentaboxLogo from 'assets/images/logo_blue_text.png';

let getFormTypeSelectOptions = (languageSchema) => Object.keys(ACCOUNT_TYPES_MAP)
	.map(accountType => ({
		value: accountType,
		label: languageSchema.User[accountType],
	}));

function SignUpForm(props) {
	let	 {
		isSuccess,
		languageSchema,
		language,
		setAccountType,
		onLogInAction,
		accountType,
		onSubmit,
		loading,
	} = props;


	let onFormTypeChange = type => {
		setAccountType(type);
	};

	let handleFormTypeChange = (type) => () => {
		onFormTypeChange(type);
	};

	let getFormFields = (type, props) => ({
		[ACCOUNT_TYPES_MAP.legal]: <LegalUserFormFields {...props} />,
		[ACCOUNT_TYPES_MAP.individual]: <IndividualUserFormFields {...props} />,
	}[type] || null);

	return (
		<div>
			<div className="text-center mb-3">
				<img
					src={rentaboxLogo}
					alt="Rentabox"
					title="Rentabox"
					width="55%"
				/>
			</div>
			<Form
				disabled={loading}
				onFinish={(values) => {
					onSubmit({...values, accountType: accountType.value});
				}}
				autoComplete="off"
			>
				<div className="form-group">
					<label>
						{languageSchema.Etc.choose} {languageSchema.User.accountType}
					</label>
					<div>
						<div className="row">
							{
								getFormTypeSelectOptions(languageSchema).map(type=> {
									let isActive = accountType?.value === type.value;

									return (
										<div className="col-md-6" key={type.value}>
											<div
												onClick={handleFormTypeChange(type)}
												className={`account-type-item ${isActive ? 'selected' : ''}`}
											>
												{type.label}
											</div>
										</div>
									);
								})
							}
						</div>
					</div>
				</div>

				{
					accountType?.value &&
					<>
						{
							getFormFields(
								accountType.value,
								{
									isEdit: false,
									language,
									languageSchema,
								})
						}
					</>
				}

				{isSuccess && (
					<div className="alert alert-success mt-3" role="alert">
						{languageSchema.Etc.success}
					</div>
				)}

				<div>
					{
						accountType?.value &&
						<Form.Item style={{ marginBottom: 0 }}>
							<Button
								style={{
									fontSize: '20px',
									width: '100%',
									backgroundColor: '#28a745',
									borderColor: '#28a745',
									height: 44,
								}}
								type="primary"
								htmlType="submit"
							>
								{ loading ? languageSchema.LogIn.wait : languageSchema.SignUp.signUp }
							</Button>
						</Form.Item>
					}

					<div style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '30px 0',
						color: '#283049',
						fontSize: '28px',
						fontWeight: 600
					}}>
						{languageSchema.BoxBooking.haveAnAccount}
					</div>

					{
						onLogInAction ?
							<div
								onClick={onLogInAction}
								style={{
									backgroundColor: '#283049',
									fontSize: '20px',
									width: '100%'
								}}
								className="btn btn-secondary"
							>
								{languageSchema.LogIn.enter}
							</div> :
							<Link
								to='/login'
								style={{
									backgroundColor: '#283049',
									fontSize: '20px',
									width: '100%'
								}}
								className="btn btn-secondary"
							>
								{languageSchema.LogIn.enter}
							</Link>
					}
				</div>
			</Form>
		</div>
	);
}

let mapStateToProps = ({ auth, users, common }) => {
	return {
		isFetchedUsers: users.isFetched,
		language: common.language,
		loggedInUser: auth.me
	};
};

export default connect(mapStateToProps)(SignUpForm);
