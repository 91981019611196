import request from 'helpers/request';

const endpoint = 'users';

export const fetchUsers = (page=0, limit=100) => async (dispatch) => {
	const users = await request('GET', `${endpoint}?page=${page}&limit=${limit}`);
	dispatch({ type: 'FETCH_USERS', payload: users.filter(({ isDeleted }) => !isDeleted) });
};

export const fetchUsersCount = () => async (dispatch) => {
	const { count } = await request('GET', `${endpoint}/count`);
	dispatch({ type: 'FETCH_USERS_COUNT', payload: count });
};

export const fetchUser = (userId) => async (dispatch) => {
	const user = await request('GET', `${endpoint}/${userId}`);

	dispatch({ type: 'FETCH_USER', payload: [user] });
};

export const createUser = (user) => async (dispatch) => {
	const createdUser = await request('POST', endpoint, user);
	
	dispatch({ type: 'CREATE_USER', payload: createdUser });
};

export const updateUser = (userId, user) => async (dispatch) => {
	const updatedUser = await request('PUT', `users/${userId}`, user);
	dispatch({ type: 'UPDATE_USER', payload: updatedUser });
};

export const deleteUser = (userId) => async (dispatch) => {
	await request('DELETE', `${endpoint}/${userId}`);
	dispatch({ type: 'DELETE_USER', payload: userId });
};
