import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { setCurrentUserDetails } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { STEP_2_ANCHOR, STEP_3_ANCHOR } from 'utils/constants';
import request, { getToken } from 'helpers/request';
import { isTokenValid, scrollToDiv  } from 'utils/functions';
import SignUpModal from 'components/SignUp/Modal';
import LogInModal from 'components/Auth/Modal';
import Loader from 'components/common/OverlayLoader';

import NavBar from './components/NavBar';
import StockSelectOption from 'components/BoxBooking/StockSelectOption';
import SizeGroups from './components/SizeGroups';
import BoxesList from './components/BoxesList';
import SideBar from './components/SideBar';
import BoxBookingContextProvider from './Context/Provider';
import BoxBookingContext from './Context/';

import 'antd/dist/antd.css';

function BoxBookingNew(props) {
	const boxBookingContext = React.useContext(BoxBookingContext);

	const [stocks, setStocks] = useState([]);
	const [fetchedBoxes, setFetchedBoxes] = useState([]);
	const [selectedSizeCode, setSelectedSizeCode] = useState(null);
	const [isSignUpModalOpened, setSignUpModalOpened] = useState(false);
	const [isLogInModalOpened, setLogInModalOpened] = useState(false);

	useEffect(() => {
		fetchStocks();
		// if user was signed in some time ago and opened this booking page, need to set his details
		if (isTokenValid()) {
			props.setCurrentUserDetails(getToken())
				.then(boxBookingContext.setUser);
		}
	}, []);

	useEffect(() => {
		if (boxBookingContext.bookingMeta.selectedStockId) {
			fetchBoxes();
			// .then(() => {
			// 	let isMobile = window.innerWidth < 900;
			//
			// 	if (!isMobile) {
			// 		scrollToDiv(STEP_2_ANCHOR);
			// 	}
			// });

			boxBookingContext.setSizeGroup(null);
			setSelectedSizeCode(null);
		}
	}, [boxBookingContext.bookingMeta.selectedStockId]);


	useEffect(() => {
		if (!_.isEmpty(boxBookingContext.bookingMeta.selectedSizeGroup)) {
			setTimeout(() => {
				scrollToDiv(STEP_3_ANCHOR);
			}, 200);
		}
	}, [boxBookingContext.bookingMeta.selectedSizeGroup]);

	//
	// useEffect(() => {
	// 	// TODO: replace with API request: fetch vat schemes from api.
	// 	// const vatRate = (boxBookingContext.bookingMeta.user &&
	// 	// 	boxBookingContext.bookingMeta.user.accountType === ACCOUNT_TYPES_MAP.legal) ?
	// 	// 	20 :
	// 	// 	0;
	//
	// 	// const vatRate = 20;
	// 	//
	// 	// boxBookingContext.setVatRate(vatRate);
	// }, [boxBookingContext.bookingMeta.user]);

	useEffect(() => {
		if (isSignUpModalOpened || isLogInModalOpened) {
			boxBookingContext.setSideBarOpened(false);
		}

		// if (boxBookingContext.bookingMeta.selectedBox && !isSignUpModalOpened && !isLogInModalOpened) {
		if (boxBookingContext.bookingMeta.selectedBox && !isSignUpModalOpened && !isLogInModalOpened) {
			boxBookingContext.setSideBarOpened(true);
		}
	}, [
		isSignUpModalOpened,
		isLogInModalOpened
	]);

	const fetchStocks = async () => {
		let fetchedStocks = await request('GET', 'stocks');

		if (!_.isEmpty(fetchedStocks)) {
			setStocks(fetchedStocks);

			let [firstStock] = fetchedStocks;

			handleSetStock({ value: firstStock });
		}
	};

	const fetchBoxes = async () => {

		let fetchedBoxes = await request('GET', `boxes/fetch?stockId=${boxBookingContext.bookingMeta.selectedStockId}`);

		if (!_.isEmpty(fetchedBoxes)) {
			setFetchedBoxes(fetchedBoxes);
		}
	};

	const handleSetStock = (stock) => {
		boxBookingContext.setStock(stock.value);
		boxBookingContext.setStockId(stock.value.stockId_sm);
	};

	const closeSideBar = () => {
		// boxBookingContext.setSelectedBox(null);
		boxBookingContext.setSideBarOpened(false);
	};

	const openSignUpModal = () => {
		setSignUpModalOpened(true);
		setLogInModalOpened(false);
	};

	const closeSignUpModal = () => setSignUpModalOpened(false);

	const openLogInModal = () => {
		setSignUpModalOpened(false);
		setLogInModalOpened(true);
	};
	const closeLogInModal = () => setLogInModalOpened(false);

	const handleSignUpCompleted = (signUpResult) => {
		boxBookingContext.setUser(signUpResult);
		closeSignUpModal();
	};

	const handleLogInCompleted = (logInResult) => {
		boxBookingContext.setUser(logInResult.user);
		closeLogInModal();
	};

	return (
		<div className='mb-5 pb-5'>
			<Loader />

			<NavBar
				selectedSizeGroup={boxBookingContext.bookingMeta.selectedSizeGroup}
				selectedSizeCode={selectedSizeCode}
			/>

			{
				false &&
				<div className='container mt-4 mb-4 pt-3 px-5 text-center' style={{
					border: '1px solid #ffd200',
				}}>
					<h4>Информируем Вас о режиме работы центра хранения в праздничные дни:</h4>
					<p> 1 и 9 мая 2024 г. – выходные дни.</p>
				</div>
			}

			<div  id="stocks_map" className="container card  mt-3" style={{ minHeight: '210px' }}>
				<div className="mb-2" style={{ fontSize: '20px', fontWeight: 'bold' }}>
					{props.languageSchema.BoxBooking.step1SelectTheStock}
				</div>
				{/* FOR NOW WE HAVE ONLY ONE STOCK */}
				{/* <div>
					<StockSelector
						stock={boxBookingContext.bookingMeta.selectedStock}
						languageSchema={props.languageSchema}
						onChange={handleSetStock}
						stocks={stocks}
					/>
				</div> */}

				{
					stocks.length > 0 && boxBookingContext.bookingMeta.selectedStockId &&
					<StockSelectOption stock={stocks.find((e) => e.stockId_sm === boxBookingContext.bookingMeta.selectedStockId)} />
				}

				{
					boxBookingContext.bookingMeta.selectedStockId &&
					<>
						<SizeGroups
							selectedStockId={boxBookingContext.bookingMeta.selectedStockId}
							selectedSizeGroup={boxBookingContext.bookingMeta.selectedSizeGroup}
							setSelectedSizeGroup={boxBookingContext.setSizeGroup}
							fetchedBoxes={fetchedBoxes}
						/>
						<div id='boxes-list'></div>
					</>
				}

				{/* {
					selectedSizeGroup &&
					<SizeCodes
						fetchedBoxes={fetchedBoxes}
						selectedSizeCode={selectedSizeCode}
						setSelectedSizeCode={setSelectedSizeCode}
						selectedSizeGroup={selectedSizeGroup}
					/>
				} */}

				{
					boxBookingContext.bookingMeta.selectedSizeGroup &&
					<BoxesList
						selectedSizeGroup={boxBookingContext.bookingMeta.selectedSizeGroup}
						fetchedBoxes={fetchedBoxes}
					/>
				}
			</div>

			<SideBar
				selectedBox={boxBookingContext.bookingMeta.selectedBox}
				selectedStockId={boxBookingContext.bookingMeta.selectedStockId}
				isOpened={boxBookingContext.bookingMeta.isSidebarOpened}
				close={closeSideBar}
				openSignUpModal={openSignUpModal}
			/>

			{
				isSignUpModalOpened &&
				<SignUpModal
					isVisible={isSignUpModalOpened}
					onSignUpCompleted={handleSignUpCompleted}
					onCancel={closeSignUpModal}
					onLogInAction={openLogInModal}
				/>
			}

			{
				isLogInModalOpened &&
				<LogInModal
					isVisible={isLogInModalOpened}
					onLogInCompleted={handleLogInCompleted}
					onCancel={closeLogInModal}
					onSignUpAction={openSignUpModal}
				/>
			}
		</div>
	);
}

const mapStateToProps = state => ({
	language: state.common.language,
	languageSchema: getLanguageSchema(state.common.language),
	currentUser: state.auth.me
});

const mapDispatchToProps = {
	setCurrentUserDetails
};

const BoxBookingWithConnect = connect(mapStateToProps, mapDispatchToProps)(BoxBookingNew);

export default () => {
	return (
		<BoxBookingContextProvider>
			<BoxBookingWithConnect />
		</BoxBookingContextProvider>
	);
};
