import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { getRoleNameFromToken } from 'utils/functions';

import SuccessUI from '../Success/SuccessUI';


const mapStateToProps = ({ common }) => {
	const languageSchema = getLanguageSchema(common.language);
	const currentUserRole = getRoleNameFromToken();
	const isSuperUser = currentUserRole === 'super';

	return {
		title: languageSchema.BoxBooking.successTitle,
		text: languageSchema.BoxBooking.successText,
		link: isSuperUser ? languageSchema.HomePage.toHome : languageSchema.Payment.myBoxes,
	};
};

export default connect(mapStateToProps)(withRouter(SuccessUI));
