import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'antd';

import { setCurrentUserDetails } from 'redux/actions';
import request from 'helpers/request';
import SignUpForm from 'components/SignUp/Form';
import { getLanguageSchema } from 'dashboard-commons/languages';

function SignUpPage(props) {
	let { languageSchema, isVisible, onCancel, onLogInAction, language } = props;

	let [accountType, setAccountType] = useState(null);
	let [loading, setLoading] = useState(false);

	let handleSubmitUserForm = async (values) => {
		setLoading(true);
		try {
			let signUpResult = await request('POST', 'auth/sign-up', {
				...values,
				language: language,
				isAdvertisingAgree: true,
			});

			props.setCurrentUserDetails(signUpResult.token);

			toastr.success(
				languageSchema.SignUp.youHaveRegistered,
				languageSchema.SignUp.checkEmail(values.email),
				{ timeOut: 9000 }
			);
			props.onSignUpCompleted(signUpResult);
		} catch (error) {
			console.log(error.message || error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal visible={isVisible} footer={null} onCancel={onCancel}>
			<SignUpForm
				languageSchema={languageSchema}
				language={language}
				setAccountType={setAccountType}
				accountType={accountType}
				onLogInAction={onLogInAction}
				onSubmit={handleSubmitUserForm}
				loading={loading}
			/>
		</Modal>
	);
}

const mapStateToProps = (state) => ({
	languageSchema: getLanguageSchema(state.common.language),
	language: state.common.language,
});

const mapDispatchToProps = {
	setCurrentUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpPage));
