import React from 'react';

import Table from 'components/UI/Table';
import Heading from 'components/UI/Heading';
import NoItems from 'components/common/NoItems';

import ListItem from './ListItem';


class EndingContractsList extends React.PureComponent {
	renderList = endingContracts => (
		<Table
			centerHeader
			header={[
				this.props.languageSchema.Stock.stock,
				this.props.languageSchema.Contract.contractNumber,
				this.props.languageSchema.User.client,
				this.props.languageSchema.Contract.contractEndingDate,
				this.props.languageSchema.Contract.planningLeavingDate,
			]}
		>
			{endingContracts.map(contract =>
				<ListItem
					key={contract._id}
					contract={contract}
					languageSchema={this.props.languageSchema}
				/>
			)}
		</Table>
	);

	render() {
		let { languageSchema, endingContracts } = this.props;
		let isDataToDisplayPresented = 0 < endingContracts.length;

		return (
			<div className="card">
				<Heading>{languageSchema.Contract.endingContracts}</Heading>
				<div className="mb-3" style={{ fontSize: '14px' }}>
					{
						isDataToDisplayPresented ?
							this.renderList(endingContracts) :
							<div className="center"><NoItems isLogo={false}/></div>
					}
				</div>
			</div>
		);
	}
}

export default EndingContractsList;
