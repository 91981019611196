export default {
	create: 'Create',
	edit: 'Edit',
	type: 'Type',
	title: 'Title',
	description: 'Description',
	isActive: 'Active status',
	forAllUsers: 'For all users',
	editor: 'Last edited by',
	active: 'Active',
	all: 'All',
	disactive: 'Disactive',
	global: 'Global',
	local: 'For authorized users',
	titleEN: 'English title',
	titleDE: 'German title',
	descriptionEN: 'English description',
	descriptionDE: 'German description',
	warning: 'Warning',
	success: 'Success',
	info: 'Info',
	danger: 'Danger',
	boxLeavedOpened: 'Box leaved opened!',
	boxLeavedOpenedDescription: 'is open more than 20 minutes! Please, check'
};
