import React, { Component } from 'react';

import { apiStartLoading, apiEndLoading, setError } from 'redux/actions/common';
import Heading from 'components/UI/Heading';
import { getLanguageSchema } from 'dashboard-commons/languages';
import { connect } from 'react-redux';
import request from 'helpers/request';
import { round } from 'helpers/amount';
import InvoiceListItem from 'components/Contract/InvoiceListItem';
import Loader from 'components/common/OverlayLoader';
import { ACCOUNT_TYPES_MAP, ROLES_MAP } from 'utils/constants';

class InvoicesList extends Component {
	state = {
		areInvoicesLoaded: false,
		invoices: [],
		paymentProcessor: null,
		isAutomaticPayments: false,
		paymentResponse: null,
		sendedNotifications: []
	};

	async componentDidMount() {
		const ADMIN_ROLES = ['super', 'admin'];


		await this.fetchInvoices()
			.then(invoices => {
				let isUserAdmin = this.props.user.role && ADMIN_ROLES.includes(this.props.user.role);

				if (isUserAdmin) {
					let invoicesIds = invoices.map(i => i.DocumentId || i.DocumentID);

					return this.fetchSendedInvoceEmailings(invoicesIds);
				}
			});
	}

	fetchInvoices = async () => {
		let { contract } = this.props;

		let invoices = await request('GET', `contracts/${contract.contractId_sm}/invoices`);

		this.setState({ invoices, areInvoicesLoaded: true });

		return invoices;
	}

	fetchSendedInvoceEmailings = async (invoicesIds) => {
		let sendedNotifications = await request('GET', 'notifications/invoices-emailing', {
			userId: this.props.contract.userId._id,
			invoicesIds
		});

		this.setState({ sendedNotifications });
	}

	togglePaymentProcessor = (paymentProcessor) => this.setState({ paymentProcessor });

	toggleAutomaticPayments = () => this.setState({ isAutomaticPayments: !this.state.isAutomaticPayments });

	handlePayButton = async (paymentParams) => {
		try {
			this.props.apiStartLoading();

			let result = await request('POST', 'payments', paymentParams);

			if (result.paymentData) {
				this.setState({ paymentResponse: result.paymentData });
			} else {
				if (result.redirectionUrl) {
					window.location = result.redirectionUrl;
				}
			}
		} catch (e) {
			this.props.setError(e.message);
		} finally {
			this.props.apiEndLoading();
		}
	}

	render() {
		let { languageSchema, language, user, contract } = this.props;
		let { invoices, areInvoicesLoaded, sendedNotifications } = this.state;

		let ADMIN_ROLES = [ROLES_MAP.superUser];
		let isUserAdmin = user.role && ADMIN_ROLES.includes(user.role);
		let filteredInvoices = isUserAdmin ? invoices : invoices.filter(({ DocumentType }) => DocumentType === 'Invoice');
		let vatRate = user.accountType === ACCOUNT_TYPES_MAP.legal ? contract.VATRate : 20;

		return (
			<div className="card">
				<Heading className="mb-3 d-flex justify-content-between align-items-start">
					{languageSchema.Contract.invoices}
				</Heading>

				{
					!areInvoicesLoaded &&
					<div className="text-center">
						<i className="fas fa-2x fa-spinner fa-spin"></i>
					</div>
				}

				<Loader />

				{(contract.contractId_sm && filteredInvoices.length > 0) &&
					<div className="accordion" id="accordionExample">
						{filteredInvoices.map((invoice) =>
							<InvoiceListItem
								fetchInvoices={this.fetchInvoices}
								manualPay={() => this.handlePayButton({
									userId: contract.userId._id,
									DocumentID: invoice.DocumentId || invoice.DocumentID,
									boxId: contract?.boxId?._id,
									amount: round(invoice.debt - (invoice.VATAmount || 0)),
									totalAmount: invoice.debt,
									vatAmount: invoice.VATAmount || 0,
									discountAmount: 0,
									discountPercent: 0,
									currency: contract.CurrencyCode,
									vatRate,
									processor: 'manual',
								})}
								key={invoice.DocumentId || invoice.DocumentID}
								stockId={contract.stockId}
								contract={contract}
								boxNumber={contract.UnitNumber}
								languageSchema={languageSchema}
								currency={contract.CurrencyCode}
								invoice={invoice}
								language={language}
								isSuperUser={isUserAdmin}
								payment={invoices.find(({ InvoiceID, DocumentType }) => InvoiceID === invoice.InvoiceID && DocumentType === 'Payment')}
								invoiceEmailSending={
									sendedNotifications.find(n => n.invoiceId_sm === (invoice.DocumentId || invoice.DocumentID))
								}
								sendInvoiceEmailCallback={this.fetchSendedInvoceEmailings}
								handlePayButton={this.handlePayButton}
							/>
						)}
					</div>
				}
			</div>
		);
	}
}

let mapStateToProps = (state) => ({
	user: state.auth.me,
	language: state.common.language,
	languageSchema: getLanguageSchema(state.common.language)
});

let mapDispatchToProps = {
	apiStartLoading,
	apiEndLoading,
	setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesList);
