import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import Heading from 'components/UI/Heading';
import { getPrettyLockStatus, formatDateFromUSAToEuroFormat } from 'utils/functions';
import request from 'helpers/request';
import { displayAmount } from 'helpers/amount';
import { getLanguageSchema } from 'dashboard-commons/languages';
import BoxHistory from 'components/Box/History';
import InvoicesList from 'components/Contract/InvoicesList';
import { apiStartLoading, apiEndLoading, setError } from 'redux/actions/common';
import { ACCOUNT_TYPES_MAP, ROLES_MAP } from 'utils/constants';
import DocsAgreement from 'components/MyBoxes/DocsAgreement';

class ContractPage extends Component {
	state = {
		contract: {},
		user: {},
		isDataLoaded: false,
		invoices: [],
		paymentProcessor: null,
		paymentResponse: null,
	};

	componentDidMount() {
		const { match: { params: { contractId, userId } } } = this.props;

		Promise.all([
			request('GET', `contracts/${contractId}`),
			request('GET', `users/${userId}`)
		])
			.then(([currentContract, user]) => {
				this.setState({
					contract: currentContract,
					user,
					isDataLoaded: true
				});
			})
			.catch(console.error);
	}

	render() {
		let { user } = this.state;
		let { languageSchema, boxHistory, box, currentUser } = this.props;
		let boxId = this.props.location.state && this.props.location.state.boxId;

		let { contract, isDataLoaded } = this.state;

		let lockStatus, status;

		let isUserAdmin = currentUser.role && [ROLES_MAP.superUser].includes(currentUser.role);
		let isCurrentUserOwner = contract.internalUserId && contract.internalUserId === currentUser._id;

		if (contract.internalUserId && currentUser._id && !isUserAdmin && !isCurrentUserOwner) {
			return <Redirect to="/" />;
		}

		if (boxId && box) {
			lockStatus = box.lockStatus;
			status = getPrettyLockStatus(lockStatus, languageSchema);
		}

		let vatAmount = contract.vatAmount || 0;
		let totalToPayPerPeriod = contract.totalToPayPerPeriod || 0;
		let vatRate = user.accountType === ACCOUNT_TYPES_MAP.legal ? contract.VATRate : 20;

		let [invoicingFrequencyLabel, monthWord] = languageSchema.Contract.paymentsFrequency.split('|');

		return (
			<div className="row py-3">
				<div className='col-md-8 offset-md-2'>
					{boxId && box &&
						<div className="card">
							<Heading className="mb-3 d-flex justify-content-between align-items-start">
								<span>{box.UnitNumber} {box.size} м²</span>
							</Heading>

							<table className="table table-borderless">
								<tbody>
									<tr>
										<th scope="row" width="30%">{languageSchema.Stock.lockStatus}</th>
										<td className={`font-${status.color}`}>{status.title}</td>
										<td className="text-right">
											{isUserAdmin &&
												<button className="btn btn-light btn-sm" onClick={this.handleClickToOpen}>
													{languageSchema.Buttons.open}
												</button>}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					}

					<div className="card">
						<Heading className="mb-3 d-flex justify-content-between align-items-start">
							{languageSchema.Contract.contractDetails}
						</Heading>
						{
							!isDataLoaded ?
								<div className="text-center">
									<i className="fas fa-2x fa-spinner fa-spin"></i>
								</div> :
								<div>
									<TextLine
										left={languageSchema.User.client}
										right={<Link to={`/users/${user._id}`}> {contract.userId.firstName} {contract.userId.lastName} </Link>}
									/>
									<TextLine
										left={languageSchema.Contract.contractNumber}
										right={`#${contract.ContractNumber}`}
									/>
									<TextLine
										left={languageSchema.Contract.startDate}
										right={`${contract.StartDate.includes('/') ? formatDateFromUSAToEuroFormat(contract.StartDate) : contract.StartDate}`}
									/>
									<TextLine
										left={languageSchema.Contract.closingDate}
										right={`${contract.EndDate.includes('/') ? formatDateFromUSAToEuroFormat(contract.EndDate) : contract.EndDate}`}
									/>
									<TextLine
										left={languageSchema.Stock.boxNumber}
										right={contract.boxId ?
											<Link to={`/boxes/${contract.boxId._id}`}>{contract.UnitNumber}</Link> :
											contract.UnitNumber
										}
									/>
									<TextLine
										left={languageSchema.Contract.square}
										right={`${contract.PhysicalSize}${languageSchema.Etc.metersShortLetter}²`}
									/>
									{totalToPayPerPeriod !== 0 && <TextLine
										left={languageSchema.Contract.periodCost}
										right={`${displayAmount(totalToPayPerPeriod, contract.CurrencyCode)}`}
									/>}
									{totalToPayPerPeriod !== 0 && vatAmount !== 0 && <TextLine
										left={`${languageSchema.Contract.inclVAT}(${vatRate}%)`}
										right={
											<div style={{ fontSize: 16 }}>
												{displayAmount(vatAmount, contract.CurrencyCode)}
											</div>
										}
									/>}
									<TextLine
										left={invoicingFrequencyLabel}
										right={`${contract.InvoiceFrequency} ${monthWord}`}
									/>
									{/* FIXME: Download Contract Pdf-file button disabled
									<div className="text-center">
										<a
											className="btn btn-success mt-4"
											rel="noopener noreferrer"
											target="_blank"
											href={this.getContractInPdfUrl()}
										>
											{languageSchema.Etc.download}
										</a>
									</div>
									*/}
									{
										currentUser._id === user._id &&
										<DocsAgreement
											user={user}
											languageSchema={languageSchema}
											contractId={contract.ContractID}
										/>
									}
								</div>
						}
					</div>

					{
						contract && contract.contractId_sm &&
						<>
							<InvoicesList contract={contract} />
						</>
					}

					{
						boxId && box &&
						<div>
							<div className="card">
								<Heading>	{languageSchema.Stock.history} </Heading>
								<BoxHistory history={boxHistory} type="box" />
							</div>
						</div>
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, common, boxes: { current: box, boxHistory }, stocks, boxcats }) => {
	const stocksWithCities = stocks.data.map(stock => stock.cityId
		? { ...stock, city: stock.cityId.city, country: stock.cityId.country }
		: stock
	);

	let currentStock = {};
	if (box) {
		currentStock = stocksWithCities.find(stock => stock._id === box.stockId);
	}

	return {
		currentUser: auth.me,
		box,
		currentStock: currentStock || {},
		stocks: { data: stocksWithCities },
		boxcats,
		boxHistory,
		language: common.language,
		languageSchema: getLanguageSchema(common.language),
	};
};

const mapDispatchToProps = {
	apiStartLoading,
	apiEndLoading,
	setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractPage);

function TextLine({ left, right }) {
	return (
		<div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
			<div style={{ fontSize: 16, }}>
				{left}
			</div>
			<div style={{ fontSize: 18, fontWeight: '600', textAlign: 'right' }}>
				{right}
			</div>
		</div>
	);
}
