import React from 'react';
import ReactPaginate from 'react-paginate';

import './Pagination.scss';

const Pagination = ({ selected, pageCount, onPageChange }) => {
	return (
		<div>
			<ReactPaginate
				previousLabel={'<'}
				nextLabel={'>'}
				breakLabel={'...'}
				pageCount={pageCount}
				marginPagesDisplayed={2}
				pageRangeDisplayed={5}
				onPageChange={onPageChange}
				pageClassName={'page-item'}
				previousClassName={'page-item'}
				nextClassName={'page-item'}
				breakClassName={'page-item break-me'}
				pageLinkClassName={'page-link'}
				previousLinkClassName={'page-link'}
				nextLinkClassName={'page-link'}
				breakLinkClassName={'page-link'}
				containerClassName={'pagination mb-0'}
				subContainerClassName={'pages pagination'}
				activeClassName={'active'}
				forcePage={selected}
			/>
		</div>
	);
};

Pagination.defaultProps = {
	pageCount: 1,
	onPageChange: null,
};

export default Pagination;