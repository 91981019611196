import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { getLanguageSchema } from 'dashboard-commons/languages';
import logo from 'assets/images/logo_blue_text.png';


import BoxBookingContext from 'screens/BoxBookingNew/Context';
import LanguagePicker from 'components/common/LanguagePicker';

import { Steps } from 'antd';

const NavBar = (props) => {
	const { selectedSizeGroup, selectedSizeCode, languageSchema } = props;
	const [current, setCurrent] = useState(0);
	const boxBookingContext = React.useContext(BoxBookingContext);

	useEffect(() => {
		if (!_.isEmpty(boxBookingContext.bookingMeta.selectedStockId)){
			setCurrent(1);
		}
		if (!_.isEmpty(boxBookingContext.bookingMeta.selectedBox)) {
			setCurrent(2);
		}
	}, [
		boxBookingContext.bookingMeta.selectedStockId,
		selectedSizeGroup,
		selectedSizeCode,
		boxBookingContext.bookingMeta.selectedBox
	]);

	const { Step } = Steps;

	return (
		<nav id="navbar_top" className="navbar navbar-expand-lg sticky-top ">
			<div className="container">
				<Link className="navbar-brand" to="/">
					<img src={logo} alt="Logo" style={{width: 200}} />
				</Link>

				<button
					className="navbar-toggler collapsed"
					type="button" data-bs-toggle="collapse"
					data-bs-target="#main_nav"
					aria-expanded="false" aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon" />
				</button>

				<div
					className="navbar-collapse collapse flex-wrap mt-2" id="main_nav"
					style={{ marginLeft: '0.8rem' }}
				>
					<ul className="navbar-nav flex-wrap" style={{ display: 'flex', justifyContent: 'center' }}>
						<Steps current={current}>
							<Step title={languageSchema.BoxBooking.selectStock} />
							<Step title={languageSchema.BoxBooking.selectBoxSize} />
							<Step title={languageSchema.BoxBooking.enterDataAndPayment} />
						</Steps>
					</ul>

					<div className="nav-link fw-normal text-sm reset-anchor flex-center flex-vertical-center">
						<div> Нужна помощь? </div>
						<div><a href="tel:+7 (495) 721-23-45">+7 (495) 721-23-45</a></div>
					</div>
				</div> {/* navbar-collapse.// */}
			</div> {/* container-fluid.// */}

			<div>
				<LanguagePicker dark />
			</div>
		</nav>
	);
};


const mapStateToProps = ({
	common
}) => ({
	languageSchema: getLanguageSchema(common.language),
});

export default connect(mapStateToProps)(NavBar);
