import React, { useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import Select from 'react-select';
import { connect } from 'react-redux';

import { getLanguageSchema } from 'dashboard-commons/languages';
import Field from 'components/UI/Form/Field';
import Checkbox from 'components/UI/Form/Checkbox';

import validationSchema from './validation';

let BtnWithModal = (props) => {
	let {
		initialValues, languageSchema, action, user, submitCallback
	} = props;

	let btnClassMap = {
		'create': 'success',
		'edit': 'outline-secondary btn-sm'
	};

	const TYPES = ['warning', 'success', 'info', 'danger'];
	let typeOptions = TYPES.map(type => ({ label: languageSchema.Notification[type], value: type }));

	let [isModalOpened, setModalState] = useState(false);

	let toggleModal = useCallback(() => setModalState(!isModalOpened), [isModalOpened]);

	let handleSubmit = async (values) => {
		try {
			let data = {
				...values,
				userId: user._id,
			};
			let requestParams = [];

			if (action === 'create') {
				requestParams = [data];
			} else {
				requestParams = [initialValues._id, data];
			}

			await submitCallback(...requestParams);

			toggleModal();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<>
			<button className={`btn btn-${btnClassMap[action]}`} type="button" onClick={toggleModal}>
				{languageSchema.Notification[action]}
			</button>
			<Modal
				show={isModalOpened}
				onHide={toggleModal}
			>
				<Formik
					initialValues={initialValues || {
						type: '',
						userId: '',
						titleEN: '',
						titleDE: '',
						descriptionDE: '',
						descriptionEN: '',
						isActive: false,
						isGlobal: false,
					}}
					validationSchema={validationSchema(languageSchema)}
					onSubmit={handleSubmit}
					render={(props) => {
						let { errors, touched, handleSubmit, values, setFieldValue } = props;

						let onTypeChange = (option) => setFieldValue('type', option.value);

						return (
							<div>
								<Modal.Header closeButton>
									<Modal.Title>{`${languageSchema.Notification[action]} ${languageSchema.Notification.notification}`}</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Field
										name="type"
										label={languageSchema.Notification.type}
										type="select"
										component={Select}
										value={{ label: languageSchema.Notification[values.type], value: values.type }}
										options={typeOptions}
										errors={errors}
										touched={touched}
										onChange={onTypeChange}
									/>

									<Field
										name="titleEN"
										label={languageSchema.Notification.titleEN}
										type="text"
										value={values.titleEN}
										errors={errors}
										touched={touched}
									/>

									<Field
										name="titleDE"
										label={languageSchema.Notification.titleDE}
										type="text"
										value={values.titleDE}
										errors={errors}
										touched={touched}
									/>

									<Field
										name="descriptionEN"
										label={languageSchema.Notification.descriptionEN}
										component="textarea"
										numberOfLines={4}
										multiline
										value={values.descriptionEN}
										errors={errors}
										touched={touched}
									/>

									<Field
										name="descriptionDE"
										label={languageSchema.Notification.descriptionDE}
										component="textarea"
										numberOfLines={4}
										multiline
										value={values.descriptionDE}
										errors={errors}
										touched={touched}
									/>

									<Checkbox
										name="isActive"
										label={languageSchema.Notification.isActive}
										type="text"
										checked={values.isActive}
										errors={errors}
										touched={touched}
									/>

									<Checkbox
										name="isGlobal"
										label={languageSchema.Notification.forAllUsers}
										type="text"
										checked={values.isGlobal}
										errors={errors}
										touched={touched}
									/>

									<div className="mt-3 text-left">
										<button
											onClick={handleSubmit}
											type="submit"
											className="btn btn-sm btn-secondary mr-3"
										>
											{languageSchema.Buttons[action === 'create' ? 'create' : 'save']}
										</button>
										<button
											className="btn btn-sm btn-outline-secondary mr-3"
											onClick={toggleModal}
										>
											{languageSchema.Buttons.close}
										</button>
									</div>
								</Modal.Body>
							</div>
						);
					}}
				/>
			</Modal>
		</>
	);
};

let mapStateToProps = (state) => ({
	user: state.auth.me,
	languageSchema: getLanguageSchema(state.common.language)
});

export default connect(mapStateToProps)(BtnWithModal);
