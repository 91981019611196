export default [
	{
		alias: 'cities',
		href: '/cities',
		permissions: ['superUser']
	},
	{
		alias: 'stocks',
		href: '/stocks',
		permissions: ['superUser', 'staff']
	},
	// {
	//	 alias: 'Клиенты',
	//	 href: '/clients',
	//	 isDisabled: true,
	//	 permissions: ['manager', 'admin', 'super']
	// },
	{
		alias: 'accounts',
		href: '/users',
		permissions: ['superUser']
	},
	{
		alias: 'reports',
		href: '/reports',
		isDisabled: true,
		permissions: ['superUser']
	},
	{
		alias: 'emailHistory',
		href: '/email-history',
		permissions: ['superUser']
	},
	{
		alias: 'myBoxes',
		href: '/my-boxes',
		permissions: ['client'],
		icon: 'cubes'
	},
	{
		alias: 'support',
		href: '/support',
		permissions: ['client'],
		icon: 'headset',
	},
	//{
	//	alias: 'appeals',
	//	href: '/appeal',
	//	permissions: ['manager', 'admin', 'super'],
	//},
	{
		alias: 'notifications',
		href: '/notifications',
		permissions: ['superUser']
	},
	//{
	//	alias: 'monthlyBills',
	//	href: '/monthly-bills',
	//	permissions: ['manager', 'admin', 'super'],
	//},
	{
		alias: 'externalPayments',
		href: '/external-payments',
		permissions: ['superUser']
	},
	{
		alias: 'usersImport',
		href: '/users-import',
		permissions: ['superUser']
	},
	{
		alias: 'book',
		href: '/book',
		permissions: ['superUser', 'client'],
		icon: 'warehouse',
	},

];
