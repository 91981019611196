import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';

import auth from './auth';
import common from './common';
import users from './users';
import cities from './cities';
import stocks from './stocks';
import boxes from './boxes';
import boxcats from './boxcats';
import boxbooking from './boxbooking';
import contact from './contact';
import cache from './cache';
import notifications from './notifications';
import iqacdocuments from './iqacdocuments';
import payments from './payments';

export default combineReducers({
	auth,
	common,
	users,
	cities,
	stocks,
	boxes,
	boxcats,
	boxbooking,
	toastr,
	appeals: contact,
	cache,
	notifications,
	iqacdocuments,
	payments,
});
