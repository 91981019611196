import cities from './cities.jpg';
import stocks from './stocks.jpg';
import users from './users.jpg';

export default [
	{
		alias: 'cities',
		link: '/cities',
		image: cities,
	},
	{
		alias: 'stocks',
		link: '/stocks',
		image: stocks,
	},
	{
		alias: 'accounts',
		link: '/users',
		image: users,
	},

];
