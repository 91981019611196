import React, { Component } from 'react';
import Heading from 'components/UI/Heading';
import request from 'helpers/request';
import ToggleBlock from 'components/common/InfoToggler';

export default class FeeForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			invoiceNotPaidFeePercent: props.stock.invoiceNotPaidFeePercent || 0,
			daysWithoutFee: props.stock.daysWithoutFee || 0,
			contractEndedFee: props.stock.contractEndedFee || 0,
			isLoading: false,
		};
	}

	applyFee = async () => {
		this.setState({ isLoading: true });
		try {
			await request('PUT', `stocks/${this.props.stock._id}`, {
				invoiceNotPaidFeePercent: this.state.invoiceNotPaidFeePercent,
				daysWithoutFee: this.state.daysWithoutFee,
				contractEndedFee: this.state.contractEndedFee,
			});

			this.setState({ isLoading: false });
		} catch(error) {
			this.setState({ isLoading: false });
		}
	}

	handleChangeField = fieldName => e => this.setState({ [fieldName]: e.target.value})

	render() {
		let { languageSchema, isSuperUser } = this.props;
		return (
			<div className="row">
				<div className="col-md-12">
					<div className="relative flex">
						<Heading>{languageSchema.Stock.feeSettings}</Heading>

						<div className="mb-3">
							<ToggleBlock
								text={[languageSchema.Stock.feeInfoBlock]}
								alertType="info"
							/>
						</div>
					</div>

					<div className="form-group row mb-3" style={{ marginBottom: 0 }}>
						<label htmlFor="pricePerMeterQuadratic" className="col-sm-5 col-form-label">
							{languageSchema.Stock.dailyFeeDescription}:
						</label>
						<div className="col-sm-5">
							<div className="input-group">
								<input
									name="invoiceNotPaidFeePercent"
									type="number"
									className="form-control"
									value={this.state.invoiceNotPaidFeePercent}
									disabled={!isSuperUser}
									onChange={this.handleChangeField('invoiceNotPaidFeePercent')}
								/>
								<div className="input-group-append"><span className="input-group-text">%</span></div>
							</div>
						</div>
					</div>

					<div className="form-group row mb-3">
						<label htmlFor="pricePerMeterQuadratic" className="col-sm-5 col-form-label">
							{languageSchema.Stock.freeFeeDays}:
						</label>
						<div className="col-sm-5">
							<div className="input-group mb-3">
								<input
									name="daysWithoutFee"
									type="number"
									className="form-control"
									value={this.state.daysWithoutFee}
									disabled={!isSuperUser}
									onChange={this.handleChangeField('daysWithoutFee')}
								/>
								<div className="input-group-append">
									<span className="input-group-text">
										{languageSchema.Stock.days}
									</span>
								</div>
							</div>
						</div>
					</div>

					<hr />

					<div className="form-group row">
						<label htmlFor="pricePerMeterQuadratic" className="col-sm-5 col-form-label">
							{languageSchema.Stock.contractEndedFeeLabel}:
						</label>
						<div className="col-sm-5">
							<div className="input-group mb-3">
								<input
									name="contractEndedFee"
									type="number"
									className="form-control"
									value={this.state.contractEndedFee}
									disabled={!isSuperUser}
									onChange={this.handleChangeField('contractEndedFee')}
								/>
								<div className="input-group-append"><span className="input-group-text">%</span></div>
							</div>
						</div>
					</div>

					<div style={{ textAlign: 'center' }}>
						<button
							className="btn btn-success"
							onClick={this.applyFee}
							disabled={!isSuperUser}
						>
							{this.state.isLoading && <span className="spinner-border spinner-border-sm"> </span>}
							<span> {languageSchema.Buttons.apply} </span>
						</button>
					</div>

				</div>
			</div>
		);
	}
}
