import React from 'react';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { Field, Form, FieldArray } from 'formik';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import FormField from 'components/UI/Form/Field';
import FormCheckBox from 'components/UI/Form/Checkbox';
import { CURRENCIES_MAP, DATE_FORMAT } from 'utils/constants';
import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

function StockForm(props) {
	const {
		type, cities, isSuccess, values, touched, isSuperUser, setFieldValue,
		errors, isSubmitting, languageSchema, handleClickDelete, language: lang,
	} = props;

	let language = lang.toLowerCase();

	let getCitiesSelectOptions = () =>
		cities.map(el => ({ value: el._id, label: `${el.city} (${countries.getName(el.country, language)})` }));

	let getCitiesSelectOptionsMap = () =>
		getCitiesSelectOptions(languageSchema)
			.reduce(((acc, curr) => ({ ...acc, [curr.value]: curr })), {});

	let citiesSelectOptionsMap = getCitiesSelectOptionsMap();

	let getCurrenciesSeleOptions = () =>
		Object.values(CURRENCIES_MAP).map(el => ({ value: el, label: el }));

	let getCurrenciesSelectOptionsMap = () =>
		getCurrenciesSeleOptions(languageSchema)
			.reduce(((acc, curr) => ({ ...acc, [curr.value]: curr })), {});

	let currenciesSelectOptionsMap = getCurrenciesSelectOptionsMap();

	let isEdit = type === 'edit';
	let buttonText = isEdit ? languageSchema.Buttons.save : languageSchema.Buttons.create;

	let { isExternal } = values;

	return (
		<Form>
			<FormField
				label={languageSchema.Stock.name}
				name="name"
				value={values['name']}
				errors={errors}
				touched={touched}
			/>

			<FormField
				label={languageSchema.Stock.city}
				component={Select}
				name="cityId"
				value={citiesSelectOptionsMap[values.cityId]}
				errors={errors}
				touched={touched}
				readOnly={isExternal}
				options={getCitiesSelectOptions()}
				onChange={(city) => setFieldValue('cityId', city.value)}
				placeholder={languageSchema.Etc.choose}
			/>

			<FormField
				label={languageSchema.Stock.address}
				name="address" value={values['address']}
				errors={errors} touched={touched} readOnly={isExternal}
			/>

			<FormField
				label={languageSchema.Stock.phone}
				name="phone" value={values['phone']}
				placeholder="+431231231231"
				errors={errors} touched={touched} readOnly={isExternal}
			/>

			<FormField
				label={languageSchema.Stock.zip}
				name="zip" value={values['zip']}
				errors={errors} touched={touched} readOnly={isExternal}
			/>

			<FormCheckBox
				label={languageSchema.Stock.hasBoxes}
				name="hasBoxes" checked={values['hasBoxes']}
				errors={errors} touched={touched} readOnly={isExternal}
			/>

			<FormField
				label={languageSchema.Stock.currency} component={Select}
				name="currency" value={currenciesSelectOptionsMap[values.currency]}
				errors={errors} touched={touched} readOnly={isExternal}
				options={getCurrenciesSeleOptions()}
				onChange={(currency) => setFieldValue('currency', currency.value)}
				placeholder={languageSchema.Etc.choose}
			/>

			<FormField
				label={languageSchema.Stock.maxContractDuration}
				name="maxContractDuration"
				value={values['maxContractDuration']}
				errors={errors}
				touched={touched}
			/>

			<FormField
				label={languageSchema.Stock.stockId_sm}
				name="stockId_sm"
				value={values.stockId_sm}
				errors={errors}
				touched={touched}
			/>

			<hr />
			<FormField
				label={languageSchema.Stock.wifiNetworkName}
				name="wifiNetworkName"
				value={values['wifiNetworkName']}
				errors={errors}
				touched={touched}
			/>
			<FormField
				label={languageSchema.Stock.wifiPassword}
				name="wifiPassword"
				value={values['wifiPassword']}
				errors={errors}
				touched={touched}
			/>
			<hr />

			<FormField
				label={languageSchema.Stock.routerIp}
				name="routerIp"
				value={values['routerIp']}
				errors={errors}
				touched={touched}
				disabled={!isSuperUser}
			/>
			<FormField
				label={languageSchema.Stock.locksServerPort}
				name="servicePort"
				value={values['servicePort']}
				errors={errors}
				touched={touched}
				disabled={!isSuperUser}
			/>

			<FormField
				label={languageSchema.Stock.offlineAppUrl}
				name="offlineAppUrl"
				value={values['offlineAppUrl']}
				errors={errors}
				touched={touched}
				disabled={!isSuperUser}
			/>

			<FormField
				label={languageSchema.Stock.databaseServer}
				name="dbServer"
				value={values['dbServer']}
				errors={errors}
				touched={touched}
				disabled={!isSuperUser}
			/>
			<FormField
				label={languageSchema.Stock.databasePort}
				name="dbPort"
				value={values['dbPort']}
				errors={errors}
				touched={touched}
				disabled={!isSuperUser}
			/>
			<FormField
				label={languageSchema.Stock.databaseName}
				name="dbName"
				value={values['dbName']}
				errors={errors}
				touched={touched}
				disabled={!isSuperUser}
			/>
			<FormField
				label={languageSchema.Stock.databaseUser}
				name="dbUsername"
				value={values['dbUsername']}
				errors={errors}
				touched={touched}
				disabled={!isSuperUser}
			/>
			<FormField
				label={languageSchema.Stock.databasePassword}
				name="dbPassword"
				value={values['dbPassword']}
				errors={errors}
				touched={touched}
				type="password"
				disabled={!isSuperUser}
			/>

			<FieldArray
				name="internalEquipments"
				render={arrayHelpers => (
					<div>
						{values.internalEquipments.map((el, index) => (
							<div key={index} className="form-group row row-small">
								<div className="col-sm-4 mb-2">
									<Field
										type="text"
										errors={errors}
										touched={touched}
										name={`internalEquipments.${index}.name`}
										value={values.internalEquipments[index].name}
										className="form-control form-control-sm"
										placeholder={languageSchema.Stock.name}
										disabled={!isSuperUser}
									/>
								</div>
								<div className="col-sm-4 mb-2">
									<Field
										as={MaskedInput}
										value={values.internalEquipments[index].id}
										errors={errors}
										touched={touched}
										name={`internalEquipments.${index}.id`}
										className="form-control form-control-sm"
										placeholder="_:__:__"
										mask={[/\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
										disabled={!isSuperUser}
									/>
								</div>
								<div className="col-sm-3 mb-2">
									<button
										type="button"
										className="btn btn-danger btn-sm"
										disabled={!isSuperUser}
										onClick={() => arrayHelpers.remove(index)}>
										<i className="fas fa-times" />
									</button>
								</div>
							</div>
						))}
						<button
							type="button"
							className="btn btn-outline-primary mb-3"
							disabled={!isSuperUser}
							onClick={() => arrayHelpers.push({ name: '', id: '' })}
						>
							<i className="fas fa-plus-circle" /> {languageSchema.Stock.addEquipment}
						</button>
					</div>
				)}
			/>
			{isEdit &&
				<div className="form-group row">
					<label htmlFor="created" className="col-sm-3 col-form-label">{languageSchema.Etc.createdAt}</label>
					<div className="col-sm-9">
						<input type="text" readOnly className="form-control-plaintext" id="created"
							value={values.created && moment(values.created).format(DATE_FORMAT)} />
					</div>
				</div>
			}
			<div className="row">
				<div className="col-auto">
					<button type="submit" className="btn btn-sm btn-secondary mr-3" disabled={isSubmitting}>
						{isSubmitting ? languageSchema.Etc.wait : buttonText}
					</button>
					<Link to="/stocks" className="btn btn-sm btn-outline-secondary mr-3">
						{languageSchema.Buttons.back}
					</Link>
					{isEdit &&
						<button type="button" className="btn btn-danger btn-sm" onClick={handleClickDelete} disabled={!isSuperUser}>
							{languageSchema.Buttons.delete}
						</button>
					}
				</div>
			</div>

			{isSuccess &&
				<div className="alert alert-success mt-3" role="alert">
					{languageSchema.Etc.success}
				</div>
			}
		</Form>
	);
}

export default StockForm;
