import React from 'react';
import { addDays } from 'date-fns';

import DatePicker from 'components/common/DatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import BoxBookingContext from 'screens/BoxBookingNew/Context/';

import './styles.css';

const today = new Date();
const maxDate = addDays(today, 29);

function ChoiceOfArrivalDate(props) {
	const boxBookingContext = React.useContext(BoxBookingContext);
	const { bookingMeta: { arrivalDate } } = boxBookingContext;

	let handleArrivalDateSelection = (arrivalDate) => {
		boxBookingContext.setArrivalDate(arrivalDate);
	};

	return (
		<div>
			<div style={{ fontSize: '20px', marginBottom: '1px' }}>{props.title}</div>
			<p style={{ fontSize: '1rem' }}>{props.text}?</p>
			<div className={`input-container ${props.isError && 'error'}`}>
				<DatePicker
					wrapperClassName="choice-of-arrival-date"
					language={props.language}
					selected={arrivalDate}
					minDate={props.today}
					maxDate={maxDate}
					onChange={handleArrivalDateSelection}
					placeholderText={props.placeholderText}
				/>
			</div>
			<hr />
		</div>
	);
}

export default ChoiceOfArrivalDate;
