export default {
	paymentSuccess: 'Zahlung erfolgreich',
	choosePaymentMethodString: 'Zahlungsmethode wählen',
	paymentFailed: 'Zahlung nicht erfolgreich',
	processorTransactionId: 'Transaktionsnummer',
	paymentDetails: 'Zahlungsdetails',
	paymentPending: 'Die Zahlung wird bearbeitet',
	paymentPendingDescription: 'Ihre Zahlung hat den Status: „Zahlung wird geprüft“. Dieser Status bedeutet, dass Ihre durchgeführte Zahlung von uns geprüft wird und anschließend als abgeschlossen oder fehlgeschlagen eingestuft wird.',
	price: 'Preis',
	cardHolder: 'Karteninhaber',
	truncatedCardNumber: 'Kartennummer',
	cycleBillingDay: 'Abbuchungsdatum',
	myBoxes: 'Mein Lagerraum',
	book: 'Buchung',
	tryPaymentLater: 'Oops, etwas ist schief gelaufen. Überprüfen Sie bitte Ihre Daten und probieren Sie es später noch einmal. ',
	errorReason: 'Fehlerbeschreibung',
	checkEmail: 'Bitte überprüfen Sie Ihre E-Mail, wir haben eine Bestätigung gesendet. Sie kann sich im Spam Ordner befinden.',
	includeVAT: 'inkl. MwSt',
	vat: 'MwSt',
	pay: 'Bezahlen',
	totalToPayString: 'Summe zu zahlen',
	threeDSecureRedirect: 'Sie können auf die 3-D Secure Verifizierungsseite weitergeleitet werden',
	confirmMandate: 'Mandat bestätigen und Abbuchung einleiten für',
	topBoxMandateDescription: 'Durch Angabe Ihrer Zahlungsinformationen und der Bestätigung der vorliegenden Zahlung ermächtigen Sie (A) Top Box GmbH und Stripe, unseren Zahlungsdienstleister, bzw. PPRO, den lokalen Bankdienstleister von Stripe, Ihrem Kreditinstitut Anweisungen zur Belastung Ihres Kontos zu erteilen, und (B) Ihr Kreditinstitut, Ihr Konto gemäß diesen Anweisungen zu belasten. Im Rahmen Ihrer Rechte haben Sie, entsprechend den Vertragsbedingungen mit Ihrem Kreditinstitut, Anspruch auf eine Rückerstattung von Ihrem Kreditinstitut. Eine Rückerstattung muss innerhalb von 8 Wochen ab dem Tag, an dem Ihr Konto belastet wurde, geltend gemacht werden. Eine Erläuterung Ihrer Rechte können Sie von Ihrem Kreditinstitut anfordern. Sie erklären sich einverstanden, Benachrichtigungen über künftige Belastungen bis spätestens 2 Tage vor dem Buchungsdatum zu erhalten.',
	rentaBoxMandateDescription: 'Durch Angabe Ihrer Zahlungsinformationen und der Bestätigung der vorliegenden Zahlung ermächtigen Sie (A) RentaBox24 GmbH und Stripe, unseren Zahlungsdienstleister, bzw. PPRO, den lokalen Bankdienstleister von Stripe, Ihrem Kreditinstitut Anweisungen zur Belastung Ihres Kontos zu erteilen, und (B) Ihr Kreditinstitut, Ihr Konto gemäß diesen Anweisungen zu belasten. Im Rahmen Ihrer Rechte haben Sie, entsprechend den Vertragsbedingungen mit Ihrem Kreditinstitut, Anspruch auf eine Rückerstattung von Ihrem Kreditinstitut. Eine Rückerstattung muss innerhalb von 8 Wochen ab dem Tag, an dem Ihr Konto belastet wurde, geltend gemacht werden. Eine Erläuterung Ihrer Rechte können Sie von Ihrem Kreditinstitut anfordern. Sie erklären sich einverstanden, Benachrichtigungen über künftige Belastungen bis spätestens 2 Tage vor dem Buchungsdatum zu erhalten.',
	errors: {
		authentication_required: 'Die Karte wurde abgelehnt, da für die Transaktion eine Authentifizierung erforderlich ist.',
		approve_with_id: 'Die Zahlung kann nicht autorisiert werden.',
		call_issuer: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		card_not_supported: 'Die Karte unterstützt diese Art des Kaufs nicht.',
		card_velocity_exceeded: 'Der Kunde hat das auf seiner Karte verfügbare Guthaben oder Kreditlimit überschritten.',
		currency_not_supported: 'Die Karte unterstützt die angegebene Währung nicht.',
		do_not_honor: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		do_not_try_again: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		duplicate_transaction: 'Kürzlich wurde eine Transaktion mit identischen Betrags- und Kreditkarteninformationen eingereicht.',
		expired_card: 'Die Karte ist abgelaufen.',
		fraudulent: 'Die Zahlung wurde abgelehnt, da Stripe den Verdacht hat, dass sie betrügerisch ist.',
		generic_decline: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		incorrect_number: 'Die Kartennummer ist falsch.',
		incorrect_cvc: 'Die CVC-Nummer ist falsch.',
		incorrect_pin: 'Die eingegebene PIN ist falsch. Dieser Ablehnungscode gilt nur für Zahlungen mit einem Kartenleser. ',
		incorrect_zip: 'Die Postleitzahl ist falsch.',
		insufficient_funds: 'Die Karte verfügt nicht über genügend Guthaben, um den Kauf abzuschließen.',
		invalid_account: 'Die Karte oder das Konto, mit dem die Karte verbunden ist, ist ungültig.',
		invalid_amount: 'Der Zahlungsbetrag ist ungültig oder übersteigt den zulässigen Betrag.',
		invalid_cvc: 'Die CVC-Nummer ist falsch.',
		invalid_expiry_month: 'Der Ablaufmonat ist ungültig.',
		invalid_expiry_year: 'Das Ablaufjahr ist ungültig.',
		invalid_number: 'Die Kartennummer ist falsch.',
		invalid_pin: 'Die eingegebene PIN ist falsch. Dieser Ablehnungscode gilt nur für Zahlungen mit einem Kartenleser. ',
		issuer_not_available: 'Der Kartenaussteller konnte nicht erreicht werden, daher konnte die Zahlung nicht autorisiert werden.',
		lost_card: 'Die Zahlung wurde abgelehnt, da die Karte als verloren gemeldet wird.',
		merchant_blacklist: 'Die Zahlung wurde abgelehnt, da sie mit einem Wert in der Sperrliste des Stripe-Benutzers übereinstimmt.',
		new_account_information_available: 'Die Karte oder das Konto, mit dem die Karte verbunden ist, ist ungültig.',
		no_action_taken: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		not_permitted: 'Die Zahlung ist nicht erlaubt.',
		offline_pin_required: 'Die Karte wurde abgelehnt, da eine PIN erforderlich ist.',
		online_or_offline_pin_required: 'Die Karte wurde abgelehnt, da eine PIN erforderlich ist.',
		pickup_card: 'Die Karte kann nicht für diese Zahlung verwendet werden (möglicherweise wurde sie als verloren oder gestohlen gemeldet).',
		pin_try_exceeded: 'Die zulässige Anzahl von PIN-Versuchen wurde überschritten.',
		processing_error: 'Bei der Verarbeitung der Karte ist ein Fehler aufgetreten.',
		reenter_transaction: 'Die Zahlung konnte vom Emittenten aus einem unbekannten Grund nicht verarbeitet werden.',
		restricted_card: 'Die Karte kann nicht für diese Zahlung verwendet werden (möglicherweise wurde sie als verloren oder gestohlen gemeldet).',
		revocation_of_all_authorizations: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		revocation_of_authorization: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		security_violation: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		service_not_allowed: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		stolen_card: 'Die Zahlung wurde abgelehnt, weil die Karte als gestohlen gemeldet wird.',
		stop_payment_order: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		testmode_decline: 'Es wurde eine Streifentestkartennummer verwendet.',
		transaction_not_allowed: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		try_again_later: 'Die Karte wurde aus einem unbekannten Grund abgelehnt.',
		withdrawal_count_limit_exceeded: 'Der Kunde hat das auf seiner Karte verfügbare Guthaben oder Kreditlimit überschritten.',
		unrecognized: 'Nicht erkannter Zahlungsfehler',
	},
	poweredByStripe: 'angetrieben durch Stripe',
	payByCard: 'Mit Kreditkarte bezahlen',
	invoiceNumber: 'Rechnungsnummer',
	amount: 'Summe',
	system: 'System',
	date: 'Datum'
};
