let initialState = {
	data: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
	case 'FETCH_NOTIFICATIONS':
		return { ...state, data: action.payload };

	case 'PUSH_NOTIFICATION':
		return { ...state, data: [...state.data, action.payload] };

	case 'UPDATE_NOTIFICATION': {
		let updatedNotifications = [...state.data];
		let index = updatedNotifications.findIndex(notification => notification._id === action.payload._id);
		let isActive = action.payload.isActive;

		if (isActive && index !== -1) {
			updatedNotifications[index] = action.payload;
		} else if (isActive && index === -1) {
			updatedNotifications.push(action.payload);
		} else {
			updatedNotifications.splice(index, 1);
		}

		return { ...state, data: updatedNotifications };
	}

	default:
		return state;
	}

}