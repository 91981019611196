import * as Yup from 'yup';

const generateValidationSchema = isExternal => {
	const shapedValidation = {
		description: Yup.string(),
		size: Yup.number().min(1).max(92).required('Укажите размер бокса'),
		lockId: Yup.string().length(7).required('Укажите номер замка'),
	};

	if (isExternal) {
		shapedValidation.size = Yup.number().min(1).max(92);
	}

	return Yup.object().shape(shapedValidation);
};

export default generateValidationSchema;